import {
    FSMNamedState as FSMState,
    ObservableFiniteStateMachine,
    OMGroup,
    OMObject,
    OMReference,
} from "firmament-node-sdk"

declare namespace FormStateMachine {

    export interface Presenting<T extends OMObject> {
        name: "presenting"
        item?: T
    }

    export interface Saving<T extends OMObject> {
        name: "saving"
        item: T
    }

    export type State<T extends OMObject> = FSMState.Start |
        FSMState.Loading |
        FSMState.ShowingError |
        Presenting<T> |
        Saving<T> |
        FSMState.Completed

}

class FormStateMachine<T extends OMObject, State extends FSMState>
    extends ObservableFiniteStateMachine<FormStateMachine.State<T> | State> {

    constructor() {
        super({ name: "start" })
    }

    public load = (ref?: OMReference<T>): void => {
        switch (this.state.name) {
            case "start":
                this.state = { name: "loading" }
                break
            default:
                return
        }

        if (!ref) {
            this.state = { name: "presenting" }
            return
        }

        if (ref.actualObject !== undefined) {
            this.success(ref.actualObject)
        } else {
            ref.load()
                .then((item: T) => this.success(item))
                .catch(this.fail)
        }

    }

    public success = (item: T): void => {
        switch (this.state.name) {
            case "start":
            case "loading":
            case "saving":
                this.state = { name: "presenting", item }
                break
            default:
                return
        }
    }

    public fail = (error: Error): void => {
        switch (this.state.name) {
            case "saving":
            case "presenting":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

    public save = (item: T) => {
        switch (this.state.name) {
            case "presenting":
            case "showingError":
                this.state = { name: "saving", item }
                break
            default:
                return
        }

        item.save()
            .then(this.dismiss)
            .catch(this.fail)
    }

    public dismiss = () => {
        switch (this.state.name) {
            case "presenting":
            case "saving":
                this.state = { name: "completed" }
                break
            default:
                break
        }
    }

}

export default FormStateMachine
