//  NotifBroadcast.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2019-08-02
//  Copyright © 2019 Apptivity Lab. All rights reserved.
//

import {
    Command,
    CommandManager,
    DomainObject,
    DomainObjectProperties,
    OMGroup as Group,
    OMReference,
} from "firmament-node-sdk"

import {
    NotifMessage,
    Profile,
} from ".."

export interface NotifBroadcastProperties extends DomainObjectProperties {
    email?: string[]
    smsPhoneNumber?: string[]
    pushEndpointArn?: string[]
    pushTopicArn?: string
    deliverEmail?: boolean
    deliverSms?: boolean
    deliverPush?: boolean
    actionUrl?: string
    bodyHtml?: string
    body: string

    targetProfile?: OMReference<Profile>
    messages?: Array<OMReference<NotifMessage>>
    targetGroup?: OMReference<Group>
}

class NotifBroadcast extends DomainObject implements NotifBroadcastProperties {

    public static Type: string = "notif_broadcast"
    public static Path: string = "notif_broadcasts"

    protected static SendCommand = class SendCommand extends Command<NotifBroadcast> {
        public static readonly Path = "send"
    }

    public email?: string[]
    public smsPhoneNumber?: string[]
    public pushEndpointArn?: string[]
    public pushTopicArn?: string
    public deliverEmail: boolean
    public deliverSms: boolean
    public deliverPush: boolean
    public actionUrl?: string
    public bodyHtml?: string
    public body: string

    public targetProfile?: OMReference<Profile>
    public messages: Array<OMReference<NotifMessage>>
    public targetGroup?: OMReference<Group>

    constructor(object: NotifBroadcastProperties) {
        super(object)

        this.email = object.email
        this.smsPhoneNumber = object.smsPhoneNumber
        this.pushEndpointArn = object.pushEndpointArn
        this.pushTopicArn = object.pushTopicArn
        this.deliverEmail = object.deliverEmail || false
        this.deliverSms = object.deliverSms || false
        this.deliverPush = object.deliverPush || true
        this.actionUrl = object.actionUrl
        this.bodyHtml = object.bodyHtml
        this.body = object.body

        this.targetProfile = object.targetProfile && new OMReference(Profile, object.targetProfile)
        this.messages = object.messages ? object.messages.map((item) => new OMReference(NotifMessage, item)) : []
        this.targetGroup = object.targetGroup && new OMReference(Group, object.targetGroup)
    }

    public send() {
        try {
            const command = new NotifBroadcast.SendCommand(this)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default NotifBroadcast
