//  SchTopupPackageCard.tsx
//
//  Opera House | Typescript - Details Card
//  Updated 2020-05-20
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import { OMReference } from "firmament-node-sdk"
import * as React from "react"

import {
    DetailColumn,
    FileRenderer,
    Body,
    Caption1,
} from "../../../../components"

import { SchTopupPackage } from "../../../../domain"

import bg_top_up_option_1 from "../../../../assets/bg_top_up_option_1.png"
import bg_top_up_option_2 from "../../../../assets/bg_top_up_option_2.png"
import bg_top_up_option_best_value from "../../../../assets/bg_top_up_option_best_value.png"
import ic_graphic_top_up_option_1 from "../../../../assets/ic_graphic_top_up_option_1.png"
import ic_graphic_top_up_option_2 from "../../../../assets/ic_graphic_top_up_option_2.png"
import ic_graphic_top_up_option_best_value from "../../../../assets/ic_graphic_top_up_option_best_value.png"
import ic_top_up_best_value_front from "../../../../assets/ic_top_up_best_value_front@3x.png"
import ic_top_up_best_value_tag_back from "../../../../assets/ic_top_up_best_value_tag_back@3x.png"

export interface SchTopupPackageCardComponentProps {
    schTopupPackage: SchTopupPackage
    onClick?: (schTopupPackage: SchTopupPackage) => void
}

export interface SchTopupPackageCardComponentState {

}

class SchTopupPackageCard extends React.Component<SchTopupPackageCardComponentProps, SchTopupPackageCardComponentState> {
    constructor(props: SchTopupPackageCardComponentProps) {
        super(props)
        this.state = {}
    }

    public render() {
        const backgroundSrc = (() => {
            switch (true) {
                case !!(this.props.schTopupPackage.backgroundImage && this.props.schTopupPackage.backgroundImage.actualObject && this.props.schTopupPackage.backgroundImage.actualObject.url):
                    return this.props.schTopupPackage.backgroundImage!.actualObject!.url
                case this.props.schTopupPackage.title && this.props.schTopupPackage.title.includes("Honey Sticks Pack"):
                    return bg_top_up_option_1
                case this.props.schTopupPackage.title && this.props.schTopupPackage.title.includes("Honey Comb Pack"):
                    return bg_top_up_option_2
                case this.props.schTopupPackage.title && this.props.schTopupPackage.title.includes("Royal Jelly Pack"):
                    return bg_top_up_option_best_value

                default:
                    return bg_top_up_option_1
            }
        })()
        const src = (() => {
            switch (true) {
                case !!(this.props.schTopupPackage.icon && this.props.schTopupPackage.icon.actualObject && this.props.schTopupPackage.icon.actualObject.url):
                    return this.props.schTopupPackage.icon!.actualObject!.url
                case this.props.schTopupPackage.title && this.props.schTopupPackage.title.includes("Honey Sticks Pack"):
                    return ic_graphic_top_up_option_1
                case this.props.schTopupPackage.title && this.props.schTopupPackage.title.includes("Honey Comb Pack"):
                    return ic_graphic_top_up_option_2
                case this.props.schTopupPackage.title && this.props.schTopupPackage.title.includes("Royal Jelly Pack"):
                    return ic_graphic_top_up_option_best_value

                default:
                    return ic_graphic_top_up_option_1
            }
        })()
        return (
            <div className="my-4">
                <div className="w-100 m-auto" style={{ position: "relative", cursor: "pointer", maxWidth: 500, color: this.props.schTopupPackage.textColor || "#fff" }} onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick(this.props.schTopupPackage)
                    }
                }}>
                    <img className="w-100" src={backgroundSrc} />

                    {this.props.schTopupPackage.promoText && (
                        <div style={{ position: "absolute", objectFit: "cover", top: "-10pt", left: "-4pt", height: "29pt" }}>
                            <img className="d-inline-block h-100" style={{}} src={ic_top_up_best_value_tag_back} />
                            <div className="d-inline-block text-white px-1" style={{ backgroundColor: "#ff7850", height: "32.87px", transform: "translate(0, -7px)" }}>
                                <span className="h-100 position-relative" style={{ top: "3pt" }}>
                                    {this.props.schTopupPackage.promoText}
                                </span>
                            </div>
                            <img className="d-inline-block h-100" style={{}} src={ic_top_up_best_value_front} />
                        </div>
                    )}

                    <img style={{ position: "absolute", objectFit: "cover", bottom: 0, height: "105%", right: "2.5rem" }} src={src} />

                    {this.props.onClick && (
                        <div className="position-absolute d-flex align-items-center justify-content-center" style={{ right: 0, top: 0, bottom: 0, width: 50 }}>
                            <span className="fa fa-chevron-right text-white"></span>
                        </div>
                    )}

                    <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", left: 0, bottom: 0, top: "1rem" }}>
                        <div className="text-reset container ml-2">
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="text-reset">{this.props.schTopupPackage.title}</h5>
                                    {this.props.schTopupPackage.limitPerUser && <Caption1 className="text-white" style={{ opacity: 0.8 }}>Limited to {this.props.schTopupPackage.limitPerUser} per Student</Caption1>}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-2">
                                    <h3 className="text-reset text-right font-weight-bold">{((this.props.schTopupPackage.baseSessions + this.props.schTopupPackage.bonusSessions || 0) / 100).toLocaleString(undefined, { maximumFractionDigits: 0 })}</h3>
                                </div>
                                <div className="col-10">
                                    <small>Tokens for</small>
                                    {this.props.schTopupPackage.finalPriceCents !== this.props.schTopupPackage.originalPriceCents
                                        ? (
                                            <div>
                                                <h6 className="text-reset d-inline text-nowrap">
                                                    <s>{this.props.schTopupPackage.currency} {(this.props.schTopupPackage.originalPriceCents / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</s>
                                                </h6>
                                                <h5 className="text-reset ml-1 d-inline text-nowrap font-weight-bold">
                                                    {this.props.schTopupPackage.currency} {(this.props.schTopupPackage.finalPriceCents / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                                </h5>
                                            </div>
                                        )
                                        : (
                                            <h6 className="text-reset">
                                                {this.props.schTopupPackage.currency} {(this.props.schTopupPackage.finalPriceCents / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                            </h6>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SchTopupPackageCard
