import { Moment } from "moment"
import moment from "moment"
import React from "react"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

import { DatePickerComponentProps } from "./DatePicker"
import Icon from "./Icon"

interface DateRangePickerComponentProps {
    onChange?: (dateFrom?: Moment, dateTo?: Moment) => void
    disabled?: boolean

    minDate?: Moment

    dateFrom?: Moment
    dateTo?: Moment

    showTimeSelect?: boolean

    filterDate?: DatePickerComponentProps["filterDate"]
}

interface DateRangePickerComponentState {
    dateFrom?: Moment
    dateTo?: Moment
}

class DateRangePicker extends React.Component<DateRangePickerComponentProps, DateRangePickerComponentState> {

    public static onDateRangeChange(
        this: React.Component<any, { queryParams: { startAt?: Moment, endAt?: Moment, currentPage?: number } }>,
        startAt?: Moment,
        endAt?: Moment) {
        this.setState({
            queryParams: {
                ...this.state.queryParams,
                currentPage: 0,
                endAt: endAt && endAt.clone().endOf("day"),
                startAt,
            },
        })
    }

    public state: DateRangePickerComponentState = {}

    constructor(props: DateRangePickerComponentProps) {
        super(props)

        this.state = {
            dateFrom: this.props.dateFrom,
            dateTo: this.props.dateTo,
        }
    }

    public componentDidUpdate(prevProps: DateRangePickerComponentProps, prevState: DateRangePickerComponentState) {
        // if (this.props.onChange) {
        //     if (
        //         this.state.dateFrom !== prevState.dateFrom ||
        //         this.state.dateTo !== prevState.dateTo
        //     ) {
        //         this.props.onChange(this.state.dateFrom, this.state.dateTo)
        //     }
        // }

        // Diff value, not reference
        if ((this.props.dateTo && this.props.dateTo.unix() !== (prevProps.dateTo && prevProps.dateTo.unix()))) {
            this.setState({
                dateTo: this.props.dateTo,
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.dateFrom, this.state.dateTo)
                }
            })
        }

        // Diff value, not reference
        if ((this.props.dateFrom && this.props.dateFrom.unix()) !== (prevProps.dateFrom && prevProps.dateFrom.unix())) {
            this.setState({
                dateFrom: this.props.dateFrom,
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.dateFrom, this.state.dateTo)
                }
            })
        }
    }

    public render() {
        return (
            <div className="d-flex">
                <div style={{ maxWidth: 420 }} className="mr-2">
                    <DatePicker
                        timeIntervals={15}
                        showTimeSelect={this.props.showTimeSelect}
                        className="form-control"
                        isClearable={true}
                        placeholderText="From..."
                        disabled={this.props.disabled}
                        dateFormat={this.props.showTimeSelect ? "dd/MM/yyyy, HH:mm" : "dd/MM/yyyy"}
                        popperPlacement="top-start"

                        minDate={this.props.minDate ? this.props.minDate.toDate() : undefined}
                        // maxDate={this.state.dateTo ? this.state.dateTo.toDate() : undefined}
                        onChange={this.onDateChange("dateFrom")}

                        selectsStart={true}
                        selected={this.state.dateFrom ? this.state.dateFrom.toDate() : undefined}
                        startDate={this.state.dateFrom ? this.state.dateFrom.toDate() : undefined}

                        timeFormat={"HH:mm"}

                        autoComplete="off"

                        // // tslint:disable-next-line
                        // onFocus={(e) => e.target.blur()}

                        filterDate={this.props.filterDate}
                    />
                </div>

                <div style={{ maxWidth: 420 }} className="">
                    <DatePicker
                        timeIntervals={15}
                        showTimeSelect={this.props.showTimeSelect}
                        className="form-control"
                        isClearable={true}
                        placeholderText="To..."
                        disabled={this.props.disabled}
                        dateFormat={this.props.showTimeSelect ? "dd/MM/yyyy, HH:mm" : "dd/MM/yyyy"}
                        popperPlacement="top-end"

                        selected={this.state.dateTo ? this.state.dateTo.toDate() : undefined}
                        minDate={this.state.dateFrom ? this.state.dateFrom.toDate() : this.props.minDate ? this.props.minDate.toDate() : undefined}
                        onChange={this.onDateChange("dateTo")}

                        selectsEnd={true}
                        startDate={this.state.dateFrom ? this.state.dateFrom.toDate() : undefined}
                        endDate={this.state.dateTo ? this.state.dateTo.toDate() : undefined}

                        timeFormat={"HH:mm"}

                        autoComplete="off"

                        filterDate={this.props.filterDate}

                    // // tslint:disable-next-line
                    // onFocus={(e) => e.target.blur()}
                    />
                </div>
            </div>
        )
    }

    private onDateChange = (keyPath: keyof DateRangePickerComponentState) =>
        (date: Date | null): void => {
            this.setState({
                [keyPath]: date ? moment(date) : undefined,
            }, () => {
                if (this.state.dateFrom && this.state.dateTo && this.state.dateTo.isBefore(this.state.dateFrom)) {
                    this.setState({ dateTo: undefined }, () => {
                        if (this.props.onChange) {
                            this.props.onChange(this.state.dateFrom, this.state.dateTo)
                        }
                    })
                } else if (this.props.onChange) {
                    this.props.onChange(this.state.dateFrom, this.state.dateTo)
                }
            })
        }
}

export default DateRangePicker
