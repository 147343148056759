// Data imports
import {
    FSMNamedState as FSMState,
    OMReference,
} from "firmament-node-sdk"
import { CnxRating } from "../../../domain"

// Feature imports
import { FormStateMachine } from "../../../components"

declare namespace CnxRatingFormStateMachine {

    export interface Presenting {
        name: "presenting"
        item?: CnxRating
    }

    export interface Saving {
        name: "saving"
        item: CnxRating
    }

    export interface ShowingError extends FSMState.ShowingError {
        item?: CnxRating
    }

    export type State = FSMState.Start |
        FSMState.Loading |
        ShowingError |
        Presenting |
        Saving |
        FSMState.Completed

}

class CnxRatingFormStateMachine
    extends FormStateMachine<CnxRating, CnxRatingFormStateMachine.State> {

    /* SECTION: Override */
    public load = (ref?: OMReference<CnxRating>): void => {
        switch (this.state.name) {
            case "start":
                this.state = { name: "loading" }
                break
            default:
                return
        }

        if (!ref) {
            this.state = { name: "presenting" }
            return
        }

        if (ref.actualObject !== undefined) {
            this.success(ref.actualObject)
        } else {
            ref.load()
                .then((item: CnxRating) => this.success(item))
                .catch(this.fail)
        }
    }

    public save = (item: CnxRating) => {
        switch (this.state.name) {
            case "presenting":
            case "showingError":
                this.state = { name: "saving", item }
                break
            default:
                return
        }

        item.save()
            .then(() => this.dismiss())
            .catch((error) => this.fail(error))
    }
}

export default CnxRatingFormStateMachine
