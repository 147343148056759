import { OMObject, OMQuery, OMReference } from "firmament-node-sdk"
import React from "react"

import AsyncSelectHeader from "./AsyncSelectHeader"

interface AsyncEntitySelectHeaderComponentProps<T extends OMObject> {
    placeholder?: string
    label: string
    labelField: keyof T
    labelFieldCallback?: (obj: T) => any
    query: OMQuery<T>

    objectType: { new(...arg: any): T }
    value?: OMReference<T>

    disabled?: boolean

    onSelectChange(ref?: OMReference<T>): void

    required?: boolean
}

class AsyncEntitySelectHeader<T extends OMObject>
    extends React.Component<AsyncEntitySelectHeaderComponentProps<T>> {

    public render() {
        const { value, ...props } = this.props

        const valueProps = (this.props.value && this.props.value.id && this.props.value.actualObject && (this.props.labelFieldCallback && this.props.labelFieldCallback(this.props.value.actualObject) || this.props.value.actualObject[this.props.labelField])) ||
            (this.props.value && this.props.value.id)

        return (
            <AsyncSelectHeader
                {...props}
                value={valueProps}
                onSelectChange={this.onSelectChange}
                valueField={"objectUuid"}
            />
        )
    }

    private onSelectChange = (value: { label: any, value: any }): void => {
        const reference = value ? new OMReference(this.props.objectType, value.value) : undefined
        this.props.onSelectChange(reference)
    }

}

export default AsyncEntitySelectHeader
