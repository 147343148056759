//  CnxPayoutAccount.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxProviderProfile,
} from ".."

export interface CnxPayoutAccountProperties extends DomainObjectProperties {
    accountHolderName?: string
    accountNumber?: string
    bankName?: string
    contactNumber?: string

    provider: OMReference<CnxProviderProfile>
}

class CnxPayoutAccount extends DomainObject implements CnxPayoutAccountProperties {

    public static Type: string = "cnx_payout_account"
    public static Path: string = "cnx_payout_accounts"

    public accountHolderName?: string
    public accountNumber?: string
    public bankName?: string
    public contactNumber?: string

    public provider: OMReference<CnxProviderProfile>

    constructor(object: CnxPayoutAccountProperties) {
        super(object)

        this.accountHolderName = object.accountHolderName
        this.accountNumber = object.accountNumber
        this.bankName = object.bankName
        this.contactNumber = object.contactNumber

        this.provider = new OMReference(CnxProviderProfile, object.provider)
    }
}

export default CnxPayoutAccount
