//  Group.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2019-09-05
//  Copyright © 2019 Apptivity Lab. All rights reserved.
//

import {
    Command,
    CommandManager,
    OMGroup,
    OMGroupProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import { Moment } from "moment"
import moment from "moment"
import {
    Party,
    PartyProperties,
    PushChannel,
} from ".."

export interface GroupProperties extends OMGroupProperties {

    pushChannel?: OMReference<PushChannel>
}

class Group extends OMGroup implements GroupProperties {

    public static Type: string = "group"
    public static Path: string = "groups"

    public pushChannel?: OMReference<PushChannel>

    constructor(object: GroupProperties) {
        super(object)

        this.pushChannel = object.pushChannel && new OMReference(PushChannel, object.pushChannel)
    }

}

export default Group
