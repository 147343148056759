import { OMObject } from "firmament-node-sdk"
import React from "react"
import { SortableContainer, SortableElement, SortableHandle, SortableContainerProps } from "react-sortable-hoc"
import "react-virtualized/styles.css"

import {
    AutoSizer,
    defaultTableRowRenderer,
    Table,
    TableProps,
    TableRowRenderer,
    Column,
} from "react-virtualized"
import "../../assets/table.css"
import ListStateMachine from "./ListStateMachine"

const SortableTable = SortableContainer<TableProps>(Table as any)
const SortableTableRowRenderer = SortableElement<TableRowRenderer>(defaultTableRowRenderer as any)
const DragHandle = SortableHandle(() => <span className="draghandle">:::</span>)

interface ModelTableComponentProps<T extends OMObject> extends Omit<TableProps, "rowHeight">, SortableContainerProps {
    noBorder?: boolean
    dragHandleWidth?: number
    queryParams?: ListStateMachine.QueryParams<T>
    items: T[]
    headerHeight?: number

    onRowClick?: TableProps["onRowClick"]
    rowHeight?: number
}

class ModelTable<T extends OMObject> extends React.Component<ModelTableComponentProps<T>> {

    static defaultProps = {
        dragHandleWidth: 0,
        rowHeight: 50,
        noBorder: false,
    }

    public static onSortChange<T extends OMObject>(
        this: React.Component<any, { queryParams: ListStateMachine.QueryParams<T> }>,
        sort: ListStateMachine.Sort<T>,
    ) {
        this.setState({
            queryParams: {
                ...this.state.queryParams,
                ...sort,
            },
        })
    }

    public static updateQueryParams<T extends OMObject, K extends keyof (ListStateMachine.QueryParams<T> & Partial<T>)>(
        this: React.Component<any, { queryParams: ListStateMachine.QueryParams<T> & Partial<T> }>,
        keyPath: K,
    ) {
        return (val: (ListStateMachine.QueryParams<T> & Partial<T>)[K]) => {
            this.setState({
                queryParams: {
                    ...this.state.queryParams,
                    currentPage: 0,
                    [keyPath]: val,
                },
            })
        }
    }

    public render() {
        if (this.props.items.length <= 0) {
            return null
        }

        if (this.props.noBorder) {
            return (
                <AutoSizer disableHeight={true}>
                    {this.renderTable(this.props.items)}
                </AutoSizer>
            )
        } else {
            return (
                <div className="card my-3">
                    <div className="card-body" style={{ padding: 1 }}>
                        <AutoSizer disableHeight={true}>
                            {this.renderTable(this.props.items)}
                        </AutoSizer>
                    </div>
                </div>
            )
        }
    }

    private renderTable = (items: T[]) => ({ width }: { width: number }) => {
        const rowHeight = this.props.rowHeight || 50
        const actionColumnWidth = this.props.actionColumnWidth !== undefined ? this.props.actionColumnWidth : 0
        const widthReserved = this.props.dragHandleWidth + actionColumnWidth

        return (
            <SortableTable
                {...this.props}
                className="table"
                headerClassName="headerColumn"
                rowClassName={this.getRowClassName}
                width={width}
                height={Math.max(0, (rowHeight * items.length) + (this.props.disableHeader ? 0 : 70))}
                headerHeight={70}
                rowHeight={rowHeight}
                gridStyle={{ overflow: "visible" }}
                onRowClick={this.props.onRowClick}
                rowCount={items.length}
                rowGetter={this.rowGetter(items)}
                rowRenderer={this.rowRenderer}
                sort={this.sort as any}
                sortBy={this.props.queryParams && this.props.queryParams.sortBy as string}
                sortDirection={this.props.queryParams && this.props.queryParams.sortDirection}
                useDragHandle={true}
            >
                <Column /* Border */
                    label=""
                    dataKey=""
                    className="ReactVirtualized__Table__rowBorder"
                    cellRenderer={() => null}
                    disableSort={true}
                    width={0}
                />
                <Column
                    label=""
                    dataKey=""
                    // tslint:disable-next-line
                    cellRenderer={({ rowIndex }) => <DragHandle />}
                    disableSort={true}
                    width={this.props.dragHandleWidth || 0}
                />
                {(this.props.children as any)(width - widthReserved, widthReserved)}
            </SortableTable>
        )
    }

    private sort = (sort: ListStateMachine.Sort<T>): void => {
        if (this.props.onSortChange) {
            this.props.onSortChange(sort)
        }
    }

    private rowGetter = (items: T[]) => ({ index }: { index: number }) => {
        const item = items[index]

        // Per row data overrides go here
        return Object.assign({}, item, { index }, item && {

        })
    }

    private rowClick = ({ rowData }: { rowData: T }) => {
        // this.props.machine.showDetail(new OMReference(Setting, rowData.objectUuid))
    }

    private rowRenderer = (props: any) => {
        return <SortableTableRowRenderer {...props} />
    }

    private getRowClassName = ({ index }: { index: number }) => {
        if (index === -1) {
            return "headerRow"
        } else {
            return index % 2 ? "evenRow" : "oddRow"
        }
    }
}

export default ModelTable
