//  CnxMeeting.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-06-02
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
    CommandManager,
    Command,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import uuid from "uuid/v4"

import {
    CnxServiceTier,
    CnxBooking,
    CnxBid,
    CnxConsumerProfile,
    CnxMemberProfile,
    CnxMessage,
    CnxProviderProfile,
    CnxRating,
    CnxVideoRoom,
    CnxServiceCategory,
    CnxConsumerPurchase,
} from ".."
import CnxProviderEarning from "./CnxProviderEarning"

export interface CnxMeetingProperties extends DomainObjectProperties {
    maxBids: number
    minBids: number
    maxBookings: number
    minBookings: number
    actualEndAt?: Moment
    scheduledEndAt?: Moment
    actualStartAt?: Moment
    expireAt?: Moment
    scheduledStartAt?: Moment
    serviceTimingType: string
    serviceLeadType: string
    meetingStatus?: "PENDING" | "SCHEDULED" | "CONFIRMED" | "STARTED" | "FAILED" | "COMPLETED"

    serviceTier: OMReference<CnxServiceTier>
    bookings?: Array<OMReference<CnxBooking>>
    acceptedBid?: OMReference<CnxBid>
    bids?: Array<OMReference<CnxBid>>
    consumers?: Array<OMReference<CnxConsumerProfile>>
    currentParticipants?: Array<OMReference<CnxMemberProfile>>
    transcript?: Array<OMReference<CnxMessage>>
    provider?: OMReference<CnxProviderProfile>
    ratings?: Array<OMReference<CnxRating>>
    matchProviders?: Array<OMReference<CnxProviderProfile>>
    videoRoom?: OMReference<CnxVideoRoom>
    serviceCategories?: Array<OMReference<CnxServiceCategory>>
    purchases?: Array<OMReference<CnxConsumerPurchase>>
    earning?: OMReference<CnxProviderEarning>

    serviceCategorySet?: string[]
    matchProviderSet?: string[]
    matchProviderCount?: number
    ratingsAverage?: number
}

class CnxMeeting extends DomainObject implements CnxMeetingProperties {

    public static Type: string = "cnx_meeting"
    public static Path: string = "cnx_meetings"

    public maxBids: number
    public minBids: number
    public maxBookings: number
    public minBookings: number
    public actualEndAt?: Moment
    public scheduledEndAt?: Moment
    public actualStartAt?: Moment
    public expireAt?: Moment
    public scheduledStartAt?: Moment
    public serviceTimingType: string
    public serviceLeadType: string
    public meetingStatus?: "PENDING" | "SCHEDULED" | "CONFIRMED" | "STARTED" | "FAILED" | "COMPLETED"

    public serviceTier: OMReference<CnxServiceTier>
    public bookings: Array<OMReference<CnxBooking>>
    public acceptedBid?: OMReference<CnxBid>
    public bids: Array<OMReference<CnxBid>>
    public consumers: Array<OMReference<CnxConsumerProfile>>
    public currentParticipants: Array<OMReference<CnxMemberProfile>>
    public transcript: Array<OMReference<CnxMessage>>
    public provider?: OMReference<CnxProviderProfile>
    public ratings: Array<OMReference<CnxRating>>
    public matchProviders: Array<OMReference<CnxProviderProfile>>
    public videoRoom?: OMReference<CnxVideoRoom>
    public serviceCategories: Array<OMReference<CnxServiceCategory>>
    public purchases: Array<OMReference<CnxConsumerPurchase>>
    public earning?: OMReference<CnxProviderEarning>

    public serviceCategorySet: string[]
    public matchProviderSet: string[]
    public matchProviderCount: number
    public ratingsAverage?: number








    /* GetRooMTokenCommand */
    protected static GetRooMTokenCommand = class GetRooMTokenCommand extends Command<CnxMeeting> {
        public static readonly Path = "get_room_token"

        public constructor(
            receiver: CnxMeeting,
        ) {
            super(receiver)
        }
    }

    public getRoomToken() {
        try {
            const command = new CnxMeeting.GetRooMTokenCommand(this)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }







    /* SetCategoriesCommand */
    public static SetCategoriesCommand = class SetCategoriesCommand extends Command<CnxMeeting> {
        public static readonly Path = "set_service_categories"
        public static readonly Type = "cnx_meeting_set_service_categories_command"

        public constructor(
            receiver: CnxMeeting,
            public categories: OMReference<CnxServiceCategory>[],
        ) {
            super(receiver)
        }

        public execute(): Promise<any> {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            categories: this.categories.map((each) => each.id)
                        },
                    }
                },
                method: "POST",
                url,
            })
        }
    }

    public setCategories(categories: OMReference<CnxServiceCategory>[]) {
        try {
            const command = new CnxMeeting.SetCategoriesCommand(this, categories)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }







    /* SetMatchProvidersCommand */
    public static SetMatchProvidersCommand = class SetMatchProvidersCommand extends Command<CnxMeeting> {
        public static readonly Path = "set_match_providers"
        public static readonly Type = "cnx_meeting_set_match_providers_command"

        public constructor(
            receiver: CnxMeeting,
            public providers: OMReference<CnxProviderProfile>[],
        ) {
            super(receiver)
        }

        public execute(): Promise<any> {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            providers: this.providers.map((each) => each.id)
                        },
                    }
                },
                method: "POST",
                url,
            })
        }
    }

    public setMatchProviders(matchProviders: OMReference<CnxProviderProfile>[]) {
        try {
            const command = new CnxMeeting.SetMatchProvidersCommand(this, matchProviders)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }








    /* OfferCommand */
    protected static OfferCommand = class OfferCommand extends Command<CnxMeeting> {
        public static readonly Path = "offer"

        public constructor(
            receiver: CnxMeeting,
            public bid: OMReference<CnxBid>,
        ) {
            super(receiver)
        }
    }

    public offer(bid: OMReference<CnxBid>) {
        try {
            const command = new CnxMeeting.OfferCommand(this, bid)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }








    /* JoinCommand */
    public static JoinCommand = class JoinCommand extends Command<CnxMeeting> {
        public static readonly Path = "join"
        public static readonly Type = "cnx_meeting_join_command"

        public constructor(
            receiver: CnxMeeting,
            public profile: OMReference<CnxMemberProfile>,
        ) {
            super(receiver)
        }
    }

    public join(profile: OMReference<CnxMemberProfile>) {
        try {
            const command = new CnxMeeting.JoinCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }









    /* LeaveCommand */
    public static LeaveCommand = class LeaveCommand extends Command<CnxMeeting> {
        public static readonly Path = "leave"
        public static readonly Type = "cnx_meeting_leave_command"

        public constructor(
            receiver: CnxMeeting,
            public profile: OMReference<CnxMemberProfile>,
        ) {
            super(receiver)
        }
    }

    public leave(profile: OMReference<CnxMemberProfile>) {
        try {
            const command = new CnxMeeting.LeaveCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }






    /* AddConsumerCommand */
    protected static AddConsumerCommand = class AddConsumerCommand extends Command<CnxMeeting> {
        public static readonly Path = "add_consumer"
        public static readonly Type = "cnx_meeting_add_consumer_command"

        public constructor(
            receiver: CnxMeeting,
            public profile: OMReference<CnxMemberProfile>,
        ) {
            super(receiver)
        }
    }


    public addConsumer(profile: OMReference<CnxMemberProfile>) {
        try {
            const command = new CnxMeeting.AddConsumerCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }





    /* NotifyMatchProvidersCommand */
    protected static NotifyMatchProvidersCommand = class NotifyMatchProvidersCommand extends Command<CnxMeeting> {
        public static readonly Path = "notify_match_providers"

        public constructor(
            receiver: CnxMeeting,
            public providers: OMReference<CnxProviderProfile>[],
        ) {
            super(receiver)
        }

        public execute(): Promise<any> {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            providers: this.providers.map((each) => each.id)
                        },
                    }
                },
                method: "POST",
                url,
            })
        }

    }


    public notifyMatchProviders(providers: OMReference<CnxProviderProfile>[]) {
        try {
            const command = new CnxMeeting.NotifyMatchProvidersCommand(this, providers)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }






    constructor(object: CnxMeetingProperties) {
        super(object)

        this.maxBids = object.maxBids
        this.minBids = object.minBids
        this.maxBookings = object.maxBookings
        this.minBookings = object.minBookings
        this.actualEndAt = object.actualEndAt && moment(object.actualEndAt)
        this.scheduledEndAt = object.scheduledEndAt && moment(object.scheduledEndAt)
        this.actualStartAt = object.actualStartAt && moment(object.actualStartAt)
        this.expireAt = object.expireAt && moment(object.expireAt)
        this.scheduledStartAt = object.scheduledStartAt && moment(object.scheduledStartAt)
        this.serviceTimingType = object.serviceTimingType
        this.serviceLeadType = object.serviceLeadType
        this.meetingStatus = object.meetingStatus

        this.serviceTier = new OMReference(CnxServiceTier, object.serviceTier)
        this.bookings = object.bookings ? object.bookings.map((item) => new OMReference(CnxBooking, item)) : []
        this.acceptedBid = object.acceptedBid && new OMReference(CnxBid, object.acceptedBid)
        this.bids = object.bids ? object.bids.map((item) => new OMReference(CnxBid, item)) : []
        this.consumers = object.consumers ? object.consumers.map((item) => new OMReference(CnxConsumerProfile, item)) : []
        this.currentParticipants = object.currentParticipants ? object.currentParticipants.map((item) => new OMReference(CnxMemberProfile, item)) : []
        this.transcript = object.transcript ? object.transcript.map((item) => new OMReference(CnxMessage, item)) : []
        this.provider = object.provider && new OMReference(CnxProviderProfile, object.provider)
        this.ratings = object.ratings ? object.ratings.map((item) => new OMReference(CnxRating, item)) : []
        this.matchProviders = object.matchProviders ? object.matchProviders.map((item) => new OMReference(CnxProviderProfile, item)) : []
        this.videoRoom = object.videoRoom && new OMReference(CnxVideoRoom, object.videoRoom)
        this.serviceCategories = object.serviceCategories ? object.serviceCategories.map((item) => new OMReference(CnxServiceCategory, item)) : []
        this.purchases = object.purchases ? object.purchases.map((item) => new OMReference(CnxConsumerPurchase, item)) : []
        this.earning = object.earning && new OMReference(CnxProviderEarning, object.earning)

        if (this.serviceTier && this.serviceTier.actualObject && this.isInserted === false) {
            const serviceTier = this.serviceTier.actualObject

            this.scheduledStartAt = moment().add(serviceTier.preserviceMins, "minutes")
            this.scheduledEndAt = this.scheduledStartAt.clone().add(serviceTier.durationMins, "minutes")
            this.expireAt = this.scheduledStartAt.clone()

            this.maxBids = serviceTier.maxBids
            this.minBids = serviceTier.minBids
            this.maxBookings = serviceTier.maxBookings
            this.minBookings = serviceTier.minBookings

            this.serviceLeadType = serviceTier.serviceLeadType
            this.serviceTimingType = serviceTier.serviceTimingType
        }

        this.serviceCategorySet = object.serviceCategorySet || []
        this.matchProviderSet = object.matchProviderSet || []
        this.matchProviderCount = object.matchProviderCount || 0
        this.ratingsAverage = object.ratingsAverage && parseFloat(object.ratingsAverage as any)
    }


    public get isSafeToLeave(): boolean {
        if (this.serviceTier && !this.serviceTier.actualObject) {
            throw new Error("Please include serviceTier when loading the meeting object")
        }
        return this.scheduledEndAt?.clone().subtract(this.serviceTier.actualObject?.gracePeriodMins || 0, "minutes").isSameOrBefore() ?? false
    }
}

export default CnxMeeting
