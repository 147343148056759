import React from "react"
import ReactDOM from "react-dom"
import classnames from "classnames"
import { Fab } from "@material-ui/core"
import PhotoCamera from "@material-ui/icons/PhotoCamera"

import domToImage from "dom-to-image"

const blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return theBlob as File;
}

const ScreenshotButton: React.FunctionComponent<{ onScreenshotCreated: (file: File) => void }> = ({ onScreenshotCreated }) => {

    const [buttonDisabled, setButtonDisabled] = React.useState(false)
    const [dataURL, setDataURL] = React.useState<string>()
    const [animated, setAnimated] = React.useState(false)

    const onClick = async () => {
        if (buttonDisabled) {
            return
        }

        const $video = document.querySelector(".tvpm-fullscreen-single video") as HTMLVideoElement | undefined

        if (!$video) {
            return
        }

        const $canvas = document.createElement("canvas")
        $canvas.width = $video.videoWidth
        $canvas.height = $video.videoHeight
        const ctx = $canvas.getContext("2d")
        console.log($video.videoWidth, $video.videoHeight)
        ctx?.drawImage($video, 0, 0, $video.videoWidth, $video.videoHeight)

        const blob = await new Promise<Blob>((resolve) => $canvas.toBlob((blob) => resolve(blob!)))

        const dataURL = $canvas.toDataURL()
        setDataURL(dataURL)
        setTimeout(() => {
            setAnimated(true)
            setTimeout(() => {
                setDataURL(undefined)
                setAnimated(false)
            }, 3000);
        }, 500)

        const file = blobToFile(blob, "Scerenshot")

        onScreenshotCreated(file)

        setButtonDisabled(true)

        setTimeout(() => {
            setButtonDisabled(false)
        }, 5000)
    }

    return (
        <>
            <Fab color="primary" size="large" className="MuiFab-lg" onClick={() => onClick()}>
                <PhotoCamera />
            </Fab>

            {dataURL && ReactDOM.createPortal(<img className={classnames("screenshot", { "screenshot-animated": animated })} src={dataURL} alt="" />, document.querySelector("#root")!)}
        </>
    )
}

export default ScreenshotButton
