import React from "react"

import { ArticleParagraph } from "../../../../domain"

export interface ArticleParagraphCardComponentProps {
    articleParagraph: ArticleParagraph
}

export interface ArticleParagraphCardComponentState {

}

class ArticleParagraphCard
    extends React.Component<ArticleParagraphCardComponentProps, ArticleParagraphCardComponentState> {

    constructor(props: ArticleParagraphCardComponentProps) {
        super(props)
    }

    public render() {
        return (
            <div className="row">

                <p className="px-3">
                    {
                        this.props.articleParagraph.body && this.props.articleParagraph.body.split("\n").map((body) => (
                            <>
                                {body} <br />
                            </>
                        ))
                    }
                </p>

            </div>
        )
    }

}

export default ArticleParagraphCard
