import { Moment } from "moment"
import moment from "moment"
import React from "react"
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import "./DatePicker.css"
import isMobile from "../../../helpers/isMobile"

type Without<T, K> = Pick<T, Exclude<keyof T, K>>

export interface DatePickerComponentProps extends Without<ReactDatePickerProps, "onChange" | "value"> {
    onChange?: (date?: Moment) => void
    value?: Moment
}

export interface DatePickerComponentState {
    date?: Moment

    result?: any
}

const AddionInput: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = React.forwardRef((props, ref: any) => (
    <div className="input-group">
        <div className="input-group-prepend">
            <span className="input-group-text">
                <i className="fa fa-calendar-o " />
            </span>
        </div>

        <input
            {...props}
            placeholder="DD/MM/YYYY"
            type="text"
            className="form-control text-align-left"
            onClick={props.onClick}
            ref={ref}
        />
    </div>
))

class DatePicker extends React.Component<DatePickerComponentProps, DatePickerComponentState> {
    constructor(props: DatePickerComponentProps) {
        super(props)
        this.state = { date: this.props.value }
    }

    public componentDidUpdate(prevProps: DatePickerComponentProps, prevState: DatePickerComponentState) {
        if (this.props.onChange && this.state.date !== prevState.date) {
            this.props.onChange(this.state.date)
        }

        if (this.props.value !== prevProps.value && !this.state.date) {
            this.setState({ date: this.props.value })
        }

        if (this.props.value && prevProps.value && this.props.value.toString() !== prevProps.value.toString()) {
            this.setState({ date: this.props.value })
        }
    }

    public render() {
        const { value, onChange, ...props } = this.props

        if (isMobile) {
            return (
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className="fa fa-calendar-o " />
                        </span>
                    </div>
                    <input
                        required
                        type="date"
                        className="form-control text-align-left"
                        defaultValue={this.state.date?.format("YYYY-MM-DD")}
                        onChange={(event) => {
                            this.setState({ result: event.target.value })
                            const newDate = moment(event.target.value, "YYYY-MM-DD")
                            this.setState({ date: newDate.isValid() && newDate || undefined })
                        }}
                    />
                </div>
            )
        }

        return (
            <ReactDatePicker
                customInput={<AddionInput />}
                dateFormat="dd/MM/yyyy"
                {...props}
                isClearable={this.props.isClearable !== undefined ? this.props.isClearable : true}
                selected={this.state.date ? this.state.date.toDate() : undefined}
                className="form-control"
                onChange={this.onChange}

                autoComplete="off"
                showYearDropdown
            />
        )

    }

    private onChange = (date: Date | null) => {
        const newDate = date ? moment(date) : undefined

        this.setState({
            date: newDate,
        })
    }
}

export default DatePicker
