import React from "react"
import { withMachine } from "../../hoc"
import { ReferralStateMachine } from "."

import { ToastContainer } from "react-toastify"
import { Callout, ErrorAlert, Headline, ModalSpinner, Title2, Title3 } from "../../components"

import ic_graphic_referral_details_payout from "../../assets/ic_graphic_referral_details_payout@3x.png"
import bg_referral_platforms from "../../assets/bg_referral_platforms.png"
import bg_hero_v2 from "../../assets/bg_hero_v2.svg"
import { AppStateContext } from "../App/elements/AppStateProvider"
import { RouteComponentProps } from "react-router"

interface ReferralComponentProps extends RouteComponentProps {
    machine: ReferralStateMachine
}

class Referral extends React.Component<ReferralComponentProps> {

    componentDidMount() {
        this.props.machine.load()
    }

    render() {
        switch (this.props.machine.state.name) {
            case "presenting":
                const item = this.props.machine.state.item
                return (
                    <div className="container">

                        <img src={bg_hero_v2} className="position-absolute" style={{ left: 0, right: 0, top: -500, zIndex: -1 }} />

                        <div className="mt-16px" style={{ minHeight: 500 }}>

                            <div className="position-relative" style={{ top: 32, right: 0 }}>
                                <img className="position-absolute" src={bg_referral_platforms} width={749} style={{ right: 0, zIndex: -1 }} />
                            </div>

                            <Title2 className="d-block text-white">{item.name} sent you a referral code!</Title2>
                            <Title2 className="d-block text-white">Use this referral code during sign up to get your rewards.</Title2>

                            <Title3 className="d-block text-white mt-12px">Referral Code: {item.slug}</Title3>

                            <AppStateContext.Consumer>
                                {({ setReferrer }) => (
                                    <button className="btn btn-light mt-20px" onClick={() => {
                                        setReferrer(item.slug)
                                        this.props.history.push("/authentication/menu")
                                    }}>
                                        <Headline className="text-primary   " style={{ paddingLeft: 20, paddingRight: 20 }}>Sign Up on Web</Headline>
                                    </button>
                                )}
                            </AppStateContext.Consumer>
                        </div>

                        <div className="container">

                            <div className="row">
                                <div className="col-12 col-lg-6 mb-20px">
                                    <div className="card">
                                        <div className="card-body">
                                            <Headline className="d-block mb-20px text-secondary">Student Rewards</Headline>

                                            <div className="d-flex align-items-start">
                                                <img className="mr-20px" src={ic_graphic_referral_details_payout} width={90} />

                                                <div className="flex-grow-1">
                                                    <Headline className="d-block">Invite a Student</Headline>
                                                    <Callout className="d-block text-secondary">Both of you will receive 1 Free Bonus Session each.</Callout>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-start mt-20px">
                                                <img className="mr-20px" src={ic_graphic_referral_details_payout} width={90} />

                                                <div className="flex-grow-1">
                                                    <Headline className="d-block">Invite a Tutor</Headline>
                                                    <Callout className="d-block text-secondary">You will receive 1 Free Bonus Session. Tutor will receive 1 Bonus Session Payout.</Callout>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 mb-20px">
                                    <div className="card">
                                        <div className="card-body">
                                            <Headline className="d-block mb-20px text-secondary">Tutor Rewards</Headline>

                                            <div className="d-flex align-items-start">
                                                <img className="mr-20px" src={ic_graphic_referral_details_payout} width={90} />

                                                <div className="flex-grow-1">
                                                    <Headline className="d-block">Invite a Student</Headline>
                                                    <Callout className="d-block text-secondary">You will receive 1 Bonus Session Payout. Student will receive 1 Free Bonus Session.</Callout>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-start mt-20px">
                                                <img className="mr-20px" src={ic_graphic_referral_details_payout} width={90} />

                                                <div className="flex-grow-1">
                                                    <Headline className="d-block">Invite a Tutor</Headline>
                                                    <Callout className="d-block text-secondary">Both of you will receive 1 Bonus Session Payout.</Callout>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case "showingError":
                return (
                    <div className="container">
                        <ErrorAlert error={this.props.machine.state.error} />
                        <ToastContainer position="top-right" autoClose={5000} />
                    </div>
                )
            case "start":
            default:
                return <ModalSpinner />
        }
    }
}

export default withMachine(ReferralStateMachine)(Referral)
