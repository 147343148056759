//  CnxConsumerProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    Command,
    CommandManager,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxMemberProfile,
    CnxMemberProfileProperties,
    Article,
    Profile,
    ProfilePhoto,
    User,
    CnxMoneyWallet,
    CnxRating,
    CnxMessage,
    CnxSessionWallet,
    CnxArticle,
    CnxBooking,
    CnxProviderProfile,
    CnxMeeting,
    CnxServiceCategory,
    CnxServiceTier,
    CnxTopupPayment,
} from ".."
import uuid from "uuid/v4"

export interface CnxConsumerProfileProperties extends CnxMemberProfileProperties {

    sessionWallet?: OMReference<CnxSessionWallet>
    consumerArticle?: Array<OMReference<CnxArticle>>
    bookings?: Array<OMReference<CnxBooking>>
    favoriteProviders?: Array<OMReference<CnxProviderProfile>>
    meetings?: Array<OMReference<CnxMeeting>>
    categories?: Array<OMReference<CnxServiceCategory>>
    topupPayments?: Array<OMReference<CnxTopupPayment>>

    bookingsCount?: number
}

class CnxConsumerProfile extends CnxMemberProfile implements CnxConsumerProfileProperties {

    public static Type: string = "cnx_consumer_profile"
    public static Path: string = "cnx_consumer_profiles"


    public sessionWallet?: OMReference<CnxSessionWallet>
    public consumerArticle: Array<OMReference<CnxArticle>>
    public bookings: Array<OMReference<CnxBooking>>
    public favoriteProviders: Array<OMReference<CnxProviderProfile>>
    public meetings: Array<OMReference<CnxMeeting>>
    public categories: Array<OMReference<CnxServiceCategory>>
    public topupPayments: Array<OMReference<CnxTopupPayment>>

    public bookingsCount: number



    /* SetCategoriesCommand */

    protected static SetCategoriesCommand = class SetCategoriesCommand extends Command<CnxConsumerProfile> {
        public static readonly Path = "set_categories"

        public constructor(
            receiver: CnxConsumerProfile,
            public categories: OMReference<CnxServiceCategory>[],
        ) {
            super(receiver)
        }

        public execute(): Promise<any> {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            categories: this.categories.map((each) => each.id)
                        },
                    }
                },
                method: "POST",
                url,
            })
        }
    }

    public setCategories(categories: OMReference<CnxServiceCategory>[]) {
        try {
            const command = new CnxConsumerProfile.SetCategoriesCommand(this, categories)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }






    protected static SummarizeMeetingsCommand = class SummarizeMeetingsCommand extends Command<CnxConsumerProfile> {
        public static readonly Path = "summarize_meetings"

        public constructor(
            receiver: CnxConsumerProfile,
        ) {
            super(receiver)
        }
    }

    public summarizeMeeting() {
        try {
            const command = new CnxConsumerProfile.SummarizeMeetingsCommand(this)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }






    /* SetTierAccess */

    protected static SetTierAccess = class SetTierAccess extends Command<CnxConsumerProfile> {
        public static readonly Path = "set_tier_access"

        public constructor(
            receiver: CnxConsumerProfile,
            public serviceTiers: OMReference<CnxServiceTier>[],
        ) {
            super(receiver)
        }

        public execute(): Promise<any> {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            serviceTiers: this.serviceTiers.map((each) => each.id)
                        },
                    }
                },
                method: "POST",
                url,
            })
        }
    }






    constructor(object: CnxConsumerProfileProperties) {
        super(object)


        this.sessionWallet = object.sessionWallet && new OMReference(CnxSessionWallet, object.sessionWallet)
        this.consumerArticle = object.consumerArticle ? object.consumerArticle.map((item) => new OMReference(CnxArticle, item)) : []
        this.bookings = object.bookings ? object.bookings.map((item) => new OMReference(CnxBooking, item)) : []
        this.favoriteProviders = object.favoriteProviders ? object.favoriteProviders.map((item) => new OMReference(CnxProviderProfile, item)) : []
        this.meetings = object.meetings ? object.meetings.map((item) => new OMReference(CnxMeeting, item)) : []
        this.categories = object.categories ? object.categories.map((item) => new OMReference(CnxServiceCategory, item)) : []
        this.topupPayments = object.topupPayments ? object.topupPayments.map((item) => new OMReference(CnxTopupPayment, item)) : []

        this.bookingsCount = object.bookingsCount || 0
    }



    public get subject(): CnxServiceCategory | undefined {
        return this.categories.find((each) => each.actualObject?.categoryType === "SUBJECT")?.actualObject
    }
    public get grade(): CnxServiceCategory | undefined {
        return this.categories.find((each) => each.actualObject?.categoryType === "GRADE")?.actualObject
    }
}

export default CnxConsumerProfile
