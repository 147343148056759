//  SchTopupVoucher.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2019-08-15
//  Copyright © 2019 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
} from "firmament-node-sdk"
import {
    SchTopupPackage,
    SchTopupPackageProperties,
    SchVoucherRedemption,
} from "."


export interface SchTopupVoucherProperties extends SchTopupPackageProperties {
    voucherCode: string

    redemption?: Array<OMReference<SchVoucherRedemption>>
}

class SchTopupVoucher extends SchTopupPackage implements SchTopupVoucherProperties {

    public static Type: string = "sch_topup_voucher"
    public static Path: string = "sch_topup_vouchers"

    public voucherCode: string

    public redemption: Array<OMReference<SchVoucherRedemption>>

    constructor(object: SchTopupVoucherProperties) {
        super(object)

        this.voucherCode = object.voucherCode

        this.redemption = object.redemption ? object.redemption.map((item) => new OMReference(SchVoucherRedemption, item)) : []
    }
}

export default SchTopupVoucher
