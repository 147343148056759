import * as React from "react"
import { useLocation } from "react-router"
import qs from "qs"

import { CnxMemberProfile, CnxServiceCategory, SchTutorProfile } from "../../../domain"
import getProfile from "../../../helpers/Profile"

type TwilioVideoPresentationMode = "bubble" | "fullscreen.side-by-side" | "fullscreen.single"
type FacingMode = "environment" | "user"

interface AppStateContext {
    role: "STUDENT" | "TUTOR" | undefined
    setRole: Function

    participant?: CnxMemberProfile
    setParticipant: Function

    onboardingHasDocuments: boolean
    setOnboardingHasDocuments: Function

    referrer?: string
    setReferrer: Function

    audioEnabled: boolean
    toggleAudio: Function

    videoEnabled: boolean
    toggleVideo: Function

    currentModalID?: string
    setCurrentModalID: Function

    twilioTrackNameOnFocus?: string
    setTwilioTrackNameOnFocus: Function

    twilioVideoPresentationMode: TwilioVideoPresentationMode
    setTwilioVideoPresentationMode: Function
    cycleTwilioVideoPresentationMode: Function

    facingMode?: string
    toggleFacingMode: Function
}

export const AppStateContext = React.createContext<AppStateContext>(null as any)

export const useAppState = () => {
    const appStateContext = React.useContext(AppStateContext)
    return appStateContext
}

export const AppStateProvider: React.FunctionComponent = (props) => {
    const [role, setRole] = React.useState<"STUDENT" | "TUTOR" | undefined>(window.localStorage.getItem("role") as any || undefined)

    const [participant, setParticipant] = React.useState<CnxMemberProfile>()

    const [onboardingHasDocuments, setOnboardingHasDocuments] = React.useState((() => {
        const profile = getProfile()

        if (!(profile instanceof SchTutorProfile)) {
            return false
        }

        if (profile.certificates.length > 0) {
            return true
        }

        return false
    })())
    const [referrer, setReferrer] = React.useState(window.localStorage.getItem("referrer") || undefined)

    const [audioEnabled, setAudio] = React.useState(window.localStorage.getItem("meeting_audio_enabled") === null ? true : window.localStorage.getItem("meeting_audio_enabled") === "true") /* NOTE: default audio to true */
    const [videoEnabled, setVideo] = React.useState(window.localStorage.getItem("meeting_video_enabled") === null ? true : window.localStorage.getItem("meeting_video_enabled") === "true") /* NOTE: default audio to true */

    const [twilioVideoPresentationMode, _setTwilioVideoPresentationMode] = React.useState<TwilioVideoPresentationMode>("bubble")
    const [twilioTrackNameOnFocus, setTwilioTrackNameOnFocus] = React.useState<string>()

    const [facingMode, setFacingMode] = React.useState<FacingMode>()

    const setTwilioVideoPresentationMode = React.useCallback((mode: TwilioVideoPresentationMode) => {
        _setTwilioVideoPresentationMode("bubble")

        if (mode !== "bubble") {
            setTimeout(() => {
                _setTwilioVideoPresentationMode(mode)
            }, 200)
        }
    }, [twilioVideoPresentationMode])

    const cycleTwilioVideoPresentationMode = React.useCallback(() => {
        const modes: TwilioVideoPresentationMode[] = ["bubble", "fullscreen.side-by-side", "fullscreen.single"]
        const currentModeIndex = modes.indexOf(twilioVideoPresentationMode)

        const nextMode = modes[(currentModeIndex + 1) % modes.length]

        setTwilioVideoPresentationMode(nextMode)
    }, [twilioVideoPresentationMode, setTwilioVideoPresentationMode])

    const [currentModalID, setCurrentModalID] = React.useState<string>()

    React.useEffect(() => {
        if (role) {
            window.localStorage.setItem("role", role)
        } else {
            window.localStorage.removeItem("role")
        }
    }, [role])

    React.useEffect(() => {
        console.log("currentModalID", currentModalID)
    }, [currentModalID])

    React.useEffect(() => {
        if (window.localStorage.getItem("meeting_audio_enabled") === null) {
            window.localStorage.setItem("meeting_audio_enabled", "true")
        }
        if (window.localStorage.getItem("meeting_video_enabled") === null) {
            window.localStorage.setItem("meeting_video_enabled", "true")
        }
    }, [])

    React.useEffect(() => {
        if (referrer) {
            window.localStorage.setItem("referrer", referrer)
        } else {
            window.localStorage.removeItem("referrer")
        }
    }, [referrer])

    React.useEffect(() => {
        window.localStorage.setItem("meeting_audio_enabled", audioEnabled ? "true" : "false")
    }, [audioEnabled])

    React.useEffect(() => {
        window.localStorage.setItem("meeting_video_enabled", videoEnabled ? "true" : "false")
    }, [videoEnabled])

    const toggleAudio = React.useCallback(() => {
        setAudio((s) => !s)
    }, [])

    const toggleVideo = React.useCallback(() => {
        setVideo((s) => !s)
    }, [])

    const toggleFacingMode = React.useCallback(() => {
        console.log("toggleFacingMode")
        setFacingMode(facingMode !== "environment" ? "environment" : "user")
    }, [facingMode])

    return (
        <AppStateContext.Provider
            value={{
                role, setRole,

                participant, setParticipant,

                onboardingHasDocuments, setOnboardingHasDocuments,

                referrer, setReferrer,

                audioEnabled, toggleAudio,

                videoEnabled, toggleVideo,

                currentModalID, setCurrentModalID,
                twilioTrackNameOnFocus, setTwilioTrackNameOnFocus,

                twilioVideoPresentationMode, setTwilioVideoPresentationMode, cycleTwilioVideoPresentationMode,

                facingMode, toggleFacingMode
            }}
        >
            {props.children}
        </AppStateContext.Provider>
    )
}

