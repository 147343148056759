import React from "react"
import classnames from "classnames"

interface Props {
    value: string
    steps: string[]
}

const Wizard: React.FunctionComponent<Props> = ({ steps, value }) => {
    const currentStep = steps.findIndex((each) => each === value)
    return (
        <div className="position-relative">
            <div className="wizard">
                {steps.map((each, index) => (
                    <a className={classnames("wizard-step", { "wizard-step--complete": index < currentStep, "wizard-step--active": index === currentStep })}>
                        <div className="wizard-step__icon"><i className={classnames("fa fa-fw", { "fa-check": index < currentStep })}></i></div>
                        <div className={classnames("wizard-step__content font-weight-bold", { "text-dark": index === currentStep })}>{each}</div>
                    </a>
                ))}
            </div>
            {/* <div className="wizard-horizontal-line" /> */}
        </div>
    )
}

export default Wizard
