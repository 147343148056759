import React from "react"

const CheckboxWithValidation = (props) => {

    function onChange(event) {
        if (!props.onChange) {
            return
        }
        props.onChange(event.target.checked)
    }

    return (
        <div className="form-check">
            <input {...props} className="form-check-input" type="checkbox" onChange={onChange} />
            <label className="form-check-label" for={props.id}>
                {props.label}
            </label>
            <div className="invalid-tooltip">
                You must agree before submitting.
            </div>
        </div>
    )
}

export default CheckboxWithValidation