//  SchIntroVideo.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-11-12
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMFile as File,
    OMFileProperties as FileProperties,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    SchTutorProfile,
} from ".."

export interface SchIntroVideoProperties extends FileProperties {

    tutor: OMReference<SchTutorProfile>
}

class SchIntroVideo extends File implements SchIntroVideoProperties {

    public static Type: string = "sch_intro_video"
    public static Path: string = "sch_intro_videos"


    public tutor: OMReference<SchTutorProfile>

    constructor(object: SchIntroVideoProperties) {
        super(object)


        this.tutor = new OMReference(SchTutorProfile, object.tutor)
    }
}

export default SchIntroVideo
