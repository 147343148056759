// Data imports
import { FSMNamedState as FSMState, OMReference, DetailStateMachine, OMObject } from "firmament-node-sdk"
import { CnxVideoRoom } from "../../../domain"

// Feature imports
import { DetailsStateMachineWithDelete, DetailsStateMachine } from "../../../components"
import getProfile from "../../../helpers/Profile"

declare namespace CnxVideoRoomDetailsStateMachine {
    export interface ShowEdit extends FSMState {
        name: "showingEdit"
        item: CnxVideoRoom
    }

    export interface PresentingItem<T extends OMObject> extends FSMState.PresentingItem<T> {
        name: "presenting"
        item: T
        twilioToken: string
    }

    export type State = DetailsStateMachineWithDelete.State<CnxVideoRoom> |
        ShowEdit |
        PresentingItem<CnxVideoRoom>
}

class CnxVideoRoomDetailsStateMachine extends DetailsStateMachineWithDelete<CnxVideoRoom, CnxVideoRoomDetailsStateMachine.State> {

    /**
     * Show Edit Transition
     * [presenting] -- dismiss -> [showingEdit]
     */
    public showEdit = (item: CnxVideoRoom): void => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingEdit", item }
            default:
                return
        }
    }

    public success = (item: CnxVideoRoom, twilioToken?: string): void => {
        switch (this.state.name) {
            case "loading":
            case "promptingDelete":
                this.state = { name: "presenting", item, twilioToken: twilioToken || "" }
            default:
                return
        }
    }

    public load = async (id: OMReference<CnxVideoRoom>) => {
        switch (this.state.name) {
            case "start":
                this.state = { name: "loading" }
                break
            default:
                return
        }

        try {
            const result = await (async () => {
                if (id.actualObject !== undefined) {
                    return id.actualObject
                } else {
                    return id.load(CnxVideoRoom.query().include("meeting"))
                        .then((result: CnxVideoRoom) => result)
                }
            })()

            // Fetch twilio token
            const meeting = result.meeting!.actualObject!

            if (["FAILED", "COMPLETED"].includes(meeting.meetingStatus || "")) {
                this.success(result)
                return /* NOTE: guard condition */
            }



            const twilioToken = await meeting.getRoomToken().then((res) => res.data.data.attributes.output)
                .catch(async (error) => {
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.errors &&
                        error.response.data.errors[0] &&
                        error.response.data.errors[0].detail === "Unable to join meeting that has already ended."
                    ) {
                        await meeting.leave(getProfile() as any)
                    }
                    throw error
                })

            this.success(result, twilioToken)

        } catch (error) {
            this.fail(error)
        }

    }

}

export default CnxVideoRoomDetailsStateMachine
