import React from "react"
import ReactDOM from "react-dom"
import uuid from "uuid/v4"
import classnames from "classnames"
import { AppStateContext } from "../../../views/App/elements/AppStateProvider"
import AppStateProvider from "../../TwilioVideo/state"

import ModalBackdrop from "./ModalBackdrop"
import { FormatListNumberedOutlined } from "@material-ui/icons"

export interface ModalComponentProps {
    show: boolean
    onDismiss?: () => void
    style?: any
}

const Modal: React.FunctionComponent<ModalComponentProps> = (props) => {
    const id = React.useMemo(() => uuid(), [])
    const { currentModalID, setCurrentModalID } = React.useContext(AppStateContext)








    // SECTION: useState
    const [isAnimating, setIsAnimating] = React.useState(false)
    const [isDisplaying, setIsDisplaying] = React.useState(props.show)
    const [isShowing, setIsShowing] = React.useState(props.show)







    // SECTION: useEffect

    React.useEffect(() => {
        if (!props.show) {
            return
        }

        setCurrentModalID(id)
    }, [props.show])

    // React.useEffect(() => {
    //     if (!props.onDismiss) {
    //         return
    //     }
    //     if (isAnimating) {
    //         return
    //     }

    //     props.onDismiss()
    // }, [isAnimating])

    React.useEffect(() => {

        if (!props.show && currentModalID === id) {
            setCurrentModalID(undefined)
        }

        if (props.show && currentModalID === id) {
            setIsDisplaying(true)
            const timeout = setTimeout(() => {
                setIsShowing(true)
            }, 100);

            return () => clearTimeout(timeout)
        } else {
            setIsAnimating(true)
            setIsDisplaying(false)
            setIsShowing(false)

            const timeout = setTimeout(() => {
                setIsAnimating(false)
            }, 300);

            return () => clearTimeout(timeout)
        }

    }, [props.show, currentModalID])

    React.useEffect(() => {
        if (props.show) {
            setCurrentModalID(id)
        }
    }, [])

    React.useEffect(() => {
        // NOTE: unset currentModalID on unmount
        // if (currentModalID === id) {
        //     return () => setCurrentModalID(undefined)
        // }

        // NOTE: set currentModal to its own if there is no active currentModalID
        if (!currentModalID && props.show) {
            setCurrentModalID(id)
        }
    }, [currentModalID, props.show])







    // SECTION: handleOnDismissModal
    const handleOnDismissModal = React.useCallback((event: any) => {
        if (!props.onDismiss) {
            return
        }
        if (event.target.id !== id) {
            return
        }

        props.onDismiss()

        if (currentModalID === id) {
            setCurrentModalID(undefined)
        }
    }, [props.onDismiss, currentModalID])

    React.useEffect(() => {
        document.addEventListener("mousedown", handleOnDismissModal, false)
        return () => document.removeEventListener("mousedown", handleOnDismissModal, false)
    }, [])









    // SECTION: render props
    const style = (() => {
        if (!isDisplaying && !isAnimating) {
            return { display: "none" }
        } else {
            return { display: "block", paddingLeft: 0, overflow: "auto" }
        }
    })()







    // SECTION: render
    const child = props.children
        ? React.cloneElement(props.children as any, {
            dismiss: props.onDismiss || Function,
        })
        : null

    return ReactDOM.createPortal(
        <>
            <div
                id={id}
                className={classnames("modal fade", { show: isShowing })}
                tabIndex={-1}
                role="dialog"
                aria-labelledby={id}
                style={{ ...props.style, ...style}}
            >
                {child}
            </div>
            <ModalBackdrop show={isDisplaying} animating={isAnimating} />
        </>,
        document.querySelector("body")!
    )
}

export default Modal
