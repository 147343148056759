//  CnxRatingCard.tsx
//
//  Opera House | Typescript - Details Card
//  Updated 2020-05-13
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import { OMReference } from "firmament-node-sdk"
import * as React from "react"

import {
    DetailColumn,
    FileRenderer,
    Body,
    Caption1,
} from "../../../../components"

import { CnxRating, SchStudentProfile } from "../../../../domain"
import { StarRating } from "../../../../airframe-components"
import { Link } from "react-router-dom"

export interface CnxRatingCardComponentProps {
    cnxRating: CnxRating
}

export interface CnxRatingCardComponentState {

}

class CnxRatingCard extends React.Component<CnxRatingCardComponentProps, CnxRatingCardComponentState> {
    constructor(props: CnxRatingCardComponentProps) {
        super(props)
        this.state = {}
    }

    public render() {
        return (
            <div className="card">

                <div className="card-header">Rating Info</div>

                <div className="card-body">
                    <div className="container">
                        <div className="row">
                            <DetailColumn label="Created At" object={this.props.cnxRating} keyPath="createdAt" />
                            <div className="w-100" />
                            <DetailColumn label="Overall Rating" value={<StarRating at={Math.round(this.props.cnxRating.overall || 0)} max={5} />} />
                            <DetailColumn label="Review" object={this.props.cnxRating} keyPath="review" />
                            <div className="w-100" />
                            <DetailColumn className="col-12 col-md-6 mb-2 text-capitalize" label={this.props.cnxRating.subratingTypes && this.props.cnxRating.subratingTypes[0] || "Subrating 1"} value={<StarRating at={Math.round(this.props.cnxRating.subrating1 || 0)} max={5} />} />
                            <DetailColumn className="col-12 col-md-6 mb-2 text-capitalize" label={this.props.cnxRating.subratingTypes && this.props.cnxRating.subratingTypes[1] || "Subrating 2"} value={<StarRating at={Math.round(this.props.cnxRating.subrating2 || 0)} max={5} />} />
                            <DetailColumn className="col-12 col-md-6 mb-2 text-capitalize" label={this.props.cnxRating.subratingTypes && this.props.cnxRating.subratingTypes[2] || "Subrating 3"} value={<StarRating at={Math.round(this.props.cnxRating.subrating3 || 0)} max={5} />} />
                            <DetailColumn className="col-12 col-md-6 mb-2 text-capitalize" label={this.props.cnxRating.subratingTypes && this.props.cnxRating.subratingTypes[3] || "Subrating 4"} value={<StarRating at={Math.round(this.props.cnxRating.subrating4 || 0)} max={5} />} />
                            <DetailColumn className="col-12 col-md-6 mb-2 text-capitalize" label={this.props.cnxRating.subratingTypes && this.props.cnxRating.subratingTypes[4] || "Subrating 5"} value={<StarRating at={Math.round(this.props.cnxRating.subrating5 || 0)} max={5} />} />
                            <DetailColumn label="Source Type" object={this.props.cnxRating} keyPath="sourceType" />

                            <DetailColumn label="Submitter" value={(() => {
                                const submitter = this.props.cnxRating.submitter && this.props.cnxRating.submitter.actualObject
                                if (!submitter) {
                                    return "-"
                                }
                                return (
                                    <span onClick={(event) => event.stopPropagation()}>
                                        <i className="fa fa-fw fa-external-link mr-1" />
                                        {submitter.name}
                                    </span>
                                )
                            })()} />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default CnxRatingCard
