import React from 'react';
import PropTypes from 'prop-types';

import {
    Card,
    Media,
    Avatar,
    AvatarAddOn,
} from "../../airframe-components"
import ic_portrait_placeholder from "../../assets/ic_portrait_placeholder.png"
import { Body, Caption1 } from '../deprecated-components';

// import { randomArray, randomAvatar } from './../../../utilities';

function randomArray(arr) {
    const index = Math.round(Math.random() * (arr.length - 1));
    return arr[index];
}

const status = [
    "warning",
    "danger",
    "success",
    "secondary"
];

const ChatLeft = (props) => (
    <React.Fragment>
        <Media className="mb-6px">
            <Media left className="mr-8px mt-auto">
                <Avatar.Image
                    size="md"
                    src={props.coverImageSrc || ic_portrait_placeholder}
                    className="mr-2"
                />
            </Media>
            <Media body style={{ maxWidth: 500 }}>
                <div className="">
                    <Caption1 className="text-inverse mr-2 text-tertiary">
                        {props.name || "-"}
                    </Caption1>
                    <Caption1 className="text-tertiary">
                        {props.createdAt || "13-Jun-2015, 08:13"}
                    </Caption1>
                </div>
                <Card body className={ `mb-2 card-no-shadow ${ props.cardClassName }` } style={{ padding: "9px 12px" }}>
                    {props.imageSrc && (
                        <img onClick={() => props.onImageClick()} id={props.messageID} src={props.imageSrc} style={{ objectFit: "contain" }} className="d-block mw-100 clickable" alt="" />
                    )}
                    {props.body && (
                        <Body>
                            {props.isReply && <i className="fa fa-fw fa-mail-reply mr-1" />}
                            {props.body}
                        </Body>
                    )}
                </Card>
            </Media>
        </Media>
    </React.Fragment>
)
ChatLeft.propTypes = {
    cardClassName: PropTypes.node,
    body: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    coverImageSrc: PropTypes.string,
    imageSrc: PropTypes.string,
    isReply: PropTypes.bool,
    messageID: PropTypes.string,
    onImageClick: PropTypes.func
};
ChatLeft.defaultProps = {
    cardClassName: "bg-white"
};

export { ChatLeft };
