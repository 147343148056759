import React from "react"

import { Body, Caption1, Caption2 } from "./Typography"

interface DetailColumnComponentProps<T> {
    label: string,
    object?: T
    keyPath?: keyof T
    value?: any
    className?: string
    style?: React.CSSProperties
}

class DetailColumn<T> extends React.Component<DetailColumnComponentProps<T>> {
    public render() {

        let value: any

        if (this.props.object && !this.props.keyPath || !this.props.object && this.props.keyPath) {
            throw new Error("object and keyPath must be provided")
        }

        if (this.props.object && this.props.keyPath) {
            value = (this.props.object[this.props.keyPath] !== undefined && this.props.object[this.props.keyPath] !== null)
                ? (this.props.object[this.props.keyPath] as NonNullable<any>).toString()
                : "-"
        } else {
            if (this.props.value === undefined) {
                throw new Error("value must be defined")
            }

            value = this.props.value
        }

        return (
            <div className={this.props.className || "col-12 col-md-6 mb-2"} style={this.props.style}>
                <Caption2 className="d-block text-tertiary">{this.props.label}</Caption2>
                <Body>{value || "-"}</Body>
            </div>
        )
    }
}

export default DetailColumn
