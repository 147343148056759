import * as React from "react"

import { SchTutorProfile, SchStudentProfile, CnxProviderProfile } from "../../../domain"
import { RouteComponentProps, Redirect } from "react-router"
import { ModalSpinner } from "../../../components"
import { OMUniverse } from "firmament-node-sdk"

export interface ProfileRedirectorComponentProps extends RouteComponentProps<{ slug: string }> {

}

export interface ProfileRedirectorComponentState {
    profile?: SchTutorProfile | SchStudentProfile | undefined
    error?: any
}

class ProfileRedirector extends React.PureComponent<ProfileRedirectorComponentProps, ProfileRedirectorComponentState> {

    constructor(props: ProfileRedirectorComponentProps) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.loadProfile()
    }

    private loadProfile = async () => {
        this.setState({ error: undefined })

        if (!this.props.match.params.slug) {
            this.setState({ error: new Error() })
            return
        }

        const query = CnxProviderProfile.query().filter("slug", "equals", this.props.match.params.slug)
        await query.execute()
        const profiles = query.resultObjects

        if (profiles.length === 0) {
            this.setState({ error: new Error() })
            return
        }

        const profile = (() => {
            switch (profiles[0].type) {
                case SchTutorProfile.Type:
                    return OMUniverse.shared.find(SchTutorProfile, profiles[0].id)
                case SchStudentProfile.Type:
                    return OMUniverse.shared.find(SchStudentProfile, profiles[0].id)
            }
        })()

        this.setState({ profile })
    }

    public render() {
        if (this.state.error) {
            return <Redirect to="/" />
        }

        switch (this.state.profile && this.state.profile.constructor) {
            case SchTutorProfile:
                return <Redirect to={`/${SchTutorProfile.Path}/${this.state.profile?.id}${window.location.search}`} />
            case SchStudentProfile:
                return <Redirect to={`/${SchStudentProfile.Path}/${this.state.profile?.id}`} />
            default:
                return <ModalSpinner />
        }
    }

}

export default ProfileRedirector
