import cron from "cron-parser"
import moment, { Moment } from "moment"

import { Setting } from "../domain"

const todayOperatingHours = (): { startTime?: Moment, endTime?: Moment, isOperating: () => boolean, toString: () => string } => {
    // NOTE: default to 2-6pm every weekday
    const cronExpr = Setting.find("sch_operating_hours")?.value || "0 14-18 * * 1-5"

    const startTime = moment(
        cron.parseExpression(cronExpr, {
            currentDate: moment().startOf("day").toDate(),
        }).next().toISOString()
    )

    const endTime = moment(
        cron.parseExpression(cronExpr, {
            currentDate: moment().endOf("day").toDate(),
        }).prev().toISOString()
    )

    const isOperating = () => {
        if (endTime.isBefore(startTime)) {
            return false
        }

        return moment().isSameOrAfter(startTime) && moment().isBefore(endTime)
    }

    const toString = () => {
        const startTime = moment(
            cron.parseExpression(cronExpr, {
                currentDate: moment().startOf("week").toDate(),
            }).next().toISOString()
        )

        const endTime = moment(
            cron.parseExpression(cronExpr, {
                currentDate: moment().endOf("week").toDate(),
            }).prev().toISOString()
        )

        return `${startTime.format("ddd")} - ${endTime.format("ddd")}, ${startTime.format("H:mma")} - ${endTime.format("H:mma")}`
    }

    if (endTime.isBefore(startTime)) {
        return { isOperating, toString }
    }

    return { startTime, endTime, isOperating, toString }
}

export default todayOperatingHours
