import React from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import Draggable from "react-draggable"
import { useAppState } from '../../../../views/App/elements/AppStateProvider';
import isMobile from '../../../../helpers/isMobile';

export default function ParticipantStrip() {
    const {
        room: { localParticipant },
    } = useVideoContext();
    const participants = useParticipants();
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

    const { twilioVideoPresentationMode } = useAppState()

    const ref = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        if (twilioVideoPresentationMode !== "bubble" && ref.current) {
            const cachedTransform = ref.current.style.transform
            ref.current.style.transform = ""
            return () => {
                if (ref.current) {
                    ref.current.style.transform = cachedTransform
                }
            }
        }
    }, [twilioVideoPresentationMode])

    const videoContainer = (
        <div ref={ref} className="d-flex video-bubbles-container" style={{ pointerEvents: "all" }}>
            {participants.map(participant => (
                <Participant
                    key={participant.sid}
                    participant={participant}
                    isSelected={selectedParticipant === participant}
                />
            ))}
            <Participant
                participant={localParticipant}
                isSelected={selectedParticipant === localParticipant}
            />
        </div>
    )

    if (!isMobile) {
        return (
            <Draggable disabled={twilioVideoPresentationMode !== "bubble"} /* position={fullScreen ? { x: -20, y: -20 } : undefined} */>
                {videoContainer}
            </Draggable>
        )
    }

    return videoContainer
}
