import React from 'react';
import { Link } from 'react-router-dom';

import ic_web_app_logo from "../../../assets/ic_web_app_logo.png"

import {
    Sidebar,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from '../../../airframe-components';
import { OMUniverse } from 'firmament-node-sdk';
import getProfile from '../../../helpers/Profile';
import { SchTutorProfile, SchStudentProfile } from '../../../domain';

const SidebarTopA = () => {

    function profile() {
        if (!OMUniverse.shared.user) {
            return
        }

        const profile = getProfile()

        return profile
    }

    const identityTypeUserpass = OMUniverse.shared.user.identities.find((identity) => identity.actualObject.identityType === "userpass")
    const identityTypeSocial = OMUniverse.shared.user.identities.find((identity) => identity.actualObject.identityType === "social")

    return (
        <React.Fragment>
            <Sidebar.HideSlim>
                <Sidebar.Section className="pt-0">

                    <Link className="d-flex justify-content-center align-items-center" to="/">
                        <img src={ic_web_app_logo} alt="ic_logo_nav" className="d-block mb-4" style={{ width: 150 }} />
                    </Link>

                    <UncontrolledButtonDropdown>
                        {/* <DropdownToggle color="link" className="pl-0 pb-0 btn-profile sidebar__link"> */}
                            <span style={{ maxWidth: "calc(200px - 17px)", display: "inline-block", textOverflow: "ellipsis", overflow: "hidden" }}>
                                {(getProfile() && getProfile().name) || identityTypeUserpass && identityTypeUserpass.actualObject.identifier}
                            </span>
                            {/* <i className="fa fa-angle-down ml-2 align-text-top"></i>
                        </DropdownToggle> */}

                        {/* <DropdownMenu persist>
                            {(() => {
                                switch (true) {
                                    case getProfile() && getProfile().type === SchTutorProfile.Type:
                                        return (
                                            <DropdownItem tag={Link} to={`/${SchTutorProfile.Path}/${getProfile().id}`}>
                                                Profile Details
                                            </DropdownItem>
                                        )
                                    case getProfile() && getProfile().type === SchStudentProfile.Type:
                                        return (
                                            <DropdownItem tag={Link} to={`/${SchStudentProfile.Path}/${getProfile().id}`}>
                                                Profile Details
                                            </DropdownItem>
                                        )
                                    default:
                                        return null
                                }
                            })()}

                            {identityTypeUserpass && (
                                <DropdownItem tag={Link} to={`/identities/${OMUniverse.shared.user.identities.find((identity) => identity.actualObject.identityType === "userpass").actualObject.identifier}/edit`}>
                                    Change Password
                                </DropdownItem>
                            )}

                            <DropdownItem divider />

                            <DropdownItem tag={"button"} onClick={() => OMUniverse.shared.logout().then(() => window.location.reload()).catch(() => window.location.reload())}>
                                <i className="fa fa-fw fa-sign-out mr-2"></i>
                                Sign Out
                            </DropdownItem>

                        </DropdownMenu> */}
                    </UncontrolledButtonDropdown>
                </Sidebar.Section>
            </Sidebar.HideSlim>
        </React.Fragment>
    )
}

export { SidebarTopA };
