//  CnxVideoRoom.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxMeeting,
    CnxSnapshotPhoto,
} from ".."

export interface CnxVideoRoomProperties extends DomainObjectProperties {
    providerRecordingUrl?: string
    consumerRecordingUrls?: string[]
    roomStatus?: string
    sid?: string
    sizeType?: string

    meeting: OMReference<CnxMeeting>
    snapshots?: Array<OMReference<CnxSnapshotPhoto>>
}

class CnxVideoRoom extends DomainObject implements CnxVideoRoomProperties {

    public static Type: string = "cnx_video_room"
    public static Path: string = "cnx_video_rooms"

    public providerRecordingUrl?: string
    public consumerRecordingUrls?: string[]
    public roomStatus: string
    public sid?: string
    public sizeType?: string

    public meeting: OMReference<CnxMeeting>
    public snapshots: Array<OMReference<CnxSnapshotPhoto>>

    constructor(object: CnxVideoRoomProperties) {
        super(object)

        this.providerRecordingUrl = object.providerRecordingUrl
        this.consumerRecordingUrls = object.consumerRecordingUrls
        this.roomStatus = object.roomStatus || "unstarted"
        this.sid = object.sid
        this.sizeType = object.sizeType

        this.meeting = new OMReference(CnxMeeting, object.meeting)
        this.snapshots = object.snapshots ? object.snapshots.map((item) => new OMReference(CnxSnapshotPhoto, item)) : []
    }
}

export default CnxVideoRoom
