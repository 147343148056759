import React from "react"
import { ObservableFiniteStateMachine, FSMNamedState as FSMState, OMReference } from "firmament-node-sdk"
import branch from "branch-sdk"

import { CnxMemberProfile } from "../../domain"

/* eslint-disable */
declare namespace ReferralStateMachine {
    export interface Presenting extends FSMState {
        name: "presenting"
        item: CnxMemberProfile
    }

    export interface Loading extends FSMState {
        name: "loading"
    }

    export type State = FSMState.Start |
        FSMState.ShowingError |
        Loading |
        Presenting
}

class ReferralStateMachine extends ObservableFiniteStateMachine<ReferralStateMachine.State> {
    constructor() {
        super({ name: "start" })
    }

    public load = async () => {
        switch (this.state.name) {
            case "start":
                this.state = { name: "loading" }
                break
            default:
                return
        }

        try {
            // init branch

            const data: branch.SessionData | null = await new Promise((resolve, reject) => {
                branch.init("key_test_miT6Ei0sbZLieixvr7rp2lpdEBiqpwkw", {}, (err, data) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(data)
                    }
                })
            })

            // get referrer from branch link
            const profileSlug = (data?.data_parsed as any)?.referrer

            if (!profileSlug) {
                throw new Error("Referral code not found or link has already expired.")
            }

            // load profile
            const query = await CnxMemberProfile.query().filter("slug", "equals", profileSlug)
            await query.execute()
            const profile = query.resultObjects[0]
            if (!profile) {
                throw new Error("Profile not found.")
            }

            // present
            this.state = { name: "presenting", item: profile }
        } catch (error) {
            this.fail(error)
        }
    }

    public fail = (error: Error) => {
        switch (this.state.name) {
            case "loading":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

}

export default ReferralStateMachine
