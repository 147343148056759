import {
    OMObject,
    OMObjectProperties,
    OMReference,
} from "firmament-node-sdk"

import { Article } from "."

export interface ArticleContentProperties extends OMObjectProperties {
    sequence?: number

    article: OMReference<Article>
}

class ArticleContent extends OMObject implements ArticleContentProperties {

    public static Type: string = "article_content"
    public static Path: string = "article_contents"

    public sequence?: number

    public article: OMReference<Article>

    constructor(object: ArticleContentProperties) {
        super(object)
        this.sequence = object.sequence

        this.article = new OMReference(Article, object.article)
    }
}

export default ArticleContent
