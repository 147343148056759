//  CnxConsumerRefund.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    WltTransaction,
    WltTransactionProperties,
    WltWallet,
    CnxRating,
} from ".."

type TransactionType = "DEBIT" | "CREDIT"
type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
export interface CnxConsumerRefundProperties extends WltTransactionProperties {
    reason?: string

    rating?: OMReference<CnxRating>
}

class CnxConsumerRefund extends WltTransaction implements CnxConsumerRefundProperties {

    public static Type: string = "cnx_consumer_refund"
    public static Path: string = "cnx_consumer_refunds"

    public reason?: string

    public rating?: OMReference<CnxRating>

    constructor(object: CnxConsumerRefundProperties) {
        super(object)

        this.reason = object.reason

        this.rating = object.rating && new OMReference(CnxRating, object.rating)
    }
}

export default CnxConsumerRefund
