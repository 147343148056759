import React from "react"
import { Route, useRouteMatch } from "react-router"
import { Link } from "react-router-dom"
import { Callout, Caption1, Modal, ModalDialog } from "../../../../components"
import { SchTutorProfile } from "../../../../domain"
import TeachSubject from "../../SchTutorProfileDetails/elements/TeachSubject"
import ic_portrait_placeholder from "../../../../assets/ic_portrait_placeholder.png"
import { CategoriesParamsContextProvider, useCategoriesParamsContext } from "../../../../helpers/useSubjectParamsContext"

import { SchTutorProfileList2 } from "../../SchTutorProfileList2"
import { makeAsyncSelect } from "react-select/lib/Async"

interface Props {
    currentTutor?: SchTutorProfile
}

let cachedTutors: SchTutorProfile[]
const BrowseOtherTutors: React.FunctionComponent<Props> = ({ currentTutor }) => {
    const match = useRouteMatch<{ id?: string }>()
    const { categories } = useCategoriesParamsContext()

    const [tutors, setTutors] = React.useState<SchTutorProfile[]>(cachedTutors)
    const [count, setCount] = React.useState<number>(0)

    const [isShowingTutorListModal, setIsShowingTutorListModal] = React.useState<boolean>(false)

    React.useEffect(() => {
        (async () => {
            const query = SchTutorProfile.query()
                .include("photo")
                .include("categories")
                .filter("approvedAt", "isNotNull", undefined)
                .filter("user.status" as any, "equals", "active")
                // .sort("modifiedAt", "DESC")
                .limit(3)

            if (match?.params.id) {
                query.filter("id", "notEquals", match.params.id)
            }

            if (currentTutor) {
                const filterGroup = [...currentTutor.categories, ...(categories || [])]
                    .map((each) => each.actualObject?.categoryType === "SUBJECT" && each.actualObject.slug)
                    .filter(Boolean)
                    .map((each) => ["categorySet", "contains", each]) as any

                if (filterGroup.length > 0) {
                    query.filterGroup(filterGroup, "OR")
                }
            }

            await query.execute()

            const tutors = query.resultObjects

            setTutors(tutors)
            setCount(query.meta.count)
            cachedTutors = tutors
        })()
    }, [match?.url])

    return (
        <div>
            {tutors?.map((tutor, index) => {
                return (
                    <>
                        <Link to={`/${tutor.slug!}`}>
                            <div className="d-flex clickable">
                                <img src={tutor.photo?.actualObject?.url || ic_portrait_placeholder} alt={tutor.name} className="mr-12px flex-shrink-0" style={{ borderRadius: "50%", width: 40, height: 40, objectFit: "cover" }} />

                                <div className="flex-grow-1 flex-shrink-1" style={{ minWidth: 0, maxWidth: 500 }}>
                                    <Caption1 className="d-block text-ellipsis font-weight-bold">{tutor.name}</Caption1>
                                    <Caption1 className="d-block text-ellipsis"><TeachSubject provider={tutor} /></Caption1>
                                </div>
                            </div>
                        </Link>

                        {index !== tutors.length - 1 && <hr />}
                    </>
                )
            })}

            {count > 3 && (
                <>
                    <hr style={{ marginBottom: 0 }} />
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-link text-primary" style={{ paddingBottom: 0 }} onClick={() => setIsShowingTutorListModal(true)}>
                            <Callout>Browse All</Callout>
                        </button>
                    </div>
                </>
            )}

            <Modal show={isShowingTutorListModal} onDismiss={() => setIsShowingTutorListModal(false)}>
                <ModalDialog
                    // title="Browse All"
                    body={isShowingTutorListModal && (
                        <Route render={(componentProps) => (
                            <CategoriesParamsContextProvider>
                                <SchTutorProfileList2 {...componentProps} tutor={currentTutor} />
                            </CategoriesParamsContextProvider>
                        )} />
                    )}
                />
            </Modal>
        </div>
    ) ?? null
}

export default BrowseOtherTutors
