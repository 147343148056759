import React from "react"
import { Caption2 } from "../../../../components"
import { CnxServiceCategory } from "../../../../domain"

interface Props {
    affiliation: CnxServiceCategory
    preferredStyle?: "BACKGROUND_PHOTO" | "ICON"
}

const defaultBackgroundColor = "#ff7850"

const Affiliation: React.FunctionComponent<Props> = ({ affiliation, preferredStyle = "ICON" }) => {

    /**
     * - ALWAYS try to get a square-ish badge/logo. For example, Monash's
     * badge is sort of square-ish, that should be extracted and set as
     * icon. Example 2, Sunway actually wants their square badge to be
     * displayed on app, like the original app design.
     *
     * - PARTNER SIGN UP WEB should ALWAYS display background photo of
     * affiliate (e.g. for Sunway, the background photo is the image file
     * displaying Sunway Uni and Sunway College side by side)
     *
     * - ALL APP PLATFORMS (INCLUDING ASKBEE WEB) to stick with v1 design
     * style, square icon, border follows background color, and the badge
     * color is background color. If no icon, then only display background
     * photo only without text.
     *
     * In conclusion:
     *
     * - All affiliate should aim to have icon, background photo, background
     * color, and title set.
     *
     * - Those affiliate who die die don't want to display like the current
     * mobile app badge, don't set the icon.
     */

    const [iconURL, setIconURL] = React.useState(affiliation.icon?.actualObject?.url)
    const [backgroundPhotoUrl, setBackgroundPhotoUrl] = React.useState(affiliation.backgroundPhoto?.actualObject?.url)

    React.useEffect(() => {
        ;(async () => {
            if (!affiliation.icon?.actualObject) {
                await affiliation.icon?.load()
                setIconURL(affiliation.icon?.actualObject?.url)
            }
        })()

        ;(async () => {
            if (!affiliation.backgroundPhoto?.actualObject) {
                await affiliation.backgroundPhoto?.load()
                setBackgroundPhotoUrl(affiliation.backgroundPhoto?.actualObject?.url)
            }
        })()
    }, [])

    const backgroundColor = React.useMemo(() => {
        return affiliation.backgroundColor || defaultBackgroundColor
    }, [affiliation])

    switch (true) {
        case preferredStyle === "BACKGROUND_PHOTO":
            return !backgroundPhotoUrl ? null : <img height={40} src={backgroundPhotoUrl} alt="Affiliation Logo" />
        case preferredStyle === "ICON" && !iconURL:
            return (
                <span className="badge badge-warning" style={{ padding: "4px 8px 4px 24px", backgroundColor }}>
                    <Caption2 className="font-weight-bold">{affiliation.title}</Caption2>
                </span>
            )
        default:
            return (
                <div className="position-relative" style={{ height: 32 }}>
                    <div className="position-absolute" style={{ zIndex: 1 }}>
                        <img
                            src={iconURL}
                            alt="Affiliation"
                            width={32}
                            height={32}
                            style={{ borderRadius: "50%", border: `2px solid ${backgroundColor}`, backgroundColor: "white" }}
                        />
                    </div>
                    <div className="h-100 d-flex align-items-center position-relative" style={{ left: 16 }}>
                        <span className="badge badge-warning" style={{ padding: "4px 8px 4px 24px", backgroundColor }}>
                            <Caption2 className="font-weight-bold">{affiliation.title}</Caption2>
                        </span>
                    </div>
                </div>
            )
    }
}

export default Affiliation
