import React from "react"

type Without<T, K> = Pick<T, Exclude<keyof T, K>>

export interface NumberInputComponentProps
    extends Without<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> {
    onChange?: (number?: number) => void
    value?: number
}

export interface NumberInputComponentState {
    rawValue?: string | number
    value?: number
}

class NumberInput extends React.Component<NumberInputComponentProps, NumberInputComponentState> {
    constructor(props: NumberInputComponentProps) {
        super(props)
        this.state = { rawValue: this.props.value, value: this.props.value }
    }

    public componentDidUpdate(prevProps: NumberInputComponentProps, prevState: NumberInputComponentState) {
        if (this.props.value !== prevState.value) {
            this.setState({ value: this.props.value, rawValue: this.props.value })
        }

        if (this.props.onChange && this.state.value !== prevState.value) {
            this.props.onChange(this.state.value)
        }
    }

    public render() {
        return (
            <input type="text" {...this.props} value={this.state.rawValue} onChange={this.onChange} />
        )
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const rawValue = event.target.value

        this.setState({ rawValue })

        const value = parseFloat(rawValue)

        if (isNaN(value)) {
            this.setState({ value: undefined })
        } else {
            this.setState({ value })
        }
    }
}

export default NumberInput
