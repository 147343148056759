//  CnxTopupPayment.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-20
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    PmtStripePayment,
    PmtStripePaymentProperties,
    Profile,
    PmtPayment,
    CnxConsumerProfile,
    CnxConsumerTopup,
    CnxTopupPackage,
} from ".."

export interface CnxTopupPaymentProperties extends PmtStripePaymentProperties {

    consumer: OMReference<CnxConsumerProfile>
    transaction?: OMReference<CnxConsumerTopup>
    package?: OMReference<CnxTopupPackage>
}

class CnxTopupPayment extends PmtStripePayment implements CnxTopupPaymentProperties {

    public static Type: string = "cnx_topup_payment"
    public static Path: string = "cnx_topup_payments"


    public consumer: OMReference<CnxConsumerProfile>
    public transaction?: OMReference<CnxConsumerTopup>
    public package?: OMReference<CnxTopupPackage>

    constructor(object: CnxTopupPaymentProperties) {
        super(object)


        this.consumer = new OMReference(CnxConsumerProfile, object.consumer)
        this.transaction = object.transaction && new OMReference(CnxConsumerTopup, object.transaction)
        this.package = object.package && new OMReference(CnxTopupPackage, object.package)
    }
}

export default CnxTopupPayment
