type Orientation = -2 | -1 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
// Take an image URL, downscale it to the given width, and return a new image URL.
async function preprocessImage(dataUrl: string, newWidth: number = 1000, imageType: string = "image/jpeg", imageArguments: number = 0.3, orientation: Orientation) {

    // Create a temporary image so that we can compute the height of the downscaled image.
    const image = new Image()
    image.src = dataUrl

    let oldWidth = image.width
    let oldHeight = image.height

    // tslint:disable-next-line
    await new Promise((resolve) => image.onload = function () {
        oldWidth = image.width
        oldHeight = image.height
        resolve()
    })

    const newHeight = Math.floor(oldHeight / oldWidth * newWidth)

    // Create a temporary canvas to draw the downscaled image on.
    const canvas = document.createElement("canvas")
    canvas.width = newWidth
    canvas.height = newHeight

    // Draw the downscaled image on the canvas and return the new data URL.
    let ctx = canvas.getContext("2d")

    function drawRotated(degrees: number) {
        ctx!.clearRect(0, 0, canvas.width, canvas.height)

        // save the unrotated context of the canvas so we can restore it later
        // the alternative is to untranslate & unrotate after drawing
        ctx!.save()

        // move to the center of the canvas
        ctx!.translate(canvas.width / 2, canvas.height / 2)

        // rotate the canvas to the specified degrees
        ctx!.rotate(degrees * Math.PI / 180)

        // draw the image
        // since the context is rotated, the image will be rotated also
        ctx!.drawImage(image, -newWidth / 2, -newHeight / 2)

        // we’re done with the rotating so restore the unrotated context
        ctx!.restore()
    }

    switch (orientation) {
        case 6:
        case 5:
            canvas.height = newWidth
            canvas.width = newHeight
            ctx = canvas.getContext("2d")
            ctx!.save()
            ctx!.translate(canvas.width / 2, canvas.height / 2)
            ctx!.rotate(90 * Math.PI / 180)
            ctx!.drawImage(image, -canvas.height / 2, -canvas.width / 2, canvas.height, canvas.width)
            ctx!.restore()
            break
        case 4:
        case 3:
            ctx!.save()
            ctx!.translate(newWidth / 2, newHeight / 2)
            ctx!.rotate(180 * Math.PI / 180)
            ctx!.drawImage(image, -newWidth / 2, -newHeight / 2, newWidth, newHeight)
            ctx!.restore()
            break
        case 8:
        case 7:
            canvas.height = newWidth
            canvas.width = newHeight
            ctx = canvas.getContext("2d")
            ctx!.save()
            ctx!.translate(canvas.width / 2, canvas.height / 2)
            ctx!.rotate(-90 * Math.PI / 180)
            ctx!.drawImage(image, -canvas.height / 2, -canvas.width / 2, canvas.height, canvas.width)
            ctx!.restore()
            break
        case 2:
        case 1:
        default:
            ctx!.drawImage(image, 0, 0, newWidth, newHeight)
            break
    }

    const newDataUrl = canvas.toDataURL(imageType, imageArguments)
    return newDataUrl
}

function dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(",")[1])

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ab], { type: mimeString })

}

export {
    preprocessImage,
    dataURItoBlob,
}
