import { OMReference, OMIdentity } from "firmament-node-sdk"
import moment from "moment"
import * as React from "react"
import { Route, RouteComponentProps } from "react-router"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import isEqual from "react-fast-compare"
import combine from "lodash/flow"

import debounce from "../../../helpers/debounce"
import { AvatarImage } from "../../../airframe-components/Avatar/AvatarImage"
import { AvatarAddonIcon } from "../../../airframe-components/Avatar/AvatarAddonIcon"
import ic_portrait_placeholder from "../../../assets/ic_portrait_placeholder.png"
import ic_graphic_coming_soon from "../../../assets/ic_graphic_coming_soon@2x.png"

import {
    DateRangePicker,
    ErrorAlert,
    ModalSpinner,
    ModelTable,
    ListStateMachine,
    Modal,
    ModalDialog,
    Title2,
    Headline,
    Caption1,
    Pagination,
} from "../../../components"
import { withContextConsumer, withMachine, withQueryString } from "../../../hoc"

import { NotifMessage, ProfilePhoto, User, SchTutorProfile, CnxConsumerProfile, CnxServiceCategory, CnxProviderProfile } from "../../../domain"

import { SchTutorProfileListStateMachine2 } from "../SchTutorProfileList2"
import ExportCSV from "../../../helpers/ExportCSV"
import TeachSubject from "../SchTutorProfileDetails/elements/TeachSubject"
import { SchTutorProfileDetails, SchTutorProfileDetailsStateMachine } from "../SchTutorProfileDetails"
import CnxBookingAskForm from "../../CnxBooking/CnxBookingAskForm/CnxBookingAskForm"
import CnxBookingAskFormStateMachine from "../../CnxBooking/CnxBookingAskForm/CnxBookingAskFormStateMachine"
import { TableCellProps } from "react-virtualized"
import { CategoriesParamsContext } from "../../../helpers/useSubjectParamsContext"
import { Link } from "react-router-dom"

interface MemberProfileListProps extends RouteComponentProps {
    machine: SchTutorProfileListStateMachine2

    messages?: OMReference<NotifMessage>[]
    photo?: OMReference<ProfilePhoto>
    user?: OMReference<User>
    favoritedByConsumer?: OMReference<CnxConsumerProfile>
    category?: OMReference<CnxServiceCategory>

    queryString: any
    updateQueryString: (keyPath: any) => (value: any) => void
    setQueryString: (state: any) => void

    // From: withContextConsumer(CategoriesParamsContext)
    categories?: OMReference<CnxServiceCategory>[]

    // From: direct injection
    tutor?: SchTutorProfile
}

interface MemberProfileListComponentState {
    machineState: SchTutorProfileListStateMachine2.State
    queryParams: ListStateMachine.QueryParams<SchTutorProfile> & Partial<SchTutorProfile> & { favoritedByConsumer?: OMReference<CnxConsumerProfile>, category?: OMReference<CnxServiceCategory> }

    newChallenge?: string
    isExporting?: boolean

    showingTutorModal?: boolean
    selectedTutor?: SchTutorProfile

    showingBookingModal?: boolean
}

class SchTutorProfileList extends React.Component<MemberProfileListProps, MemberProfileListComponentState> {

    static contextType = CategoriesParamsContext

    private machine: SchTutorProfileListStateMachine2

    /* SECTION: Callbacks */

    private onSortChange = (sort: any) => this.props.setQueryString({ ...sort })
    private updateQueryParams = ModelTable.updateQueryParams.bind(this as any)
    private onDateRangeChange = DateRangePicker.onDateRangeChange.bind(this)

    constructor(props: MemberProfileListProps) {
        super(props)
        this.machine = this.props.machine
        this.state = {
            machineState: this.machine.state,
            queryParams: {
                currentPage: 0,
                sortBy: "createdAt",
                sortDirection: "DESC",

                user: this.props.user ? new OMReference(User, this.props.user.id) : undefined,
                favoritedByConsumer: this.props.favoritedByConsumer ? new OMReference(CnxConsumerProfile, this.props.favoritedByConsumer.id) : undefined,
                category: this.props.category ? new OMReference(CnxServiceCategory, this.props.category.id) : undefined,
            },
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)

        this.machine.load({
            categories: this.props.categories,
            tutor: this.props.tutor,
            ...this.state.queryParams,
            ...this.props.queryString,
        })
    }

    public componentDidUpdate(prevProps: MemberProfileListProps, prevState: MemberProfileListComponentState) {
        if (this.state.queryParams !== prevState.queryParams || !isEqual(this.props.queryString, prevProps.queryString)) {
            this.machine.load({
                categories: this.props.categories,
                tutor: this.props.tutor,
                ...this.state.queryParams,
                ...this.props.queryString,
            })
        }

        if (this.props.category?.id !== prevProps.category?.id) {
            this.machine.load({
                categories: this.props.categories,
                tutor: this.props.tutor,
                ...this.state.queryParams,
                ...this.props.queryString,
                category: this.props.category ? new OMReference(CnxServiceCategory, this.props.category.id) : undefined,
            })
        }
    }

    public componentWillUnmount() {
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
                return <ModalSpinner />
            case "loading":
            case "presenting":
            case "promptingDelete":
            case "performingDelete":
            case "promptingResetPassword":
            case "performingResetPassword":
            case "promptingSuccessfulResetPassword":
            case "promptingSuspend":
            case "performingSuspend":
            case "promptingUnsuspend":
            case "performingUnsuspend":
                return (
                    <>
                        {/* {machineState.name === "loading" && <ModalSpinner />} */}

                        <Modal show={!!this.state.showingTutorModal} onDismiss={() => this.setState({ showingTutorModal: false })}>
                            <div className="modal-dialog container-420px my-8px m" role="document">
                                <div className="modal-content">
                                    <div className="position-relative">
                                        <div className="position-absolute" style={{ right: 8, zIndex: 2 }}>
                                            <button
                                                type="button"
                                                className="close"
                                                aria-label="Close"
                                                onClick={() => this.setState({ showingTutorModal: false })}
                                            >
                                                <span className="fa fa-times-circle" />
                                            </button>
                                        </div>
                                    </div>

                                    <Route path="/" render={(componentProps) => {
                                        if (!this.state.showingTutorModal) {
                                            return null
                                        }

                                        if (!this.state.selectedTutor) {
                                            return null
                                        }

                                        const props = {
                                            ...componentProps,
                                            match: {
                                                ...componentProps.match,
                                                url: `/${SchTutorProfile.Path}/${this.state.selectedTutor.id}`,
                                                params: {
                                                    id: this.state.selectedTutor.id
                                                }
                                            }
                                        }

                                        return <SchTutorProfileDetails machine={new SchTutorProfileDetailsStateMachine()} {...props} />
                                    }} />
                                </div>
                            </div>
                        </Modal>

                        <Modal show={!!this.state.showingBookingModal} onDismiss={() => this.setState({ showingBookingModal: false })} >
                            <ModalDialog
                                title={<Title2 className="d-block" style={{ marginLeft: 15, marginTop: 15 }}>Book A Session</Title2>}
                                body={
                                    <Route render={(componentProps) => {
                                        if (!this.state.selectedTutor) {
                                            return null
                                        }

                                        return (
                                            <CnxBookingAskForm
                                                machine={new CnxBookingAskFormStateMachine()}
                                                category={this.props.category}
                                                matchProvider={new OMReference(CnxProviderProfile, this.state.selectedTutor.id)}
                                                {...componentProps}
                                            />
                                        )
                                    }} />
                                }
                            />
                        </Modal>

                        {this.props.category?.actualObject?.providerCount === 0 && (
                            <div className="d-flex flex-column align-items-center mt-12px">
                                <img src={ic_graphic_coming_soon} alt="ic_graphic_coming_soon" width={252} />
                                <Headline className="text-center mt-8px text-secondary">We are preparing the resources to make this subject available. Please check back later!</Headline>
                            </div>
                        )}

                        <div className="mx-16px my-16px" style={{ display: this.props.category?.actualObject?.providerCount === 0 && "none" || undefined }}>

                            <Title2>Browse All</Title2>

                            <ul className="list-group card my-8px" style={{ minHeight: !this.props.location.pathname.startsWith(`/${CnxServiceCategory.Path}`) && 196 || undefined }}>
                                {machineState.items.map((item) => {
                                    const isApproved = item.providerStatus === "APPROVED" || !!item.approvedAt
                                    const isAvailable = isApproved && item.isOnline
                                    const isBusy = !!item.currentMeeting

                                    const coverImageSrc = item.photo?.actualObject?.preview

                                    return (
                                        <Link to={`/${SchTutorProfile.Path}/${item.id}`}>
                                            <li className="list-group-item-action list-group-item clickable" style={{ padding: "12px 16px" }}>
                                                <div className="d-flex align-items-center">

                                                    <div className="flex-grow-1">
                                                        <div className="d-flex align-items-center">
                                                            <AvatarImage
                                                                size="md"
                                                                src={coverImageSrc || ic_portrait_placeholder}
                                                                className=""
                                                                // addOns={isApproved ? [
                                                                //     <AvatarAddonIcon
                                                                //         className="fa fa-circle text-white"
                                                                //         color="white"
                                                                //         key="avatar-icon-bg"
                                                                //     />,
                                                                //     <AvatarAddonIcon
                                                                //         className={`fa fa-circle ${isAvailable ? isBusy ? "text-accent-2" : "text-success" : "text-tertiary"}`}
                                                                //         color="success"
                                                                //         key="avatar-icon-fg"
                                                                //     />
                                                                // ] : undefined}
                                                            />
                                                            <div className="flex-column ml-16px">
                                                                <Headline className="d-block text-dark">{item.name || "-"}</Headline>
                                                                <Caption1 className="d-block text-secondary">
                                                                    <TeachSubject provider={item} showGrade={false} />
                                                                </Caption1>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </li>
                                        </Link>
                                    )
                                })}
                            </ul>

                            <Pagination
                                maxPages={3}
                                totalItems={machineState.totalItems}
                                itemsPerPage={3}
                                className="d-flex justify-content-center"
                                onPageChanged={this.props.updateQueryString("currentPage")}
                                currentPage={this.props.queryString.currentPage}
                            />
                        </div >
                    </>
                )
            case "showingDetail":
                this.props.history.push(`/${SchTutorProfile.Path}/${machineState.ref.id}/edit`)
                return null
            case "showingError":
                return <ErrorAlert error={machineState.error} />
            case "showingCreate":
                this.props.history.push(`/${SchTutorProfile.Path}/new`)
                return null
            case "completed":
                return null
        }
    }

    private subscriptionCallback = (machineState: SchTutorProfileListStateMachine2.State) => {
        return this.setState({ machineState })
    }

    /* SECTION: Custom Cell Renderer */

    private debounceUpdate = debounce((value: string) => {
        this.props.updateQueryString("title")(value || null as any)
    }, 250)

}

export default combine([
    withContextConsumer(CategoriesParamsContext),
    withQueryString({ path: SchTutorProfile.Path }),
    withMachine(SchTutorProfileListStateMachine2),
])(SchTutorProfileList)
