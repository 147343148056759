import {
    FSMNamedState as FSMState,
    OMObject,
    OMReference,
} from "firmament-node-sdk"

import DetailsStateMachine from "../DetailsStateMachine"

declare namespace DetailsStateMachineWithDelete {

    export interface PromptingDelete<T extends OMObject> {
        name: "promptingDelete"
        item: T
    }

    export interface PerformingDelete<T extends OMObject> {
        name: "performingDelete"
        item: T
    }

    export type State<T extends OMObject> = DetailsStateMachine.State<T> |
        PromptingDelete<T> |
        PerformingDelete<T>

}

abstract class DetailsStateMachineWithDelete<T extends OMObject, State extends FSMState>
    extends DetailsStateMachine<T, DetailsStateMachineWithDelete.State<T> | State> {

    public promptDelete = () => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "promptingDelete", item: (this.state as any).item }
                break
            default:
                return
        }
    }

    public performDelete = () => {
        switch (this.state.name) {
            case "promptingDelete":
                this.state = { name: "performingDelete", item: (this.state as any).item }
                break
            default:
                return
        }

        this.state.item
            .delete()
            .then(() => this.dismiss())
            .catch((error) => this.fail(error))
    }

    /* SECTION: Override */

    /**
     * Success Transition
     * [loading] -- success -> [presenting]
     */
    public success = (item: T): void => {
        switch (this.state.name) {
            case "loading":
            case "promptingDelete":
                this.state = { name: "presenting", item }
            default:
                return
        }
    }

    public fail = (error: Error): void => {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
                this.state = { name: "showingError", error, item: (this.state as any).item }
                break
            default:
                return
        }
    }

    public dismiss = () => {
        switch (this.state.name) {
            case "presenting":
            case "showingError":
            case "performingDelete":
                this.state = { name: "completed" }
                break
            default:
                break
        }
    }

    public cancel = () => {
        switch (this.state.name) {
            case "promptingDelete":
                this.state = { name: "presenting", item: (this.state as any).item }
                break
            default:
                break
        }
    }

}

export default DetailsStateMachineWithDelete
