import * as React from "react"
import * as ReactDOM from "react-dom"
import { LayoutSidebar } from "../../../airframe-components/Layout/LayoutSidebar"

export interface SplitViewControllerComponentProps {
    parent?: any
    child?: any

    parentWidth: number
}

export interface SplitViewControllerComponentState {

}

class SplitViewController extends React.PureComponent<SplitViewControllerComponentProps, SplitViewControllerComponentState> {
    static defaultProps: {
        parentWidth: 420
    }

    componentDidMount() {
        this.forceUpdate()
    }

    public render() {
        return (
            <>
                {document.getElementById("layout__split-view") && ReactDOM.createPortal((
                    <div className="position-static" style={{ width: 420 }}>
                        <div
                            style={{
                                height: "100vh",
                                overflowY: "scroll",
                                position: "fixed",
                                top: 0,
                                left: 250,
                                bottom: 0,
                                width: 420,
                                borderRight: "1px solid #DEE2E6",
                                backgroundColor: "rgb(241, 241, 245)",
                            }}
                        >
                            {this.props.parent || null}
                        </div>
                    </div>
                ), document.getElementById("layout__split-view") as any)}
                {this.props.child || null}
            </>
        )
    }
}

export default SplitViewController
