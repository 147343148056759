import React from "react"
import { ObservableFiniteStateMachine, FSMNamedState as FSMState, OMUniverse, OMUser, OMReference } from "firmament-node-sdk"
import getProfile from "../../helpers/Profile"
import { CnxMemberProfile, CnxServiceCategory, CnxServiceTier, PmtCurrencyPair, SchStudentProfile, SchTutorProfile, Setting } from "../../domain"
import qs from "qs"
import { utimesSync } from "fs"

/* eslint-disable */
declare namespace AppStateMachine {
    export interface Authenticated extends FSMState {
        name: "authenticated"
    }

    export interface Unauthenticated extends FSMState {
        name: "unauthenticated"
    }

    export interface RestoringSession extends FSMState {
        name: "restoringSession"
    }

    export type State = FSMState.Start |
        FSMState.ShowingError |
        Authenticated |
        Unauthenticated |
        RestoringSession
}

class AppStateMachine extends ObservableFiniteStateMachine<AppStateMachine.State> {
    constructor() {
        super({ name: "start" })
    }

    private handleUTMLink = () => {
        const { utm_source, utm_medium, utm_campaign, utm_term, utm_content }: {
            utm_source?: string,
            utm_medium?: string,
            utm_campaign?: string,
            utm_term?: string,
            utm_content?: string,
        } = qs.parse(window.location.search.slice(1))

        if (
            utm_source ||
            utm_medium ||
            utm_campaign ||
            utm_term ||
            utm_content
        ) {
            return OMUniverse.shared.session?.setUtmTags(
                utm_source,
                utm_medium,
                utm_campaign,
                utm_term,
                utm_content,
            )
        }
    }

    public restoreSession = async () => {
        switch (this.state.name) {
            case "start":
                this.state = { name: "restoringSession" }
                break
            default:
                return
        }

        try {

            await OMUniverse.shared.restoreUserSession(OMUser)

            if (OMUniverse.shared.session && OMUniverse.shared.session.refreshTokenHasExpired) {
                OMUniverse.shared.logout()
                OMUniverse.shared.clearUserSession()
                window.location.reload()
            }

            this.handleUTMLink()

            /* Load setting */
            await Setting.query().limit(1000).execute() /* TODO: filters? */

            /* Load currency pair */
            await PmtCurrencyPair.query().limit(1000).execute() /* TODO: filters? */

            /* Load all categories */
            await CnxServiceCategory.query().limit(1000).execute()

            /* Load all service tiers */
            await CnxServiceTier.query().limit(1000).execute()

            /* Register Stripe Customer ID */
            const profile = getProfile()
            if (profile instanceof CnxMemberProfile && !profile.stripeCustomerId) {
                await profile.registerStripeCustomer({ name: profile.name })
            }

            this.success()
        } catch (error) {
            this.fail(error)
        }
    }

    public success = async () => {
        switch (this.state.name) {
            case "restoringSession":
                break
            default:
                return
        }

        try {
            if (OMUniverse.shared.user!.identities && OMUniverse.shared.user!.identities.length > 0) {
                const profile = getProfile()

                if (profile && profile instanceof CnxMemberProfile) {
                    const item = await (async () => {

                        if (profile instanceof SchTutorProfile) {
                            return await new OMReference(SchTutorProfile, profile.id).load(
                                SchTutorProfile.query()
                                    .include("categories")
                                    .include("pendingCategories")
                                    .include("photo")
                                    .include("currentMeeting")
                                    .include("moneyWallet")
                                    .include("pointWallet")
                                    .include("certificates")
                                    .include("payoutAccount")
                            )
                        }

                        if (profile instanceof SchStudentProfile) {
                            return await new OMReference(SchStudentProfile, profile.id).load(
                                SchStudentProfile.query()
                                    .include("categories")
                                    .include("photo")
                                    .include("currentMeeting")
                                    .include("moneyWallet")
                                    .include("pointWallet")
                                    .include("sessionWallet")
                                    .include("favoriteProviders")
                            )
                        }

                    })()

                    if (item) {
                        OMUniverse.shared.touch(item, true)
                    }
                }

                this.state = { name: "authenticated" }
            } else {
                this.state = { name: "unauthenticated" }
            }
        } catch (error) {
            this.fail(error)
        }
    }

    public fail = (error: Error) => {
        switch (this.state.name) {
            case "restoringSession":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

}

export default AppStateMachine
