// Data imports
import { FSMNamedState as FSMState, OMRealtimeSubscription, OMReference, OMUniverse } from "firmament-node-sdk"
import moment from "moment"
import { CnxMeeting, CnxMemberProfile, CnxRating, SchStudentProfile, SchTutorProfile } from "../../../domain"

// Feature imports
import { DetailsStateMachineWithDelete } from "../../../components"
import getProfile from "../../../helpers/Profile"

declare namespace CnxMeetingDetailsStateMachine {
    export interface ShowEdit extends FSMState {
        name: "showingEdit"
        item: CnxMeeting
    }

    export interface LeavingMeeting extends FSMState {
        name: "leavingMeeting"
        item: CnxMeeting
    }

    export interface LeavedPrematurely extends FSMState {
        name: "leavedPrematurely"
    }

    export interface ParticipantHasLeft extends FSMState {
        name: "participantHasLeft"
        item: CnxMeeting
    }

    export interface MeetingHasEnded extends FSMState {
        name: "meetingHasEnded"
        item: CnxMeeting
    }

    export type State = DetailsStateMachineWithDelete.State<CnxMeeting> |
        ShowEdit |
        LeavingMeeting |
        LeavedPrematurely |
        ParticipantHasLeft |
        MeetingHasEnded
}

class CnxMeetingDetailsStateMachine extends DetailsStateMachineWithDelete<CnxMeeting, CnxMeetingDetailsStateMachine.State> {

    private subscription?: OMRealtimeSubscription<typeof CnxMeeting>

    /**
     * Show Edit Transition
     * [presenting] -- dismiss -> [showingEdit]
     */
    public showEdit = (item: CnxMeeting): void => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingEdit", item }
            default:
                return
        }
    }

    public load = async (id: OMReference<CnxMeeting>) => {
        switch (this.state.name) {
            case "start":
                this.state = { name: "loading" }
                break
            default:
                return
        }

        try {
            const profile = getProfile()
            const item = await id.load(
                CnxMeeting.query()
                    .include("bookings")
                    .include("provider")
                    .include("provider.photo" as any)
                    .include("consumers")
                    .include("videoRoom")
                    .include("serviceTier")
                    .include("serviceCategories")
                    .include("currentParticipants")
                    .include("ratings")
            )

            if (profile?.id !== item.consumers?.[0]?.id) {
                // NOTE: load participant photo
                await new OMReference(SchStudentProfile, item.consumers?.[0].id)
                    .load(SchStudentProfile.query().include("photo"))
            }

            if (!["FAILED", "COMPLETED"].includes(item.meetingStatus || "") && profile && !item.currentParticipants.map((each) => each.id).includes(profile.id)) {
                await item.join(new OMReference(CnxMemberProfile, profile.id))
            }

            this.subscription = item.realtimeSubscription(CnxMeeting)
                .listen(async (command) => {
                    if (this.state.name === "meetingHasEnded") {
                        return
                    }

                    const meeting = await command.receiver.load(CnxMeeting.query()
                        .include("currentParticipants")
                        .include("videoRoom")
                        .include("serviceTier")
                    )

                    OMUniverse.shared.touch(profile!)

                    meeting.provider = item.provider
                    meeting.consumers = item.consumers
                    meeting.serviceCategories = item.serviceCategories

                    switch (meeting.meetingStatus) {
                        case "COMPLETED":
                        case "FAILED":
                            this.state = { name: "participantHasLeft", item: meeting }
                            break
                        default:
                            this.state = { name: "presenting", item: meeting /* TODO: this item do not have certain rels */ }
                            break
                    }

                })

            OMUniverse.shared.touch(profile!)
            this.success(item)
        } catch (error) {
            this.fail(error)
        }
    }



    public leaveMeeting = async () => {
        switch (this.state.name) {
            case "presenting":
            case "participantHasLeft":
                this.state = { name: "leavingMeeting", item: this.state.item }
                break
            default:
                return
        }

        try {
            const meeting = this.state.item
            const profile = getProfile()

            if (!profile) {
                throw new Error("Profile not found.")
            }

            await meeting.leave(new OMReference(CnxMemberProfile, profile.id))

            switch (true) {
                case profile instanceof SchStudentProfile && moment().isSameOrAfter(meeting.scheduledEndAt?.clone().add(meeting.serviceTier.actualObject?.gracePeriodMins, "minutes")):
                    this.state = { name: "meetingHasEnded", item: meeting }
                    break
                case getProfile() instanceof SchTutorProfile && meeting.meetingStatus !== "COMPLETED" && !meeting.isSafeToLeave:
                case getProfile() instanceof SchStudentProfile && !meeting.provider:
                    this.state = { name: "leavedPrematurely" }
                    break
                default:
                    this.dismiss()
                    break
            }

        } catch (error) {
            this.fail(error)
        }

    }


    public fail = (error: Error): void => {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
            case "leavingMeeting":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

    public dismiss = () => {
        switch (this.state.name) {
            case "presenting":
            case "showingError":
            case "performingDelete":
            case "leavingMeeting":
            case "meetingHasEnded":
                this.state = { name: "completed" }
                break
            default:
                break
        }
    }

    public unsubscribeRealtime = () => {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }

}

export default CnxMeetingDetailsStateMachine
