//  CnxSessionWallet.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    CommandManager,
    Command,
    JsonUtils,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import uuid from "uuid/v4"

import {
    WltWallet,
    WltWalletProperties,
    WltTransaction,
    CnxConsumerProfile,
} from ".."
import { PmtPayment } from "../Payment"
import CnxTopupPackage from "./CnxTopupPackage"

export interface CnxSessionWalletProperties extends WltWalletProperties {

    consumer: OMReference<CnxConsumerProfile>
}

class CnxSessionWallet extends WltWallet implements CnxSessionWalletProperties {

    public static Type: string = "cnx_session_wallet"
    public static Path: string = "cnx_session_wallets"


    public consumer: OMReference<CnxConsumerProfile>

    protected static TopupCommand = class TopupCommand extends Command<CnxSessionWallet> {
        public static readonly Path = "topup"
        public static readonly Type = "cnx_session_wallet_topup_command"

        public constructor(
            receiver: CnxSessionWallet,
            public payment: OMReference<PmtPayment>,
            public topupPackage: OMReference<CnxTopupPackage>,
        ) {
            super(receiver)
        }

        public execute() {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            payment: this.payment.id,
                            package: this.topupPackage.id,
                        }
                    }
                },
                method: "POST",
                url,
            })
        }
    }


    public topup(payment: OMReference<PmtPayment>, topupPackage: OMReference<CnxTopupPackage>) {
        try {
            const command = new CnxSessionWallet.TopupCommand(this, payment, topupPackage)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }





    /* RedeemVoucherCommand */
    protected static RedeemVoucherCommand = class RedeemVoucherCommand extends Command<CnxSessionWallet> {
        public static readonly Path = "redeem_voucher"

        public constructor(
            receiver: CnxSessionWallet,
            public voucherCode: string,
        ) {
            super(receiver)
        }
    }

    public redeemVoucher(voucherCode: string) {
        try {
            const command = new CnxSessionWallet.RedeemVoucherCommand(this, voucherCode)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }




    constructor(object: CnxSessionWalletProperties) {
        super(object)


        this.consumer = new OMReference(CnxConsumerProfile, object.consumer)
    }
}

export default CnxSessionWallet
