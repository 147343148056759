import React, { Suspense } from "react"
import { withMachine } from "../../hoc"
import { AppStateMachine } from "."
import { Switch, Route, Redirect } from "react-router"
import { BrowserRouter as Router } from "react-router-dom"


import { ToastContainer } from "react-toastify"
import { ErrorAlert, ModalSpinner } from "../../components"

import { SchStudentProfile, SchTutorProfile, CnxMemberProfile, CnxMeeting } from "../../domain"
import ProfileRedirector from "../Root/elements/ProfileRedirector"
import getProfile from "../../helpers/Profile"
import { CnxMeetingDetails, CnxMeetingDetailsStateMachine } from "../../features/CnxMeeting/CnxMeetingDetails"
import { CnxMeetingWaitingLounge, CnxMeetingWaitingLoungeStateMachine } from "../../features/CnxMeeting/CnxMeetingWaitingLounge"
import { Referral } from "../Referral"
import ArticleModule from "../../features/Article/ArticleModule"
import TutorProfile from "./elements/TutorProfile"
import { AffiliationProvider } from "../../features/CnxServiceCategory/CnxServiceCategoryList/elements/AffiliationProvider"
import { CategoriesParamsContextProvider } from "../../helpers/useSubjectParamsContext"

// import WelcomeView from "../Root/WelcomeView"
const WelcomeView = React.lazy(() => import("../Root/WelcomeView"));
const SchTutorProfileModule = React.lazy(() => import("../../features/SchTutorProfile/SchTutorProfileModule"))
const SchStudentProfileModule = React.lazy(() => import("../../features/SchStudentProfile/SchStudentProfileModule"))
const SessionModule = React.lazy(() => import("../Session/SessionModule"))
const IdentityModule = React.lazy(() => import("../Identity/IdentityModule"))

class App extends React.Component<{ machine: AppStateMachine }> {

    componentDidMount() {
        this.props.machine.restoreSession()
    }

    render() {
        switch (this.props.machine.state.name) {
            case "unauthenticated":
                return (
                    <AffiliationProvider>
                        <Suspense fallback={() => <ModalSpinner />}>
                            <Router>

                                <ToastContainer position="top-right" autoClose={5000} />

                                <Switch>
                                    <Route path="/articles" component={ArticleModule} />
                                    <Route path="/referral" component={Referral} />
                                    <Route path="/authentication" component={SessionModule} />
                                    <Route path="/identities" component={IdentityModule} />

                                    <Route
                                        exact
                                        path={`/:slug`}
                                        component={ProfileRedirector}
                                    />

                                    <Route
                                        exact
                                        path={`/${SchTutorProfile.Path}/:id`}
                                        render={(componentProps) => (
                                            <CategoriesParamsContextProvider>
                                                <TutorProfile {...componentProps as any} />
                                            </CategoriesParamsContextProvider>
                                        )}
                                    />

                                    <Redirect to="/authentication/menu" />
                                </Switch>
                            </Router>
                        </Suspense>
                    </AffiliationProvider>
                )
            case "authenticated":
                return (
                    <AffiliationProvider>
                        <Suspense fallback={() => <ModalSpinner />}>
                            <Router>
                                <ToastContainer position="top-right" autoClose={5000} />


                                <Switch>
                                    <Route path="/articles" component={ArticleModule} />
                                    <Route path="/identities" component={IdentityModule} />

                                    <Route
                                        path={`/${CnxMeeting.Path}/:id/lounge`}
                                        render={(componentProps) => <CnxMeetingWaitingLounge machine={new CnxMeetingWaitingLoungeStateMachine()} {...componentProps} />}
                                    />

                                    <Route
                                        exact
                                        path={`/${CnxMeeting.Path}/:id`}
                                        render={(componentProps) => <CnxMeetingDetails machine={new CnxMeetingDetailsStateMachine()} {...componentProps} />}
                                    />
                                    <Route path={`/${SchTutorProfile.Path}/new`} render={() => <div className="container"><SchTutorProfileModule /></div>} />
                                    <Route path={`/${SchStudentProfile.Path}/new`} render={() => <div className="container"><SchStudentProfileModule /></div>} />

                                    <Route path="/" component={WelcomeView} />

                                    <Redirect to="/" />
                                </Switch>
                            </Router>
                        </Suspense>
                    </AffiliationProvider>
                )
            case "showingError":
                return (
                    <div className="container">
                        <ErrorAlert error={this.props.machine.state.error} />
                        <ToastContainer position="top-right" autoClose={5000} />
                    </div>
                )
            case "restoringSession":
            case "start":
                return <ModalSpinner />
        }
    }
}

export default withMachine(AppStateMachine)(App)
