import React from "react"

import { default as SmartBannerView } from "react-smartbanner"
import "react-smartbanner/dist/main.css"

const SmartBanner = () => {
    return <SmartBannerView title="AskBee" />
}

export default SmartBanner
