// Data imports
import { OMReference } from "firmament-node-sdk"
import * as React from "react"
import { RouteComponentProps } from "react-router"
import { Redirect, Route, Switch } from "react-router-dom"
import { CnxMeeting, GenImage, CnxMemberProfile, CnxMessage } from "../../domain"

// Feature Imports
import { CnxMessageDetails, CnxMessageDetailsStateMachine } from "./CnxMessageDetails"
import { CnxMessageForm, CnxMessageFormStateMachine } from "./CnxMessageForm"
import { CnxMessageList, CnxMessageListStateMachine } from "./CnxMessageList"

const Create = (ownProps: CnxMessageModuleProps) => (props: RouteComponentProps) => {
    return <CnxMessageForm machine={new CnxMessageFormStateMachine()} {...ownProps} {...props} />
}

const Edit = (ownProps: CnxMessageModuleProps) => (props: RouteComponentProps<{ id: string }>) => {
    return <CnxMessageForm machine={new CnxMessageFormStateMachine()} {...ownProps} {...props} />
}

const Details = (ownProps: CnxMessageModuleProps) => (props: RouteComponentProps<{ id: string }>) => {
    return <CnxMessageDetails machine={new CnxMessageDetailsStateMachine()} {...ownProps} {...props} />
}

export interface CnxMessageModuleProps {
    meeting?: OMReference<CnxMeeting>
    sender?: OMReference<CnxMemberProfile>
}

class CnxMessageModule extends React.Component<CnxMessageModuleProps> {

    public render() {
        return (
            <div id="CnxMessageModule" className="">
                <Switch>

                    <Route
                        path={`:parentPath(.*)/${CnxMessage.Path}/new`}
                        component={Create(this.props)}
                    />

                    <Route
                        path={`:parentPath(.*)/${CnxMessage.Path}/:id/edit`}
                        component={Edit(this.props)}
                    />

                    <Route
                        path={`:parentPath(.*)/${CnxMessage.Path}/:id`}
                        component={Details(this.props)}
                    />

                    <Redirect
                        strict={true}
                        from={`:parentPath(.*)/${CnxMessage.Path}/`}
                        to={`:parentPath(.*)/${CnxMessage.Path}`}
                    />

                    <Route
                        path={`:parentPath(.*)/${CnxMessage.Path}`}
                        render={(componentProps) => <CnxMessageList machine={new CnxMessageListStateMachine()} {...this.props} {...componentProps} />}
                    />

                    <Route
                        path={`:parentPath(.*)`}
                        // tslint:disable-next-line
                        render={(componentProps) => {
                            componentProps.match.url = `${componentProps.match.url}/${CnxMessage.Path}`
                            return <CnxMessageList machine={new CnxMessageListStateMachine()} {...this.props} {...componentProps} />
                        }}
                    />

                </Switch>
            </div>
        )
    }
}

export default CnxMessageModule
