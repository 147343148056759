// Data module imports
import { OMReference } from "firmament-node-sdk"
import React from "react"
import { RouteComponentProps } from "react-router"
import moment from "moment"

import { CnxMeetingWaitingLoungeStateMachine } from "."
import {
    ErrorAlert,
    ModalSpinner,
    ListStateMachine,
    Body,
    Title2,
    ModalDialog,
    Modal,
    Footnote,
} from "../../../components"

import { CnxConsumerProfile, CnxBid, CnxBooking, CnxMessage, CnxProviderProfile, CnxRating, CnxVideoRoom, CnxMeeting, CnxServiceTier, CnxMemberProfile, CnxServiceCategory, SchTutorProfile } from "../../../domain"
import CnxBookingModule from "../../CnxBooking/CnxBookingModule"
import WaitingLoungeWrapper from "./elements/WaitingLounge"
import getProfile from "../../../helpers/Profile"

declare interface CnxMeetingWaitingLoungeProps extends RouteComponentProps <{ id: string }> {
    machine: CnxMeetingWaitingLoungeStateMachine

    cnxServiceTier?: OMReference<CnxServiceTier>
    bookings?: OMReference<CnxBooking>[]
    acceptedBid?: OMReference<CnxBid>
    bids?: OMReference<CnxBid>[]
    consumer?: OMReference<CnxConsumerProfile>
    consumers?: OMReference<CnxConsumerProfile>[]
    currentParticipants?: OMReference<CnxMemberProfile>[]
    transcript?: OMReference<CnxMessage>[]
    provider?: OMReference<CnxProviderProfile>
    ratings?: OMReference<CnxRating>[]
    matchProviders?: OMReference<CnxProviderProfile>[]
    videoRoom?: OMReference<CnxVideoRoom>
    cnxServiceCategories?: OMReference<CnxServiceCategory>[]
}

interface CnxMeetingWaitingLoungeComponentState {
    machineState: CnxMeetingWaitingLoungeStateMachine.State
    queryParams: ListStateMachine.QueryParams<CnxMeeting>
    activeTab: string

    timeElapsedWaitingForProvider: moment.Duration
    countDownTimer: moment.Duration

    isShowingCancelBookingModal?: boolean
}

class CnxMeetingWaitingLounge
extends React.Component<CnxMeetingWaitingLoungeProps, CnxMeetingWaitingLoungeComponentState> {

    private worker?: NodeJS.Timeout

    private machine: CnxMeetingWaitingLoungeStateMachine

    private id?: NodeJS.Timeout

    constructor(props: CnxMeetingWaitingLoungeProps) {
        super(props)
        this.machine = this.props.machine
        this.state = {
            machineState: this.machine.state,
            queryParams: {
                currentPage: 0,
                sortBy: "createdAt",
                sortDirection: "DESC",
            },
            activeTab: "0",

            timeElapsedWaitingForProvider: moment.duration(0, "second"),
            countDownTimer: moment.duration(0, "second"),
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)
        this.machine.load(new OMReference(CnxMeeting, this.props.match.params.id))

        this.worker = setInterval(() => {
            if (this.machine.state.name !== "presenting") {
                return
            }

            const meeting = this.machine.state.item

            switch (meeting.meetingStatus) {
                case "PENDING":
                    this.setState({
                        timeElapsedWaitingForProvider: moment.duration(moment().diff(meeting.createdAt))
                    })
                    return
                case "STARTED":
                    this.setState({
                        countDownTimer: moment.duration(meeting.scheduledEndAt!.diff(moment()))
                    })
                    return
                case "COMPLETED":
                case "FAILED":
                    if (this.worker) {
                        clearTimeout(this.worker)
                    }
                    break
                default:
                    return
            }
        }, 1000)
    }

    public componentWillUnmount() {
        this.machine.unsubscribeRealtime()
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
            case "loading":
                return <ModalSpinner />
            case "promptingDelete":
            case "performingDelete":
            case "presenting":
            case "leavingMeeting":
            case "tryingOtherTutors":
                return (
                    <>
                        <Modal show={this.state.isShowingCancelBookingModal || false} onDismiss={() => this.setState({ isShowingCancelBookingModal: false })} >
                            <ModalDialog
                                title={<Body className="d-block">Cancel Booking?</Body>}
                                body={<Footnote>Are you sure you want to leave and cancel this booking?</Footnote>}
                                shouldDisplayCancelActionButton={false}
                                actionButton={
                                    <>
                                        <button className="btn btn-link text-danger" disabled={this.state.machineState.name === "leavingMeeting"} onClick={() => this.machine.leaveMeeting()}>
                                            Leave
                                        </button>

                                        <button className="btn btn-link text-accent" disabled={this.state.machineState.name === "leavingMeeting"} onClick={() => this.setState({ isShowingCancelBookingModal: false })}>
                                            Stay
                                        </button>
                                    </>
                                }
                            />
                        </Modal>
                        <WaitingLoungeWrapper
                            potentialMatches={machineState.item.matchProviders}
                            match={machineState.item.provider}
                            consumer={machineState.item.consumers[0]}
                            subject={machineState.item.serviceCategories.filter((each) => each.actualObject?.categoryType === "SUBJECT")[0]}
                            onCancelButtonClicked={this.onCancelButtonClick}
                            onTryOtherTutorButtonClicked={this.onTryOtherTutorButtonClicked}
                            scheduledStartAt={machineState.item.scheduledStartAt!}
                            meeting={machineState.item}
                            expiredAt={machineState.item.expireAt!}
                        />
                    </>
                )
            case "showingError":
                return <ErrorAlert error={machineState.error} />
            case "showingEdit":
                this.props.history.push(`${this.props.match.url}/edit`)
                return null
            case "completed":
                window.location = "/" as any
                return null
        }
    }

    onTryOtherTutorButtonClicked = () => this.machine.tryOtherTutors()

    onCancelButtonClick = () => {
        switch (this.state.machineState.name) {
            case "promptingDelete":
            case "performingDelete":
            case "presenting":
            case "leavingMeeting":
            case "tryingOtherTutors":
                break
            default:
                return
        }

        const hasExpired = this.state.machineState.item.expireAt?.isSameOrBefore() ?? true
        if (hasExpired) {
            this.machine.leaveMeeting()
        } else {
            this.setState({ isShowingCancelBookingModal: true })
        }
    }

    private subscriptionCallback = (machineState: CnxMeetingWaitingLoungeStateMachine.State) => {
        this.setState({ machineState })
    }

    private showEdit = (item: CnxMeeting) => () => {
        this.machine.showEdit(item)
    }
}

export default CnxMeetingWaitingLounge
