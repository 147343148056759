import { OMReference, OMUniverse } from "firmament-node-sdk"
import moment from "moment"
import * as React from "react"
import { RouteComponentProps } from "react-router"
import { TableCellProps } from "react-virtualized"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import isEqual from "react-fast-compare"
import Dropzone, { DropEvent } from "react-dropzone"
import "react-photoswipe/lib/photoswipe.css"

import debounce from "../../../helpers/debounce"

import {
    DateRangePicker,
    ErrorAlert,
    ModalSpinner,
    ModelTable,
    ListStateMachine,
    Form,
    PhotoPicker,
} from "../../../components"
import { withMachine, withQueryString } from "../../../hoc"

import { CnxMeeting, GenImage, CnxMemberProfile, CnxMessage, WltTransaction } from "../../../domain"

import { CnxMessageListStateMachine } from "../CnxMessageList"
import CnxMessageChat from "./elements/CnxMessageChat"
import getProfile from "../../../helpers/Profile"
import TextInput from "../../../components/deprecated-components/TextInput"
import { PhotoSwipe, PhotoSwipeGalleryItem } from "react-photoswipe"
import getImageDimension from "../../../helpers/getImageDimension"
import ScreenshotButton from "../../../components/deprecated-components/ScreenshotButton"
import { AppStateContext } from "../../../views/App/elements/AppStateProvider"

interface CnxMessageListProps extends RouteComponentProps {
    machine: CnxMessageListStateMachine

    meeting?: OMReference<CnxMeeting>
    attachedImage?: OMReference<GenImage>
    sender?: OMReference<CnxMemberProfile>

    queryString: any
    updateQueryString: (keyPath: any) => (value: any) => void
    setQueryString: (state: any) => void
}

interface CnxMessageListComponentState {
    machineState: CnxMessageListStateMachine.State
    queryParams: ListStateMachine.QueryParams<CnxMessage> & Partial<CnxMessage>

    text?: string

    photos?: GenImage[]

    presentingImageFullscreen?: boolean

    images?: PhotoSwipe.Item[]
    selectedMessage?: CnxMessage
    imageIndex?: number
}

class CnxMessageList extends React.Component<CnxMessageListProps, CnxMessageListComponentState> {

    private machine: CnxMessageListStateMachine

    private messageListRefs: React.RefObject<HTMLDivElement> = React.createRef()

    /* SECTION: Callbacks */

    private onSortChange = (sort: any) => this.props.setQueryString({ ...sort })
    private updateQueryParams = ModelTable.updateQueryParams.bind(this as any)
    private onDateRangeChange = DateRangePicker.onDateRangeChange.bind(this)

    constructor(props: CnxMessageListProps) {
        super(props)
        this.machine = new CnxMessageListStateMachine()
        this.state = {
            machineState:  this.machine.state,
            queryParams: {
                currentPage:  0,
                sortBy: "createdAt",
                sortDirection: "DESC",

                meeting: this.props.meeting ? new OMReference(CnxMeeting, this.props.meeting.id) : undefined,
                sender: this.props.sender ? new OMReference(CnxMemberProfile, this.props.sender.id) : undefined,
            },
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)

        this.machine.load({
            ...this.state.queryParams,
            ...this.props.queryString,
        })

        this.scrollToBottom()
    }

    public componentDidUpdate(prevProps: CnxMessageListProps, prevState: CnxMessageListComponentState) {
        if (this.state.queryParams !== prevState.queryParams || !isEqual(this.props.queryString, prevProps.queryString)) {
            this.machine.load({
                ...this.state.queryParams,
                ...this.props.queryString,
            })
        }

        if (this.machine.state.name === "presenting") {
            const photoMessages = this.machine.state.items
                .filter((message) => message.messageType === "PHOTO" && message.attachedImage?.actualObject)

            const photos = photoMessages.map((message) => message.attachedImage?.actualObject).filter(Boolean) as GenImage[]

            photos.forEach((photo) => getImageDimension(photo.id))
        }

        if (this.state.selectedMessage !== prevState.selectedMessage) {
            const selectedMessage = this.state.selectedMessage
            if (this.machine.state.name === "presenting" && selectedMessage) {
                const photoMessages = this.machine.state.items
                    .filter((message) => message.messageType === "PHOTO" && message.attachedImage?.actualObject)

                const imageIndex = photoMessages.findIndex((msg) => msg.id === selectedMessage.id)

                this.setState({ imageIndex, presentingImageFullscreen: true })
            }
        }
    }

    public componentWillUnmount() {
        this.machine.unsubscribeRealtime()
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
                return <ModalSpinner />
            case "loading":
            case "presenting":
            case "promptingDelete":
            case "performingDelete":
            case "performingCreate":
                return (
                    <>
                        {machineState.name === "loading" && <ModalSpinner />}

                        <AppStateContext.Consumer>
                            {({ twilioVideoPresentationMode }) => {
                                if (twilioVideoPresentationMode !== "fullscreen.single") {
                                    return null
                                }

                                return (
                                    <div className="position-absolute d-flex justify-content-center align-items-center" style={{ zIndex: 999, bottom: 63 + 8, left: 0, right: 0, pointerEvents: "none" }}>
                                        <span style={{ pointerEvents: "all" }}>
                                            <ScreenshotButton onScreenshotCreated={(file) => {
                                                const screenshot = new GenImage({ file })

                                                const screenshotMessage = new CnxMessage({
                                                    attachedImage: new OMReference(GenImage, screenshot.id),
                                                    messageType: "PHOTO",

                                                    meeting: this.props.meeting!,
                                                    sender: new OMReference(CnxMemberProfile, getProfile()!.id),
                                                })

                                                OMUniverse.shared.touch([screenshot])

                                                this.machine.performCreate([screenshotMessage])
                                            }} />
                                        </span>
                                    </div>
                                )
                            }}
                        </AppStateContext.Consumer>

                        <div style={{ zIndex: 999 }}>
                            <PhotoSwipe
                                onClose={() => this.setState({ presentingImageFullscreen: false, selectedMessage: undefined })}
                                // thumbnailContent={(item) => <img src={item.thumbnail} alt="" width={120} height={90} />}
                                isOpen={!!this.state.presentingImageFullscreen}
                                items={
                                    machineState.items
                                        .filter((message) => message.messageType === "PHOTO" && message.attachedImage?.actualObject)
                                        .map<PhotoSwipe.Item>((message) => {
                                            const dimension = getImageDimension(message.attachedImage?.id || "")
                                            return {
                                                src: message.attachedImage!.actualObject!.url,
                                                // thumbnail: message.attachedImage!.actualObject!.preview,
                                                w: dimension?.x ?? 0,
                                                h: dimension?.y ?? 0,
                                            }
                                        })
                                }
                                options={{
                                    index: this.state.imageIndex,
                                    closeOnScroll: false,
                                    shareEl: false,
                                    fullscreenEl: false,
                                } as any}
                            />
                        </div>

                        <div className="position-absolute" style={{ top: 55, bottom: this.props.match.url.includes(WltTransaction.Path) ? 50 : 0, left: 0, right: 0, overflowY: "scroll" }}>
                            <div className="d-flex flex-column bg-white h-100">

                                <div className="flex-grow-1" style={{ overflow: "auto", minHeight: 0 }} ref={this.messageListRefs}>
                                    <div className="my-16px mx-16px">
                                        <CnxMessageChat onImageClick={(selectedMessage) => this.setState({ selectedMessage })} cnxMessages={machineState.items} reply={(replyToMessage, suggestion) => {
                                            if (this.machine.state.name !== "presenting") {
                                                return
                                            }

                                            if (!this.props.meeting) {
                                                return
                                            }

                                            const profile = getProfile()
                                            const meeting = this.props.meeting

                                            if (!profile) {
                                                return
                                            }

                                            const message = new CnxMessage({
                                                text: suggestion,
                                                messageType: "TEXT",

                                                meeting,
                                                sender: new OMReference(CnxMemberProfile, profile.id),

                                                inReplyTo: new OMReference(CnxMessage, replyToMessage.id),
                                            })

                                            this.machine.performCreate([message])
                                        }} />
                                    </div>
                                </div>

                                {!this.props.match.url.includes(`/${WltTransaction.Path}/`) && (
                                    <div className="bg-white" style={{ boxShadow: "0 -1px 0 0 rgba(0, 0, 0, 0.12)", paddingTop: 8, paddingBottom: 8 }}>
                                        <Form onSubmit={this.onSubmit} className="w-100">
                                            <div className="col">
                                                <PhotoPicker type="image" value={this.state.photos} max={0} onChange={(photos) => this.setState({ photos })} />
                                            </div>

                                            <div className="d-flex align-items-center">
                                                <Dropzone
                                                    multiple={true}
                                                    onDropAccepted={this.onDropAccepted}
                                                    accept="image/*"
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <button type="button" className="btn btn-lg btn-link text-secondary">
                                                                <i className="fa fa-fw fa-image" />
                                                            </button>
                                                        </div>
                                                    )}
                                                </Dropzone>

                                                <div className="flex-grow-1">
                                                    <TextInput
                                                        placeholder="Message"
                                                        value={this.state.text}
                                                        onChange={(text) => this.setState({ text })}
                                                        style={{
                                                            borderRadius: 20,
                                                            backgroundColor: "rgba(118, 118, 128, 0.16)",
                                                        }}
                                                    />
                                                </div>

                                                <button className="mx-1 btn btn-primary">Send</button>
                                            </div>
                                        </Form>
                                    </div>
                                )}
                            </div>
                        </div>

                    </>
                )
            case "showingDetail":
                this.props.history.push(`${this.props.match.url}/${machineState.ref.id}/edit`)
                return null
            case "showingError":
                return <ErrorAlert error={machineState.error} />
            case "showingCreate":
                this.props.history.push(`${this.props.match.url}/new`)
                return null
            case "completed":
                return null
        }
    }

    private subscriptionCallback = (machineState: CnxMessageListStateMachine.State) => {
        this.setState({ machineState })

        if (machineState.name === "presenting") {
            this.scrollToBottom()
        }
    }

    /* SECTION: Custom Cell Renderer */

    private showDetail = (id: string) => () => {
        const ref = new OMReference(CnxMessage, id)
        this.machine.showDetail(ref)
    }

    private actionCellRenderer = (props: TableCellProps) => {
        const cnxMessage = new CnxMessage(props.rowData)

        return (
            <div onClick={(event) => event.stopPropagation()}>
                <UncontrolledDropdown>
                    <DropdownToggle color="">
                        <i className="fa fa-fw fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem className="text-primary" tag={"button"} type="button" onClick={() => this.machine.showDetail(new OMReference(CnxMessage, cnxMessage.objectUuid))}>Edit</DropdownItem>
                        <DropdownItem className="text-danger" tag={"button"} type="button" onClick={() => this.machine.promptDelete(cnxMessage)}>Delete</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        )
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        this.debounceUpdate(value)
    }

    private debounceUpdate = debounce((value: string) => {
        this.props.updateQueryString("title")(value || null as any)
    }, 250)

    private onSubmit = (event: React.FormEvent) => {
        event.preventDefault()

        if (this.machine.state.name !== "presenting") {
            return
        }

        if (!this.state.text && !this.state.photos) {
            return
        }

        if (!this.props.meeting) {
            return
        }

        const profile = getProfile()
        const meeting = this.props.meeting

        if (!profile) {
            return
        }

        const photoMessages = this.state.photos && this.state.photos.map((each) => new CnxMessage({
            attachedImage: new OMReference(GenImage, each.id),
            messageType: "PHOTO",

            meeting,
            sender: new OMReference(CnxMemberProfile, profile.id),
        })) || []

        const message = this.state.text && new CnxMessage({
            text: this.state.text,
            messageType: "TEXT",

            meeting,
            sender: new OMReference(CnxMemberProfile, profile.id)
        }) || undefined

        const messages = [...photoMessages, message].filter(Boolean) as CnxMessage[]

        this.setState({ photos: [], text: "" })

        this.machine.performCreate(messages)
    }

    private onDropAccepted = (files: File[], event: DropEvent) => {
        const newValues = files.map((file, index) => new GenImage({ file, mimetype: file.type, createdAt: moment().add(index, "seconds") }))
        OMUniverse.shared.touch(newValues)
        this.setState({ photos: [...this.state.photos || [], ...newValues] })
    }

    private scrollToBottom = () => {
        if (this.messageListRefs.current) {
            const div = this.messageListRefs.current
            div.scrollTo(0, div.scrollHeight)

            // Re-do scrolling because photo takes a while to load
            setTimeout(() => {
                if (this.messageListRefs.current) {
                    const div = this.messageListRefs.current
                    div.scrollTo(0, div.scrollHeight)
                }
            }, 1500);
        }
    }

}

export default withMachine(CnxMessageListStateMachine)(withQueryString({ path: CnxMessage.Path })(CnxMessageList))
