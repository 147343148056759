import * as Sentry from "@sentry/react"

let visibilityState = "visible"

document.addEventListener("visibilitychange", () => {
    visibilityState = document.visibilityState
    console.log(visibilityState)
})

const _notify = (title: string, options?: NotificationOptions, mode: "always" | "background" = "background") => {
    if ((navigator?.serviceWorker as any)?.showNotification) {
        try {
            (navigator?.serviceWorker as any)?.showNotification(title, options)
        } catch (error) {
            Sentry.captureMessage(error)
        }
    } else {
        new Notification(title, options)
    }
}

const notify = (title: string, options?: NotificationOptions, mode: "always" | "background" = "background") => {

    if (!window.Notification) {
        return
    }

    switch (Notification.permission) {
        case "denied":
            return
        case "default":
            Notification.requestPermission(() => {
                switch (mode) {
                    case "always":
                        _notify(title, options)
                        break
                    case "background":
                        if (Notification.permission === "granted" && visibilityState === "hidden") {
                            _notify(title, options)
                        }
                        break
                }
            })
            break
        case "granted":
            switch (mode) {
                case "always":
                    _notify(title, options)
                    break
                case "background":
                    if (visibilityState === "hidden") {
                        _notify(title, options)
                    }
                    break
            }
            break
    }
}

notify.requestPermissionIfHavent = (title: string, options?: NotificationOptions) => {

    if (!window.Notification) {
        return
    }

    if (Notification.permission !== "default") {
        return
    }

    Notification.requestPermission(() => {
        _notify(title, options)
    })

}

export default notify
