import React from "react"
import DelayRun from "../../helpers/DelayRun"


interface TextInputHeaderComponentProps {
    value?: string
    label?: string
    onChange: (value?: string) => void
}

class TextInputHeader extends React.Component<TextInputHeaderComponentProps> {

    private delayRun: DelayRun = new DelayRun(0.2)

    public render() {
        return (
            <div>
                {this.props.label && <label>{this.props.label}</label>}
                {/* tslint:disable-next-line */}
                <input defaultValue={this.props.value} className="form-control" type="search" onChange={this.delayedOnChange} placeholder="Search..." onClick={(event) => event.stopPropagation()} />
            </div>
        )
    }

    private delayedOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value || undefined

        this.delayRun.execute(() => {
            this.props.onChange(value)
        })
    }
}

export default TextInputHeader
