//  NotifMessage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2019-08-02
//  Copyright © 2019 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    Profile,
} from "firmament-node-sdk"
import { Moment } from "moment"
import moment from "moment"

import {
    NotifBroadcast,
} from ".."

export interface NotifMessageProperties extends DomainObjectProperties {
    body?: string
    bodyHtml?: string
    archivedAt?: Moment
    lastReadAt?: Moment

    profile?: OMReference<Profile>
    broadcast: OMReference<NotifBroadcast>
}

class NotifMessage extends DomainObject implements NotifMessageProperties {

    public static Type: string = "notif_message"
    public static Path: string = "notif_messages"

    public body?: string
    public bodyHtml?: string
    public archivedAt?: Moment
    public lastReadAt?: Moment

    public profile?: OMReference<Profile>
    public broadcast: OMReference<NotifBroadcast>

    constructor(object: NotifMessageProperties) {
        super(object)

        this.body = object.body
        this.bodyHtml = object.bodyHtml
        this.archivedAt = moment(object.archivedAt)
        this.lastReadAt = moment(object.lastReadAt)

        this.profile = object.profile && new OMReference(Profile, object.profile)
        this.broadcast = new OMReference(NotifBroadcast, object.broadcast)
    }
}

export default NotifMessage
