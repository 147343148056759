//  SchTutorProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-11-12
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    Command,
    CommandManager,
    OMReference,
} from "firmament-node-sdk"
import {
    CnxProviderProfile,
    CnxProviderProfileProperties,
    SchIntroVideo,
} from ".."

export interface SchTutorProfileProperties extends CnxProviderProfileProperties {
    university?: string

    introVideo?: OMReference<SchIntroVideo>
}

class SchTutorProfile extends CnxProviderProfile implements SchTutorProfileProperties {

    public static Type: string = "sch_tutor_profile"
    public static Path: string = "sch_tutor_profiles"

    public university?: string

    public introVideo?: OMReference<SchIntroVideo>




    /* NotifyPendingReviewCommand */
    protected static NotifyPendingReviewCommand = class NotifyPendingReviewCommand extends Command<SchTutorProfile> {
        public static readonly Path = "notify_pending_review"

        public constructor(
            receiver: SchTutorProfile,
        ) {
            super(receiver)
        }
    }


    public notifyPendingReview() {
        try {
            const command = new SchTutorProfile.NotifyPendingReviewCommand(this)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }




    constructor(object: SchTutorProfileProperties) {
        super(object)

        this.university = object.university

        this.introVideo = object.introVideo && new OMReference(SchIntroVideo, object.introVideo)
    }
}

export default SchTutorProfile
