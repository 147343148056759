import React from "react"
import ReactDOM from "react-dom"
import Lottie from "@thamcheongkit/react-lottie"

import ic_loading from "../../assets/ic_loading.json"
import { isBlock } from "typescript"

interface Props {
    isBlocking?: boolean
}

const ModalSpinner: React.FunctionComponent<Props> = ({ isBlocking }) => {
    const [shouldShow, setShouldShow] = React.useState(false)

    React.useEffect(() => {
        setTimeout(() => setShouldShow(true), 250)
    }, [])

    if (!shouldShow) {
        return null
    }

    return ReactDOM.createPortal(
        <div
            className="modal show d-flex justify-content-center align-items-center w-100 h-100"
            style={{ display: "block", pointerEvents: !isBlocking ? "none" : undefined }}
        >
            {/* <button type="button" className="btn btn-secondary btn-lg" disabled={true} style={{ borderRadius: 4 }}> */}
                {/* <i className="fa fa-fw fa-spinner faa-spin animated" /> */}
                <Lottie width={200} height={200} options={{ animationData: ic_loading }} />
            {/* </button> */}
        </div>,
        document.getElementById("modal") as HTMLElement,
    )
}

export default ModalSpinner
