import React from "react"

import ic_app_store from "../../assets/ic_app_store.png"
import ic_google_play from "../../assets/ic_google_play.png"
import settings from "../../settings"

const DownloadApp = () => {
    return (
        <div className="d-flex">
            <a href={settings.play_store_url} target="_blank">
                <img alt="Google Play" src={ic_google_play} style={{ maxHeight: 30 }} className="mr-4px" />
            </a>

            <a href={settings.app_store_url} target="_blank">
                <img alt="App Store" src={ic_app_store} style={{ maxHeight: 30 }} />
            </a>
        </div>
    )
}

export default DownloadApp
