import React from "react"
import Dropzone, { DropzoneProps, DropzoneOptions } from "react-dropzone";
import { OMFile } from "firmament-node-sdk";
import { AvatarImage } from "../../../airframe-components/Avatar/AvatarImage";
import { AvatarAddonIcon } from "../../../airframe-components/Avatar/AvatarAddonIcon";
import ic_portrait_placeholder from "../../../assets/ic_portrait_placeholder.png"

// import "react-dropzone/examples/theme.css"

interface ProfilePhotoInputProps extends Omit<DropzoneProps, "onDropAccepted" | "children"> {
    value?: OMFile
    onChange?: (value?: OMFile) => void

    required?: boolean
    invalidText?: React.ReactElement
}

class ProfilePhotoInput extends React.Component<ProfilePhotoInputProps> {

    private get isInvalid(): boolean {
        if (!this.props.required) {
            return false
        }

        if (!this.props.value) {
            return true
        }

        return false
    }

    render() {
        return (
            <div className="position-relative">
                <Dropzone onDropAccepted={this.onDropAccepted} {...this.props}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "d-inline-block" })}>
                            <AvatarImage
                                size="lg"
                                src={this.props.value && (this.props.value.preview || (this.props.value.file && window.URL.createObjectURL(this.props.value.file))) || ic_portrait_placeholder}
                                addOns={[
                                    <AvatarAddonIcon
                                        className="fa fa-circle text-gray-200"
                                        color="white"
                                        key="avatar-icon-bg"
                                    />,
                                    <AvatarAddonIcon
                                        className="fa fa-pencil"
                                        color="success"
                                        key="avatar-icon-fg"
                                    />
                                ]}
                            />
                            {/* <i className="fa fa-cloud-upload fa-fw fa-3x mb-3"></i>
                        <h5 className='mt-0'>
                            Upload Your Photo
                        </h5>
                        <p>
                            Drag a file here or <span className='text-primary'>browse</span> for a file to upload.
                        </p>
                        {this.props.children} */}
                            <input {...getInputProps()} />
                        </div>
                    )}
                </Dropzone>

                <input type="text" hidden={true} className="form-control" required={this.isInvalid} />
                <div className="invalid-tooltip" style={{ zIndex: 0 }}>{this.props.invalidText || "Field is required"}</div>
            </div>
        )
    }

    public onDropAccepted: DropzoneOptions["onDropAccepted"] = (files) => {
        const file = files[files.length - 1]
        const omFile = new OMFile({ file, mimetype: file.type, title: file.name })
        if (this.props.onChange) {
            this.props.onChange(omFile)
        }
    }

    public remove: React.DOMAttributes<HTMLInputElement>["onClick"] = (event) => {
        event.stopPropagation()
        if (this.props.onChange) {
            this.props.onChange()
        }
    }
}

export default ProfilePhotoInput
