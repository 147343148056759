import * as React from "react"
import qs from "qs"
import memoize from "lodash/memoize"

import { CnxServiceCategory } from "../domain"
import { OMReference, OMUniverse } from "firmament-node-sdk"
import { useLocation } from "react-router"

interface CategoriesParamsContext {
    categories?: OMReference<CnxServiceCategory>[]
}

export const CategoriesParamsContext = React.createContext<CategoriesParamsContext>({} as any)

export const useCategoriesParamsContext = () => React.useContext(CategoriesParamsContext)

export const CategoriesParamsContextProvider: React.FunctionComponent = (props) => {
    const [categories, setCategories] = React.useState<OMReference<CnxServiceCategory>[]>()

    const location = useLocation()

    const findCategoryBySlug = memoize((slug: string) => {
        const memoryCachedObjects = OMUniverse.shared.cache.getState() as { [_: string]: any | undefined }
        const category = Object.values(memoryCachedObjects).find((obj) => obj.type === CnxServiceCategory.Type && obj.slug === slug)
        return category?.objectUuid && new OMReference(CnxServiceCategory, category.objectUuid)
    })

    React.useEffect(() => {
        const queryString = qs.parse(location.search?.slice(1))

        const categoriesSlugRaw = queryString["categories"]
        if (!categoriesSlugRaw || typeof categoriesSlugRaw !== "string") {
            return
        }

        const categoriesSlug = categoriesSlugRaw.split(",")
        const categories = categoriesSlug.map(findCategoryBySlug).filter(Boolean) as OMReference<CnxServiceCategory>[]

        setCategories(categories)
    }, [location.search])

    return (
        <CategoriesParamsContext.Provider value={{ categories }}>
            {props.children}
        </CategoriesParamsContext.Provider>
    )
}
