import React from "react"

import { BuildNumber } from "."

export class FullscreenLayout extends React.Component {
    render() {
        return (
            <div className="fullscreen">
                <div className="fullscreen__section fullscreen__section--center align-items-start">
                    <div className="fullscrenn__section__child" style={{ width: 420 }}>
                        {this.props.children}

                        <div className="mt-5" />

                        <BuildNumber />
                    </div>
                </div>
            </div>
        )
    }
}
