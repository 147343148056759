import React from "react"
import moment from "moment"
import { CnxMeeting } from "../../../../domain"

interface Props {
    forceEndMeeting?: Function
    meeting: CnxMeeting
}

const MeetingTimer: React.FunctionComponent<Props> = ({ meeting, forceEndMeeting }) => {
    const [countDownTimer, setCountDownTimer] = React.useState<moment.Duration>(moment.duration(0, "second"))
    const [timeElapsedWaitingForProvider, setTimeElapsedWaitingForProvider] = React.useState<moment.Duration>(moment.duration(0, "second"))

    React.useEffect(() => {
        const gracePeriodMins = meeting.serviceTier.actualObject?.gracePeriodMins
        const gracePeriodSeconds = gracePeriodMins !== undefined ? gracePeriodMins * 60 : 120

        const worker = setInterval(() => {
            switch (meeting.meetingStatus) {
                case "PENDING":
                    setTimeElapsedWaitingForProvider(moment.duration(moment().diff(meeting.createdAt)))
                    return
                case "STARTED":
                    const duration = moment.duration(meeting.scheduledEndAt!.diff(moment()))
                    if (forceEndMeeting && duration.asSeconds() <= -gracePeriodSeconds) {
                        forceEndMeeting()
                    }
                    setCountDownTimer(duration)
                    return
                case "COMPLETED":
                case "FAILED":
                    if (worker) {
                        clearTimeout(worker)
                    }
                    break
                default:
                    return
            }
        }, 1000)

        return () => clearTimeout(worker)
    }, [meeting.meetingStatus])

    return (
        <div className="position-absolute" style={{ top: 63, pointerEvents: "none", left: 0, right: 0, zIndex: 2 }}>
            <div className="d-flex justify-content-center">
                <span className={`badge ${(() => {
                    switch (meeting.meetingStatus) {
                        case "STARTED":
                            return countDownTimer.asSeconds() < 0
                                ? "badge-danger"
                                : countDownTimer.asMinutes() > 1
                                    ? "badge-secondary"
                                    : countDownTimer.asSeconds() % 2 === 0
                                        ? "badge-secondary"
                                        : "badge-danger"
                        default:
                            return "badge-secondary"
                    }
                })()}`}>
                    {(() => {
                        switch (meeting.meetingStatus) {
                            case "PENDING":
                            case "CONFIRMED":
                                return `Waiting for Tutor ${Math.abs(timeElapsedWaitingForProvider.hours()) > 0
                                        ? Math.abs(timeElapsedWaitingForProvider.hours()).toString().padStart(2, "0") + ":"
                                        : ""
                                    }${Math.abs(timeElapsedWaitingForProvider.minutes()).toString().padStart(2, "0")}:${Math.abs(timeElapsedWaitingForProvider.seconds()).toString().padStart(2, "0")}`
                            case "STARTED":
                                return `${countDownTimer.asSeconds() < 0 ? "Overtime -" : ""}${Math.abs(countDownTimer.hours()) > 0
                                        ? Math.abs(countDownTimer.hours()).toString().padStart(2, "0") + ":"
                                        : ""
                                    }${Math.abs(countDownTimer.minutes()).toString().padStart(2, "0")}:${Math.abs(countDownTimer.seconds()).toString().padStart(2, "0")}`
                            default:
                                return "Meeting has Ended"
                        }
                    })()}
                </span>
            </div>
        </div>
    )
}
export default MeetingTimer
