import { OMReference } from "firmament-node-sdk"

import { ArticleContent, ArticleContentProperties, ArticleImage } from "."

export interface ArticleMediaProperties extends ArticleContentProperties {
    image?: OMReference<ArticleImage>
}

class ArticleMedia extends ArticleContent implements ArticleMediaProperties {

    public static Type: string = "article_media"
    public static Path: string = "article_media"

    public static isType(object: any): object is ArticleMedia {
        return object.type === "article_media" || object.objectType === "article_media"
    }

    public image?: OMReference<ArticleImage>

    constructor(object: ArticleMediaProperties) {
        super(object)

        this.image = object.image && new OMReference(ArticleImage, object.image)
    }

}

export default ArticleMedia
