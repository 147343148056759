import { OMFile, OMReference } from "firmament-node-sdk"
import React from "react"

import { FileRenderer, Body } from "../../../../components"
import { ArticleMedia } from "../../../../domain"

export interface ArticleMediaCardComponentProps {
    articleMedia: ArticleMedia
}

export interface ArticleMediaCardComponentState {

}

class ArticleMediaCard
    extends React.Component<ArticleMediaCardComponentProps, ArticleMediaCardComponentState> {

    constructor(props: ArticleMediaCardComponentProps) {
        super(props)
    }

    public render() {
        return (
            <div className="row">
                <div className="container">
                    <div className="row">
                        <p className="w-100">
                            {
                                this.props.articleMedia.image
                                    ? (() => {
                                        const image = this.props.articleMedia.image.actualObject!

                                        if (image.mimetype.startsWith("video")) {
                                            return (
                                                <FileRenderer
                                                    hideDescription={true}
                                                    type="video"
                                                    file={this.props.articleMedia.image as OMReference<OMFile>}
                                                    className="w-100"
                                                />
                                            )
                                        }

                                        return (
                                            <FileRenderer
                                                hideDescription={true}
                                                type="image"
                                                file={this.props.articleMedia.image as OMReference<OMFile>}
                                                className="w-100"
                                            />
                                        )
                                    })()
                                    : "-"
                            }
                        </p>
                    </div>

                    <div className="row">
                        <p>
                            <Body>{this.props.articleMedia.title}</Body>
                        </p>
                    </div>
                </div>
            </div >
        )
    }

}

export default ArticleMediaCard
