//  CnxArticle.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    CommandManager,
    Command,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    Article,
    ArticleProperties,
    ArticleImage,
    ArticleContent,
    Profile,
    CnxServiceCategory,
    CnxConsumerProfile,
    CnxProviderProfile,
} from ".."
import uuid from "uuid/v4"

type TargetAudience = "CONSUMERS" | "PROVIDERS" | "ALL"

export interface CnxArticleProperties extends ArticleProperties {
    tag?: string
    tagColor?: string
    targetAudience: TargetAudience

    serviceCategories?: Array<OMReference<CnxServiceCategory>>
    consumerProfiles?: Array<OMReference<CnxConsumerProfile>>
    providerProfiles?: Array<OMReference<CnxProviderProfile>>
}

class CnxArticle extends Article implements CnxArticleProperties {

    public static Type: string = "cnx_article"
    public static Path: string = "cnx_articles"

    public tag?: string
    public tagColor?: string
    public targetAudience: TargetAudience

    public serviceCategories: Array<OMReference<CnxServiceCategory>>
    public consumerProfiles: Array<OMReference<CnxConsumerProfile>>
    public providerProfiles: Array<OMReference<CnxProviderProfile>>



    /* SetCategories */
    protected static SetCategoriesCommand = class SetCategoriesCommand extends Command<CnxArticle> {
        public static readonly Path = "set_categories"

        public constructor(
            receiver: CnxArticle,
            public categories: OMReference<CnxServiceCategory>[],
        ) {
            super(receiver)
        }

        public execute(): Promise<any> {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            categories: this.categories.map((each) => each.id)
                        },
                    }
                },
                method: "POST",
                url,
            })
        }
    }

    public setCategories(categories: OMReference<CnxServiceCategory>[]) {
        try {
            const command = new CnxArticle.SetCategoriesCommand(this, categories)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }



    /* SetProviders */
    protected static SetProvidersCommand = class SetProvidersCommand extends Command<CnxArticle> {
        public static readonly Path = "set_providers"

        public constructor(
            receiver: CnxArticle,
            public providers: OMReference<CnxProviderProfile>[],
        ) {
            super(receiver)
        }

        public execute(): Promise<any> {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            providers: this.providers.map((each) => each.id)
                        },
                    }
                },
                method: "POST",
                url,
            })
        }
    }

    public setProviders(providers: OMReference<CnxProviderProfile>[]) {
        try {
            const command = new CnxArticle.SetProvidersCommand(this, providers)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }



    /* SetConsumers */

    protected static SetConsumersCommand = class SetConsumersCommand extends Command<CnxArticle> {
        public static readonly Path = "set_consumers"

        public constructor(
            receiver: CnxArticle,
            public consumers: OMReference<CnxConsumerProfile>[],
        ) {
            super(receiver)
        }

        public execute(): Promise<any> {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            consumers: this.consumers.map((each) => each.id)
                        },
                    }
                },
                method: "POST",
                url,
            })
        }
    }

    public setConsumers(consumers: OMReference<CnxConsumerProfile>[]) {
        try {
            const command = new CnxArticle.SetConsumersCommand(this, consumers)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }



    constructor(object: CnxArticleProperties) {
        super(object)

        this.tag = object.tag
        this.tagColor = object.tagColor
        this.targetAudience = object.targetAudience

        this.serviceCategories = object.serviceCategories ? object.serviceCategories.map((item) => new OMReference(CnxServiceCategory, item)) : []
        this.consumerProfiles = object.consumerProfiles ? object.consumerProfiles.map((item) => new OMReference(CnxConsumerProfile, item)) : []
        this.providerProfiles = object.providerProfiles ? object.providerProfiles.map((item) => new OMReference(CnxProviderProfile, item)) : []
    }
}

export default CnxArticle
