import React from "react"
import Select from "react-select"

interface MultiSelectHeaderComponentProps {
    disabled?: boolean
    label: string
    defaultValue?: string[]
    options: string[]
    onChange: (value?: string[]) => void
    value?: string[]
}

interface MultiSelectHeaderComponentState {
    value?: string[]
}

class MultiSelectHeader extends React.Component<MultiSelectHeaderComponentProps, MultiSelectHeaderComponentState> {

    public constructor(props: MultiSelectHeaderComponentProps) {
        super(props)
        this.state = {
            value: this.props.value,
        }
    }

    public componentDidUpdate(prevProps: MultiSelectHeaderComponentProps, prevState: MultiSelectHeaderComponentState) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value })
        }

        if (this.state.value !== prevState.value) {
            this.props.onChange(this.state.value || undefined)
        }
    }

    public render() {
        return (
            <div>
                <label>{this.props.label}</label>
                <Select
                    isDisabled={this.props.disabled}
                    placeholder="All"
                    isMulti={true}
                    isClearable={true}
                    value={
                        this.state.value && this.state.value.map((option) => ({
                            label: option,
                            value: option,
                        }))
                    }
                    options={
                        this.props.options.map((option) => ({
                            label: option,
                            value: option,
                        }))
                    }
                    onChange={this.onChange}
                />
            </div>
        )
    }

    private onChange = (values: any): void => {
        this.setState({
            value: values
                ? values.map((item: any) => item.value)
                : undefined,
        })
    }

}

export default MultiSelectHeader
