//  CnxProviderProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    Command,
    CommandManager,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxMemberProfile,
    CnxMemberProfileProperties,
    User,
    Profile,
    Article,
    ProfilePhoto,
    CnxMessage,
    CnxMoneyWallet,
    CnxRating,
    CnxServiceTier,
    CnxServiceCategory,
    CnxBooking,
    CnxArticle,
    CnxCertificate,
    CnxConsumerProfile,
    CnxMeeting,
    CnxBid,
    CnxPayoutAccount,
} from ".."
import uuid from "uuid/v4"
import HmapCoder from "../../helpers/HmapCoder"

export interface CnxProviderProfileProperties extends CnxMemberProfileProperties {
    bio?: string
    approvedAt?: Moment
    providerStatus?: string
    shortIntro?: string
    isOnline?: boolean
    socialLinks?: HmapCoder

    serviceTier?: Array<OMReference<CnxServiceTier>>
    categories?: Array<OMReference<CnxServiceCategory>>
    requestedBooking?: Array<OMReference<CnxBooking>>
    providerArticle?: Array<OMReference<CnxArticle>>
    certificates?: Array<OMReference<CnxCertificate>>
    favoritedByConsumers?: Array<OMReference<CnxConsumerProfile>>
    meetings?: Array<OMReference<CnxMeeting>>
    bids?: Array<OMReference<CnxBid>>
    payoutAccount?: OMReference<CnxPayoutAccount>
    serviceRatings?: Array<OMReference<CnxRating>>
    pendingCategories?: Array<OMReference<CnxServiceCategory>>

    serviceRatingsCount?: number
    serviceRatingsByConsumersAverage?: number
    serviceRatingsByConsumersSubratingAverage?: (number | null)[]
    favoritedByConsumerCount?: number
    meetingsCount?: number

    categorySet?: string[]
    serviceTierSet?: string[]
}

class CnxProviderProfile extends CnxMemberProfile implements CnxProviderProfileProperties {

    public static Type: string = "cnx_provider_profile"
    public static Path: string = "cnx_provider_profiles"

    public bio?: string
    public approvedAt?: Moment
    public providerStatus?: string
    public shortIntro?: string
    public isOnline: boolean
    public socialLinks?: HmapCoder

    public serviceTier: Array<OMReference<CnxServiceTier>>
    public categories: Array<OMReference<CnxServiceCategory>>
    public requestedBooking: Array<OMReference<CnxBooking>>
    public providerArticle: Array<OMReference<CnxArticle>>
    public certificates: Array<OMReference<CnxCertificate>>
    public favoritedByConsumers: Array<OMReference<CnxConsumerProfile>>
    public meetings: Array<OMReference<CnxMeeting>>
    public bids: Array<OMReference<CnxBid>>
    public payoutAccount?: OMReference<CnxPayoutAccount>
    public serviceRatings: Array<OMReference<CnxRating>>
    public pendingCategories: Array<OMReference<CnxServiceCategory>>

    public serviceRatingsCount: number
    public serviceRatingsByConsumersAverage?: number
    public serviceRatingsByConsumersSubratingAverage?: (number | null)[]
    public favoritedByConsumerCount?: number
    public meetingsCount: number

    public categorySet: string[]
    public serviceTierSet: string[]

    /* ApproveCategoriesCommand */

    protected static ApproveCategoriesCommand = class ApproveCategoriesCommand extends Command<CnxProviderProfile> {
        public static readonly Path = "approve_categories"

        public constructor(
            receiver: CnxProviderProfile,
            public categories: OMReference<CnxServiceCategory>[],
        ) {
            super(receiver)
        }

        public execute(): Promise<any> {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            categories: this.categories.map((each) => each.id)
                        },
                    }
                },
                method: "POST",
                url,
            })
        }
    }

    public approveCategories(categories: OMReference<CnxServiceCategory>[]) {
        try {
            const command = new CnxProviderProfile.ApproveCategoriesCommand(this, categories)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }



    /* UpdateCategoriesCommand */

    protected static UpdateCategoriesCommand = class UpdateCategoriesCommand extends Command<CnxProviderProfile> {
        public static readonly Path = "update_categories"

        public constructor(
            receiver: CnxProviderProfile,
            public categories: OMReference<CnxServiceCategory>[],
        ) {
            super(receiver)
        }

        public execute(): Promise<any> {
            const url = (() => {
                if (this.receiver) {
                    return `${(this.typeClass as any).Path}/${this.receiver.id}/${(this.constructor as any).Path}`
                } else {
                    return `${(this.typeClass as any).Path}/${(this.constructor as any).Path}`
                }
            })()

            return OMUniverse.shared.apiClient.send({
                data: {
                    data: {
                        id: uuid(),
                        type: (this.constructor as any).Type,
                        attributes: {
                            categories: this.categories.map((each) => each.id)
                        },
                    }
                },
                method: "POST",
                url,
            })
        }
    }

    public updateCategories(categories: OMReference<CnxServiceCategory>[]) {
        try {
            const command = new CnxProviderProfile.UpdateCategoriesCommand(this, categories)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }



    /* ApproveCommand */

    protected static ApproveCommand = class ApproveCommand extends Command<CnxProviderProfile> {
        public static readonly Path = "approve"

        public constructor(
            receiver: CnxProviderProfile,
        ) {
            super(receiver)
        }
    }

    public approve() {
        try {
            const command = new CnxProviderProfile.ApproveCommand(this)
            return CommandManager.shared.addToQueue(command)
                .then(() => {
                    this.providerStatus = "APPROVED"
                    this.approvedAt = moment()
                    OMUniverse.shared.touch(this)
                })
        } catch (error) {
            return Promise.reject(error)
        }
    }



    /* Follow */

    protected static FollowCommand = class FollowCommand extends Command<CnxProviderProfile> {
        public static readonly Path = "follow"

        profile: string

        public constructor(
            receiver: CnxProviderProfile,
            profile: CnxConsumerProfile,
        ) {
            super(receiver)
            this.profile = profile.id
        }
    }

    public follow(profile: CnxConsumerProfile) {
        try {
            const command = new CnxProviderProfile.FollowCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }




    /* Unfollow */

    protected static UnfollowCommand = class UnfollowCommand extends Command<CnxProviderProfile> {
        public static readonly Path = "unfollow"

        profile: string

        public constructor(
            receiver: CnxProviderProfile,
            profile: CnxConsumerProfile,
        ) {
            super(receiver)
            this.profile = profile.id
        }
    }

    public unfollow(profile: CnxConsumerProfile) {
        try {
            const command = new CnxProviderProfile.UnfollowCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }








    /* SummarizeMeetings */

    protected static SummarizeMeetingsCommand = class SummarizeMeetingsCommand extends Command<CnxProviderProfile> {
        public static readonly Path = "summarize_meetings"
    }

    public summarizeMeetings() {
        try {
            const command = new CnxProviderProfile.SummarizeMeetingsCommand(this)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }





    constructor(object: CnxProviderProfileProperties) {
        super(object)

        this.bio = object.bio
        this.approvedAt = object.approvedAt && moment(object.approvedAt)
        this.providerStatus = object.providerStatus
        this.shortIntro = object.shortIntro
        this.isOnline = object.isOnline || false

        this.serviceTier = object.serviceTier ? object.serviceTier.map((item) => new OMReference(CnxServiceTier, item)) : []
        this.categories = object.categories ? object.categories.map((item) => new OMReference(CnxServiceCategory, item)) : []
        this.requestedBooking = object.requestedBooking ? object.requestedBooking.map((item) => new OMReference(CnxBooking, item)) : []
        this.providerArticle = object.providerArticle ? object.providerArticle.map((item) => new OMReference(CnxArticle, item)) : []
        this.certificates = object.certificates ? object.certificates.map((item) => new OMReference(CnxCertificate, item)) : []
        this.favoritedByConsumers = object.favoritedByConsumers ? object.favoritedByConsumers.map((item) => new OMReference(CnxConsumerProfile, item)) : []
        this.meetings = object.meetings ? object.meetings.map((item) => new OMReference(CnxMeeting, item)) : []
        this.bids = object.bids ? object.bids.map((item) => new OMReference(CnxBid, item)) : []
        this.payoutAccount = object.payoutAccount && new OMReference(CnxPayoutAccount, object.payoutAccount)
        this.serviceRatings = object.serviceRatings ? object.serviceRatings.map((item) => new OMReference(CnxRating, item)) : []
        this.pendingCategories = object.pendingCategories ? object.pendingCategories.map((item) => new OMReference(CnxServiceCategory, item)) : []

        this.serviceRatingsCount = object.serviceRatingsCount || 0
        this.serviceRatingsByConsumersAverage = object.serviceRatingsByConsumersAverage && parseFloat(object.serviceRatingsByConsumersAverage as any)
        this.serviceRatingsByConsumersSubratingAverage = object.serviceRatingsByConsumersSubratingAverage
        this.favoritedByConsumerCount = object.favoritedByConsumerCount && parseFloat(object.favoritedByConsumerCount as any)
        this.meetingsCount = object.meetingsCount || 0

        this.categorySet = object.categorySet || []
        this.serviceTierSet = object.serviceTierSet || []
        this.socialLinks = typeof object.socialLinks === "string" ? new HmapCoder(object.socialLinks) : object.socialLinks
    }
}

export default CnxProviderProfile
