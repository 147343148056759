//  PmtStripePayment.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-06
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    PmtPayment,
    PmtPaymentProperties,
    Profile,
} from ".."

export interface PmtStripePaymentProperties extends PmtPaymentProperties {
    paymentIntentId?: string

}

class PmtStripePayment extends PmtPayment implements PmtStripePaymentProperties {

    public static Type: string = "pmt_stripe_payment"
    public static Path: string = "pmt_stripe_payments"

    public paymentIntentId?: string


    constructor(object: PmtStripePaymentProperties) {
        super(object)

        this.paymentIntentId = object.paymentIntentId

    }
}

export default PmtStripePayment
