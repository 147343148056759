import { OMObject, OMQuery, OMReference } from "firmament-node-sdk"
import React from "react"

import { Props } from "react-select/lib/Select"
import { ActionMeta } from "react-select/lib/types"
import AsyncSelectHeader from "./AsyncSelectHeader"
import { Caption2 } from "./Typography"

interface AsyncMultipleEntitySelectHeaderComponentProps<T extends OMObject> {
    disabled?: boolean

    filterOption?: Props["filterOption"]

    placeholder?: string
    label: string
    labelField: keyof T
    labelFieldCallback?: (obj: T) => any
    query: OMQuery<T>

    objectType: { new(...arg: any): T }
    value?: Array<OMReference<T>>

    onRemove?: (previousValue: Array<OMReference<T>>, ref: OMReference<T>) => void
    onClear?: (previousValue: Array<OMReference<T>>) => void
    onSelect?: (previousValue: Array<OMReference<T>>, ref: OMReference<T>) => void

    onSelectChange(refs?: Array<OMReference<T>>): void

    required?: boolean

    isOptionDisabled?: Function

    styles?: any
}

interface Option<Label = any, Value = any> {
    label: Label
    value: Value
}

interface SelectOptionAction {
    action: "select-option"
    option: Option
}

interface RemoveValueAction {
    action: "remove-value"
    removedValue: Option
}

interface PopValueAction {
    action: "pop-value"
    removedValue: Option
}

interface ClearAction {
    action: "clear"
}

export type Action = SelectOptionAction | RemoveValueAction | PopValueAction| ClearAction

class AsyncMultipleEntitySelectHeader<T extends OMObject>
    extends React.Component<AsyncMultipleEntitySelectHeaderComponentProps<T>> {

    public render() {
        const { value, ...props } = this.props

        const valueProps = this.props.value && this.props.value.map((item) => ({
            label: item.actualObject && (this.props.labelFieldCallback ? this.props.labelFieldCallback(item.actualObject) : item.actualObject[this.props.labelField]) || item.id,
            value: item.id,
        }))

        return (
            <>
                <AsyncSelectHeader
                    disabled={this.props.disabled}
                    {...props}
                    isMulti={true}
                    value={valueProps}
                    onSelectChange={this.onSelectChange as any}
                    valueField={"objectUuid"}
                />
                <Caption2 className="text-tertiary">* You can select more than 1 items.</Caption2>
            </>
        )
    }

    private onSelectChange = (values: [{ label: any, value: any }], action: Action): void => {
        const refs = values.map((value) => new OMReference(this.props.objectType, value.value))
        this.props.onSelectChange(refs)

        switch (action.action) {
            case "pop-value":
            case "remove-value":
                if (this.props.onRemove) {
                    const ref = new OMReference(this.props.objectType, action.removedValue.value)
                    this.props.onRemove(this.props.value!, ref)
                }
                break
            case "clear":
                if (this.props.onClear) {
                    this.props.onClear(this.props.value!)
                }
                break
            case "select-option":
                if (this.props.onSelect) {
                    const ref = new OMReference(this.props.objectType, action.option.value)
                    this.props.onSelect(this.props.value!, ref)
                }
                break
            default:
                break
        }
    }

}

export default AsyncMultipleEntitySelectHeader
