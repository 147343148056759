//  CnxCertificate.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMFileProperties as FileProperties,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    File,
    CnxProviderProfile,
} from ".."

export type CertificateType = "IDENTITY" | "QUALIFICATION"

export interface CnxCertificateProperties extends FileProperties {
    certificateType: CertificateType

    provider: OMReference<CnxProviderProfile>
}

class CnxCertificate extends File implements CnxCertificateProperties {

    public static Type: string = "cnx_certificate"
    public static Path: string = "cnx_certificates"

    public certificateType: CertificateType

    public provider: OMReference<CnxProviderProfile>

    constructor(object: CnxCertificateProperties) {
        super(object)

        this.certificateType = object.certificateType

        this.provider = new OMReference(CnxProviderProfile, object.provider)
    }
}

export default CnxCertificate
