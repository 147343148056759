//  CnxConsumerPurchase.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    WltTransaction,
    WltTransactionProperties,
    WltWallet,
    CnxBooking,
    CnxMeeting,
} from ".."

type TransactionType = "DEBIT" | "CREDIT"
type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
export interface CnxConsumerPurchaseProperties extends WltTransactionProperties {

    booking?: OMReference<CnxBooking>
    meeting?: OMReference<CnxMeeting>
}

class CnxConsumerPurchase extends WltTransaction implements CnxConsumerPurchaseProperties {

    public static Type: string = "cnx_consumer_purchase"
    public static Path: string = "cnx_consumer_purchases"


    public booking?: OMReference<CnxBooking>
    public meeting?: OMReference<CnxMeeting>

    constructor(object: CnxConsumerPurchaseProperties) {
        super(object)


        this.booking = object.booking && new OMReference(CnxBooking, object.booking)
        this.meeting = object.meeting && new OMReference(CnxMeeting, object.meeting)
    }
}

export default CnxConsumerPurchase
