// Data imports
import { FSMNamedState as FSMState, OMQuery, OMReference, OMUniverse } from "firmament-node-sdk"
import moment, { Moment } from "moment"
import { CnxTopupPayment, SchTopupPackage, SchTopupPayment } from "../../../domain"

// Feature imports
import ListStateMachine from "../../../components/ListStateMachine"

declare namespace SchTopupPackageListStateMachine {

    export interface ShowingCreate extends FSMState {
        name: "showingCreate"
    }

    export interface PromptingDelete {
        name: "promptingDelete"
        item: SchTopupPackage

        items: SchTopupPackage[]
        totalItems: number
    }

    export interface PerformingDelete {
        name: "performingDelete"
        item: SchTopupPackage

        items: SchTopupPackage[]
        totalItems: number
    }

    export interface ShowingError {
        name: "showingError"
        items: SchTopupPackage[]
        totalItems: number
    }

    export type State = ListStateMachine.State<SchTopupPackage> |
        ShowingCreate |
        PromptingDelete |
        PerformingDelete |
        ShowingError
}

class SchTopupPackageListStateMachine extends ListStateMachine<SchTopupPackage, SchTopupPackageListStateMachine.State> {

    public promptDelete = (item: SchTopupPackage) => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "promptingDelete", items: this.state.items, totalItems: this.state.totalItems, item }
                break
            default:
                return
        }
    }

    public performDelete = async () => {
        switch (this.state.name) {
            case "promptingDelete":
                this.state = { name: "performingDelete", items: this.state.items, totalItems: this.state.totalItems, item: this.state.item }
                break
            default:
                return
        }

        try {
            const item = this.state.item
            await item.delete()
            this.state = { name: "presenting", items: this.state.items.filter((each) => each.id !== item.id), totalItems: this.state.totalItems - 1 }
        } catch (error) {
            this.fail(error)
        }
    }

    public cancel = (): void => {
        switch (this.state.name) {
            case "promptingDelete":
                this.state = { name: "presenting", items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                return
        }
    }

    public load = (filters: {
        currentPage?: number,
        sortBy?: keyof SchTopupPackage,
        sortDirection?: "ASC" | "DESC",
        startAt?: Moment,
        endAt?: Moment,

        title?: string,
    } = { currentPage: 0, sortBy: "createdAt", sortDirection: "DESC" }): void => {
        switch (this.state.name) {
            case "start":
            case "showingError":
                this.state = { name: "loading", items: [], totalItems: 0 }
                break
            case "presenting":
                this.state = { name: "loading", items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                return
        }

        const query = SchTopupPackage.query()
            .filter("objectType", "equals", SchTopupPackage.Type)
            .include("icon")
            .include("backgroundImage")
            .limit(10)

        if (filters.currentPage) {
            query.offset(filters.currentPage)
        }

        if (filters.sortBy) {
            query.sort(filters.sortBy, filters.sortDirection!)
        }

        if (filters.startAt) {
            query.filter("createdAt", "moreThan", filters.startAt.toISOString() as any)
        }

        if (filters.endAt) {
            query.filter("createdAt", "lessThan", filters.endAt.toISOString() as any)
        }

        if (filters.title) {
            query.filter("title", "like", filters.title)
        }

        query.filterGroup([
            ["publishAt", "isNull", undefined],
            ["publishAt", "lessThanEqual", moment().toISOString() as any],
        ], "OR")

        query.filterGroup([
            ["unpublishAt", "isNull", undefined],
            ["unpublishAt", "moreThan", moment().toISOString() as any],
        ], "OR")


        query
            .execute()
            .then(() => this.success(query.resultObjects, query.meta.count))
            .catch((error: Error) => this.fail(error))
    }

    public showDetail = async (ref: OMReference<SchTopupPackage>) => {
        switch (this.state.name) {
            case "presenting":
            case "showingError":
                break
            default:
                return
        }

        try {
            const topupPackage = ref.actualObject || await ref.load()

            switch (true) {
                case typeof topupPackage.limitPerUser === "number":
                    // check limitPerUser
                    const query = SchTopupPayment.query()
                        .filter("package", "equals", ref.id as any)
                        .filter("ownerUuid", "equals", OMUniverse.shared.user?.id as any)
                        .limit(1)
                    await query.execute()
                    const purchaseCount = query.meta.count

                    if (purchaseCount >= topupPackage!.limitPerUser!) {
                        throw new Error("Purchase limit reached.")
                    } else {
                        this.state = { name: "showingDetail", ref }
                    }
                    break
                default:
                    this.state = { name: "showingDetail", ref }
                    break
            }
        } catch (error) {
            this.state = { name: "showingError", error, items: (this.state as any).items, totalItems: (this.state as any).totalItems }
        }
    }

    public showCreate = () => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingCreate" }
                break
            default:
                return
        }
    }

    public success(items: SchTopupPackage[], totalItems: number = 0): void {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
                this.state = { name: "presenting", items, totalItems }
                break
            default:
                return
        }
    }

    public fail(error: Error): void {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

}

export default SchTopupPackageListStateMachine
