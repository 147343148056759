import { OMFile, OMReference, OMUniverse } from "firmament-node-sdk"
import React from "react"
import Dropzone, { DropEvent, DropzoneProps } from "react-dropzone"
import FileRenderer from "../FileRenderer"

export type MediaType = "image" | "video"

export interface FileInputComponentProps extends DropzoneProps {
    onChange?: (file?: OMFile) => void
    value?: OMFile
    type: MediaType
}

export interface FileInputComponentState {
    value?: OMFile

    fileRef?: OMReference<OMFile>
}

class FileInput extends React.Component<FileInputComponentProps, FileInputComponentState> {
    constructor(props: FileInputComponentProps) {
        super(props)
        this.state = {
            fileRef: this.props.value && new OMReference(OMFile, this.props.value),
            value: this.props.value,
        }
    }

    public componentDidUpdate(prevProps: FileInputComponentProps, prevState: FileInputComponentState) {
        if (this.props.onChange && this.state.value !== prevState.value) {
            this.props.onChange(this.state.value)
        }

        if (this.props.value !== prevProps.value && !prevProps.value) {
            this.setState({ value: this.props.value })
        }

        if (this.state.value !== prevState.value) {
            if (this.state.value) {
                OMUniverse.shared.touch(this.state.value)
                const fileRef = new OMReference(OMFile, this.state.value)
                this.setState({ fileRef })
            } else {
                this.setState({ fileRef: undefined })
            }
        }
    }

    public render() {
        return (
            <Dropzone
                multiple={false}
                {...this.props}
                onDropAccepted={this.onDropAccepted}
                accept={this.props.type === "image" ? "image/*" : "video/*"}
            >
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            {this.state.value && this.state.fileRef && (
                                <FileRenderer file={this.state.fileRef} className="mw-100" type={this.props.type} />
                            )}

                            {!this.state.value && <p>Drag 'n' drop some files here, or click to select files</p>}
                        </div>
                    </section>
                )}
            </Dropzone>
        )
    }

    private onDropAccepted = (files: File[], event: DropEvent) => {
        const file = files[0]

        const value = new OMFile({
            ...this.props.value,
            file, mimetype: file.type,

            isInserted: this.props.value ? true : false,
        })

        this.setState({ value })
    }
}

export default FileInput
