// https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side

type Orientation = -2 | -1 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

async function getImageOrientation(file: File): Promise<Orientation> {
    const reader = new FileReader()

    const view: DataView = await new Promise((resolve) => {
        reader.onload = (e) => {
            resolve(
                new DataView((e.target! as any).result),
            )
        }
        reader.readAsArrayBuffer(file)
    })

    if (view.getUint16(0, false) !== 0xFFD8) {
        return -2
    }

    const length = view.byteLength
    let offset = 2

    while (offset < length) {

        if (view.getUint16(offset + 2, false) <= 8) {
            return -1
        }

        const marker = view.getUint16(offset, false)
        offset += 2

        if (marker === 0xFFE1) {
            if (view.getUint32(offset += 2, false) !== 0x45786966) {
                return -1
            }

            const little = view.getUint16(offset += 6, false) === 0x4949
            offset += view.getUint32(offset + 4, little)
            const tags = view.getUint16(offset, little)
            offset += 2
            for (let i = 0; i < tags; i++) {
                if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                    return view.getUint16(offset + (i * 12) + 8, little) as Orientation
                }
            }
            // tslint:disable-next-line:no-bitwise
        } else if ((marker & 0xFF00) !== 0xFF00) {
            break
        } else {
            offset += view.getUint16(offset, false)
        }
    }

    return -1

}

export default getImageOrientation
