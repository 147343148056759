//  CnxMessage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
    Command,
    CommandManager,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxMemberProfile,
    CnxMeeting,
    GenImage,
    Hstore,
} from ".."

type MessageType = "TEXT" | "PHOTO" | "ACTION"

export interface CnxMessageProperties extends DomainObjectProperties {
    messageType: MessageType
    text?: string
    suggestions?: Hstore

    sender: OMReference<CnxMemberProfile>
    meeting: OMReference<CnxMeeting>
    attachedImage?: OMReference<GenImage>
    recipient?: OMReference<CnxMemberProfile>
    inReplyTo?: OMReference<CnxMessage>
    replies?: Array<OMReference<CnxMessage>>
}

class CnxMessage extends DomainObject implements CnxMessageProperties {

    public static Type: string = "cnx_message"
    public static Path: string = "cnx_messages"

    public messageType: MessageType
    public text?: string
    public suggestions?: Hstore

    public sender: OMReference<CnxMemberProfile>
    public meeting: OMReference<CnxMeeting>
    public attachedImage?: OMReference<GenImage>
    public recipient?: OMReference<CnxMemberProfile>
    public inReplyTo?: OMReference<CnxMessage>
    public replies: Array<OMReference<CnxMessage>>

    protected static SendCommand = class SendCommand extends Command<CnxMessage> {
        public static readonly Path = "send"

        public constructor(
            receiver: CnxMessage,
        ) {
            super(receiver)
        }
    }


    public send() {
        try {
            const command = new CnxMessage.SendCommand(this)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    constructor(object: CnxMessageProperties) {
        super(object)

        this.messageType = object.messageType
        this.text = object.text
        this.suggestions = object.suggestions && new Hstore(object.suggestions)

        this.sender = new OMReference(CnxMemberProfile, object.sender)
        this.meeting = new OMReference(CnxMeeting, object.meeting)
        this.attachedImage = object.attachedImage && new OMReference(GenImage, object.attachedImage)
        this.recipient = object.recipient && new OMReference(CnxMemberProfile, object.recipient)
        this.inReplyTo = object.inReplyTo && new OMReference(CnxMessage, object.inReplyTo)
        this.replies = object.replies ? object.replies.map((item) => new OMReference(CnxMessage, item)) : []
    }
}

export default CnxMessage
