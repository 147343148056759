//  GenImage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMFileProperties as FileProperties,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    File,
    CnxMessage,
} from ".."

export interface GenImageProperties extends FileProperties {
}

class GenImage extends File implements GenImageProperties {

    public static Type: string = "gen_image"
    public static Path: string = "gen_images"


    public?: OMReference<CnxMessage>

    constructor(object: GenImageProperties) {
        super(object)
    }
}

export default GenImage
