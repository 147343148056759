import { OMFile, OMReference } from "firmament-node-sdk"
import { Moment } from "moment"
import classnames from "classnames"
import * as React from "react"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import { Column, TableCellProps } from "react-virtualized"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink } from "reactstrap"
import isEqual from "react-fast-compare"
import debounce from "../../../helpers/debounce"
import ic_portrait_placeholder from "../../../assets/ic_portrait_placeholder.png"
import ic_graphic_no_review from "../../../assets/ic_graphic_no_review@2x.png"
import inflection from "inflection"

import {
    DateRangePicker,
    ErrorAlert,
    ModalSpinner,
    ModelTable,
    Pagination,
    AsyncEntitySelectHeader,
    FileRenderer,
    SelectHeader,
    TextInputHeader,
    ListStateMachine,
    Body,
    Title3,
    Modal,
    ModalDialog,
    LoadingButton,
    Headline, Caption1, Subheadline, Title2, Callout
} from "../../../components"
import { withMachine, withQueryString } from "../../../hoc"

import { CnxMemberProfile, CnxConsumerRefund, CnxMeeting, CnxProviderChargeback, CnxRating, SchStudentProfile, CnxProviderProfile, Setting, SchTutorProfile } from "../../../domain"

import { CnxRatingListStateMachine } from "../CnxRatingList"
import { StarRating } from "../../../airframe-components"
import { AvatarImage } from "../../../airframe-components/Avatar/AvatarImage"
import { AvatarAddonIcon } from "../../../airframe-components/Avatar/AvatarAddonIcon"
import TeachSubject from "../../SchTutorProfile/SchTutorProfileDetails/elements/TeachSubject"

interface CnxRatingListProps extends RouteComponentProps {
    machine: CnxRatingListStateMachine

    submitter?: OMReference<CnxMemberProfile>
    refund?: OMReference<CnxConsumerRefund>
    meeting?: OMReference<CnxMeeting>
    providerChargeback?: OMReference<CnxProviderChargeback>
    provider?: OMReference<CnxProviderProfile>

    queryString: any
    updateQueryString: (keyPath: any) => (value: any) => void
    setQueryString: (state: any) => void
}

interface CnxRatingListComponentState {
    machineState: CnxRatingListStateMachine.State
    queryParams: ListStateMachine.QueryParams<CnxRating> & Partial<CnxRating>
}

class CnxRatingList extends React.Component<CnxRatingListProps, CnxRatingListComponentState> {

    private machine: CnxRatingListStateMachine

    /* SECTION: Callbacks */

    private onSortChange = (sort: any) => this.props.setQueryString({ ...sort })
    private updateQueryParams = ModelTable.updateQueryParams.bind(this as any)
    private onDateRangeChange = DateRangePicker.onDateRangeChange.bind(this)

    constructor(props: CnxRatingListProps) {
        super(props)
        this.machine = new CnxRatingListStateMachine()
        this.state = {
            machineState:  this.machine.state,
            queryParams: {
                currentPage:  0,
                sortBy: "createdAt",
                sortDirection: "DESC",

                provider: this.props.provider ? new OMReference(CnxProviderProfile, this.props.provider.id) : undefined,
                submitter: this.props.submitter ? new OMReference(CnxMemberProfile, this.props.submitter.id) : undefined,
                meeting: this.props.meeting ? new OMReference(CnxMeeting, this.props.meeting.id) : undefined,
            },
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)

        this.machine.load({
            ...this.state.queryParams,
            ...this.props.queryString,
        })
    }

    public componentDidUpdate(prevProps: CnxRatingListProps, prevState: CnxRatingListComponentState) {
        if (this.state.queryParams !== prevState.queryParams || !isEqual(this.props.queryString, prevProps.queryString)) {
            this.machine.load({
                ...this.state.queryParams,
                ...this.props.queryString,
            })
        }
    }

    public componentWillUnmount() {
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
                return <ModalSpinner />
            case "loading":
            case "presenting":
            case "promptingDelete":
            case "performingDelete":
                return (
                    <div className="container-420px">
                        {machineState.name === "loading" && <ModalSpinner />}

                        {this.props.match.path.includes(`/all`) && <Headline>All Reviews</Headline>}

                        {this.props.match.path.includes(`/all`) && this.props.provider && (
                            <div className="card mt-12px">
                                <div className="card-body">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <AvatarImage
                                            size="lg"
                                            src={this.props.provider.actualObject?.photo && this.props.provider.actualObject?.photo.actualObject && this.props.provider.actualObject?.photo.actualObject.preview || ic_portrait_placeholder}
                                            className="mr-2"
                                        />

                                        <div className="mb-2 flex-grow-1">
                                            <div>
                                                <Headline>{this.props.provider.actualObject?.name || " "}</Headline>
                                            </div>
                                            <div>
                                                <Caption1 className="text-secondary">
                                                    <TeachSubject provider={this.props.provider.actualObject!} />
                                                </Caption1>
                                            </div>
                                        </div>

                                        <div>
                                            <Title2>
                                                <span className="fa fa-star mr-8px text-accent-2" />
                                                {(this.props.provider.actualObject?.serviceRatingsByConsumersAverage ?? 0).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                            </Title2>
                                        </div>
                                    </div>

                                    <hr />

                                    {Setting.find("cnx_service_ratings_subrating_types") && Setting.find("cnx_service_ratings_subrating_types")!.value!.split("|").map((subratingType, index) => {
                                        const subrating = this.props.provider?.actualObject?.serviceRatingsByConsumersSubratingAverage?.[index] ?? 0
                                        const inflectedSubratingType = inflection.humanize(subratingType)
                                        return (
                                            <div className="d-flex align-items-center mb-12px">
                                                <Subheadline>{inflectedSubratingType}</Subheadline>

                                                <div className="flex-grow-1">
                                                    <div className="d-flex justify-content-end align-items-center" style={{ fontSize: 16 }}>
                                                        <StarRating
                                                            starColor="accent-2"
                                                            at={Math.round(subrating)}
                                                            max={5}
                                                        />
                                                        <div className="ml-16px">
                                                            <Callout className="d-block" style={{ minWidth: 25 }}>{subrating.toLocaleString(undefined, { minimumFractionDigits: 1   , maximumFractionDigits: 1 })}</Callout>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        )}

                        {(machineState.name === "presenting" && machineState.totalItems === 0)
                            ? (
                                <div className="text-center">
                                    <img className="d-block mx-auto mb-16px" src={ic_graphic_no_review} height={117} />
                                    <Body className="d-block text-quaternary">Be the first to review this tutor.</Body>
                                </div>
                            )
                            : (
                                <div className={classnames("mt-3", { "card": this.props.match.path.includes(`/all`) })}>
                                    <div className={classnames({ "card-body": this.props.match.path.includes(`/all`) })}>
                                        <div className="d-flex justify-content-between">
                                            <Headline className="text-secondary">
                                                {(() => {
                                                    switch (true) {
                                                        case this.props.match.path.includes(`/all`):
                                                            return `${machineState.totalItems || 0} Reviews`
                                                        default:
                                                            return "Reviews"
                                                    }
                                                })()}
                                            </Headline>
                                            {!this.props.match.path.includes(`/all`) && <Link className="text-primary" to={`/${SchTutorProfile.Path}/${this.props.provider?.id}/${CnxRating.Path}/all`}>See All</Link>} {/* TODO: Fix broken route */}
                                        </div>

                                        <hr className="mb-0" />

                                        <ModelTable
                                            noBorder
                                            disableHeader
                                            rowHeight={90}
                                            actionColumnWidth={50}
                                            items={machineState.items}
                                            queryParams={{ ...this.state.queryParams, ...this.props.queryString }}
                                            onSortChange={this.onSortChange}
                                        // onRowClick={(props) => this.props.history.push(`${this.props.match.url}/${props.rowData.objectUuid}${this.props.history.location.search}`)}
                                        >
                                            {(width: number, widthReserved: number) => (
                                                [
                                                    (
                                                        <Column
                                                            label="Created At"
                                                            dataKey="createdAt"
                                                            disableSort={false}
                                                            width={width}
                                                            cellRenderer={({ rowData }) => {
                                                                const rating = new CnxRating(rowData)
                                                                const submitter = rating.submitter && rating.submitter.actualObject

                                                                const coverImageSrc = submitter && submitter.photo && submitter.photo.actualObject && submitter.photo.actualObject.preview

                                                                const meeting = rating.meeting && rating.meeting.actualObject
                                                                const subject = meeting && meeting.serviceCategories.map((each) => each.actualObject && each.actualObject.categoryType === "SUBJECT" && each.actualObject.title).filter(Boolean).join(", ")

                                                                return (
                                                                    <div className="d-flex align-items-start">
                                                                        <AvatarImage
                                                                            size="sm"
                                                                            src={coverImageSrc || ic_portrait_placeholder}
                                                                            className="mr-2 mt-1"
                                                                        />
                                                                        <div className="flex-column ml-2 mb-1">
                                                                            <Caption1 className="mb-8px text-secondary">{submitter && submitter.name || "-"}</Caption1>

                                                                            <Headline className="d-block" style={{ marginLeft: -4 }}>
                                                                                <StarRating starColor="yellow" max={5} at={Math.round(rating.overall || 0)} />
                                                                            </Headline>

                                                                            <Subheadline className="d-block text-dark">{rating.review || rating.report || null}</Subheadline>

                                                                            <Caption1>{rating.createdAt.format("DD-MM-YYYY")} • {subject || "-"}</Caption1>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }}
                                                        />
                                                    ),
                                                ]
                                            )}
                                        </ModelTable>

                                        {this.props.match.path.includes(`/all`)
                                            ? (
                                                <div className="mt-16px">
                                                    <Pagination
                                                        maxPages={4}
                                                        totalItems={machineState.totalItems}
                                                        itemsPerPage={10}
                                                        className="d-flex justify-content-center"
                                                        onPageChanged={this.props.updateQueryString("currentPage")}
                                                        currentPage={this.props.queryString.currentPage}
                                                    />
                                                </div>
                                            )
                                            : (
                                                <div className="d-flex justify-content-center mt-16px">
                                                    <Link className="text-primary" to={`/${SchTutorProfile.Path}/${this.props.provider?.id}/${CnxRating.Path}/all`}>
                                                        <Headline>
                                                            See All {machineState.totalItems} Reviews
                                                    </Headline>
                                                    </Link> {/* TODO: Fix broken route */}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }

                    </div>
                )
            case "showingDetail":
                this.props.history.push(`${this.props.match.url}/${machineState.ref.id}/edit`)
                return null
            case "showingError":
                return <ErrorAlert error={machineState.error} />
            case "showingCreate":
                this.props.history.push(`${this.props.match.url}/new`)
                return null
            case "completed":
                return null
        }
    }

    private subscriptionCallback = (machineState: CnxRatingListStateMachine.State) => {
        return this.setState({machineState})
    }

    /* SECTION: Custom Cell Renderer */

    private showDetail = (id: string) => () => {
        const ref = new OMReference(CnxRating, id)
        this.machine.showDetail(ref)
    }

    private actionCellRenderer = (props: TableCellProps) => {
        const cnxRating = new CnxRating(props.rowData)

        return (
            <div onClick={(event) => event.stopPropagation()}>
                <UncontrolledDropdown>
                    <DropdownToggle color="">
                        <i className="fa fa-fw fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem className="text-primary" tag={"button"} type="button" onClick={() => this.machine.showDetail(new OMReference(CnxRating, cnxRating.objectUuid))}>Edit</DropdownItem>
                        <DropdownItem className="text-danger" tag={"button"} type="button" onClick={() => this.machine.promptDelete(cnxRating)}>Delete</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        )
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        this.debounceUpdate(value)
    }

    private debounceUpdate = debounce((value: string) => {
        this.props.updateQueryString("title")(value || null as any)
    }, 250)

}

export default withMachine(CnxRatingListStateMachine)(withQueryString({ path: CnxRating.Path })(CnxRatingList))
