// Data imports
import { FSMNamedState as FSMState, OMQuery, OMReference, OMIdentity } from "firmament-node-sdk"
import { Moment } from "moment"
import { NotifMessage, ProfilePhoto, User, SchTutorProfile, CnxConsumerProfile, CnxServiceCategory, SchStudentProfile } from "../../../domain"
import moment from "moment"

// Feature imports
import ListStateMachine from "../../../components/ListStateMachine"
import getProfile from "../../../helpers/Profile"
import PathToRegexp from "path-to-regexp"

declare namespace SchTutorProfileListStateMachine {

    export interface ShowingCreate extends FSMState {
        name: "showingCreate"
    }

    export interface PromptingDelete {
        name: "promptingDelete"
        item: SchTutorProfile

        items: SchTutorProfile[]
        totalItems: number
    }

    export interface PerformingDelete {
        name: "performingDelete"
        item: SchTutorProfile

        items: SchTutorProfile[]
        totalItems: number
    }

    export interface PromptingResetPassword extends FSMState {
        name: "promptingResetPassword"
        item: SchTutorProfile

        items: SchTutorProfile[]
        totalItems: number
    }

    export interface PerformingResetPassword extends FSMState {
        name: "performingResetPassword"
        item: SchTutorProfile

        items: SchTutorProfile[]
        totalItems: number
    }
    export interface PromptingSuccessfulResetPassword extends FSMState {
        name: "promptingSuccessfulResetPassword"
        item: SchTutorProfile

        items: SchTutorProfile[]
        totalItems: number
    }


    export interface PromptingSuspend {
        name: "promptingSuspend"
        item: SchTutorProfile

        items: SchTutorProfile[]
        totalItems: number
    }

    export interface PerformingSuspend {
        name: "performingSuspend"
        item: SchTutorProfile

        items: SchTutorProfile[]
        totalItems: number
    }


    export interface PromptingUnsuspend {
        name: "promptingUnsuspend"
        item: SchTutorProfile

        items: SchTutorProfile[]
        totalItems: number
    }

    export interface PerformingUnsuspend {
        name: "performingUnsuspend"
        item: SchTutorProfile

        items: SchTutorProfile[]
        totalItems: number
    }

    export type State = ListStateMachine.State<SchTutorProfile> |
        ShowingCreate |
        PromptingDelete |
        PerformingDelete |

        PromptingResetPassword |
        PerformingResetPassword |
        PromptingSuccessfulResetPassword |

        PromptingSuspend |
        PerformingSuspend |
        PromptingUnsuspend |
        PerformingUnsuspend

}

class SchTutorProfileListStateMachine extends ListStateMachine<SchTutorProfile, SchTutorProfileListStateMachine.State> {

    private latestQuery?: OMQuery<SchTutorProfile>

    public promptSUDAction = (item: SchTutorProfile) => {
        switch (this.state.name) {
            case "presenting":
                break
            default:
                return
        }

        if (!item.user.actualObject) {
            this.fail(new Error("Expecting user object"))
            return
        }

        switch (item.user.actualObject.status) {
            case "suspended":
                this.state = { name: "promptingUnsuspend", item, items: this.state.items, totalItems: this.state.totalItems }
                break
            case "active":
                this.state = { name: "promptingSuspend", item, items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                break
        }

    }

    public performSUDAction = async () => {
        switch (this.state.name) {
            case "promptingSuspend":
                this.state = { "name": "performingSuspend", item: this.state.item, items: this.state.items, totalItems: this.state.totalItems }
                break
            case "promptingUnsuspend":
                this.state = { "name": "performingUnsuspend", item: this.state.item, items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                return
        }

        if (!this.state.item.user.actualObject) {
            this.fail(new Error("Expecting user object"))
            return
        }

        try {
            switch (this.state.name) {
                case "performingSuspend":
                    await this.state.item.user.actualObject.suspend()
                    break
                case "performingUnsuspend":
                    await this.state.item.user.actualObject.unsuspend()
                    break
            }

            this.success(this.state.items, this.state.totalItems)
        } catch (error) {
            this.fail(error)
        }
    }

    public promptResetPassword = (item: SchTutorProfile) => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "promptingResetPassword", item, items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                return
        }
    }

    public performResetPassword = (newChallenge?: string) => {
        switch (this.state.name) {
            case "promptingResetPassword":
                this.state = { name: "performingResetPassword", item: this.state.item, items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                return
        }

        const user = this.state.item && this.state.item.user && this.state.item.user.actualObject
        const identities = user && user.identities.map((identity) => identity.actualObject!).filter(Boolean)
        const userpassIdentity = identities && identities.find((identity) => identity.identityType === "userpass")

        if (!userpassIdentity) {
            this.success(this.state.items, this.state.totalItems)
            return
        }

        userpassIdentity.challenge = newChallenge
        userpassIdentity
            .save()
            .then(() => {
                if (this.state.name !== "performingResetPassword") {
                    return
                }
                this.state = { name: "promptingSuccessfulResetPassword", item: this.state.item, items: this.state.items, totalItems: this.state.totalItems }
            })
            .catch((error) => {
                this.fail(error)
            })
    }

    public promptDelete = (item: SchTutorProfile) => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "promptingDelete", items: this.state.items, totalItems: this.state.totalItems, item }
                break
            default:
                return
        }
    }

    public performDelete = async () => {
        switch (this.state.name) {
            case "promptingDelete":
                this.state = { name: "performingDelete", items: this.state.items, totalItems: this.state.totalItems, item: this.state.item }
                break
            default:
                return
        }

        try {
            const item = this.state.item;

            await (async function preDeleteActions() {
                const user = item.user && item.user.actualObject
                const identities = user && user.identities && user.identities.map((each) => each.actualObject).filter(Boolean) as OMIdentity[]

                identities && await Promise.all(identities.map((each) => each.delete()))
                user && await user.delete()
            })()

            await item.delete()

            this.state = { name: "presenting", items: this.state.items.filter((each) => each.id !== item.id), totalItems: this.state.totalItems - 1 }
        } catch (error) {
            this.fail(error)
        }
    }

    public cancel = (): void => {
        switch (this.state.name) {
            case "promptingDelete":
            case "promptingResetPassword":
            case "promptingSuccessfulResetPassword":
            case "promptingSuspend":
            case "performingSuspend":
            case "promptingUnsuspend":
            case "performingUnsuspend":
                this.state = { name: "presenting", items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                return
        }
    }

    public load = (filters: {
        currentPage?: number,
        sortBy?: keyof SchTutorProfile,
        sortDirection?: "ASC" | "DESC",
        startAt?: Moment,
        endAt?: Moment,

        title?: string,
        user?: OMReference<User>,
        // favoritedByConsumer?: OMReference<CnxConsumerProfile>,

        categoryType?: OMReference<CnxServiceCategory> | string,
        status?: "Active" | "Pending" | "Rejected",

        category?: OMReference<CnxServiceCategory>,

        // From: withContextConsumer(CategoriesParamsContext)
        categories?: OMReference<CnxServiceCategory>[],

        // From: direct injection
        tutor?: SchTutorProfile,
    } = { currentPage: 0, sortBy: "createdAt", sortDirection: "DESC" }): void => {
        switch (this.state.name) {
            case "start":
            case "showingError":
                this.state = { name: "loading", items: [], totalItems: 0 }
                break
            case "presenting":
                this.state = { name: "loading", items: [], totalItems: this.state.totalItems }
                break
            case "loading":
                this.state = { name: "loading", items: [], totalItems: this.state.totalItems }
                break
            default:
                return
        }

        this.latestQuery = undefined

        const query = SchTutorProfile.query()
            .include("photo")
            .include("categories")
            .filter("approvedAt", "isNotNull", undefined)
            .filter("user.status" as any, "equals", "active")
            .limit(3)

        if ((filters.categories?.length ?? 0) > 0 || filters.tutor) {
            const filterGroup = [...filters.categories ?? [], ...filters.tutor?.categories ?? []]
                .map((each) => each.actualObject?.categoryType === "SUBJECT" && each.actualObject.slug)
                .filter(Boolean)
                .map((each) => ["categorySet", "contains", each]) as any

            if (filterGroup.length > 0) {
                query.filterGroup(filterGroup, "OR")
            }
        }

        this.latestQuery = query


        if (filters.currentPage) {
            query.offset(filters.currentPage)
        }

        if (filters.startAt) {
            query.filter("createdAt", "moreThan", filters.startAt.toISOString() as any)
        }

        if (filters.endAt) {
            query.filter("createdAt", "lessThan", filters.endAt.toISOString() as any)
        }

        if (filters.title) {
            query.filterGroup([
                ["name", "like", filters.title],
                ["slug", "like", filters.title],
                ["user.identityUsername" as any, "like", filters.title],
            ], "OR")
        }

        if (filters.status) {
            switch (filters.status) {
                case "Active":
                    query.filter("providerStatus", "equals", "APPROVED")
                    break
                case "Pending":
                    query.filter("providerStatus", "equals", "PENDING")
                    break
                case "Rejected":
                    query.filter("providerStatus", "equals", "REJECTED")
                    break
            }
        }

        if (filters.user) {
            query.filter("user", "equals", filters.user.id as any)
        }

        query
            .execute()
            .then(() => {
                if (query === this.latestQuery) {
                    this.success(query.resultObjects, query.meta.count)
                }
            })
            .catch((error: Error) => this.fail(error))
    }

    public showDetail = (ref: OMReference<SchTutorProfile>) => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingDetail", ref }
                break
            default:
                return
        }
    }

    public showCreate = () => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingCreate" }
                break
            default:
                return
        }
    }

    public success(items: SchTutorProfile[], totalItems: number = 0): void {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
            case "promptingResetPassword":
            case "promptingSuccessfulResetPassword":
            case "promptingSuspend":
            case "performingSuspend":
            case "promptingUnsuspend":
            case "performingUnsuspend":
                this.state = { name: "presenting", items, totalItems }
                break
            default:
                return
        }
    }

    public fail(error: Error): void {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
            case "promptingResetPassword":
            case "promptingSuccessfulResetPassword":
            case "promptingSuspend":
            case "performingSuspend":
            case "promptingUnsuspend":
            case "performingUnsuspend":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

}

export default SchTutorProfileListStateMachine
