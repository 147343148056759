//  CnxConsumerTopup.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-20
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    WltTransaction,
    WltTransactionProperties,
    WltWallet,
    CnxTopupPayment,
} from ".."

type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
type TransactionType = "DEBIT" | "CREDIT"
export interface CnxConsumerTopupProperties extends WltTransactionProperties {

    payment: OMReference<CnxTopupPayment>
}

class CnxConsumerTopup extends WltTransaction implements CnxConsumerTopupProperties {

    public static Type: string = "cnx_consumer_topup"
    public static Path: string = "cnx_consumer_topups"


    public payment: OMReference<CnxTopupPayment>

    constructor(object: CnxConsumerTopupProperties) {
        super(object)


        this.payment = new OMReference(CnxTopupPayment, object.payment)
    }
}

export default CnxConsumerTopup
