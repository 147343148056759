// Data module imports
import { OMReference, Permission } from "firmament-node-sdk"
import React from "react"
import { RouteComponentProps } from "react-router"

import { CnxVideoRoomDetailsStateMachine } from "."
import { default as TwilioVideoApp } from "./elements/Twilio"
import { default as TwilioAppStateProvider } from "../../../components/TwilioVideo/state"
import useRoomState from "../../../components/TwilioVideo/hooks/useRoomState/useRoomState"

import {
    ModalSpinner,
    ListStateMachine,
    errorToast,
} from "../../../components"

import { CnxMeeting, CnxSnapshotPhoto, CnxVideoRoom } from "../../../domain"
import useVideoContext from "../../../components/TwilioVideo/hooks/useVideoContext/useVideoContext"
import { DisconnectOnMeetingEnd } from "../../Playground/PlaygroundDetails/Playground"
import { Room } from "twilio-video"
import * as Sentry from "@sentry/react"


declare interface CnxVideoRoomDetailsProps extends RouteComponentProps <{ id: string }> {
    machine: CnxVideoRoomDetailsStateMachine

    meeting?: OMReference<CnxMeeting>
    snapshots?: OMReference<CnxSnapshotPhoto>[]
}

interface CnxVideoRoomDetailsComponentState {
    machineState: CnxVideoRoomDetailsStateMachine.State
    queryParams: ListStateMachine.QueryParams<CnxVideoRoom>
}

interface LobbyProps {
    twilioToken?: string
    meeting?: OMReference<CnxMeeting>
    roomSidCallback?: (room: Room) => void
}

const Lobby: React.FunctionComponent<LobbyProps> = ({ twilioToken, meeting, roomSidCallback }) => {

    const { room, connect } = useVideoContext()
    const roomState = useRoomState()
    const previousRoomState = React.useRef(roomState)

    React.useEffect(() => {
        if (roomState === "disconnected" && previousRoomState.current === "connected") {
            return
        }

        previousRoomState.current = roomState

        if (!twilioToken) {
            return
        }

        if (roomState === "connected") {
            if (roomSidCallback) {
                roomSidCallback(room)
            }
            return
        }

        connect(twilioToken)
    }, [roomState])

    return null

}

class CnxVideoRoomDetails
extends React.Component <CnxVideoRoomDetailsProps, CnxVideoRoomDetailsComponentState> {

    private machine: CnxVideoRoomDetailsStateMachine

    constructor(props: CnxVideoRoomDetailsProps) {
        super(props)
        this.machine = this.props.machine
        this.state = {
            machineState: this.machine.state,
            queryParams: {
                currentPage: 0,
                sortBy: "createdAt",
                sortDirection: "DESC",
            },
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)
        this.machine.load(new OMReference(CnxVideoRoom, this.props.match.params.id))
    }

    public componentWillUnmount() {
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
            case "loading":
                return <ModalSpinner />
            case "promptingDelete":
            case "performingDelete":
            case "presenting":
                return (
                    <div id="CnxVideoRoomDetail">

                        {machineState.name === "presenting" && (
                            <TwilioAppStateProvider>
                                <TwilioVideoApp onDisconnect={async () => {
                                    // const profile = getProfile() as CnxMemberProfile
                                    // if (!profile) {
                                    //     throw new Error("Expecting profile to exist")
                                    // }

                                    // const meeting = machineState.item.meeting.actualObject || await machineState.item.meeting.load()
                                    // await meeting.leave(new OMReference(CnxMemberProfile, profile.id))

                                    // profile.currentMeeting = undefined
                                    // OMUniverse.shared.touch(profile)

                                    // if (profile instanceof CnxConsumerProfile) {
                                    //     this.props.history.push(`${this.props.match.url}/${CnxRating.Path}/new`)
                                    // }
                                }}>
                                    <Lobby
                                        twilioToken={(machineState as any).twilioToken}
                                        meeting={this.props.meeting}
                                        roomSidCallback={async (room) => {
                                            const machineState = this.state.machineState
                                            switch (machineState.name) {
                                                case "presenting":
                                                    break
                                                default:
                                                    return
                                            }
                                            try {
                                                if (!room.sid) {
                                                    return
                                                }

                                                const videoRoom = machineState.item

                                                if (!videoRoom.getPermissions().includes(Permission.write)) {
                                                    return
                                                }

                                                videoRoom.sid = room.sid
                                                await videoRoom.save()

                                                Sentry.addBreadcrumb({
                                                    category: "auth",
                                                    message: `videoRoom: ${videoRoom.id}, twilioRoom: ${room.sid}`,
                                                    level: Sentry.Severity.Info,
                                                })
                                            } catch (error) {
                                                /* NOTE: ignore error */
                                            }
                                        }}
                                    />

                                    <DisconnectOnMeetingEnd meeting={this.props.meeting} />
                                </TwilioVideoApp>
                            </TwilioAppStateProvider>
                        )}

                    </div>
                )
            case "showingError":
                errorToast(machineState.error)
                return null
            case "showingEdit":
                this.props.history.push(`${this.props.match.url}/edit`)
                return null
            case "completed":
                this.props.history.goBack()
                return null
        }
    }

    private subscriptionCallback = (machineState: CnxVideoRoomDetailsStateMachine.State) => this.setState({machineState})

    private showEdit = (item: CnxVideoRoom) => () => {
        this.machine.showEdit(item)
    }
}

export default CnxVideoRoomDetails
