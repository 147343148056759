//  WltTransaction.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-04-09
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    WltWallet,
} from ".."

export type TransactionType = "DEBIT" | "CREDIT"
export type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
export interface WltTransactionProperties extends DomainObjectProperties {
    transactionType: TransactionType
    currency: string
    transactionStatus: TransactionStatus
    amountInCents: number

    wallet: OMReference<WltWallet>
}

class WltTransaction extends DomainObject implements WltTransactionProperties {

    public static Type: string = "wlt_transaction"
    public static Path: string = "wlt_transactions"

    public transactionType: TransactionType
    public currency: string
    public transactionStatus: TransactionStatus
    public amountInCents: number

    public wallet: OMReference<WltWallet>

    constructor(object: WltTransactionProperties) {
        super(object)

        this.transactionType = object.transactionType
        this.currency = object.currency
        this.transactionStatus = object.transactionStatus
        this.amountInCents = object.amountInCents

        this.wallet = new OMReference(WltWallet, object.wallet)
    }
}

export default WltTransaction
