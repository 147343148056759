import React from 'react';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import getProfile from '../../../../helpers/Profile';
import { CnxMemberProfile } from '../../../../domain';
import ic_portrait_placeholder from "../../../../assets/ic_portrait_placeholder.png"

export default function LocalVideoPreview() {
    const { localTracks } = useVideoContext();

    const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;

    return videoTrack
        ? <VideoTrack track={videoTrack} isLocal />
        : <img
            src={(getProfile() as CnxMemberProfile)?.photo?.actualObject?.preview || ic_portrait_placeholder} /* TODO: display other party photo */
            alt="Profile Photo"
            style={{
                pointerEvents: "none",
                objectFit: "cover",

                height: 163,
                width: 163,

                border: "6px solid white",

                borderRadius: "50%",
                maskImage: "-webkit-radial-gradient(white, black)",

                boxShadow: "0 4px 12px 0 rgba(15, 18, 26, 0.2)",
                margin: 12
            }}
        />;
}
