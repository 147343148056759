import { OMUniverse } from 'firmament-node-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './views/App';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import { Column } from 'react-virtualized'
import * as Sentry from '@sentry/react';

import "./polyfill"

import "./layout"
import BackgroundHeader from './components/BackgroundHeader';
import { AppStateProvider } from './views/App/elements/AppStateProvider';
import { VolumeStateProvider } from './VideoControls';
import SmartBanner from './SmartBanner';

moment.prototype.toString = function () {
    return this.clone().locale("en").format("DD-MM-YYYY, hh:mm A")
}

Column.defaultProps.headerRenderer = (headerProps) => (
    <div className="text-nowrap text-truncate">
        <span>{headerProps.label}</span>
        {!headerProps.disableSort && <i className={"fa fa-fw fa-sort " + (headerProps.sortBy === headerProps.dataKey ? headerProps.sortDirection!.toLowerCase() : "sort")} />}
    </div>
)

switch (process.env.REACT_APP_ENV) {
    case "production":
        OMUniverse.shared.changeEnvironment("production")
        OMUniverse.setApplicationKey("nAjeefRjYL9EFKrB2staT8WwxJhKEwfJSMst")
        OMUniverse.setBaseURL("https://kong-gateway.apptivitylab.com/harbour-cloud/v0.5/askbee-my")
        break
    case "localOnly":
    case "development":
    case "test":
    case "staging":
    default:
        OMUniverse.shared.changeEnvironment("staging")
        OMUniverse.setApplicationKey("sch-connectx-web-dev")
        OMUniverse.setBaseURL("https://kong-gateway.apptivitylab.com/harbour-cloud/v0.5/sch-connectx")
        break
}

Sentry.init({ dsn: "https://9a92dd0be62b4e42a52b2f4433f45f7b@o411798.ingest.sentry.io/5320076" });
ReactDOM.render(
    <Sentry.ErrorBoundary>
        <AppStateProvider>
            <VolumeStateProvider>
                <App />
                <SmartBanner />
            </VolumeStateProvider>
        </AppStateProvider>
        {/* <BackgroundHeader /> */}
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
