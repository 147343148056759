import React from "react"

export interface TextInputComponentProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
    onChange?: (value?: string) => void
    value?: string
    maxCharactersCount?: number
}

export interface TextInputComponentState {
    value?: string
}

class TextInput extends React.Component<TextInputComponentProps, TextInputComponentState> {

    constructor(props: TextInputComponentProps) {
        super(props)
        this.state = {
            value: this.props.value,
        }
    }

    public componentDidUpdate(prevProps: TextInputComponentProps, prevState: TextInputComponentState) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value })
        }

        if (this.props.onChange && this.state.value !== prevState.value) {
            this.props.onChange(this.state.value)
        }
    }

    public render() {
        return (
            <>
                <input
                    type="text"
                    className="form-control"
                    {...this.props}

                    value={this.state.value}
                    onChange={this.onChange}
                />
                {this.props.maxCharactersCount && (
                    <small className="text-secondary">{(this.state.value ? this.state.value.length : 0).toLocaleString()} / {this.props.maxCharactersCount.toLocaleString()}</small>
                )}
            </>
        )
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value || undefined
        this.setState({ value: value ? value.slice(0, this.props.maxCharactersCount) : undefined })
    }
}

export default TextInput
