//  SchStudentProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxConsumerProfile,
    CnxConsumerProfileProperties,
    Profile,
    ProfilePhoto,
    Article,
    User,
    CnxMemberProfile,
    CnxMessage,
    CnxMoneyWallet,
    CnxRating,
    CnxArticle,
    CnxSessionWallet,
    CnxProviderProfile,
    CnxMeeting,
    CnxServiceCategory,
    CnxBooking,
} from ".."

export interface SchStudentProfileProperties extends CnxConsumerProfileProperties {
    school?: string
    parentName?: string
    parentPhone?: string
    dateOfBirth?: Moment

}

class SchStudentProfile extends CnxConsumerProfile implements SchStudentProfileProperties {

    public static Type: string = "sch_student_profile"
    public static Path: string = "sch_student_profiles"

    public school?: string
    public parentName?: string
    public parentPhone?: string
    public dateOfBirth?: Moment



    constructor(object: SchStudentProfileProperties) {
        super(object)

        this.school = object.school
        this.parentName = object.parentName
        this.parentPhone = object.parentPhone
        this.dateOfBirth = object.dateOfBirth && moment(object.dateOfBirth)

    }
}

export default SchStudentProfile
