import React from "react"
import Select from "react-select"
import { ValueType } from "react-select/lib/types"

interface SelectHeaderComponentProps {
    isClearable?: boolean
    label?: string
    defaultValue?: string
    options: string[]
    onSelectChange: (value?: string) => void
    value?: string
    placeholder?: string

    required?: boolean
}

interface SelectHeaderComponentState {
    value?: string
}

class SelectHeader extends React.Component<SelectHeaderComponentProps, SelectHeaderComponentState> {

    private get isInvalid(): boolean {
        return !!(this.props.required && !this.props.value)
    }

    public constructor(props: SelectHeaderComponentProps) {
        super(props)
        this.state = {
            value: this.props.value,
        }
    }

    public componentDidUpdate(prevProps: SelectHeaderComponentProps, prevState: SelectHeaderComponentState) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value })
        }

        if (this.state.value !== prevState.value) {
            this.props.onSelectChange(this.state.value || undefined)
        }
    }

    public render() {
        return (
            <div className="position-relative">
                {this.props.label && <label>{this.props.label}</label>}
                <Select
                    className={this.isInvalid ? "is-invalid" : "is-valid"}
                    styles={{
                        control: (base) => ({
                            ...base,
                            backgroundColor: "#F9FAFC",
                            border: "1px solid #DEE2E6",
                        }),
                        placeholder: (base) => ({
                            ...base,
                            color: "5D636D",
                        })
                    }}
                    placeholder={this.props.placeholder || "All"}
                    isMulti={false}
                    isClearable={this.props.isClearable}
                    value={
                        (this.state.value !== undefined && this.state.value !== null)
                            ? { label: this.state.value, value: this.state.value }
                            : (this.props.defaultValue
                                ? { label: this.props.defaultValue, value: this.props.defaultValue }
                                : undefined
                            )
                    }
                    options={this.props.options.map((option) => ({
                        label: option,
                        value: option,
                    }))}
                    onChange={this.onChange}
                />

                <input type="text" hidden={true} className="form-control" required={this.isInvalid} />
                <div className="invalid-tooltip">Field is required</div>
            </div>
        )
    }

    private onChange = (value: ValueType<any>): void => {
        this.setState({
            value: value ? value.value : undefined,
        })
    }

}

(SelectHeader as any).defaultProps = {
    isClearable: true
}

export default SelectHeader
