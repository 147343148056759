import * as React from "react"

import { CnxMessage } from "../../../../domain"
import { ChatLeft, ChatRight } from "../../../../components"
import getProfile from "../../../../helpers/Profile"
import settings from "../../../../settings"
import { OMUniverse } from "firmament-node-sdk"

export interface CnxMessageChatComponentProps {
    cnxMessages: CnxMessage[]
    reply?: (message: CnxMessage, text: string) => void
    onImageClick?: (message: CnxMessage) => void
}

export interface CnxMessageChatComponentState {
}

class CnxMessageChat extends React.PureComponent<CnxMessageChatComponentProps, CnxMessageChatComponentState> {
    public render() {

        const profile = getProfile()

        const repliedSuggestionIds = this.props.cnxMessages
            .filter((each) => {
                return each.inReplyTo &&
                    each.sender.id === profile!.id &&
                    each.inReplyTo.actualObject &&
                    each.inReplyTo.actualObject.suggestions
            })
            .map((each) => each.inReplyTo!.id)

        const meeting = this.props.cnxMessages[0] && this.props.cnxMessages[0].meeting && this.props.cnxMessages[0].meeting.actualObject
        // const provider = meeting && meeting.provider && meeting.provider.actualObject

        return this.props.cnxMessages.sort((lhs, rhs) => lhs.createdAt.diff(rhs.createdAt)).map((message, index) => {

            const sender = message.sender.actualObject

            const body = message.text
            const name = sender && sender.name
            const createdAt = message.createdAt.format("DD/MM/YYYY HH:mm")
            const coverImageSrc = sender?.photo?.actualObject?.preview

            const imageSrc = message.attachedImage?.actualObject?.preview

            const senderIsPhoebee = sender && sender.id === settings.phoebee_profile_uuid

            const chatBubble = (() => {
                switch (true) {
                    case profile && profile.id === message.sender.id:
                        return (
                            <ChatRight
                                onImageClick={() => this.props.onImageClick && this.props.onImageClick(message)}
                                messageID={message.id}
                                cardClassName="text-dark"
                                body={body}
                                name={name}
                                createdAt={createdAt}
                                coverImageSrc={coverImageSrc}
                                imageSrc={imageSrc}
                                isReply={!!message.inReplyTo}
                            />
                        )
                    default:
                        return (
                            <ChatLeft
                                onImageClick={() => this.props.onImageClick && this.props.onImageClick(message)}
                                messageID={message.id}
                                cardClassName={`b-0 text-dark ${senderIsPhoebee ? "bg-yellow" : "bg-general-bg "}`}
                                body={body}
                                name={name}
                                createdAt={createdAt}
                                coverImageSrc={coverImageSrc}
                                imageSrc={imageSrc}
                                isReply={!!message.inReplyTo}
                            />
                        )
                }
            })()

            const actionView = (() => {
                if (message.messageType !== "ACTION") {
                    return null
                }

                if (!message.suggestions) {
                    return null
                }

                const isReplied = repliedSuggestionIds.includes(message.id)

                return (
                    <div style={{ marginLeft: 'calc(30px + 0.5rem + 1rem)', maxWidth: 500 }} className="mb-3">
                        {Object.entries(message.suggestions).map(([suggestion, meta]) => (
                            <button disabled={isReplied} type="button" className="btn btn-sm btn-primary mr-1 mb-1" onClick={() => {
                                if (!this.props.reply) {
                                    return
                                }
                                this.props.reply(message, suggestion)}
                            }>
                                {suggestion}
                            </button>
                        ))}
                    </div>
                )
            })()

            return (
                <div key={`${message.id}-${message.attachedImage?.actualObject?.preview ? 0 : 1}`}>
                    {chatBubble}
                    {actionView}
                </div>
            )
        })

        // return (
        //     <div className="container" style={{ maxWidth: "50em" }}>
        //         {this.props.cnxMessages.sort((lhs, rhs) => lhs.createdAt.diff(rhs.createdAt)).map((message, index) => {

        //             const sender = message.sender.actualObject

        //             const body = message.text
        //             const name = sender && sender.name
        //             const createdAt = message.createdAt.format("DD/MM/YYYY HH:mm")
        //             const coverImageSrc = sender && sender.photo && sender.photo.actualObject && sender.photo.actualObject.preview

        //             const imageSrc = message.attachedImage && message.attachedImage.actualObject && message.attachedImage.actualObject.url

        //             switch (true) {
        //                 case provider && provider.id === message.sender.id:
        //                     return (
        //                         <ChatRight
        //                             cardClassName="text-dark"
        //                             body={body}
        //                             name={name}
        //                             createdAt={createdAt}
        //                             coverImageSrc={coverImageSrc}
        //                             imageSrc={imageSrc}
        //                         />
        //                     )
        //                 default:
        //                     return (
        //                         <ChatLeft
        //                             cardClassName="bg-gray-300 b-0 text-dark"
        //                             body={body}
        //                             name={name}
        //                             createdAt={createdAt}
        //                             coverImageSrc={coverImageSrc}
        //                             imageSrc={imageSrc}
        //                         />
        //                     )
        //             }
        //         })}
        //         {/* <ChatLeft cardClassName="bg-gray-300 b-0 text-dark" />
        //         <ChatRight cardClassName="text-dark" />
        //         <ChatLeft cardClassName="bg-gray-300 b-0 text-dark" />
        //         <ChatRight cardClassName="text-dark" />
        //         <div className="small mb-3 mt-4 text-center">
        //             Yesterday
        //         </div>
        //         <ChatLeft cardClassName="bg-gray-300 b-0 text-dark" />
        //         <ChatRight cardClassName="text-dark" />
        //         <ChatLeft cardClassName="bg-gray-300 b-0 text-dark" /> */}
        //     </div>
        // )
    }
}

export default CnxMessageChat
