// Data imports
import { OMReference } from "firmament-node-sdk"
import * as React from "react"
import { RouteComponentProps } from "react-router"
import { Redirect, Route, Switch } from "react-router-dom"
import { CnxMemberProfile, CnxConsumerRefund, CnxMeeting, CnxProviderChargeback, CnxRating, CnxProviderProfile } from "../../domain"

// Feature Imports
import { CnxRatingDetails, CnxRatingDetailsStateMachine } from "./CnxRatingDetails"
import { CnxRatingForm, CnxRatingFormStateMachine } from "./CnxRatingForm"
import { CnxRatingList, CnxRatingListStateMachine } from "./CnxRatingList"

const Create = (ownProps: CnxRatingModuleProps) => (props: RouteComponentProps) => {
    return <CnxRatingForm machine={new CnxRatingFormStateMachine()} {...ownProps} {...props} />
}

const Edit = (ownProps: CnxRatingModuleProps) => (props: RouteComponentProps<{ id: string }>) => {
    return <CnxRatingForm machine={new CnxRatingFormStateMachine()} {...ownProps} {...props} />
}

const Details = (ownProps: CnxRatingModuleProps) => (props: RouteComponentProps<{ id: string }>) => {
    return <CnxRatingDetails machine={new CnxRatingDetailsStateMachine()} {...ownProps} {...props} />
}

export interface CnxRatingModuleProps {
    submitter?: OMReference<CnxMemberProfile>
    meeting?: OMReference<CnxMeeting>
    provider?: OMReference<CnxProviderProfile>
}

class CnxRatingModule extends React.Component<CnxRatingModuleProps> {

    public render() {
        return (
            <div id="CnxRatingModule" className="">
                <Switch>

                    <Route
                        path={`:parentPath(.*)/${CnxRating.Path}/new`}
                        component={Create(this.props)}
                    />

                    {/* <Route
                        path={`:parentPath(.*)/${CnxRating.Path}/:id/edit`}
                        component={Edit(this.props)}
                    /> */}

                    <Route
                        path={`:parentPath(.*)/${CnxRating.Path}/all`}
                        render={(componentProps) => <CnxRatingList machine={new CnxRatingListStateMachine()} {...this.props} {...componentProps} />}
                    />

                    <Route
                        path={`:parentPath(.*)/${CnxRating.Path}/:id`}
                        component={Details(this.props)}
                    />

                    <Redirect
                        strict={true}
                        from={`:parentPath(.*)/${CnxRating.Path}/`}
                        to={`:parentPath(.*)/${CnxRating.Path}`}
                    />

                    <Route
                        path={`:parentPath(.*)/${CnxRating.Path}`}
                        render={(componentProps) => <CnxRatingList machine={new CnxRatingListStateMachine()} {...this.props} {...componentProps} />}
                    />

                    <Route
                        path={`:parentPath(.*)`}
                        // tslint:disable-next-line
                        render={(componentProps) => {
                            componentProps.match.url = `${componentProps.match.url}/${CnxRating.Path}`
                            return <CnxRatingList machine={new CnxRatingListStateMachine()} {...this.props} {...componentProps} />
                        }}
                    />

                </Switch>
            </div>
        )
    }
}

export default CnxRatingModule
