//  CnxMoneyWallet.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    WltWallet,
    WltWalletProperties,
    WltTransaction,
    CnxMemberProfile,
} from ".."

export interface CnxMoneyWalletProperties extends WltWalletProperties {

    moneyOwner: OMReference<CnxMemberProfile>
}

class CnxMoneyWallet extends WltWallet implements CnxMoneyWalletProperties {

    public static Type: string = "cnx_money_wallet"
    public static Path: string = "cnx_money_wallets"


    public moneyOwner: OMReference<CnxMemberProfile>

    constructor(object: CnxMoneyWalletProperties) {
        super(object)


        this.moneyOwner = new OMReference(CnxMemberProfile, object.moneyOwner)
    }
}

export default CnxMoneyWallet
