//  PushChannel.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2019-09-05
//  Copyright © 2019 Apptivity Lab. All rights reserved.
//

import {
    OMUniverse,
    OMObjectProperties as ObjectProperties
} from "firmament-node-sdk"
import { Moment } from "moment"
import moment from "moment"
import {
    Object,
} from ".."

export interface PushChannelProperties extends ObjectProperties {

}

class PushChannel extends Object implements PushChannelProperties {

    public static Type: string = "push_channel"
    public static Path: string = "push_channels"

    constructor(object: PushChannelProperties) {
        super(object)

    }
}

export default PushChannel
