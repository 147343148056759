//  CnxServiceTier.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
    Command,
    CommandManager,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxBooking,
    CnxBid,
    CnxProviderProfile,
    CnxConsumerProfile,
    CnxMeeting,
} from ".."

export interface CnxServiceTierProperties extends DomainObjectProperties {
    preserviceMins: number
    durationMins: number
    gracePeriodMins: number
    serviceLeadType: "CONSUMER" | "PROVIDER"
    serviceTimingType: "ONDEMAND" | "PLANNED"
    earningPlatformFeeCents: number
    earningProcessingFeeCents: number
    earningCurrency: string
    earningGrossCents: number
    servicePlatformFeeCents: number
    serviceProcessingFeeCents: number
    serviceGrossCents: number
    serviceCurrency: string
    maximumBids: number
    maximumBookings: number
    minimumBids: number
    minimumBookings: number

    maxBids?: number
    minBids?: number
    maxBookings?: number
    minBookings?: number

    bookings?: Array<OMReference<CnxBooking>>
    bids?: Array<OMReference<CnxBid>>
    provider?: Array<OMReference<CnxProviderProfile>>
    consumer?: Array<OMReference<CnxConsumerProfile>>
    meetings?: Array<OMReference<CnxMeeting>>
}

class CnxServiceTier extends DomainObject implements CnxServiceTierProperties {

    public static Type: string = "cnx_service_tier"
    public static Path: string = "cnx_service_tiers"

    public preserviceMins: number
    public durationMins: number
    public gracePeriodMins: number
    public serviceLeadType: "CONSUMER" | "PROVIDER"
    public serviceTimingType: "ONDEMAND" | "PLANNED"
    public earningPlatformFeeCents: number
    public earningProcessingFeeCents: number
    public earningCurrency: string
    public earningGrossCents: number
    public servicePlatformFeeCents: number
    public serviceProcessingFeeCents: number
    public serviceGrossCents: number
    public serviceCurrency: string
    public maximumBids: number
    public maximumBookings: number
    public minimumBids: number
    public minimumBookings: number


    public maxBids: number
    public minBids: number
    public maxBookings: number
    public minBookings: number

    public bookings: Array<OMReference<CnxBooking>>
    public bids: Array<OMReference<CnxBid>>
    public provider: Array<OMReference<CnxProviderProfile>>
    public consumer: Array<OMReference<CnxConsumerProfile>>
    public meetings: Array<OMReference<CnxMeeting>>

    protected static AccessCommand = class AccessCommand extends Command<CnxServiceTier> {
        public static readonly Path = "access"

        public constructor(
            receiver: CnxServiceTier,
            public profile: OMReference<CnxConsumerProfile>,
        ) {
            super(receiver)
        }
    }


    public access(profile: OMReference<CnxConsumerProfile>) {
        try {
            const command = new CnxServiceTier.AccessCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    constructor(object: CnxServiceTierProperties) {
        super(object)

        this.preserviceMins = object.preserviceMins
        this.durationMins = object.durationMins
        this.gracePeriodMins = object.gracePeriodMins
        this.serviceLeadType = object.serviceLeadType
        this.serviceTimingType = object.serviceTimingType
        this.earningPlatformFeeCents = object.earningPlatformFeeCents
        this.earningProcessingFeeCents = object.earningProcessingFeeCents
        this.earningCurrency = object.earningCurrency
        this.earningGrossCents = object.earningGrossCents
        this.servicePlatformFeeCents = object.servicePlatformFeeCents
        this.serviceProcessingFeeCents = object.serviceProcessingFeeCents
        this.serviceGrossCents = object.serviceGrossCents
        this.serviceCurrency = object.serviceCurrency
        this.maximumBids = object.maximumBids
        this.maximumBookings = object.maximumBookings
        this.minimumBids = object.minimumBids
        this.minimumBookings = object.minimumBookings

        this.maxBids = object.maxBids || 1
        this.minBids = object.minBids || 1
        this.maxBookings = object.maxBookings || 1
        this.minBookings = object.minBookings || 1

        this.bookings = object.bookings ? object.bookings.map((item) => new OMReference(CnxBooking, item)) : []
        this.bids = object.bids ? object.bids.map((item) => new OMReference(CnxBid, item)) : []
        this.provider = object.provider ? object.provider.map((item) => new OMReference(CnxProviderProfile, item)) : []
        this.consumer = object.consumer ? object.consumer.map((item) => new OMReference(CnxConsumerProfile, item)) : []
        this.meetings = object.meetings ? object.meetings.map((item) => new OMReference(CnxMeeting, item)) : []
    }

    public get servicePriceCents(): number {
        return this.serviceGrossCents + this.serviceProcessingFeeCents + this.servicePlatformFeeCents
    }

}

export default CnxServiceTier
