import { OMFile, OMReference } from "firmament-node-sdk"
import { Moment } from "moment"
import * as React from "react"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import { Column, TableCellProps } from "react-virtualized"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavLink } from "reactstrap"
import isEqual from "react-fast-compare"
import debounce from "../../../helpers/debounce"
import bg_top_up_header from "../../../assets/bg_top_up_header.png"
import ic_splash_graphic_iphone from "../../../assets/ic_splash_graphic_iphone@2x.png"

import {
    DateRangePicker,
    ErrorAlert,
    ModalSpinner,
    ModelTable,
    Pagination,
    AsyncEntitySelectHeader,
    FileRenderer,
    SelectHeader,
    TextInputHeader,
    ListStateMachine,
    Body,
    Title3,
    Modal,
    ModalDialog,
    LoadingButton,
    errorToast,
    Headline,
} from "../../../components"
import { withMachine, withQueryString } from "../../../hoc"

import { CnxTopupPayment, SchTopupPackage, SchTopupPayment, Setting } from "../../../domain"

import { SchTopupPackageListStateMachine } from "../SchTopupPackageList"
import SchTopupPackageCard from "../SchTopupPackageDetails/elements/SchTopupPackageCard"

interface SchTopupPackageListProps extends RouteComponentProps {
    machine: SchTopupPackageListStateMachine

    purchases?: OMReference<CnxTopupPayment>[]

    queryString: any
    updateQueryString: (keyPath: any) => (value: any) => void
    setQueryString: (state: any) => void
}

interface SchTopupPackageListComponentState {
    machineState: SchTopupPackageListStateMachine.State
    queryParams: ListStateMachine.QueryParams<SchTopupPackage> & Partial<SchTopupPackage>
}

class SchTopupPackageList extends React.Component<SchTopupPackageListProps, SchTopupPackageListComponentState> {

    private machine: SchTopupPackageListStateMachine

    /* SECTION: Callbacks */

    private onSortChange = (sort: any) => this.props.setQueryString({ ...sort })
    private updateQueryParams = ModelTable.updateQueryParams.bind(this as any)
    private onDateRangeChange = DateRangePicker.onDateRangeChange.bind(this)

    constructor(props: SchTopupPackageListProps) {
        super(props)
        this.machine = new SchTopupPackageListStateMachine()
        this.state = {
            machineState:  this.machine.state,
            queryParams: {
                currentPage:  0,
                sortBy: "sequence",
                sortDirection: "ASC",

            },
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)

        this.machine.load({
            ...this.state.queryParams,
            ...this.props.queryString,
        })
    }

    public componentDidUpdate(prevProps: SchTopupPackageListProps, prevState: SchTopupPackageListComponentState) {
        if (this.state.queryParams !== prevState.queryParams || !isEqual(this.props.queryString, prevProps.queryString)) {
            this.machine.load({
                ...this.state.queryParams,
                ...this.props.queryString,
            })
        }
    }

    public componentWillUnmount() {
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
                return <ModalSpinner />
            case "loading":
            case "presenting":
            case "promptingDelete":
            case "performingDelete":
            case "showingError":
                if (!(machineState as any).items) {
                    return <ErrorAlert error={(machineState as any).error} />
                }

                if (machineState.name === "showingError") {
                    errorToast((machineState as any).error)
                }

                return (
                    <>
                        {((machineState as any).items as SchTopupPackage[]).map((topupPackage) => <SchTopupPackageCard schTopupPackage={topupPackage} onClick={() => this.machine.showDetail(new OMReference(SchTopupPackage, topupPackage.id))} />)}

                        <Link to={{ state: { "presentingRedeemVoucherModal": true } }}>
                            <button type="button" className="btn btn-primary btn-block mb-4">
                                <Headline>Redeem voucher</Headline>
                            </button>
                        </Link>

                        <p className="text-center">
                            <small>
                                By purchasing the pack, you are agree to this app's <a target="_blank" href={Setting.find("sch_onboarding_terms_url") && Setting.find("sch_onboarding_terms_url")!.value || "https://askbee.my/"}>terms of use</a>
                            </small>
                        </p>
                    </>
                )
            case "showingDetail":
                this.props.history.push(`/${SchTopupPackage.Path}/${machineState.ref.id}/${SchTopupPayment.Path}/new`)
                return null
            case "showingCreate":
                this.props.history.push(`${this.props.match.url}/new`)
                return null
            case "completed":
                return null
        }
    }

    private subscriptionCallback = (machineState: SchTopupPackageListStateMachine.State) => {
        return this.setState({machineState})
    }

    /* SECTION: Custom Cell Renderer */

    private showDetail = (id: string) => () => {
        const ref = new OMReference(SchTopupPackage, id)
        this.machine.showDetail(ref)
    }

    private actionCellRenderer = (props: TableCellProps) => {
        const schTopupPackage = new SchTopupPackage(props.rowData)

        return (
            <div onClick={(event) => event.stopPropagation()}>
                <UncontrolledDropdown>
                    <DropdownToggle color="">
                        <i className="fa fa-fw fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem className="text-primary" tag={"button"} type="button" onClick={() => this.machine.showDetail(new OMReference(SchTopupPackage, schTopupPackage.objectUuid))}>Edit</DropdownItem>
                        <DropdownItem className="text-danger" tag={"button"} type="button" onClick={() => this.machine.promptDelete(schTopupPackage)}>Delete</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem className="text-primary" tag={"button"} type="button" onClick={() => this.props.history.push(`${SchTopupPackage.Path}/${schTopupPackage.id}/${SchTopupPayment.Path}/new`)}>Topup</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        )
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        this.debounceUpdate(value)
    }

    private debounceUpdate = debounce((value: string) => {
        this.props.updateQueryString("title")(value || null as any)
    }, 250)

}

export default withMachine(SchTopupPackageListStateMachine)(withQueryString({ path: SchTopupPackage.Path })(SchTopupPackageList))
