import * as React from "react"

import getProfile from "../../../../helpers/Profile"
import { SchStudentProfile } from "../../../../domain"

export interface TokensLeftCaptionComponentProps {

}

export interface TokensLeftCaptionComponentState {

}

class TokensLeftCaption extends React.PureComponent<TokensLeftCaptionComponentProps, TokensLeftCaptionComponentState> {
    public render() {
        const profile = getProfile()

        if (!profile) {
            return null
        }

        if (!(profile instanceof SchStudentProfile)) {
            return null
        }

        const sessionWallet = profile.sessionWallet && profile.sessionWallet.actualObject

        if (!sessionWallet) {
            return null
        }

        const sessionBalance = ((sessionWallet.transactionsAvailableBalance || 0) / 100).toLocaleString(undefined, { maximumFractionDigits: 0 })

        return `Available Balance: ${sessionBalance} Tokens`
    }
}

export default TokensLeftCaption
