import React from "react"
import "./PasswordInput.css"

export type PasswordInputType = "doubleConfirmation" | "hide/show" | "normal"

export interface PasswordInputComponentProps {
    autoComplete?: string
    label?: string
    type?: PasswordInputType
    onChange?: (challenge?: string) => void
    header?: JSX.Element
    disabled?: boolean
    validationFormat?: RegExp
    invalidMessage?: string
    required?: boolean
}

export interface PasswordInputComponentState {
    hide: boolean
    challenge?: string
    confirmChallenge?: string
    type: PasswordInputType
    isValid: boolean
}

class PasswordInput extends React.Component<PasswordInputComponentProps, PasswordInputComponentState> {

    static defaultProps = {
        required: true
    }

    private get isPasswordMatched() {
        return this.state.challenge === this.state.confirmChallenge
    }

    public static onChange(
        this: React.Component<any, { challenge?: string }>,
        challenge?: string,
    ) {
        this.setState({ challenge })
    }

    constructor(props: PasswordInputComponentProps) {
        super(props)
        this.state = {
            hide: true,
            isValid: true,
            type: this.props.type || "hide/show",
        }
    }

    public componentDidUpdate(prevProps: PasswordInputComponentProps, prevState: PasswordInputComponentState) {

        if (this.props.type !== prevProps.type) {
            this.setState({
                type: this.props.type || "hide/show",
            })
        }

        if (this.props.onChange && this.state !== prevState) {

            switch (this.state.type) {
                case "doubleConfirmation":
                    if (this.state.challenge && this.state.confirmChallenge && this.state.challenge) {
                        this.props.onChange(this.state.challenge)
                    } else {
                        this.props.onChange()
                    }
                    break
                case "hide/show":
                case "normal":
                    if (this.state.challenge && this.state.isValid) {
                        this.props.onChange(this.state.challenge)
                    } else {
                        this.props.onChange()
                    }
                    break
            }
        }

        if (this.props.validationFormat && this.state.challenge !== prevState.challenge) {
            this.setState({
                isValid: this.state.challenge
                    ? this.props.validationFormat.test(this.state.challenge)
                    : true,
            })
        }

    }

    public render() {
        switch (this.state.type) {
            case "doubleConfirmation":
                return (
                    <>
                        <div className="form-group">
                            {this.props.header || <label htmlFor="PasswordInput.challenge">{this.props.label || "Password"}</label>}
                            <input
                                autoComplete={this.props.autoComplete}
                                disabled={this.props.disabled}
                                type="password"
                                id="PasswordInput.challenge"
                                className="form-control bg-white"
                                required={this.props.required}
                                onChange={this.onChange("challenge")}
                                value={this.state.challenge}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="PasswordInput.confirmChallenge">Repeat Password</label>
                            <input
                                autoComplete={this.props.autoComplete}
                                disabled={this.props.disabled}
                                type="password"
                                id="PasswordInput.confirmChallenge"
                                className="form-control bg-white"
                                required={this.props.required}
                                onChange={this.onChange("confirmChallenge")}
                                value={this.state.confirmChallenge}
                            />
                            {!this.isPasswordMatched && (
                                <small id="PasswordInput.confirmChallenge" className="form-text text-danger">
                                    Password does not match.
                                </small>
                            )}
                        </div>
                    </>
                )
            case "hide/show":
                return (
                    <div className="form-group">
                        {this.props.header || <label htmlFor="PasswordInput.challenge">{this.props.label || "Password"}</label>}
                        <div className="input-group">
                            <input
                                autoComplete={this.props.autoComplete}
                                disabled={this.props.disabled}
                                type={this.state.hide ? "password" : "text"}
                                className="form-control bg-white"
                                id="PasswordInput.challenge"
                                required={this.props.required}
                                onChange={this.onChange("challenge")}
                                value={this.state.challenge}
                                placeholder={this.props.disabled ? "******" : undefined}
                            />

                            <div className="input-group-append">
                                <div className="input-group-text visibility-toggle" onClick={this.toggleVisibility}>
                                    {this.state.hide
                                        ? <i className="fa fa-fw fa-eye" />
                                        : <i className="fa fa-fw fa-eye-slash" />
                                    }
                                </div>
                            </div>
                        </div>

                        {!this.state.isValid && (
                            <small id="PasswordInput.confirmChallenge" className="form-text text-danger">
                                {this.props.invalidMessage || "Invalid password format."}
                            </small>
                        )}
                    </div>
                )
            case "normal":
                return (
                    <div className="form-group">
                        {this.props.header || <label htmlFor="PasswordInput.challenge">{this.props.label || "Password"}</label>}
                        <div className="input-group">
                            <input
                                autoComplete={this.props.autoComplete}
                                type="password"
                                className="form-control bg-white"
                                id="PasswordInput.challenge"
                                required={this.props.required}
                                onChange={this.onChange("challenge")}
                                value={this.state.challenge}
                            />
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    private onChange = (keyPath: keyof PasswordInputComponentState) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({
                [keyPath]: event.target.value,
            } as any)
        }

    private toggleVisibility = () => {
        if (!this.props.disabled) {
            this.setState({ hide: !this.state.hide })
        }
    }

}

export default PasswordInput
