// Data imports
import { FSMNamedState as FSMState, OMQuery, OMRealtimeSubscription, OMReference, OMUniverse } from "firmament-node-sdk"
import { CnxMeeting, CnxMemberProfile, CnxRating, CnxProviderProfile, SchTutorProfile, CnxConsumerProfile, SchStudentProfile } from "../../../domain"

// Feature imports
import { DetailsStateMachineWithDelete } from "../../../components"
import getProfile from "../../../helpers/Profile"

declare namespace CnxMeetingWaitingLoungeStateMachine {
    export interface ShowEdit extends FSMState {
        name: "showingEdit"
        item: CnxMeeting
    }

    export interface LeavingMeeting extends FSMState {
        name: "leavingMeeting"
        item: CnxMeeting
    }

    export interface TryingOtherTutors extends FSMState {
        name: "tryingOtherTutors"
        item: CnxMeeting
    }

    export type State = DetailsStateMachineWithDelete.State<CnxMeeting> |
        ShowEdit |
        LeavingMeeting |
        TryingOtherTutors
}

class CnxMeetingWaitingLoungeStateMachine extends DetailsStateMachineWithDelete<CnxMeeting, CnxMeetingWaitingLoungeStateMachine.State> {

    private subscription?: OMRealtimeSubscription<typeof CnxMeeting>

    /**
     * Show Edit Transition
     * [presenting] -- dismiss -> [showingEdit]
     */
    public showEdit = (item: CnxMeeting): void => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingEdit", item }
            default:
                return
        }
    }

    public leaveMeeting = async () => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "leavingMeeting", item: this.state.item }
                break
            default:
                return
        }

        try {
            const meeting = this.state.item
            const profile = getProfile()

            if (!profile) {
                throw new Error("Profile not found.")
            }

            await meeting.leave(new OMReference(CnxMemberProfile, profile.id))

            this.dismiss()
        } catch (error) {
            this.fail(error)
        }

    }

    public tryOtherTutors = async () => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "tryingOtherTutors", item: this.state.item }
                break
            default:
                return
        }

        try {
            const meeting = this.state.item
            const profile = getProfile()

            if (!profile) {
                throw new Error("Profile not found.")
            }

            const query = SchTutorProfile.query()
                .limit(5)
                .sort("meetingsCount", "DESC")
                .include("photo")
                .include("categories")
                .include("categories.availableTiers" as any)
                .include("serviceTier")
                .filter("categorySet", "contains", meeting.serviceCategorySet.join(","))
                .filter("serviceTierSet", "contains", [meeting.serviceTier.actualObject?.slug].join(","))
                .filter("isOnline", "equals", true)

            await query.execute()

            await meeting.setMatchProviders(query.results)

            meeting.matchProviders = query.results
            OMUniverse.shared.touch(meeting)

            await meeting.notifyMatchProviders(meeting.matchProviders)

            this.success(meeting)
        } catch (error) {
            this.fail(error)
        }

    }

    public load = async (id: OMReference<CnxMeeting>) => {
        switch (this.state.name) {
            case "start":
                this.state = { name: "loading" }
                break
            default:
                return
        }

        try {
            const profile = getProfile()

            const query = CnxMeeting.query()
                .include("bookings")
                // .include("consumers")
                .include("videoRoom")
                .include("serviceTier")
                .include("serviceCategories")
                .include("currentParticipants")
                .include("ratings")
                .include("matchProviders")
                .include("matchProviders.photo" as any)

                .include("provider")
                .include("provider.photo" as any)
                .include("provider.categories" as any)

            if (profile instanceof CnxProviderProfile) {
                query.include("consumers")
            }

            const item = await id.load(query)

            if (profile instanceof CnxProviderProfile) {
                const consumer = item.consumers?.[0]
                await consumer.load(
                    SchStudentProfile.query()
                        .include("photo")
                        .include("categories")
                )
            }

            this.success(item)

            if (!["FAILED", "COMPLETED"].includes(item.meetingStatus || "") && profile && !item.currentParticipants.map((each) => each.id).includes(profile.id)) {
                await item.join(new OMReference(CnxMemberProfile, profile.id))
            }

            this.subscription = item.realtimeSubscription(CnxMeeting)
                .listen(async (command) => {
                    const query = CnxMeeting.query()
                        .include("provider")
                        .include("provider.photo" as any)
                        .include("provider.categories" as any)

                    if (profile instanceof CnxProviderProfile) {
                        query.include("consumers")
                    }

                    const meeting = await command.receiver.load(query)

                    if (profile instanceof CnxProviderProfile) {
                        const consumer = meeting.consumers?.[0]
                        consumer.load(SchStudentProfile.query()
                            .include("photo")
                            .include("categories")
                        )
                    }

                    meeting.matchProviders = item.matchProviders
                    meeting.serviceCategories = item.serviceCategories
                    meeting.serviceTier = item.serviceTier

                    this.state = { name: "presenting", item: meeting /* TODO: this item do not have certain rels */ }
                })

        } catch (error) {
            this.fail(error)
        }
    }

    public fail = (error: Error): void => {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
            case "leavingMeeting":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

    public dismiss = () => {
        switch (this.state.name) {
            case "presenting":
            case "showingError":
            case "performingDelete":
            case "leavingMeeting":
                this.state = { name: "completed" }
                break
            default:
                break
        }
    }

    public unsubscribeRealtime = () => {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }

}

export default CnxMeetingWaitingLoungeStateMachine
