import { ArticleContent, ArticleContentProperties } from "."

export interface ArticleParagraphProperties extends ArticleContentProperties {
    body?: string
}

class ArticleParagraph extends ArticleContent implements ArticleParagraphProperties {

    public static Type: string = "article_paragraph"
    public static Path: string = "article_paragraphs"

    public static isType(object: any): object is ArticleParagraph {
        return object.type === "article_paragraph" || object.objectType === "article_paragraph"
    }

    public body?: string

    constructor(object: ArticleParagraphProperties) {
        super(object)
        this.body = object.body
    }

}

export default ArticleParagraph
