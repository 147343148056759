//  SchTopupPayment.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-20
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxTopupPayment,
    CnxTopupPaymentProperties,
    Profile,
    PmtPayment,
    CnxTopupPackage,
    CnxConsumerProfile,
    CnxConsumerTopup,
} from ".."

export interface SchTopupPaymentProperties extends CnxTopupPaymentProperties {
    sessionsPurchased: number

}

class SchTopupPayment extends CnxTopupPayment implements SchTopupPaymentProperties {

    public static Type: string = "sch_topup_payment"
    public static Path: string = "sch_topup_payments"

    public sessionsPurchased: number


    constructor(object: SchTopupPaymentProperties) {
        super(object)

        this.sessionsPurchased = object.sessionsPurchased

    }
}

export default SchTopupPayment
