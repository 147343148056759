// tslint:disable

import React from "react"
import isEqual from "react-fast-compare"
import qs from "qs"
import moment from "moment"
import { OMReference } from 'firmament-node-sdk'

const withQueryString = (opts) => (Component) => {

    return class WithQueryString extends React.Component {

        constructor(props) {
            super(props)
            this.state = {
                ...qs.parse(this.props.location.search && this.props.location.search.replace(/^\?(.+)/, "$1")),
            }

            this.state[opts.path] = {
                ...opts.default,
                ...this.state[opts.path],
            }
        }

        componentDidUpdate(_, prevState) {
            if (!isEqual(this.state, prevState)) {
                this.props.history.push(`${this.props.location.pathname}?${qs.stringify(JSON.parse(JSON.stringify(this.state)))}`)
            }
        }

        render() {
            return <Component {...this.props} queryString={this.state[opts.path] || {}} setQueryString={this.setQueryString} updateQueryString={this.updateQueryString} />
        }

        updateQueryString = (keyPath) => (val, callback) => {
            this.setState({
                [opts.path]: {
                    ...this.state[opts.path],
                    currentPage: 0,
                    [keyPath]: val instanceof moment ? val.toISOString() : val instanceof OMReference ? val.id : val,
                }
            }, callback)
        }

        setQueryString = (state) => {
            this.setState({
                [opts.path]: {
                    ...this.state[opts.path],
                    ...state
                }
            })
        }

    }
}

export default withQueryString
