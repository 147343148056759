//  PmtPayment.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-06
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMObjectProperties as ObjectProperties,
    CommandManager,
    Command,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    Object,
    Profile,
} from ".."

export interface PmtPaymentProperties extends ObjectProperties {
    currency: string
    amountInCents: number
    processorPaymentId?: string
    status: string
    refundedAt?: Moment
    cancellationReason?: string
    cancelledAt?: Moment
    description?: string
    failureCode?: string
    failureReason?: string
    chargedAt?: Moment
    refundReason?: string
    cardNumber?: string
    methodType?: string
    cardBrand?: string
    receiptEmail?: string

    customer: OMReference<Profile>
}

class PmtPayment extends Object implements PmtPaymentProperties {

    public static Type: string = "pmt_payment"
    public static Path: string = "pmt_payments"

    public currency: string
    public amountInCents: number
    public processorPaymentId?: string
    public status: string
    public refundedAt?: Moment
    public cancellationReason?: string
    public cancelledAt?: Moment
    public description?: string
    public failureCode?: string
    public failureReason?: string
    public chargedAt?: Moment
    public refundReason?: string
    public cardNumber?: string
    public methodType?: string
    public cardBrand?: string
    public receiptEmail?: string

    public customer: OMReference<Profile>

    protected static FetchStatusCommand = class FetchStatusCommand extends Command<PmtPayment> {
        public static readonly Path = "fetch_status"

        public constructor(
            receiver: PmtPayment,
        ) {
            super(receiver)
        }
    }

    public fetchStatus() {
        try {
            const command = new PmtPayment.FetchStatusCommand(this)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    constructor(object: PmtPaymentProperties) {
        super(object)

        this.currency = object.currency
        this.amountInCents = object.amountInCents
        this.processorPaymentId = object.processorPaymentId
        this.status = object.status
        this.refundedAt = object.refundedAt && moment(object.refundedAt)
        this.cancellationReason = object.cancellationReason
        this.cancelledAt = object.cancelledAt && moment(object.cancelledAt)
        this.description = object.description
        this.failureCode = object.failureCode
        this.failureReason = object.failureReason
        this.chargedAt = object.chargedAt && moment(object.chargedAt)
        this.refundReason = object.refundReason
        this.cardNumber = object.cardNumber
        this.methodType = object.methodType
        this.cardBrand = object.cardBrand
        this.receiptEmail = object.receiptEmail

        this.customer = new OMReference(Profile, object.customer)
    }
}

export default PmtPayment
