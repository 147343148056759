import React from "react"

import { ArticleHeader } from "../../../../domain"

export interface ArticleHeaderCardComponentProps {
    articleHeader: ArticleHeader
}

export interface ArticleHeaderCardComponentState {

}

class ArticleHeaderCard
    extends React.Component<ArticleHeaderCardComponentProps, ArticleHeaderCardComponentState> {

    constructor(props: ArticleHeaderCardComponentProps) {
        super(props)
    }

    public render() {
        return (
            <div className="row">

                <p className="px-3">
                    {(() => {
                        switch (this.props.articleHeader.level) {
                            case 1:
                                return <h1>{this.props.articleHeader.title}</h1>
                            case 2:
                                return <h2>{this.props.articleHeader.title}</h2>
                            case 3:
                                return <h3>{this.props.articleHeader.title}</h3>
                            case 4:
                                return <h4>{this.props.articleHeader.title}</h4>
                            case 5:
                                return <h5>{this.props.articleHeader.title}</h5>
                            case 6:
                                return <h6>{this.props.articleHeader.title}</h6>
                            default:
                                return <h1>{this.props.articleHeader.title}</h1>
                        }
                    })()}
                </p>

            </div>
        )
    }

}

export default ArticleHeaderCard
