import { OMLocation } from "firmament-node-sdk"
import React from "react"

export interface LocationFormInputComponentProps {
    value?: OMLocation
    onChange(location?: OMLocation): void
}

export interface LocationFormInputComponentState {
    longitude?: number
    latitude?: number
    location?: OMLocation

    longitudeRaw?: string
    latitudeRaw?: string
}

class LocationFormInput extends React.Component<LocationFormInputComponentProps, LocationFormInputComponentState> {

    constructor(props: LocationFormInputComponentProps) {
        super(props)
        this.state = {
            latitude: this.props.value && this.props.value.latitude,
            location: this.props.value,
            longitude: this.props.value && this.props.value.longitude,

            latitudeRaw: this.props.value && `${this.props.value.latitude}`,
            longitudeRaw: this.props.value && `${this.props.value.longitude}`,
        }
    }

    public componentDidUpdate(prevProps: LocationFormInputComponentProps, prevState: LocationFormInputComponentState) {
        if (
            this.state.longitude !== prevState.longitude ||
            this.state.latitude !== prevState.latitude
        ) {

            if (
                this.state.longitude !== undefined &&
                this.state.latitude !== undefined
            ) {
                this.setState({
                    location: new OMLocation({
                        latitude: this.state.latitude,
                        longitude: this.state.longitude,
                    }),
                })
            } else {
                this.setState({
                    location: undefined,
                })
            }

        }

        if (this.state.location !== prevState.location) {
            this.props.onChange(this.state.location)
        }
    }

    public render() {
        return (
            <div className="row">
                <div className="col-6">
                    <label>Latitude</label>
                    <input
                        className="form-control"
                        type="text"
                        value={this.state.latitudeRaw || ""}
                        placeholder="Latitude"
                        onChange={this.onChange("latitude")}
                    />
                    <small className={this.state.latitudeRaw && !this.state.latitude ? "text-danger" : "d-none"}>Invalid input</small>
                </div>

                <div className="col-6">
                    <label>Longitude</label>
                    <input
                        className="form-control"
                        type="text"
                        value={this.state.longitudeRaw || ""}
                        placeholder="Longitude"
                        onChange={this.onChange("longitude")}
                    />
                    <small className={this.state.longitudeRaw && !this.state.longitude ? "text-danger" : "d-none"}>Invalid input</small>
                </div>
            </div>
        )
    }

    private onChange = (field: "longitude" | "latitude") => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!/^-?\d*[.,]?\d*$/.test(event.target.value)) {
            return
        }

        this.setState({
            [`${field}Raw`]: event.target.value,
            [field]: parseFloat(event.target.value) || 0,
        })
    }

}

export default LocationFormInput
