// Data module imports
import { OMReference } from "firmament-node-sdk"
import * as React from "react"
import { RouteComponentProps } from "react-router"

import { CnxMessageDetailsStateMachine } from "."
import CnxMessageCard from "./elements/CnxMessageCard"

import {
    ErrorAlert,
    LoadingButton,
    Modal,
    ModalDialog,
    ModalSpinner,
    Title3,
    ListStateMachine,
} from "../../../components"

import { CnxMeeting, GenImage, CnxMemberProfile, CnxMessage } from "../../../domain"

declare interface CnxMessageDetailsProps extends RouteComponentProps <{ id: string }> {
    machine: CnxMessageDetailsStateMachine

    meeting?: OMReference<CnxMeeting>
    attachedImage?: OMReference<GenImage>
    sender?: OMReference<CnxMemberProfile>
}

interface CnxMessageDetailsComponentState {
    machineState: CnxMessageDetailsStateMachine.State
    queryParams: ListStateMachine.QueryParams<CnxMessage>
}

class CnxMessageDetails
extends React.Component <CnxMessageDetailsProps, CnxMessageDetailsComponentState> {

    private machine: CnxMessageDetailsStateMachine

    constructor(props: CnxMessageDetailsProps) {
        super(props)
        this.machine = this.props.machine
        this.state = {
            machineState: this.machine.state,
            queryParams: {
                currentPage: 0,
                sortBy: "createdAt",
                sortDirection: "DESC",
            },
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)
        this.machine.load(new OMReference(CnxMessage, this.props.match.params.id))
    }

    public componentWillUnmount() {
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
            case "loading":
                return <ModalSpinner />
            case "promptingDelete":
            case "performingDelete":
            case "presenting":
                return (
                    <div id="CnxMessageDetail" className="p-3">

                        <Modal show={machineState.name === "promptingDelete" || machineState.name === "performingDelete"} onDismiss={this.machine.cancel} >
                            <ModalDialog
                                title="Confirm Delete"
                                body="This action cannot be reversed."
                                actionButton={
                                    <LoadingButton
                                        isLoading={machineState.name === "performingDelete"}
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={this.machine.performDelete}
                                    >
                                        DELETE
                                    </LoadingButton>
                                }
                            />
                        </Modal>

                        <div className="d-flex justify-content-end">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.showEdit(machineState.item)}
                            >
                                EDIT
                            </button>
                        </div>

                        <Title3 className="my-4">{machineState.item.title}</Title3>

                        <CnxMessageCard cnxMessage={machineState.item} />

                    </div>
                )
            case "showingError":
                return <ErrorAlert error={machineState.error} />
            case "showingEdit":
                this.props.history.push(`${this.props.match.url}/edit`)
                return null
            case "completed":
                this.props.history.goBack()
                return null
        }
    }

    private subscriptionCallback = (machineState: CnxMessageDetailsStateMachine.State) => this.setState({machineState})

    private showEdit = (item: CnxMessage) => () => {
        this.machine.showEdit(item)
    }
}

export default CnxMessageDetails
