import React from "react"
import groupBy from "lodash/groupBy"
import intersection from "lodash/intersection"

import { CnxProviderProfile, CnxServiceCategory, SchStudentProfile } from "../../../../domain"
import getProfile from "../../../../helpers/Profile"

interface Props {
    provider: CnxProviderProfile
    showGrade?: boolean
    showPending?: boolean
}

export const teachSubject = (provider: CnxProviderProfile, showGrade: boolean = true, showPending?: boolean): string[] => {
    /**
     * NOTE: end goal:
     * e.g. English (#SaveSPM2020; Standard 1-6), ...
     *
     * Components:
     *
     * English -> subject.title
     * #SaveSPM2020; Standard -> subject.parentCategory.grouping
     * 1; 6 -> subject.parentCategory.nominalValue
     *
     * Steps:
     * x := groupby(subjects, .title)
     * y := x.values.each :groupBy(.parentCategory.grouping)
     * z := y.values.each :groupBy(..parentCategory.nominalValue)
     */

    let subjects: CnxServiceCategory[] = (showPending ? provider.pendingCategories : provider.categories)
        .map((each) => each.actualObject)
        .filter((each) => each?.categoryType === "SUBJECT")
        .filter((each) => showPending ? true : intersection(each!.availableTierSet, provider.serviceTierSet).length > 0)
        .sort((lhs, rhs) => (lhs?.sequence || 0) - (rhs?.sequence || 0)) as any

    const profile = getProfile()
    if (!showGrade && profile instanceof SchStudentProfile && profile.grade) {
        const grade = profile.grade
        subjects = subjects.filter((each) => each.parentCategory?.id === grade.id)
    }

    // e.g. { English: [item1, item2, item3], Math: [item4] }
    const subjectsBySubjectTitle = groupBy(subjects, (subject) => subject.title)

    // e.g. { English: { Standard: [item1] } }
    const subjectsByParentGroupingBySubjectTitle = Object.fromEntries(Object.entries(subjectsBySubjectTitle).map(([grouping, subjects]) => [grouping, groupBy(subjects, (subject) => subject.parentCategory?.actualObject?.grouping)]))

    if (!showGrade) {
        return Object.keys(subjectsBySubjectTitle).sort((lhs, rhs) => lhs.localeCompare(rhs))
    }




    // e.g. { 1: [S-PG-NV-1, S-PG-NV-2, S-PG-NV-3], 5: [S-PG-NV-5, S-PG-NV-6] }
    const contiguousSubGroups = (subjects: CnxServiceCategory[]): CnxServiceCategory[][] | undefined => {
        let p1: number | undefined
        let p2: number | undefined

        if (subjects.length <= 0) {
            return
        }

        return Object.values(
            subjects
                .reduce((map, subject) => {
                    const nominalValue = subject.parentCategory?.actualObject?.nominalValue || 0

                    switch (true) {
                        case p1 === undefined || p2 === undefined:
                            p1 = p2 = nominalValue
                            map[p1] = [...map[p1] || [], subject]
                            return map
                        case nominalValue === p2! + 1:
                            p2 = nominalValue
                            map[p1!] = [...map[p1!] || [], subject]
                            return map
                        default:
                            p1 = p2 = nominalValue
                            map[p1] = [...map[p1] || [], subject]
                            return map
                    }

                }, {} as Record<number, CnxServiceCategory[]>)
        )
    }

    return Object.entries(subjectsByParentGroupingBySubjectTitle)
        .sort((lhs, rhs) => lhs[0].localeCompare(rhs[0]))
        .map(([subjectTitle, parentGrouping]) => {
            return Object.entries(parentGrouping).map(([parentGrouping, subjects]) => {
                const partitioned = contiguousSubGroups(subjects)

                const formattedNominalValue = partitioned?.map((subjects) => {
                    if (subjects.length === 0) {
                        return
                    }

                    const firstSubject = subjects[0]
                    const lastSubject = subjects[subjects.length - 1]

                    if (subjects.length === 1) {
                        return `${firstSubject.parentCategory?.actualObject?.nominalValue}`
                    } else {
                        return `${firstSubject.parentCategory?.actualObject?.nominalValue}-${lastSubject.parentCategory?.actualObject?.nominalValue}`
                    }
                }).join(", ")

                switch (true) {
                    case !!parentGrouping && parentGrouping !== "null" && !!formattedNominalValue && formattedNominalValue !== "null":
                        return `${subjectTitle} (${parentGrouping} ${formattedNominalValue})`
                    case !!parentGrouping && parentGrouping !== "null":
                        return `${subjectTitle} (${parentGrouping})`
                    default:
                        return subjectTitle
                }
            })
        }).flat()
}

const memoizedTeachSubject = teachSubject

const TeachSubject: React.FunctionComponent<Props> = ({ provider, showGrade, showPending }) => {
    return <>{memoizedTeachSubject(provider, showGrade, showPending).join(", ")}</>
}

const TeachSubjectBadge: React.FunctionComponent<Props> = ({ provider, showGrade, showPending }) => {
    return (
        <>
            {memoizedTeachSubject(provider, showGrade, showPending).map((each) => (
                <span key={each} className="badge badge-success mr-1 mb-1">{each}</span>
            ))}
        </>
    )
}

export { TeachSubjectBadge }

export default TeachSubject
