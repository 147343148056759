import React from "react"
import { Caption1 } from "./deprecated-components"
import pkg from "../../package.json"

export class BuildNumber extends React.PureComponent {
    render() {
        return (
            <>
                <p className="text-center my-0" {...this.props}>
                    <Caption1>ver {pkg.version} #{process.env.REACT_APP_CI_PIPELINE_ID || "localhost"}</Caption1>
                </p>
                <p className="text-center my-0" {...this.props}>
                    <small>2020 © Apptivity Lab Sdn. Bhd. (576984-W). All rights reserved.</small>
                </p>
            </>
        )
    }
}
