//  CnxRating.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxMeeting,
    CnxMemberProfile,
    CnxProviderChargeback,
    CnxConsumerRefund,
    CnxProviderProfile,
} from ".."

export interface CnxRatingProperties extends DomainObjectProperties {
    overall?: number
    review?: string
    subratingTypes?: string[]
    subrating1?: number
    subrating2?: number
    subrating3?: number
    subrating4?: number
    subrating5?: number
    sourceType: string
    report?: string

    meeting: OMReference<CnxMeeting>
    submitter?: OMReference<CnxMemberProfile>
    providerChargeback?: OMReference<CnxProviderChargeback>
    refund?: OMReference<CnxConsumerRefund>
    provider?: OMReference<CnxProviderProfile>
}

class CnxRating extends DomainObject implements CnxRatingProperties {

    public static Type: string = "cnx_rating"
    public static Path: string = "cnx_ratings"

    public overall?: number
    public review?: string
    public subratingTypes?: string[]
    public subrating1?: number
    public subrating2?: number
    public subrating3?: number
    public subrating4?: number
    public subrating5?: number
    public sourceType: string
    public report?: string

    public meeting: OMReference<CnxMeeting>
    public submitter?: OMReference<CnxMemberProfile>
    public providerChargeback?: OMReference<CnxProviderChargeback>
    public refund?: OMReference<CnxConsumerRefund>
    public provider?: OMReference<CnxProviderProfile>

    constructor(object: CnxRatingProperties) {
        super(object)

        this.overall = object.overall
        this.review = object.review
        this.subratingTypes = object.subratingTypes
        this.subrating1 = object.subrating1
        this.subrating2 = object.subrating2
        this.subrating3 = object.subrating3
        this.subrating4 = object.subrating4
        this.subrating5 = object.subrating5
        this.sourceType = object.sourceType
        this.report = object.report

        this.meeting = new OMReference(CnxMeeting, object.meeting)
        this.submitter = object.submitter && new OMReference(CnxMemberProfile, object.submitter)
        this.providerChargeback = object.providerChargeback && new OMReference(CnxProviderChargeback, object.providerChargeback)
        this.refund = object.refund && new OMReference(CnxConsumerRefund, object.refund)
        this.provider = object.provider && new OMReference(CnxProviderProfile, object.provider)
    }
}

export default CnxRating
