import React from "react"

export interface TextAreaInputComponentProps extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
    onChange?: (value?: string) => void
    value?: string
    maxCharactersCount?: number
}

export interface TextAreaInputComponentState {
    value?: string
}

class TextAreaInput extends React.Component<TextAreaInputComponentProps, TextAreaInputComponentState> {

    public maxCharactersCount: number

    constructor(props: TextAreaInputComponentProps) {
        super(props)
        this.state = {
            value: this.props.value,
        }

        this.maxCharactersCount = this.props.maxCharactersCount || 1000
    }

    public componentDidUpdate(prevProps: TextAreaInputComponentProps, prevState: TextAreaInputComponentState) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value })
        }

        if (this.props.onChange && this.state.value !== prevState.value) {
            this.props.onChange(this.state.value)
        }
    }

    public render() {
        return (
            <>
                <textarea
                    className="form-control"
                    {...this.props}

                    value={this.state.value}
                    onChange={this.onChange}
                />
                <small className="text-secondary">{(this.state.value ? this.state.value.length : 0).toLocaleString()} / {this.maxCharactersCount.toLocaleString()}</small>
            </>
        )
    }

    private onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value || undefined
        this.setState({ value: value ? value.slice(0, this.maxCharactersCount) : undefined })
    }
}

export default TextAreaInput
