import React from "react"
import TwilioAppStateProvider from "../../../components/TwilioVideo/state"
import TwilioVideoApp from "../../CnxVideoRoom/CnxVideoRoomDetails/elements/Twilio"
import useVideoContext from "../../../components/TwilioVideo/hooks/useVideoContext/useVideoContext"
import useRoomState from "../../../components/TwilioVideo/hooks/useRoomState/useRoomState"
import { Fab } from "@material-ui/core"
// import ToggleAudioButton from "../../../components/TwilioVideo/components/Controls/ToggleAudioButton/ToggleAudioButton"
import { useAppState } from "../../../views/App/elements/AppStateProvider"



// ToggleAudioButton
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import useLocalAudioToggle from "../../../components/TwilioVideo/hooks/useLocalAudioToggle/useLocalAudioToggle"

// ToggleVideoButton
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import useLocalVideoToggle from "../../../components/TwilioVideo/hooks/useLocalVideoToggle/useLocalVideoToggle"

// ToggleScreenShareButton
import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import SwitchCamera from "@material-ui/icons/SwitchCamera"
import useScreenShareToggle from '../../../components/TwilioVideo/hooks/useScreenShareToggle/useScreenShareToggle';
import { OMReference } from "firmament-node-sdk"
import { CnxMeeting } from "../../../domain"
import isMobile from "../../../helpers/isMobile"














export const RedirectContext = React.createContext<{ redirectURL: string | null, setRedirectURL: Function, performRedirect: Function }>(null as any)

export const RedirectProvider: React.FunctionComponent = (props) => {
    const [redirectURL, setRedirectURL] = React.useState(window.localStorage.getItem("redirectURL") || null)

    const performRedirect = () => {
        if (redirectURL) {
            setRedirectURL(null)
            window.location.href = redirectURL
        }
    }

    React.useEffect(() => {
        if (redirectURL) {
            window.localStorage.setItem("redirectURL", redirectURL)
        } else {
            window.localStorage.removeItem("redirectURL")
        }
    }, [redirectURL])

    return (
        <RedirectContext.Provider value={{ redirectURL, setRedirectURL, performRedirect }}>
            {props.children}
        </RedirectContext.Provider>
    )
}

















export const ToggleAudioButton = () => {
    // NOTE: useAppState takes precedence over useLocalAudioToggle
    // { audioEnabled, toggleAudio } in useAppState will be persisted across
    // all meeting sessions, refer to useAppState for implementation
    const { audioEnabled: globalIsAudioEnabled, toggleAudio: globalToggleAudioEnabled } = useAppState()
    const [isAudioEnabled, toggleAudio] = useLocalAudioToggle()

    React.useEffect(() => {
        if (globalIsAudioEnabled !== isAudioEnabled) {
            toggleAudio()
        }
    }, [globalIsAudioEnabled, isAudioEnabled])

    return (
        <Fab onClick={() => globalToggleAudioEnabled()}>
            {isAudioEnabled ? <Mic /> : <MicOff />}
        </Fab>
    )
}

export const ToggleVideoButton = () => {
    // NOTE: useAppState takes precedence over useLocalAudioToggle
    // { videoEnabled, toggleVideo } in useAppState will be persisted across
    // all meeting sessions, refer to useAppState for implementation
    const { videoEnabled: globalIsVideoEnabled, toggleVideo: globalToggleVideoEnabled } = useAppState()
    const [isVideoEnabled, toggleVideo] = useLocalVideoToggle()

    React.useEffect(() => {
        if (globalIsVideoEnabled !== isVideoEnabled) {
            toggleVideo()
        }
    }, [globalIsVideoEnabled, isVideoEnabled])

    return (
        <Fab onClick={() => globalToggleVideoEnabled()}>
            {isVideoEnabled ? <Videocam /> : <VideocamOff />}
        </Fab>
    )
}

export const ToggleCameraFacingModeButton = () => {
    const { facingMode, toggleFacingMode, toggleVideo } = useAppState()

    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()

    React.useEffect(() => {
        // By default, we want `undefined' to means facingMode=user
        // skip effect of initial render
        if (!facingMode) {
            return
        }

        // NOTE: This is a little bit counter intuitive... Why didn't we call
        // toggleVideoEnabled() twice? Because there is a effect in
        // <ToggleVideoButton> that will reenable the video because value of
        // `globalIsVideoEnabled' is not changed
        toggleVideoEnabled()
    }, [facingMode])

    return (
        <Fab onClick={() => toggleFacingMode()}>
            <SwitchCamera />
        </Fab>
    )
}

export const ToggleScreenShareButton = () => {
    const [isScreenShared, toggleScreenShare] = useScreenShareToggle();

    return (
        <Fab onClick={toggleScreenShare}>
            {isScreenShared ? <StopScreenShare /> : <ScreenShare />}
        </Fab>
    )
}



export const Controls: React.FunctionComponent<{ isLocal?: boolean }> = ({ isLocal = false }) => {
    const [controlIsVisible, setControlIsVisible] = React.useState(true)

    return (
        <div className="position-absolute" style={{ right: 12, bottom: 12, zIndex: 3 }}> {/* margin of the video container for shadow is 12px */}
            <div className="d-flex flex-column">

                {!isLocal && !isMobile && (
                    <div className="mb-4px" style={{ visibility: controlIsVisible ? "visible" : "hidden" }}>
                        <ToggleScreenShareButton />
                    </div>
                )}

                {isMobile && (
                    <div className="mb-4px" style={{ visibility: controlIsVisible ? "visible" : "hidden" }}>
                        <ToggleCameraFacingModeButton />
                    </div>
                )}

                <div className="mb-4px" style={{ visibility: controlIsVisible ? "visible" : "hidden" }}>
                    <ToggleAudioButton />
                </div>

                <div className="mb-4px" style={{ visibility: controlIsVisible ? "visible" : "hidden" }}>
                    <ToggleVideoButton />
                </div>

                <Fab color="primary" onClick={() => setControlIsVisible(!controlIsVisible)}>
                    <span className="fa fa-fw fa-ellipsis-h" />
                </Fab>

            </div>
        </div>
    )
}









const TOKEN_A = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2JlMjZlYzBmZmRiYTg0Y2IxM2ViNTAzMDEzMmRkMjUxLTE1OTc4OTUyMTAiLCJncmFudHMiOnsiaWRlbnRpdHkiOiI0MTAzYWY4OS1iNmNjLTRmN2QtYWUxNi0wODA2MWUxYjM2YTMiLCJ2aWRlbyI6eyJyb29tIjoiZjIwY2E3YjQtNTYxZC00ZjA3LTg1ZGUtMTUxM2Y5MTNmYjc5In19LCJpYXQiOjE1OTc4OTUyMTAsImV4cCI6MTU5Nzg5ODgxMCwiaXNzIjoiU0tiZTI2ZWMwZmZkYmE4NGNiMTNlYjUwMzAxMzJkZDI1MSIsInN1YiI6IkFDZDYxOThlYTg3ZDc5NjllNmUyMzU4NDM1YjJjODBkYjUifQ.Q6jj3zRhFs82yZgf4J6zqWeht2RCSLhu3kiI3rR2UYM"
const TOKEN_B = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2JlMjZlYzBmZmRiYTg0Y2IxM2ViNTAzMDEzMmRkMjUxLTE1OTc4OTUyNzciLCJncmFudHMiOnsiaWRlbnRpdHkiOiI1ZjM3YjMyNS0xYzc3LTQ1YTctYTA2OS03NmIzOWUzZTNjMjkiLCJ2aWRlbyI6eyJyb29tIjoiZjIwY2E3YjQtNTYxZC00ZjA3LTg1ZGUtMTUxM2Y5MTNmYjc5In19LCJpYXQiOjE1OTc4OTUyNzcsImV4cCI6MTU5Nzg5ODg3NywiaXNzIjoiU0tiZTI2ZWMwZmZkYmE4NGNiMTNlYjUwMzAxMzJkZDI1MSIsInN1YiI6IkFDZDYxOThlYTg3ZDc5NjllNmUyMzU4NDM1YjJjODBkYjUifQ.L95Cx7uJabHVYLyrOlLKpkVzTxWH1UsI9p74efVxzw0"

const Lobby: React.FunctionComponent = () => {

    const { connect, isConnecting, isAcquiringLocalTracks, ...props } = useVideoContext()

    const roomState = useRoomState()

    React.useEffect(() => {
        if (roomState !== "connected") {
            (Math.random() > 0.5 ? connect(TOKEN_A) : connect(TOKEN_B))
        }
    }, [roomState])

    return null
}

const Playground = () => {
    return (
        <TwilioAppStateProvider>
            <TwilioVideoApp>
                <Lobby />
            </TwilioVideoApp>
        </TwilioAppStateProvider>
    )
}

const DisconnectOnMeetingEnd: React.FunctionComponent<{ meeting?: OMReference<CnxMeeting> }> = ({ meeting }) => {

    const { room } = useVideoContext()

    React.useEffect(() => {
        if (["COMPLETED", "FAILED"].includes(meeting?.actualObject?.meetingStatus ?? "")) {
            if (room?.disconnect) {
                room.disconnect()
            }
        }
    }, [meeting, room])

    return null
}

export {
    DisconnectOnMeetingEnd
}

export default Playground
