// Copyright © 2020 Apptivity Lab. All rights reserved.

/**
 * (Type docs)
 *
 * @author Cheongkit Tham
 */

export default class Hstore {

    private static Regex = /\"((?:(?:(?!\").)+)+)\"=>\"((?:(?:(?!\").)+)+)\"/gm

    public constructor(obj: string | Hstore) {
        switch (typeof obj) {
            case "string":
                (() => {
                    const matches = [...(obj as any).matchAll(Hstore.Regex)] as [string, string, string][]

                    matches.forEach(([_, key, value]) => {
                        (this as any)[key] = value
                    })
                })()
                break
            default:
                let _ = this
                _ = Object.create(this, obj as any) as any
                break
        }
    }

    public toJSON(): string {
        return Object.entries(this).map(([key, value]) => `"${key}"=>"${value}"`).join(", ")
    }

    public toString(): string {
        return this.toJSON()
    }

}
