// Data imports
import { FSMNamedState as FSMState, OMReference } from "firmament-node-sdk"
import { CnxMessage } from "../../../domain"

// Feature imports
import { DetailsStateMachineWithDelete } from "../../../components"

declare namespace CnxMessageDetailsStateMachine {
    export interface ShowEdit extends FSMState {
        name: "showingEdit"
        item: CnxMessage
    }

    export type State = DetailsStateMachineWithDelete.State<CnxMessage> |
        ShowEdit
}

class CnxMessageDetailsStateMachine extends DetailsStateMachineWithDelete<CnxMessage, CnxMessageDetailsStateMachine.State> {

    /**
     * Show Edit Transition
     * [presenting] -- dismiss -> [showingEdit]
     */
    public showEdit = (item: CnxMessage): void => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingEdit", item }
            default:
                return
        }
    }

}

export default CnxMessageDetailsStateMachine
