// Data module imports
import { OMReference } from "firmament-node-sdk"
import * as React from "react"
import * as ReactDOM from "react-dom"
import { Route, RouteComponentProps } from "react-router"

import { SchTutorProfileDetailsStateMachine } from "."
import Linkify from "react-linkify"
import bg_web_app from "../../../assets/bg_web_app.svg"

import {
    ErrorAlert,
    Modal,
    ModalDialog,
    ModalSpinner,
    Title3,
    ListStateMachine,
    Title2, Subheadline,
    Caption1,
    Headline,
    Caption2,
    Callout,
} from "../../../components"

import { NotifMessage, ProfilePhoto, User, SchTutorProfile, CnxServiceCategory, CnxBooking, CnxProviderProfile } from "../../../domain"
import getProfile from "../../../helpers/Profile"
import { Link } from "react-router-dom"
import CnxRatingModule from "../../CnxRating/CnxRatingModule"
import FollowButton from "./elements/FollowButton"
import { Video } from "../../../VideoControls"
import Affiliation from "../SchTutorProfileDetails/elements/Affiliation"
import { TeachSubjectBadge } from "../SchTutorProfileDetails/elements/TeachSubject"
import settings from "../../../settings"
import CnxBookingAskForm from "../../CnxBooking/CnxBookingAskForm/CnxBookingAskForm"
import CnxBookingAskFormStateMachine from "../../CnxBooking/CnxBookingAskForm/CnxBookingAskFormStateMachine"

import "./index.scss"
import BrowseOtherTutors from "./elements/BrowseOtherTutors"
import classnames from "classnames"
import DownloadApp from "../../../components/deprecated-components/DownloadApp"
import { CategoriesParamsContext, useCategoriesParamsContext } from "../../../helpers/useSubjectParamsContext"

declare interface SchTutorProfileDetailsProps extends RouteComponentProps<{ id: string }> {
    machine: SchTutorProfileDetailsStateMachine

    messages?: OMReference<NotifMessage>[]
    photo?: OMReference<ProfilePhoto>
    user?: OMReference<User>
}

interface SchTutorProfileDetailsComponentState {
    machineState: SchTutorProfileDetailsStateMachine.State
    queryParams: ListStateMachine.QueryParams<SchTutorProfile>

    newChallenge?: string
    newEmail?: string
    activeTab: string

    serviceCategories?: CnxServiceCategory[]

    showAskNowModal?: boolean
}


const FollowMeCard = ({ tutor, className }: { tutor: SchTutorProfile, className?: string }) => {
    if (!tutor.socialLinks?.value) {
        return null
    }

    return (
        <div className={classnames("card", className)}>
            <div className="card-header">
                Follow me
            </div>

            <div className="card-body">
                {/* Left sidebar: Follow me */}

                {!tutor.socialLinks?.value ? null : Object.entries(tutor.socialLinks?.value).map(([key, value]) => (
                    <>
                        <Caption2 className="d-block">{key}</Caption2>
                        <a href={value} target="_blank" className="link">{value}</a>
                        <br />
                        <br />
                    </>
                ))}
            </div>
        </div>
    )
}

const BrowseOtherTutorCardTitle: React.FunctionComponent<{ currentTutor: SchTutorProfile }> = ({ currentTutor }) => {
    const { categories } = React.useContext(CategoriesParamsContext)
    const subjectsFromTutor = currentTutor.categories.filter((each) => each.actualObject?.categoryType === "SUBJECT")

    if (subjectsFromTutor.length === 1) {
        return <>{`Browse ${subjectsFromTutor[0].actualObject?.title} Tutors...`}</>
    }

    if (subjectsFromTutor.length > 1) {
        return <>{"Browse Related Tutors..."}</>
    }

    const subjectsFromCategories = categories?.filter((each) => each.actualObject?.categoryType === "SUBJECT") || []

    if (subjectsFromCategories.length === 1) {
        return <>{`Browse ${subjectsFromCategories[0].actualObject?.title} Tutors...`}</>
    }

    if (subjectsFromCategories.length > 1) {
        return <>{"Browse Related Tutors..."}</>
    }

    return <>{"Browse Other Tutors..."}</>
}

class SchTutorProfileDetails
    extends React.Component<SchTutorProfileDetailsProps, SchTutorProfileDetailsComponentState> {

    private machine: SchTutorProfileDetailsStateMachine

    constructor(props: SchTutorProfileDetailsProps) {
        super(props)
        this.machine = this.props.machine
        this.state = {
            machineState: this.machine.state,
            queryParams: {
                currentPage: 0,
                sortBy: "createdAt",
                sortDirection: "DESC",
            },
            activeTab: "Intro",
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)
        this.machine.load(new OMReference(SchTutorProfile, this.props.match.params.id))
    }

    public componentDidUpdate(prevProps: SchTutorProfileDetailsProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.machine.load(new OMReference(SchTutorProfile, this.props.match.params.id))
        }
    }

    public componentWillUnmount() {
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
            case "loading":
                return <ModalSpinner />
            case "promptingResetPassword":
            case "performingResetPassword":
            case "promptingSuccessfulResetPassword":
            case "promptingSuspend":
            case "performingSuspend":
            case "promptingUnsuspend":
            case "performingUnsuspend":
            case "promptingChangeEmail":
            case "performingChangeEmail":
            case "promptingDelete":
            case "performingDelete":
            case "presenting":
                const isApproved = !!machineState.item.approvedAt
                const isAvailable = isApproved && machineState.item.isOnline
                const affiliation = machineState.item.categories.find((each) => each.actualObject?.categoryType === "AFFILIATION")?.actualObject

                return (
                    <>

                        <div className="position-relative">
                            <div
                                className="position-fixed"
                                style={{
                                    pointerEvents: "none",
                                    zIndex: -1,
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    height: 450,

                                    backgroundImage: `url("${bg_web_app}")`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center top",
                                    backgroundSize: "auto 450px",
                                }}
                            />
                        </div>

                        <Modal show={!!this.state.showAskNowModal} onDismiss={() => this.setState({ showAskNowModal: false })}>
                            <ModalDialog
                                title={<Title2 className="d-block" style={{ marginLeft: 15, marginTop: 15 }}>Book A Session</Title2>}
                                body={
                                    <Route
                                        render={(componentProps) => {
                                            if (!getProfile()) {
                                                return null
                                            }

                                            const props = {
                                                ...componentProps,
                                                match: {
                                                    ...componentProps.match,
                                                    params: {},
                                                    url: `${componentProps.match.url}/${CnxBooking.Path}/ask`
                                                }
                                            }
                                            return (
                                                <CnxBookingAskForm
                                                    machine={new CnxBookingAskFormStateMachine()}
                                                    matchProvider={new OMReference(CnxProviderProfile, machineState.item.id)}
                                                    {...props}
                                                />
                                            )
                                        }}
                                    />
                                }
                            />
                        </Modal>

                        <div id="SchTutorProfileDetails" className="container mt-4">
                            <div className="row">

                                <div className="col-12 col-xl-9">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card w-100">
                                                <div className="card-body">


                                                    {/* Header */}

                                                    <div className="d-flex justify-content-center flex-wrap flex-lg-nowrap">
                                                        <div className="mb-12px">
                                                            <img src={machineState.item.photo?.actualObject?.url} className="mr-20px flex-shrink-0" style={{ borderRadius: "50%", width: 180, height: 180, objectFit: "cover" }} />
                                                        </div>

                                                        <div className="flex-grow-1">
                                                            <div className="d-flex align-items center">
                                                                <div className="flex-grow-1">
                                                                    <div>
                                                                        <Title2 className="d-inline mb-4px">{machineState.item.name}</Title2>
                                                                        {machineState.item.meetingsCount === 0 && <Title2 className="d-inline font-weight-normal ml-4px text-danger">ᴺᴱᵂᵎ</Title2>}
                                                                    </div>

                                                                    <div className="">
                                                                        {affiliation
                                                                            ? <Affiliation affiliation={affiliation} />
                                                                            : <Subheadline className="d-block text-tertiary">{machineState.item.university || "-"}</Subheadline>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="mb-4px">
                                                                <a className="callout text-primary font-weight-medium text-underline" target="_blank" href={`https://${settings.app_url}/${machineState.item.slug}`}>{settings.app_url}/{machineState.item.slug}</a>
                                                                <span className="ml-4px text-primary callout fa fa-share" />
                                                            </div>

                                                            <Caption2 className="d-block text-tertiary font-weight-bold text-black">Teaches:</Caption2>

                                                            <CategoriesParamsContext.Consumer>
                                                                {({ categories }) => {
                                                                    const tutorProfile = new SchTutorProfile(machineState.item)
                                                                    tutorProfile.categories = [...tutorProfile.categories, ...(categories || [])]

                                                                    return (
                                                                        <>
                                                                            <Subheadline className="d-block text-dark mb-10px">
                                                                                <TeachSubjectBadge provider={tutorProfile} />
                                                                            </Subheadline>

                                                                            <Caption2 className="d-block text-tertiary font-weight-bold text-black">Languages:</Caption2>
                                                                            <Subheadline className="d-block text-dark mb-12px">
                                                                                {([...machineState.item.categories, ...(categories || [])]).filter((each) => each.actualObject?.categoryType === "LANGUAGE").map((each) => (
                                                                                    <span className="badge badge-success mr-1 mb-1">{each.actualObject?.title}</span>
                                                                                ))}
                                                                            </Subheadline>
                                                                        </>
                                                                    )
                                                                }}
                                                            </CategoriesParamsContext.Consumer>



                                                            <div className="d-flex">
                                                                {(() => {
                                                                    switch (true) {
                                                                        case !isApproved:
                                                                            return (
                                                                                <button role="button" className="btn btn-block btn-secondary" style={{ maxWidth: 250, opacity: 1 }} disabled>
                                                                                    <Headline>Unavailable</Headline>
                                                                                </button>
                                                                            )
                                                                        default:
                                                                            return (
                                                                                <button role="button" className="btn btn-block btn-primary" style={{ maxWidth: 250 }} onClick={() => {
                                                                                    if (!getProfile()) {
                                                                                        this.props.history.push(`${this.props.match.url}/${CnxBooking.Path}/ask`)
                                                                                    } else {
                                                                                        this.setState({ showAskNowModal: true })
                                                                                    }
                                                                                }}>
                                                                                    <Headline>Ask Now</Headline>
                                                                                </button>
                                                                            )
                                                                    }
                                                                })()}

                                                                <div className="ml-4px">
                                                                    <FollowButton targetProfile={machineState.item} onClick={() => this.forceUpdate()} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {/* Left sidebar */}
                                        <div className="col-12 col-md-5">
                                            <div style={{ position: "sticky", top: 54 + 8 }}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="card w-100">

                                                            <div className="card-header">
                                                                Activity
                                                            </div>

                                                            <div className="card-body">

                                                                {/* Left sidebar: Activity */}

                                                                <div className="d-flex align-items-center">
                                                                    <Title3 className="text-accent-2 mr-10px"><span className="fa fa-star" /></Title3>
                                                                    <Caption1 className="flex-grow-1 font-weight-bold text-tertiary mr-16px">Ratings</Caption1>
                                                                    <Headline>{machineState.item.serviceRatingsByConsumersAverage?.toLocaleString(undefined, { maximumFractionDigits: 1 }) || "n/a"}</Headline>
                                                                </div>

                                                                <hr style={{ marginTop: 9, marginBottom: 9 }} />

                                                                <div className="d-flex align-items-center">
                                                                    <Title3 className=" text-accent-2 mr-10px"><span className="fa fa-thumbs-up" /></Title3>
                                                                    <Caption1 className="flex-grow-1 font-weight-bold text-tertiary">Favourites</Caption1>
                                                                    <Headline>{machineState.item.favoritedByConsumerCount || 0}</Headline>
                                                                </div>

                                                                <hr style={{ marginTop: 9, marginBottom: 9 }} />

                                                                <div className="d-flex align-items-center">
                                                                    <Title3 className=" text-accent-2 mr-10px"><span className="fa fa-book" /></Title3>
                                                                    <Caption1 className="flex-grow-1 font-weight-bold text-tertiary">Sessions</Caption1>
                                                                    <Headline>{machineState.item.meetingsCount || 0}</Headline>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <FollowMeCard tutor={machineState.item} className="w-100" />
                                                    </div>
                                                </div>

                                                <div className="row hidden-xl-up">
                                                    <div className="col-12">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <div className="text-ellipsis">
                                                                    <BrowseOtherTutorCardTitle currentTutor={machineState.item} />
                                                                </div>
                                                            </div>

                                                            <div className="card-body">
                                                                <BrowseOtherTutors currentTutor={machineState.item} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Main content */}
                                        <div className="col-12 col-md-7">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card w-100">
                                                        <div className="card-header">
                                                            <ul className="card-header-tabs nav nav nav-tabs">
                                                                {(machineState.item.introVideo || machineState.item.bio) && <li className="nav-item"><a href="javascript:;" onClick={() => this.setState({ activeTab: "Intro" })} className={classnames("nav-link", { active: this.state.activeTab === "Intro" })}>Intro</a></li>}
                                                                <li className="nav-item"><a href="javascript:;" onClick={() => this.setState({ activeTab: "Reviews" })} className={classnames("nav-link", { active: this.state.activeTab === "Reviews" })}>Reviews</a></li>
                                                            </ul>
                                                        </div>

                                                        <div className={classnames("card-body", { hidden: this.state.activeTab !== "Intro" })}>
                                                            {/*  */}

                                                            {machineState.item.introVideo?.actualObject && <Video loop={false} controls className="w-100" src={machineState.item.introVideo.actualObject.url} />}

                                                            {machineState.item.bio && (
                                                                <div className="mt-8px">
                                                                    {machineState.item.bio?.split("\n").map((segment) => {
                                                                        return segment
                                                                            ? (
                                                                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a className="link" target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>
                                                                                    <Caption2 className="d-block">{segment}</Caption2>
                                                                                </Linkify>
                                                                            )
                                                                            : <br />
                                                                    })}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className={classnames("card-body", { hidden: this.state.activeTab !== "Reviews" })}>
                                                            <CnxRatingModule provider={new OMReference(CnxProviderProfile, machineState.item.id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-xl-3 hidden-xl-down">
                                    <div className="card" style={{ position: "sticky", top: 54 + 8 }}>
                                        <div className="card-header">
                                            <div className="text-ellipsis">
                                                <BrowseOtherTutorCardTitle currentTutor={machineState.item} />
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <BrowseOtherTutors currentTutor={machineState.item} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {!getProfile() && ReactDOM.createPortal((
                            <div className="footer">
                                <div className="mx-20px">
                                    <div className="container-420px text-center" style={{ maxWidth: 500 }}>
                                        <div style={{ height: 20 }} />

                                        <Headline className="d-block">Homework help whenever, and wherever you need it.</Headline>

                                        <div className="d-block mt-16px">
                                            <span className="badge badge-success bg-alternative-1" style={{ color: "white", width: 160 }}>
                                                <Callout>How It Works!</Callout>
                                            </span>
                                        </div>

                                        <div className="row mt-8px">
                                            <div className="col-4 text-center">
                                                <Caption1 className="font-weight-bold d-block">STEP 1</Caption1>
                                                <span style={{ fontSize: 10 }}>
                                                    Stuck on a question? Choose the subject you need help with, and tap on “Ask Now”.
                                            </span>
                                            </div>
                                            <div className="col-4 text-center">
                                                <Caption1 className="font-weight-bold d-block">STEP 2</Caption1>
                                                <span style={{ fontSize: 10 }}>
                                                    The app will connect you with an available tutor within minutes.3
                                            </span>
                                            </div>
                                            <div className="col-4 text-center">
                                                <Caption1 className="font-weight-bold d-block">STEP 3</Caption1>
                                                <span style={{ fontSize: 10 }}>
                                                    As soon as you're matched with a tutor, the tutor will appear on your screen for a video call.
                                            </span>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center mt-16px mx-16px">
                                            <Link className="d-block flex-grow-1" to="/authentication/menu">
                                                <button type="button" className="btn btn-primary btn-block">
                                                    <Headline>Sign Up</Headline>
                                                </button>
                                            </Link>

                                            <Caption1 className="text-secondary mx-16px">or</Caption1>

                                            <Link className="d-block flex-grow-1" to="/authentication/login">
                                                <button type="button" className="btn btn-outline-primary btn-block">
                                                    <Headline>Login</Headline>
                                                </button>
                                            </Link>
                                        </div>

                                        <div className="mt-12px d-flex justify-content-center">
                                            <DownloadApp />
                                        </div>

                                        <div style={{ height: 20 }} />
                                    </div>
                                </div>
                            </div>
                        ), document.body)}
                    </>
                )
            case "showingError":
                return <ErrorAlert error={machineState.error} />
            case "showingEdit":
                this.props.history.push(`${this.props.match.url}/edit`)
                return null
            case "completed":
                this.props.history.goBack()
                return null
        }
    }

    private subscriptionCallback = (machineState: SchTutorProfileDetailsStateMachine.State) => {
        this.setState({
            machineState,
            activeTab: machineState.name === "presenting" && (machineState.item.introVideo || machineState.item.bio) ? "Intro" : "Reviews"
        })
    }

}

export default SchTutorProfileDetails
