import React from "react"
import * as Sentry from "@sentry/react"

export class ErrorAlert extends React.PureComponent<{ error: any }> {
    componentDidMount() {
        Sentry.captureMessage(this.props.error)
    }

    render() {
        const { status, title, detail } = (this.props.error && this.props.error.response && this.props.error.response.data && this.props.error.response.data.errors && this.props.error.response.data.errors[0]) || {}

        return (
            <div
                className="alert alert-danger fade show"
                role="alert"
            >
                <div className="media">
                    <div className="mr-2 media-left media-middle">
                        <i className="fa fa-close fa-fw fa-2x alert-icon" />
                    </div>
                    <div className="media-body">
                        <h6 className="mb-1 alert-heading">{(status || title) ? `${status || 0}: ${title}` : this.props.error.message}</h6>
                        {detail}
                    </div>
                </div>
            </div>
        )
    }
}
