import React, { useState, useRef } from "react"
import { errorToast } from "../ErrorToast"

const Form = (props) => {
    const [wasValidated, setWasValidated] = useState(false)

    const formRef = useRef()

    function onSubmit(event) {
        const formValidity = formRef.current ? formRef.current.checkValidity() : false

        if (!formValidity && !wasValidated) {
            setWasValidated(true)
        }

        event.preventDefault()

        if (!formValidity) {
            errorToast(new Error("Please fill out all required fields."))
        }

        if (formValidity) {
            props.onSubmit(event)
        }
    }

    return <form ref={formRef} {...props} noValidate={!wasValidated} onSubmit={onSubmit} className={wasValidated ? "needs-validation was-validated" : "needs-validation"}>{props.children}</form>
}

export default Form
