import React from "react"

interface LoadingButtonComponentProps
    extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    onClick?: () => void
    isLoading: boolean
}

class LoadingButton extends React.Component<LoadingButtonComponentProps> {
    public render() {
        return (
            <button
                {...this.props}
                className={this.props.className || "btn btn-primary"}
                disabled={this.props.disabled || this.props.isLoading}
                onClick={this.props.onClick}
            >
                {this.props.isLoading && (
                    <i className="fa fa-fw fa-spinner faa-spin animated mr-8px" />
                )}
                {this.props.children}
            </button>
        )
    }
}

export default LoadingButton
