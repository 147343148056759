// Data imports
import { FSMNamedState as FSMState, OMQuery, OMReference, OMObject, OMRealtimeSubscription, OMUniverse } from "firmament-node-sdk"
import { Moment } from "moment"
import { CnxMeeting, GenImage, CnxMemberProfile, CnxMessage } from "../../../domain"

// Feature imports
import ListStateMachine from "../../../components/ListStateMachine"
import getProfile from "../../../helpers/Profile"
import notify from "../../../helpers/notify"

declare namespace CnxMessageListStateMachine {

    export interface ShowingCreate extends FSMState {
        name: "showingCreate"
    }

    export interface PromptingDelete {
        name: "promptingDelete"
        item: CnxMessage

        items: CnxMessage[]
        totalItems: number
    }

    export interface PerformingDelete {
        name: "performingDelete"
        item: CnxMessage

        items: CnxMessage[]
        totalItems: number
    }

    export interface PerformingCreate {
        name: "performingCreate"
        newItems: CnxMessage[]

        items: CnxMessage[]
        totalItems: number
    }

    export type State = ListStateMachine.State<CnxMessage> |
        ShowingCreate |
        PromptingDelete |
        PerformingDelete |
        PerformingCreate
}

class CnxMessageListStateMachine extends ListStateMachine<CnxMessage, CnxMessageListStateMachine.State> {

    private subscription?: OMRealtimeSubscription<typeof CnxMessage>

    public performCreate = async (items: CnxMessage[]) => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "performingCreate", items: this.state.items, totalItems: this.state.totalItems, newItems: items }
                break
            default:
                return
        }

        try {
            await Promise.all(
                items.map((each) => each.attachedImage && each.attachedImage.actualObject && each.attachedImage.actualObject.save())
            )
            await Promise.all(items.map((each) => each.save()))

            await items[items.length - 1].send()

            this.success(this.state.items, this.state.totalItems)
        } catch (error) {
            this.fail(error)
        }
    }

    public promptDelete = (item: CnxMessage) => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "promptingDelete", items: this.state.items, totalItems: this.state.totalItems, item }
                break
            default:
                return
        }
    }

    public performDelete = async () => {
        switch (this.state.name) {
            case "promptingDelete":
                this.state = { name: "performingDelete", items: this.state.items, totalItems: this.state.totalItems, item: this.state.item }
                break
            default:
                return
        }

        try {
            const item = this.state.item
            await item.delete()
            this.state = { name: "presenting", items: this.state.items.filter((each) => each.id !== item.id), totalItems: this.state.totalItems - 1 }
        } catch (error) {
            this.fail(error)
        }
    }

    public cancel = (): void => {
        switch (this.state.name) {
            case "promptingDelete":
                this.state = { name: "presenting", items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                return
        }
    }

    public load = async (filters: {
        currentPage?: number,
        sortBy?: keyof CnxMessage,
        sortDirection?: "ASC" | "DESC",
        startAt?: Moment,
        endAt?: Moment,

        meeting?: OMReference<CnxMeeting>,
        sender?: OMReference<CnxMemberProfile>,
    } = { currentPage: 0, sortBy: "createdAt", sortDirection: "DESC" }) => {
        switch (this.state.name) {
            case "start":
            case "showingError":
                this.state = { name: "loading",  items: [], totalItems: 0 }
                break
            case "presenting":
                this.state = { name: "loading",  items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                return
        }

        try {
            const profile = getProfile()

            const query = CnxMessage.query()
                .include("sender")
                .include("sender.photo" as any)
                .include("attachedImage")
                .limit(5000)

            const messageSubscription = filters.meeting &&
                filters.meeting.actualObject &&
                filters.meeting.actualObject.realtimeSubscription(CnxMessage)

            if (filters.currentPage) {
                query.offset(filters.currentPage)
            }

            if (filters.sortBy) {
                query.sort(filters.sortBy, filters.sortDirection!)
            }

            if (filters.startAt) {
                query.filter("createdAt", "moreThan", filters.startAt.toISOString() as any)
                // messageSubscription && messageSubscription.filter("createdAt", "moreThan", filters.startAt.toISOString() as any)
            }

            if (filters.endAt) {
                query.filter("createdAt", "lessThan", filters.endAt.toISOString() as any)
                // messageSubscription && messageSubscription.filter("createdAt", "lessThan", filters.endAt.toISOString() as any)
            }

            if (filters.meeting) {
                query.filter("meeting", "equals", filters.meeting.id as any)
            }

            if (filters.sender) {
                query.filter("sender", "equals", filters.sender.id as any)
                // messageSubscription && messageSubscription.filter("sender", "equals", filters.sender.id as any)
            }

            await query.execute()
            OMUniverse.shared.touch(profile!)
            this.success(query.resultObjects, query.meta.count)

            if (messageSubscription) {
                this.subscription = messageSubscription
                messageSubscription.listen(async (command) => {
                    if (this.state.name !== "presenting" && this.state.name !== "performingCreate") {
                        return
                    }

                    if (!command.receiver || !command.receiver.actualObject) {
                        return
                    }

                    const newMessage = command.receiver.actualObject

                    if (!newMessage.sender.actualObject) {
                        await command.receiver.load(CnxMessage.query().include("sender").include("sender.photo" as any))
                    }

                    if (newMessage.messageType === "PHOTO") {
                        await new Promise((resolve) => setTimeout(resolve, 1000))
                        await command.receiver.load(CnxMessage.query().include("attachedImage"))
                    }

                    switch (command.constructor) {
                        case OMObject.Create:
                            notify(newMessage.sender.actualObject?.name || "Someone", { body: newMessage.text || "New message" })
                            this.success([...this.state.items, newMessage], this.state.totalItems + 1)
                            break
                        case OMObject.Update:
                            // throw new Error("Unimplemented case: on message update")
                            break
                        default:
                            // throw new Error("Unimplemented case: on message delete")
                            break
                    }
                })
            }

        } catch (error) {
            this.fail(error)
        }
    }

    public showDetail = (ref: OMReference<CnxMessage>) => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingDetail", ref }
                break
            default:
                return
        }
    }

    public showCreate = () => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingCreate" }
                break
            default:
                return
        }
    }

    public success(items: CnxMessage[], totalItems: number = 0): void {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
            case "presenting":
            case "performingCreate":
                this.state = { name: "presenting", items, totalItems }
                break
            default:
                return
        }
    }

    public fail(error: Error): void {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
            case "performingCreate":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

    public unsubscribeRealtime = () => {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }

}

export default CnxMessageListStateMachine
