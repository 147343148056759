//  ArticleContentCard.tsx
//
//  Opera House | Typescript - Details Card
//  Updated 2020-04-24
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import { OMReference, OMUniverse } from "firmament-node-sdk"
import React from "react"

import {
    DetailColumn,
    FileRenderer,
    Body,
    Caption1,
} from "../../../../components"

import { ArticleContent, ArticleHeader, ArticleParagraph, ArticleMedia } from "../../../../domain"
import ArticleHeaderCard from "./ArticleHeaderCard"
import ArticleParagraphCard from "./ArticleParagraphCard"
import ArticleMediaCard from "./ArticleMediaCard"

export interface ArticleContentCardComponentProps {
    articleContent: ArticleContent
}

export interface ArticleContentCardComponentState {

}

class ArticleContentCard extends React.Component<ArticleContentCardComponentProps, ArticleContentCardComponentState> {
    constructor(props: ArticleContentCardComponentProps) {
        super(props)
        this.state = {}
    }

    get articleContent() {
        return (
            OMUniverse.shared.find(ArticleHeader, this.props.articleContent.id) ||
            OMUniverse.shared.find(ArticleParagraph, this.props.articleContent.id) ||
            OMUniverse.shared.find(ArticleMedia, this.props.articleContent.id)
        )!
    }

    public render() {
        switch (this.articleContent && this.articleContent.constructor) {
            case ArticleHeader:
                return <ArticleHeaderCard articleHeader={this.articleContent as ArticleHeader} />
            case ArticleParagraph:
                return <ArticleParagraphCard articleParagraph={this.articleContent} />
            case ArticleMedia:
                return <ArticleMediaCard articleMedia={this.articleContent} />
            default:
                return null
        }
    }
}

export default ArticleContentCard
