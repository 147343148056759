import React from "react"
import uuidv4 from "uuid/v4"

export interface DropdownButtonComponentProps
    extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    menuItems: React.ReactNode
}

class DropdownButton extends React.Component<DropdownButtonComponentProps> {
    public id = uuidv4()

    public render() {
        return (
            <div className="btn-group">
                <button
                    {...this.props}
                    type="button"
                    className={`btn btn-primary ${this.props.className}`}
                >
                    {this.props.children}
                </button>

                <button
                    {...this.props}
                    // tslint:disable-next-line
                    className={`btn btn-primary dropdown-toggle dropdown-toggle-split ${this.props.className}`}
                    type="button"
                    id={this.id}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span className="sr-only">Toggle Dropdown</span>
                </button>

                <div className="dropdown-menu dropdown-menu-right" aria-labelledby={this.id}>
                    {this.props.menuItems}
                </div>
            </div>
        )
    }
}

export default DropdownButton
