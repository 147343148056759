import React from "react"
import { OMReference, OMQuery } from "firmament-node-sdk"
import { CnxProviderProfile, CnxServiceCategory, CnxConsumerProfile, SchTutorProfile } from "../../../../domain"
import { AvatarImage } from "../../../../airframe-components/Avatar/AvatarImage"
import ic_portrait_placeholder from "../../../../assets/ic_portrait_placeholder.png"
import classnames from "classnames"
import getProfile from "../../../../helpers/Profile"
import { Headline, Subheadline } from "../../../../components"
import { intersection } from "lodash"

type TutorRef = OMReference<SchTutorProfile>
export type SelectedType = "PREFERRED_TUTOR" | "MY_FAVOURITE_TUTORS" | "ANY_AVAILABLE_TUTORS" | undefined

interface Props {
    subject?: OMReference<CnxServiceCategory>
    preferredTutor?: TutorRef

    // matchProvider: TutorRef
    // tutors: TutorRef[] /* NOTE: Sorted beforehand */
    selectedTutors?: TutorRef
    onTutorsChange?: (tutors?: TutorRef[], count?: number) => void

    onSelectedTypeChange?: (selectedType?: SelectedType) => void
}

const Options: React.FunctionComponent<{ type: SelectedType, selectedType: SelectedType, setSelectedType: Function, disabled?: boolean }> = (props) => {

    React.useEffect(() => {
        if (props.selectedType === props.type && props.disabled) {
            props.setSelectedType(undefined)
        }
    }, [props.type, props.selectedType, props.disabled])

    return (
        <div
            onClick={() => !props.disabled && props.setSelectedType(props.type)}
            className={classnames("w-100 mb-8px", { "bg-accent-11-light-3": props.selectedType === props.type, "border-accent-1": props.selectedType === props.type, "clickable": !!props.disabled })}
            style={{ borderRadius: 8, border: props.selectedType !== props.type && "1px solid rgba(0, 0, 0, 0.2)" || undefined }}
        >
            <div className="my-12px mx-12px">
                <div className={classnames("d-flex align-items-center text-dark", { "text-secondary": props.disabled })}>
                    <div className="flex-grow-1" style={{ color: props.disabled && "rgba(15, 18, 26, 0.6)" || undefined }}>
                        {props.children}
                    </div>

                    {props.selectedType === props.type && <span className="fa fa-check text-accent" />}
                </div>
            </div>
        </div>
    )
}

const SelectTutor: React.FunctionComponent<Props> = ({ onTutorsChange, preferredTutor, ...props }) => {
    const profile = getProfile()
    const grade = profile instanceof CnxConsumerProfile && profile.categories.find((each) => each.actualObject?.categoryType === "GRADE") || undefined







    /* REGION: useState */
    const [isShowingEdit, toggleIsShowingEdit] = React.useState(true)

    const [selectedType, setSelectedType] = React.useState<SelectedType>(preferredTutor && "PREFERRED_TUTOR" || undefined)

    const [favouriteTutors, setFavouriteTutors] = React.useState<TutorRef[]>([])
    const [favouriteTutorsCount, setFavouriteTutorsCount] = React.useState<number>(0)

    const [anyAvailableTutors, setAnyAvailableTutors] = React.useState<TutorRef[]>([])
    const [anyAvailableTutorsCount, setAnyAvailableTutorsCount] = React.useState<number>(0)



    const isQualifiedToTeach = (tutor?: TutorRef, subject?: OMReference<CnxServiceCategory>): boolean => {
        switch (true) {
            case !!tutor && !subject:
                return true
            default:
                return tutor?.actualObject?.categorySet?.includes(subject?.actualObject?.slug ?? "") &&
                    tutor?.actualObject?.serviceTierSet?.includes(grade?.actualObject?.serviceTier?.slug || "") &&
                    intersection(tutor?.actualObject?.serviceTierSet ?? [], subject?.actualObject?.availableTierSet ?? []).length > 0
                    || false
        }
    }





    /* Callback: onSelectedTypeChange */
    const setSelectedTypeWithCallback = React.useCallback((any: any) => {
        setSelectedType(any)
        if (props.onSelectedTypeChange) {
            props.onSelectedTypeChange(any)
        }
    }, [setSelectedType])







    /* REGION: useEffect */

    React.useEffect(() => {
        if (preferredTutor && !isQualifiedToTeach(preferredTutor, props.subject)) {
            setSelectedTypeWithCallback("ANY_AVAILABLE_TUTORS")
        } else {
            setSelectedTypeWithCallback("PREFERRED_TUTOR")
        }
    }, [preferredTutor, props.subject])

    React.useEffect(() => {
        if (!props.selectedTutors) {
            toggleIsShowingEdit(true)
        }
    }, [props.selectedTutors])

    /* Load favourite tutors */
    React.useEffect(() => {
        (async () => {
            const profile = getProfile()

            if (!(profile instanceof CnxConsumerProfile)) {
                return
            }

            const query = new OMQuery(SchTutorProfile, "favoriteProviders", new OMReference(CnxConsumerProfile, profile.id))
                .limit(3)
                .sort("createdAt", "DESC")
                .include("photo")
                .include("categories")
                .include("categories.availableTiers" as any)

                .filter("user.status" as any, "equals", "active")
                .filter("approvedAt", "isNotNull", undefined)
                .filter("categorySet", "contains", [props.subject?.actualObject?.slug].join(",")) /* TODO: should compare all categories */
                .filter("serviceTierSet", "contains", [props.subject?.actualObject?.lowestPricedTier?.slug].join(","))
                .filter("isOnline", "equals", true)

            await query.execute()

            setFavouriteTutors(isQualifiedToTeach(preferredTutor, props.subject) ? [preferredTutor!, ...query.results.filter((each) => each.id !== preferredTutor?.id)] : query.results)
            setFavouriteTutorsCount(query.meta.count)
        })()
    }, [props.subject])

    /* Load any available tutors */
    React.useEffect(() => {
        (async () => {

            const query = SchTutorProfile.query()
                .limit(3)
                .sort("createdAt", "DESC")
                .include("photo")
                .include("categories")
                .include("categories.availableTiers" as any)
                .filter("categorySet", "contains", [props.subject?.actualObject?.slug].join(",")) /* TODO: should compare all categories */
                .filter("serviceTierSet", "contains", [props.subject?.actualObject?.lowestPricedTier?.slug].join(","))
                .filter("isOnline", "equals", true)

            await query.execute()

            setAnyAvailableTutors(isQualifiedToTeach(preferredTutor, props.subject) ? [preferredTutor!, ...query.results.filter((each) => each.id !== preferredTutor?.id)] : query.results)
            setAnyAvailableTutorsCount(query.meta.count)
        })()
    }, [props.subject])

    /* Callback */
    React.useEffect(() => {
        if (!onTutorsChange) {
            return
        }

        switch (true) {
            case preferredTutor && selectedType === "PREFERRED_TUTOR":
                onTutorsChange([preferredTutor!], 1)
                break
            case selectedType === "MY_FAVOURITE_TUTORS":
                onTutorsChange(favouriteTutors, favouriteTutorsCount)
                break
            case selectedType === "ANY_AVAILABLE_TUTORS":
                onTutorsChange(anyAvailableTutors, anyAvailableTutorsCount)
                break
            default:
                break
        }
    }, [preferredTutor, selectedType, favouriteTutors, anyAvailableTutors])






    /* REGION: Render */
    switch (true) {
        // case !props.subject:
        //     return null
        case !isShowingEdit:
            return (
                null
            )
        case isShowingEdit:
            return (
                <>
                    {/* Preferred Tutor */}
                    {preferredTutor && (
                        <Options type="PREFERRED_TUTOR" selectedType={selectedType} setSelectedType={setSelectedTypeWithCallback} disabled={!isQualifiedToTeach(preferredTutor, props.subject)}>
                            <Headline className="d-block mb-8px">Preferred Tutor</Headline>
                            <div className="d-flex align-items-center">
                                <AvatarImage
                                    size="md"
                                    src={preferredTutor?.actualObject?.photo?.actualObject?.preview || ic_portrait_placeholder}
                                />
                                <Subheadline className="ml-8px" style={{ color: "rgba(15, 18, 26, 0.6)" }}>{(() => {
                                    switch (true) {
                                        // case !preferredTutor:
                                        //     return "None Available"
                                        case !isQualifiedToTeach(preferredTutor, props.subject):
                                            return "Doesn't teach this subject"
                                        default:
                                            return preferredTutor?.actualObject?.name ?? "-"
                                    }
                                })()}</Subheadline>
                            </div>
                        </Options>
                    )}

                    {/* My Favourite Tutors */}
                    <Options type="MY_FAVOURITE_TUTORS" selectedType={selectedType} setSelectedType={setSelectedTypeWithCallback} disabled={!favouriteTutorsCount}>
                        <Headline className="d-block mb-8px">My Favourite Tutors</Headline>

                        <div className="d-flex align-items-center">
                            {favouriteTutorsCount === 0 && (
                                <AvatarImage
                                    size="md"
                                    src={ic_portrait_placeholder}
                                />
                            )}
                            {favouriteTutors.slice(0, 3).filter(Boolean).map((each) => (
                                <AvatarImage
                                    size="md"
                                    src={each.actualObject?.photo?.actualObject?.preview || ic_portrait_placeholder}
                                />
                            ))}
                            {(() => {
                                switch (true) {
                                    case favouriteTutorsCount <= 3:
                                        return <Subheadline className="ml-8px" style={{ color: "rgba(15, 18, 26, 0.6)" }}>{`${favouriteTutorsCount} tutors available`}</Subheadline>
                                    case favouriteTutorsCount > 3:
                                        return <Subheadline className="ml-8px" style={{ color: "rgba(15, 18, 26, 0.6)" }}>{`+${favouriteTutorsCount - 3} tutors available`}</Subheadline>
                                }
                            })()}
                        </div>
                    </Options>

                    {/* Any Available Tutors */}
                    <Options type="ANY_AVAILABLE_TUTORS" selectedType={selectedType} setSelectedType={setSelectedTypeWithCallback} disabled={!anyAvailableTutorsCount}>
                        <Headline className="d-block mb-8px">Any Available Tutors</Headline>
                        <div className="d-flex align-items-center">
                            {anyAvailableTutorsCount === 0 && (
                                <AvatarImage
                                    size="md"
                                    src={ic_portrait_placeholder}
                                />
                            )}
                            {anyAvailableTutors.slice(0, 3).filter(Boolean).map((each) => (
                                <AvatarImage
                                    size="md"
                                    src={each.actualObject?.photo?.actualObject?.preview || ic_portrait_placeholder}
                                />
                            ))}
                            {(() => {
                                switch (true) {
                                    case anyAvailableTutorsCount <= 3:
                                        return <Subheadline className="ml-8px" style={{ color: "rgba(15, 18, 26, 0.6)" }}>{`${anyAvailableTutorsCount} tutors available`}</Subheadline>
                                    case anyAvailableTutorsCount > 3:
                                        return <Subheadline className="ml-8px" style={{ color: "rgba(15, 18, 26, 0.6)" }}>{`+${anyAvailableTutorsCount - 3} tutors available`}</Subheadline>
                                }
                            })()}
                        </div>
                    </Options>
                </>
            )
        default:
            return null
    }

}

export default SelectTutor
