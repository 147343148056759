import React from 'react';
import { Link, Route } from 'react-router-dom';

import {
    Navbar,
    Nav,
    NavItem,
    SidebarTrigger,
    NavLink,
} from "../../../airframe-components"
import { NotifMessage } from '../../../domain';
import BackButton from './BackButton';

export const DefaultNavbar = () => {

    return (
        (
            <Navbar light expand="xs" fluid>
                <Nav navbar>
                    <NavItem className="mr-3">
                        <SidebarTrigger />
                    </NavItem>

                    {/* <NavItem className="navbar-brand d-lg-none">
                <Link to="/">
                    <Logo />
                </Link>
            </NavItem> */}

                    <NavItem className="d-block"> {/* d-none d-md-block */}
                        {/* <span className="navbar-text">
                            <Link to="/">
                                <i className="fa fa-home"></i>
                            </Link>
                        </span> */}
                        <div className="d-flex h-100 align-items-center">
                            <BackButton />
                        </div>


                        {/* --- Breadcrumb --- */}
                        {/* <span className="navbar-text px-2">
                    <i className="fa fa-angle-right"></i>
                </span>
                <span className="navbar-text">
                    <Link to="/">Start</Link>
                </span>
                <span className="navbar-text px-2">
                    <i className="fa fa-angle-right"></i>
                </span>
                <span className="navbar-text">
                    Page Link
                </span> */}
                    </NavItem>
                </Nav>

                <Nav navbar className="ml-auto">
                    {/* <NavbarActivityFeed />
            <NavbarMessages className="ml-2" /> */}
                    {/* <NavbarUser className="ml-2" /> */}

                    <Route path=":parentPath(.*)/menu" render={() => (
                        <NavItem className="d-block text-accent ml-2">
                            <NavLink tag={Link} to={`/${NotifMessage.Path}`}>
                                <i className="fa fa-envelope text-accent" style={{ fontSize: 18 }}></i>
                            </NavLink>
                        </NavItem>
                    )} />
                </Nav>
            </Navbar>
        )
    )
};
