import { OMFile, OMQuery, OMReference } from "firmament-node-sdk"
import { memoize } from "lodash"
import * as React from "react"
import getImageOrientation from "../../helpers/getImageOrientation"
import { dataURItoBlob, preprocessImage } from "../../helpers/imagePreprocessor"

type MediaType = "image" | "video" | "pdf"

interface FileRendererComponentProps {
    type: MediaType
    preview?: boolean
    file: OMReference<OMFile>
    className?: string
    style?: React.CSSProperties
    hideDescription?: boolean
}

interface FileRendererComponentState {
    fileObject?: OMFile
    error?: Error

    loading?: boolean

    src?: string
}

const generateThumbnail = memoize(async (file: File): Promise<string> => {
    const orientation = await getImageOrientation(file)

    const compresssedUri = await preprocessImage(
        window.URL.createObjectURL(file),
        150,
        file.type,
        0.3,
        orientation,
    )

    return compresssedUri
})

class FileRenderer extends React.Component<FileRendererComponentProps, FileRendererComponentState> {

    constructor(props: FileRendererComponentProps) {
        super(props)
        this.state = {
            loading: true,
        }
    }

    public componentDidMount() {
        this.updateActualFileObject()
    }

    public componentDidUpdate(prevProps: FileRendererComponentProps, prevState: FileRendererComponentState) {
        if (this.props.file !== prevProps.file) {
            this.setState({
                loading: true,
            }, () => {
                this.updateActualFileObject()
            })
        }

        if (this.state.fileObject !== prevState.fileObject) {
            this.computeSrc()
        }
    }

    public render() {
        if (this.state.loading || !this.state.src || !this.state.fileObject) {
            return <div style={this.props.style} className={this.props.className}>Loading...</div>
        }

        if (this.state.error) {
            return <div>File not found</div>
        }

        const src = this.state.src

        const description = (() => {
            if (this.state.fileObject.file) {
                // tslint:disable-next-line
                return `${this.state.fileObject.file.name} | ${(this.state.fileObject.file.size / 1000).toLocaleString()}kB`
            }

            return `${this.state.fileObject.url} | ${(this.state.fileObject.size / 1000).toLocaleString()}kB`
        })()

        return (
            <div>
                {(() => {
                    switch (this.props.type) {
                        case "image":
                            return (
                                <img
                                    style={this.props.style}
                                    className={this.props.className}
                                    src={src}
                                />
                            )
                        case "video":
                            return (
                                <video
                                    style={this.props.style}
                                    className={this.props.className}
                                    autoPlay={true}
                                    src={src}
                                />
                            )
                        default:
                            return null
                    }
                })()}
                {!this.props.hideDescription && (
                    <small className="d-inline-block" style={this.props.style}>{description}</small>
                )}
            </div>
        )

    }

    private updateActualFileObject = async () => {
        if (this.props.file.actualObject) {
            this.setState({ fileObject: this.props.file.actualObject })
        } else {
            ((this.props.file.referencedClazz as any).query() as OMQuery<OMFile>)
                .filter("id", "equals", this.props.file.id)
                .execute()
                .then((refs) => refs[0].actualObject!)
                .then((fileObject) => this.setState({ fileObject }))
                .catch((error) => {
                    this.setState({ error })
                })
        }
    }

    private computeSrc = async () => {
        if (!this.state.fileObject) {
            return
        }

        if (!this.props.preview) {
            this.setState({
                loading: false,
                src: this.state.fileObject.url || window.URL.createObjectURL(this.state.fileObject.file),
            })
            return
        }

        if (this.state.fileObject.preview) {
            this.setState({
                loading: false,
                src: this.state.fileObject.preview,
            })
            return
        }

        if (this.state.fileObject.url && !this.state.fileObject.preview) {
            this.setState({
                loading: false,
                src: this.state.fileObject.preview ? this.state.fileObject.preview : this.state.fileObject.url,
            })
            return
        }

        if (!this.state.fileObject.file) {
            throw new Error("Expecting `this.state.fileObject.file' to be defined")
        }

        const compresssedUri = await generateThumbnail(this.state.fileObject.file)

        this.setState({ src: compresssedUri, loading: false })

        return
    }

}

export default FileRenderer
