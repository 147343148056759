// tslint:disable

import React from "react"

const withMachine = (Machine) => (Component) => {
    const machine = new Machine()
    return class WithMachine extends React.Component {
        constructor() {
            super()
            machine.subscribe(this.callback)
        }

        callback = () => this.forceUpdate()

        componentWillUnmount() {
            machine.unsubscribe(this.callback)
        }

        render() {
            return <Component {...this.props} machine={machine} />
        }
    }
}

export default withMachine
