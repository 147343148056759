//  PmtCurrencyRate.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-08
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMObjectProperties as ObjectProperties,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    Object,
    PmtCurrencyPair,
} from ".."

export interface PmtCurrencyRateProperties extends ObjectProperties {
    rate: number
    date: Moment
    source: string

    currencyPair: OMReference<PmtCurrencyPair>
}

class PmtCurrencyRate extends Object implements PmtCurrencyRateProperties {

    public static Type: string = "pmt_currency_rate"
    public static Path: string = "pmt_currency_rates"

    public rate: number
    public date: Moment
    public source: string

    public currencyPair: OMReference<PmtCurrencyPair>

    constructor(object: PmtCurrencyRateProperties) {
        super(object)

        this.rate = object.rate
        this.date = moment(object.date)
        this.source = object.source

        this.currencyPair = new OMReference(PmtCurrencyPair, object.currencyPair)
    }
}

export default PmtCurrencyRate
