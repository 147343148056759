import React from "react"

import { ArticleContent } from "../../../../domain"
import ArticleContentCard from "./ArticleContentCard"

export interface ArticleContentGroupCardComponentProps {
    articleContents: ArticleContent[]
    articleType?: "slt" | "video" | "core_value" | "announcement"
}

export interface ArticleContentGroupCardComponentState {
}

class ArticleContentGroupCard
    extends React.Component<ArticleContentGroupCardComponentProps, ArticleContentGroupCardComponentState> {

    constructor(props: ArticleContentGroupCardComponentProps) {
        super(props)
    }

    public render() {
        return this.props.articleContents.map((articleContent) => (
            <ArticleContentCard articleContent={articleContent} />
        ))
    }
}

export default ArticleContentGroupCard
