import { OMReference, OMUniverse } from "firmament-node-sdk"
import { Moment } from "moment"
import * as React from "react"
import inflection from "inflection"

import {
    DatePicker,
    TextInput,
    Checkbox,
    NumberInput,
    TextAreaInput,
    Callout,
    Body,
    Title2,
    Subheadline, Headline, Caption1
} from "../../../../components"
import ic_portrait_placeholder from "../../../../assets/ic_portrait_placeholder.png"

import { CnxMemberProfile, CnxConsumerRefund, CnxMeeting, CnxProviderChargeback, CnxRating, CnxRatingProperties, Setting } from "../../../../domain"
import { AvatarImage } from "../../../../airframe-components/Avatar/AvatarImage"
import { StarRating } from "../../../../airframe-components"
import FollowButton from "../../../SchTutorProfile/SchTutorProfileDetails/elements/FollowButton"

export interface CnxRatingFieldsetComponentProps {
    onChange?: (cnxRating?: CnxRating) => void
    cnxRating?: CnxRating

    submitter?: OMReference<CnxMemberProfile>
    refund?: OMReference<CnxConsumerRefund>
    meeting?: OMReference<CnxMeeting>
    providerChargeback?: OMReference<CnxProviderChargeback>

    onFavouriteButtonClick?: (favourited: boolean) => void
}

export interface CnxRatingFieldsetComponentState extends Partial<CnxRatingProperties> {
    cnxRating?: CnxRating
    favourited?: boolean

    reportSessionIntent?: boolean
}

class CnxRatingFieldset extends React.Component<CnxRatingFieldsetComponentProps, CnxRatingFieldsetComponentState> {
    constructor(props: CnxRatingFieldsetComponentProps) {
        super(props)
        this.state = {
            cnxRating: props.cnxRating,
            ...props.cnxRating,
        }
    }

    public componentDidUpdate(
        prevProps: CnxRatingFieldsetComponentProps,
        prevState: CnxRatingFieldsetComponentState,
    ) {

        if (
            this.state.overall !== prevState.overall ||
            this.state.review !== prevState.review ||
            this.state.subratingTypes !== prevState.subratingTypes ||
            this.state.subrating1 !== prevState.subrating1 ||
            this.state.subrating2 !== prevState.subrating2 ||
            this.state.subrating3 !== prevState.subrating3 ||
            this.state.subrating4 !== prevState.subrating4 ||
            this.state.subrating5 !== prevState.subrating5 ||
            this.state.createdAt !== prevState.createdAt ||
            this.state.sourceType !== prevState.sourceType ||

            this.props.submitter !== prevProps.submitter ||
            this.props.refund !== prevProps.refund ||
            this.props.meeting !== prevProps.meeting ||
            this.props.providerChargeback !== prevProps.providerChargeback ||
            false
        ) {
            const model = this.model()
            this.setState({ cnxRating: model })
        }

        if (this.props.onChange && this.state.cnxRating !== prevState.cnxRating) {
            this.props.onChange(this.state.cnxRating)
        }
    }

    private updateOverall = () => {
        this.setState({
            overall: (
                (this.state.subrating1 || 0) +
                (this.state.subrating2 || 0) +
                (this.state.subrating3 || 0) +
                (this.state.subrating4 || 0) +
                (this.state.subrating5 || 0)
            ) / 5
        })
    }

    public render() {
        const meeting = this.props.meeting && OMUniverse.shared.find(CnxMeeting, this.props.meeting.id)
        const provider = meeting && meeting.provider && meeting.provider.actualObject
        const photo = provider && provider.photo && provider.photo.actualObject

        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <AvatarImage
                                size="lg"
                                src={photo && photo.preview || ic_portrait_placeholder}
                                className=""
                            />

                            <Callout className="text-secondary">{provider && provider.name || "-"}</Callout>

                            <Title2 className="mt-16px">Rate Your Experience</Title2>

                            <div className="mt-20px" style={{ fontSize: 40 }}>
                                <StarRating starColor="accent-2" at={Math.round(this.state.overall || 0)} max={5} onSelect={(star) => { this.setState({ subrating1: star, subrating2: star, subrating3: star, subrating4: star, subrating5: star, overall: star }) }} />
                            </div>

                        </div>
                    </div>

                    <hr />

                    {(this.state.overall || 0) < 5 && (
                        <div className="mx-20px">

                            <div className="row mb-20px">
                                <div className="col">
                                    <Body className="d-block text-center text-secondary">
                                        Tell us more about your experience.
                                    </Body>
                                </div>
                            </div>

                            {Setting.find("cnx_service_ratings_subrating_types") && Setting.find("cnx_service_ratings_subrating_types")!.value!.split("|").map((subratingType, index) => {
                                const inflectedSubratingType = inflection.humanize(subratingType)
                                return (
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <Callout>{inflectedSubratingType}</Callout>
                                        </div>

                                        <div className="flex-grow-1">
                                            <div className="d-flex justify-content-end" style={{ fontSize: 32 }}>
                                                <StarRating
                                                    starColor="accent-2"
                                                    at={(() => {
                                                        switch (index) {
                                                            case 0:
                                                                return this.state.subrating1
                                                            case 1:
                                                                return this.state.subrating2
                                                            case 2:
                                                                return this.state.subrating3
                                                            case 3:
                                                                return this.state.subrating4
                                                            case 4:
                                                                return this.state.subrating5
                                                        }
                                                    })()}
                                                    max={5}
                                                    onSelect={(star) => {
                                                        switch (index) {
                                                            case 0:
                                                                this.setState({ subrating1: star }, () => this.updateOverall())
                                                                break
                                                            case 1:
                                                                this.setState({ subrating2: star }, () => this.updateOverall())
                                                                break
                                                            case 2:
                                                                this.setState({ subrating3: star }, () => this.updateOverall())
                                                                break
                                                            case 3:
                                                                this.setState({ subrating4: star }, () => this.updateOverall())
                                                                break
                                                            case 4:
                                                                this.setState({ subrating5: star }, () => this.updateOverall())
                                                                break
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className="mt-20px"></div>

                            <div className="mt-20px">
                                <TextAreaInput
                                    placeholder="Leave your review"
                                    id="CnxRating.review"
                                    className="form-control bg-white"

                                    value={this.state.review}
                                    onChange={this.onChange("review")}
                                />
                            </div>

                            <div className="mt-20px mb-20px">
                                {this.state.reportSessionIntent
                                    ? (
                                        <div>
                                            <TextAreaInput
                                                placeholder="Report Reason"
                                                id="CnxRating.report"
                                                className="form-control bg-white"

                                                value={this.state.report}
                                                onChange={this.onChange("report")}
                                            />

                                            <Caption1 className="d-block text-center text-secondary">
                                                AskBee will review your session and get back to you soon.
                                            </Caption1>
                                        </div>
                                    )
                                    : (
                                        <div className="">
                                            <button className="btn btn-block btn-link text-primary" onClick={() => this.setState({ reportSessionIntent: true })}>
                                                <Callout>Had a serious issue? Report session</Callout>
                                            </button>
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                    )}


                    {(this.state.overall || 0) === 5 && (
                        <div className="mx-20px my-20px">

                            <div className="row">
                                <div className="col-12">
                                    <Body className="d-block text-center text-secondary mb-20px">Tell us more about your experience.</Body>
                                </div>

                                <div className="col-12 form-group mb-3">
                                    <TextAreaInput
                                        placeholder="Leave your review"
                                        id="CnxRating.review"
                                        className="form-control bg-white"

                                        value={this.state.review}
                                        onChange={this.onChange("review")}
                                    />
                                </div>
                            </div>

                            {this.props.meeting?.actualObject?.provider?.actualObject && <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        {/* <button type="button" className="btn btn-outline-alternative-2" style={{ borderRadius: "50%", height: 64.56 }} onClick={() => {
                                            const favourited = !this.state.favourited
                                            this.setState({ favourited })
                                            // TODO: Perform favourite tutor
                                        }}>
                                            <span className="fa fa-fw fa-thumbs-up" style={{ fontSize: 30 }} />
                                        </button> */}

                                        <FollowButton targetProfile={this.props.meeting?.actualObject?.provider?.actualObject} />

                                        <Headline className="d-block mt-12px">Favourite This Tutor?</Headline>

                                        <Subheadline className="d-block mt-4px text-secondary">Find your favourite tutors easily the next time you have a question.</Subheadline>
                                    </div>
                                </div>
                            </div>}

                        </div>
                    )}

                </div>
            </>
        )
    }

    private onChange = (keyPath: keyof CnxRatingFieldsetComponentState) =>
        (value?: any) => {
            this.setState({
                [keyPath]: value || null,
            })
        }

    private isValid(): boolean {
        // Check that all required attributes & relationships are present
        return (
            // this.state.sourceType !== undefined &&
            true
        )
    }

    private model(): CnxRating | undefined {
        if (!this.isValid()) {
            return undefined
        }

        let cnxRating = this.state.cnxRating

        // Check that all required attributes & relationships are present
        if (

            this.props.meeting !== undefined &&

            true
        ) {

            const props: CnxRatingProperties = {
                ...this.state,
                sourceType: this.state.sourceType || "CONSUMER",
                subratingTypes: Setting.find("cnx_service_ratings_subrating_types") && Setting.find("cnx_service_ratings_subrating_types")!.value!.split("|"),

                submitter: this.props.submitter,
                refund: this.props.refund,
                meeting: this.props.meeting,
                providerChargeback: this.props.providerChargeback,
            }

            cnxRating = new CnxRating({ ...this.props.cnxRating, ...props })
        }

        return cnxRating
    }
}

export default CnxRatingFieldset
