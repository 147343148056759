import React from "react"
import { Route } from "react-router"
import { SchTutorProfileDetails, SchTutorProfileDetailsStateMachine } from "../../../features/SchTutorProfile/SchTutorProfileDetails2"
import { Layout, ThemeProvider } from "../../../airframe-components"
import { DefaultNavbar, DefaultSidebar } from "../../Root/elements"

const TutorProfile = () => {
    return (
        <Route render={(componentProps) => (
            <ThemeProvider initialStyle="light" initialColor="primary">

                <Layout sidebarSlim>
                    { /* --------- Navbar ----------- */}
                    <Layout.Navbar>
                        <DefaultNavbar />
                    </Layout.Navbar>

                    { /* -------- Sidebar ------------*/}
                    <Layout.Sidebar>
                        <DefaultSidebar />
                    </Layout.Sidebar>

                    { /* -------- Content ----s--------*/}
                    <Layout.Content>
                        <SchTutorProfileDetails machine={new SchTutorProfileDetailsStateMachine()} {...componentProps} />
                    </Layout.Content>
                </Layout>
            </ThemeProvider>
        )} />
    )
}

export default TutorProfile
