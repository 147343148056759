import React from "react"

export interface ModalDialogComponentProps {
    dismiss?: () => void
    title?: React.ReactNode
    body?: React.ReactNode
    cancelText?: React.ReactNode
    actionButton?: React.ReactNode
    shouldDisplayCancelActionButton: boolean
}

export interface ModalDialogComponentState {

}

class ModalDialog extends React.Component<ModalDialogComponentProps, ModalDialogComponentState> {

    public static defaultProps = {
        shouldDisplayCancelActionButton: true,
    }

    public render() {
        return (
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        {this.props.title && (
                            <h5 className="modal-title">
                                {this.props.title}
                            </h5>
                        )}
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={this.props.dismiss}
                        >
                            <span className="fa fa-times-circle" />
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.props.body || <p>Woohoo, you're reading this text in a modal!</p>}
                    </div>
                    {this.props.actionButton && (
                        <div className="modal-footer">
                            {this.props.shouldDisplayCancelActionButton && (
                                <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={this.props.dismiss}
                                >
                                    {this.props.cancelText || "Cancel"}
                                </button>
                            )}

                            {this.props.actionButton}
                        </div>
                    )}
                </div>
            </div>
        )
    }

}

export default ModalDialog
