//  Party.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2019-08-22
//  Copyright © 2019 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMObjectProperties as ObjectProperties
} from "firmament-node-sdk"
import { Moment } from "moment"
import moment from "moment"
import {
    Group,
    Object,
} from ".."

export interface PartyProperties extends ObjectProperties {

    group?: Array<OMReference<Group>>
}

class Party extends Object implements PartyProperties {

    public static Type: string = "party"
    public static Path: string = "parties"

    public group: Array<OMReference<Group>>

    constructor(object: PartyProperties) {
        super(object)

        this.group = object.group ? object.group.map((item) => new OMReference(Group, item)) : []
    }
}

export default Party
