import React from "react"
import classnames from "classnames"

export const LargeTitle: React.FC<React.HTMLAttributes<HTMLHeadElement>> = React.memo((props) => <h1 {...props} className={classnames("large-title font-weight-bold", props.className)}>{props.children}</h1>)

export const Title1: React.FC<React.HTMLAttributes<HTMLHeadElement>> = React.memo((props) => <h2 {...props} className={classnames("title-1 font-weight-bold", props.className)}>{props.children}</h2>)
export const Title2: React.FC<React.HTMLAttributes<HTMLHeadElement>> = React.memo((props) => <h3 {...props} className={classnames("title-2 font-weight-bold", props.className)}>{props.children}</h3>)
export const Title3: React.FC<React.HTMLAttributes<HTMLHeadElement>> = React.memo((props) => <h4 {...props} className={classnames("title-3 font-weight-medium", props.className)}>{props.children}</h4>)

export const Headline: React.FC<React.HTMLAttributes<HTMLElement>> = React.memo((props) => <strong {...props} className={classnames("headline font-weight-bold", props.className)}>{props.children}</strong>)

export const Body: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = React.memo((props) => <main {...props} className={classnames("body font-weight-medium", props.className)}>{props.children}</main>)

export const Callout: React.FC<React.HTMLAttributes<HTMLElement>> = React.memo((props) => <small {...props} className={classnames("callout font-weight-bold", props.className)}>{props.children}</small>)
export const Subheadline: React.FC<React.HTMLAttributes<HTMLElement>> = React.memo((props) => <small {...props} className={classnames("subheadline font-weight-medium", props.className)}>{props.children}</small>)
export const Footnote: React.FC<React.HTMLAttributes<HTMLElement>> = React.memo((props) => <small {...props} className={classnames("footnote font-weight-medium", props.className)}>{props.children}</small>)
export const Caption1: React.FC<React.HTMLAttributes<HTMLElement>> = React.memo((props) => <small {...props} className={classnames("caption-1 font-weight-medium", props.className)}>{props.children}</small>)
export const Caption2: React.FC<React.HTMLAttributes<HTMLElement>> = React.memo((props) => <small {...props} className={classnames("caption-2 font-weight-medium", props.className)}>{props.children}</small>)
