import React from "react"

export interface AlertComponentProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {

    type: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark"
}

export interface AlertComponentState {

}

class Alert extends React.Component<AlertComponentProps, AlertComponentState> {
    constructor(props: AlertComponentProps) {
        super(props)
        this.state = {}
    }

    public render() {
        return (
            <div
                {...this.props}
                className={`alert alert-${this.props.type} m-3 ${this.props.className}`}
                role="alert"
            >
                {this.props.children}
            </div>
        )
    }
}

export default Alert
