// Data imports
import { OMReference } from "firmament-node-sdk"
import * as React from "react"
import { RouteComponentProps } from "react-router"
import { Link, Redirect, Route, Switch } from "react-router-dom"
import { ArticleContent, Profile, ArticleImage, Article } from "../../domain"
import BackButton from "../../views/Root/elements/BackButton"

// Feature Imports
import { ArticleDetails, ArticleDetailsStateMachine } from "./ArticleDetails"
// import { ArticleList, ArticleListStateMachine } from "./ArticleList"

const Details = (ownProps: ArticleModuleProps) => (props: RouteComponentProps<{ id: string }>) => {
    return <ArticleDetails machine={new ArticleDetailsStateMachine()} {...ownProps} {...props} />
}

export interface ArticleModuleProps {
    author?: OMReference<Profile>
}

class ArticleModule extends React.Component<ArticleModuleProps> {

    public render() {
        return (
            <div id="ArticleModule" className="">
                <div className="container-420px my-8px sm-px-15px" style={{ maxWidth: 500 }}>
                    <Route render={(props) => <BackButton />} />
                </div>

                <Switch>

                    <Route
                        path={`:parentPath(.*)/${Article.Path}/:id`}
                        component={Details(this.props)}
                    />

                    {/* <Redirect
                        strict={true}
                        from={`:parentPath(.*)/${Article.Path}/`}
                        to={`:parentPath(.*)/${Article.Path}`}
                    />

                    <Route
                        path={`:parentPath(.*)/${Article.Path}`}
                        render={(componentProps) => <ArticleList machine={new ArticleListStateMachine()} {...this.props} {...componentProps} />}
                    />

                    <Route
                        path={`:parentPath(.*)`}
                        // tslint:disable-next-line
                        render={(componentProps) => {
                            componentProps.match.url = `${componentProps.match.url}/${Article.Path}`
                            return <ArticleList machine={new ArticleListStateMachine()} {...this.props} {...componentProps} />
                        }}
                    /> */}

                </Switch>
            </div>
        )
    }
}

export default ArticleModule
