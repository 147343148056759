import { OMObject, OMQuery } from "firmament-node-sdk"
import { cloneDeep } from "lodash"
import React from "react"
import AsyncSelect from "react-select/lib/Async"
import { ActionMeta, ValueType } from "react-select/lib/types"
import DelayRun from "../../helpers/DelayRun"
import Control from "react-select/lib/components/Control"

interface AsyncSelectHeaderComponentProps<T extends OMObject> {
    placeholder?: string
    label: string
    onSelectChange: (value: { label: any, value: any }, action: ActionMeta) => void
    valueField: keyof T
    labelField: keyof T
    labelFieldCallback?: (obj: T) => any
    query: OMQuery<T>
    isMulti?: boolean

    disabled?: boolean

    value?: any

    required?: boolean
}

interface AsyncSelectHeaderComponentState {
    value?: any
}

class AsyncSelectHeader<T extends OMObject>
    extends React.Component<AsyncSelectHeaderComponentProps<T>, AsyncSelectHeaderComponentState> {

    private delayRun: DelayRun = new DelayRun(0.2)

    private get isInvalid(): boolean {
        if (!this.props.required) {
            return false
        }

        if (Array.isArray(this.props.value) && this.props.value.length === 0) {
            return true
        }

        if (!this.props.value) {
            return true
        }

        return false
    }

    public render() {
        return (
            <div className="position-relative">
                {this.props.label && <label htmlFor="inputContactPerson">{this.props.label}</label>}

                <AsyncSelect
                    {...this.props}
                    className={this.isInvalid ? "is-invalid" : "is-valid"}
                    styles={{
                        ...(this.props as any).styles,
                        control: (base) => ({
                            ...base,
                            backgroundColor: "#F9FAFC",
                            border: "1px solid #DEE2E6",
                        }),
                        placeholder: (base) => ({
                            ...base,
                            color: "5D636D",
                        })
                    }}
                    isMulti={this.props.isMulti}
                    closeMenuOnSelect={!this.props.isMulti}
                    defaultOptions={true}
                    isDisabled={this.props.disabled}
                    value={
                        this.props.value
                            ? Array.isArray(this.props.value)
                                ? this.props.value
                                : { label: this.props.value }
                            : undefined
                    }
                    isClearable={true}
                    placeholder={this.props.placeholder || "All"}
                    onChange={this.onChange}
                    loadOptions={this.promiseOptions}
                    cacheOptions={true}
                />

                <input type="text" hidden={true} className="form-control" required={this.isInvalid} />
                <div className="invalid-tooltip" style={{ zIndex: 0 }}>Field is required</div>
            </div>
        )
    }

    private promiseOptions = (inputValue: string) => {

        return this.delayRun.execute(() => {
            const query = Object.assign(
                Object.create(Object.getPrototypeOf(this.props.query)),
                cloneDeep(this.props.query),
            ) as OMQuery<T>

            return query
                .filter(this.props.labelField, "like", inputValue as any || "")
                .execute()
                .then((refs) => refs.map((ref) => ref.actualObject!))
                .then((items) => items.map((item) => ({
                    label: this.props.labelFieldCallback
                        ? this.props.labelFieldCallback(item)
                        : item[this.props.labelField],
                    value: item[this.props.valueField],
                })))
        })

    }

    private onChange = (value: ValueType<any>, action: ActionMeta): void => {
        this.setState({
            value,
        })

        this.props.onSelectChange(value, action)
    }

}

export default AsyncSelectHeader
