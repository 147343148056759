import React from "react"

import { default as TextInput, TextInputComponentProps } from "./TextInput"

export interface TextInputWithValidationComponentProps extends TextInputComponentProps {
    invalidTooltip?: string
}

export interface TextInputWithValidationComponentState {

}

class TextInputWithValidation extends React.Component<TextInputWithValidationComponentProps> {
    public render() {
        return (
            <>
                <TextInput {...this.props} />
                <div className="invalid-tooltip">
                    {this.props.invalidTooltip || "Field is required"}
                </div>
            </>
        )
    }
}

export default TextInputWithValidation
