//  CnxBooking.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-06-02
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxMeeting,
    CnxConsumerProfile,
    CnxConsumerPurchase,
} from ".."

export interface CnxBookingProperties extends DomainObjectProperties {
    baseCurrency?: string
    paymentGrossRateCents?: number
    paymentCurrency?: string
    paymentPlatformFeeCents?: number
    paymentProcessingFeeCents?: number
    currencyExchangeConstant?: number
    currencyExchangeRate?: number
    bookingStatus?: string

    meeting: OMReference<CnxMeeting>
    consumer: OMReference<CnxConsumerProfile>
    purchase?: OMReference<CnxConsumerPurchase>
}

class CnxBooking extends DomainObject implements CnxBookingProperties {

    public static Type: string = "cnx_booking"
    public static Path: string = "cnx_bookings"

    public baseCurrency?: string
    public paymentGrossRateCents?: number
    public paymentCurrency?: string
    public paymentPlatformFeeCents?: number
    public paymentProcessingFeeCents?: number
    public currencyExchangeConstant?: number
    public currencyExchangeRate?: number
    public bookingStatus?: string

    public meeting: OMReference<CnxMeeting>
    public consumer: OMReference<CnxConsumerProfile>
    public purchase?: OMReference<CnxConsumerPurchase>

    constructor(object: CnxBookingProperties) {
        super(object)

        this.baseCurrency = object.baseCurrency
        this.paymentGrossRateCents = object.paymentGrossRateCents
        this.paymentCurrency = object.paymentCurrency
        this.paymentPlatformFeeCents = object.paymentPlatformFeeCents
        this.paymentProcessingFeeCents = object.paymentProcessingFeeCents
        this.currencyExchangeConstant = object.currencyExchangeConstant
        this.currencyExchangeRate = object.currencyExchangeRate
        this.bookingStatus = object.bookingStatus

        this.meeting = new OMReference(CnxMeeting, object.meeting)
        this.consumer = new OMReference(CnxConsumerProfile, object.consumer)
        this.purchase = object.purchase && new OMReference(CnxConsumerPurchase, object.purchase)
    }
}

export default CnxBooking
