import React, { useMemo } from 'react';
import { LocalVideoTrack, Participant, RemoteVideoTrack, Track } from 'twilio-video';
import Publication from '../Publication/Publication';
import usePublications from '../../hooks/usePublications/usePublications';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { CnxMemberProfile } from '../../../../domain';
import ic_portrait_placeholder from "../../../../assets/ic_portrait_placeholder.png"
import { AppStateContext } from '../../../../views/App/elements/AppStateProvider';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import ParticipantCoverPhoto from '../Participant/ParticipantCoverPhoto';

interface ParticipantTracksProps {
    participant: Participant;
    disableAudio?: boolean;
    enableScreenShare?: boolean;
    videoPriority?: Track.Priority | null;
}

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

export default function ParticipantTracks({
    participant,
    disableAudio,
    enableScreenShare,
    videoPriority,
}: ParticipantTracksProps) {
    const { room } = useVideoContext();
    const publications = usePublications(participant);
    const isLocal = participant === room.localParticipant;

    let filteredPublications;

    if (publications.some(p => p.trackName.includes('Screen'))) {
        // Hide camera feed if sharing screen
        filteredPublications = publications.filter(p => p.trackName.includes('Screen') || p.kind === 'audio');
    } else {
        filteredPublications = publications;
    }

    return (
        <>
            {!filteredPublications.find(p => p.kind === 'video') && <ParticipantCoverPhoto isLocal={isLocal} />}

            {filteredPublications.map(publication => (
                <Publication
                    key={publication.kind}
                    publication={publication}
                    participant={participant}
                    isLocal={isLocal}
                    disableAudio={disableAudio}
                    videoPriority={videoPriority}
                />
            ))}
        </>
    );
}
