import React from "react"

interface IconComponentProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {}

class Icon extends React.PureComponent<IconComponentProps> {
    public render() {
        return (
            <i {...this.props} className={`material-icons ${this.props.className}`}>{this.props.children}</i>
        )
    }
}

export default Icon
