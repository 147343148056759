//  CnxProviderEarning.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-06-02
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    WltTransaction,
    WltTransactionProperties,
    WltWallet,
    CnxMeeting,
    CnxBid,
} from ".."

type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
type TransactionType = "DEBIT" | "CREDIT"
export interface CnxProviderEarningProperties extends WltTransactionProperties {

    meeting: OMReference<CnxMeeting>
}

class CnxProviderEarning extends WltTransaction implements CnxProviderEarningProperties {

    public static Type: string = "cnx_provider_earning"
    public static Path: string = "cnx_provider_earnings"


    public meeting: OMReference<CnxMeeting>

    constructor(object: CnxProviderEarningProperties) {
        super(object)


        this.meeting = new OMReference(CnxMeeting, object.meeting)
    }
}

export default CnxProviderEarning
