//  CnxMessageCard.tsx
//
//  Opera House | Typescript - Details Card
//  Updated 2020-05-14
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import { OMReference } from "firmament-node-sdk"
import * as React from "react"

import {
    DetailColumn,
    FileRenderer,
    Body,
    Caption1,
} from "../../../../components"

import { CnxMessage } from "../../../../domain"

export interface CnxMessageCardComponentProps {
    cnxMessage: CnxMessage
}

export interface CnxMessageCardComponentState {

}

class CnxMessageCard extends React.Component<CnxMessageCardComponentProps, CnxMessageCardComponentState> {
    constructor(props: CnxMessageCardComponentProps) {
        super(props)
        this.state = {}
    }

    public render() {
        return (
            <div className="card">

                <div className="card-header">Message Info</div>

                <div className="card-body">
                    <div className="container">
                        <div className="row">
                            <DetailColumn label="Message Type" object={this.props.cnxMessage} keyPath="messageType" />
                            <DetailColumn label="Text" object={this.props.cnxMessage} keyPath="text" />
                            <DetailColumn label="Created At" object={this.props.cnxMessage} keyPath="createdAt" />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default CnxMessageCard
