//  CnxSnapshotPhoto.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMFileProperties as FileProperties,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    File,
    CnxVideoRoom,
} from ".."

export interface CnxSnapshotPhotoProperties extends FileProperties {

    videoRoom?: OMReference<CnxVideoRoom>
}

class CnxSnapshotPhoto extends File implements CnxSnapshotPhotoProperties {

    public static Type: string = "cnx_snapshot_photo"
    public static Path: string = "cnx_snapshot_photos"


    public videoRoom?: OMReference<CnxVideoRoom>

    constructor(object: CnxSnapshotPhotoProperties) {
        super(object)


        this.videoRoom = object.videoRoom && new OMReference(CnxVideoRoom, object.videoRoom)
    }
}

export default CnxSnapshotPhoto
