import React from "react"
import classnames from "classnames"
import getProfile from "../../../../helpers/Profile"
import { AppStateContext } from "../../../../views/App/elements/AppStateProvider"
import ic_portrait_placeholder from "../../../../assets/ic_portrait_placeholder.png"
import { CnxMemberProfile } from "../../../../domain"

interface Props {
    isLocal?: boolean
}

const ParticipantCoverPhoto: React.FunctionComponent<Props> = ({ isLocal }) => {
    const { twilioVideoPresentationMode } = React.useContext(AppStateContext)
    const profile = getProfile() as CnxMemberProfile | undefined

    // TODO: to force Safari to rerender
    const [isAnimating, setIsAnimating] = React.useState(false)

    React.useEffect(() => {
        setIsAnimating(true)
        const timeout = setTimeout(() => {
            setIsAnimating(false)
        }, 200)

        return () => clearTimeout(timeout)
    }, [twilioVideoPresentationMode])

    return (
        <AppStateContext.Consumer>
            {({ participant }) => (
                <div
                    className={classnames("d-flex justify-content-center align-items-center", { "tvpm-fullscreen-side-by-side": twilioVideoPresentationMode === "fullscreen.side-by-side", "backdrop-blur": twilioVideoPresentationMode === "fullscreen.side-by-side" && !isAnimating })}
                >
                    <img
                        src={isLocal ? profile?.photo?.actualObject?.preview : participant?.photo?.actualObject?.preview || ic_portrait_placeholder}
                        alt="Profile Photo"
                        style={{
                            pointerEvents: "none",
                            objectFit: "cover",

                            height: 163,
                            width: 163,

                            border: "6px solid white",

                            borderRadius: "50%",
                            maskImage: "-webkit-radial-gradient(white, black)",

                            boxShadow: "0 4px 12px 0 rgba(15, 18, 26, 0.2)",
                            margin: 12
                        }}
                    />
                </div>
            )}
        </AppStateContext.Consumer>
    )
}

export default ParticipantCoverPhoto
