import {
    FSMNamedState as FSMState,
    ObservableFiniteStateMachine,
    OMObject,
    OMReference,
} from "firmament-node-sdk"

declare namespace DetailsStateMachine {
    /* SECTION: State */

    export type State<T extends OMObject> = FSMState.Start |
        FSMState.Loading |
        FSMState.ShowingError |
        FSMState.PresentingItem<T> |
        FSMState.Completed
}

export interface DetailTransitions<T extends OMObject> {
    load(id: OMReference<T>): void
    success(item: T): void
    fail(error: Error): void
    dismiss(): void
}

abstract class DetailsStateMachine<T extends OMObject, State extends FSMState>
    extends ObservableFiniteStateMachine<DetailsStateMachine.State<T> | State> {

    constructor() {
        super({ name: "start" })
    }

    /**
     * Load Transition
     * [start] -- load -> [loading]
     */
    public load = (id: OMReference<T>): void => {
        switch (this.state.name) {
            case "start":
                this.state = { name: "loading" }
                break
            default:
                return
        }

        if (id.actualObject !== undefined) {
            this.success(id.actualObject)
        } else {
            id
                .load()
                .then((result: T) => {
                    this.success(result)
                })
                .catch((error) => {
                    this.fail(error)
                })
        }
    }

    /**
     * Success Transition
     * [loading] -- success -> [presenting]
     */
    public success = (item: T): void => {
        switch (this.state.name) {
            case "loading":
                this.state = { name: "presenting", item }
                break
            default:
                return
        }
    }

    /**
     * Fail Transition
     * [loading] -- fail -> [showingError]
     */
    public fail = (error: Error): void => {
        switch (this.state.name) {
            case "loading":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

    /**
     * Dismiss Transition
     * [presenting] | [showingError] -- dismiss -> [completed]
     */
    public dismiss = (): void => {
        switch (this.state.name) {
            case "presenting":
            case "showingError":
                this.state = { name: "completed" }
                break
            default:
                return
        }
    }
}

export default DetailsStateMachine
