// Data module imports
import { OMReference, OMUser, Permission } from "firmament-node-sdk"
import * as React from "react"
import { Route, RouteComponentProps } from "react-router"
import classnames from "classnames"
import ic_portrait_placeholder from "../../../assets/ic_portrait_placeholder.png"

import { SchTutorProfileDetailsStateMachine } from "."
import SchTutorProfileCard from "./elements/SchTutorProfileCard"

import {
    ErrorAlert,
    LoadingButton,
    Modal,
    ModalDialog,
    ModalSpinner,
    Title3,
    ListStateMachine,
    EmailInput,
    PasswordInput,
    DetailColumn,
    Title2, Title1, Subheadline,
    Caption1,
    Headline,
    Caption2,
    Callout,
} from "../../../components"

import { NotifMessage, ProfilePhoto, User, SchTutorProfile, CnxMoneyWallet, CnxServiceCategory, WltWallet, CnxPointWallet, SchStudentProfile, CnxBooking, CnxProviderProfile } from "../../../domain"
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap"
import FileInput2 from "../../../components/deprecated-components/FileInput2"
import SchStudentProfileModule from "../../SchStudentProfile/SchStudentProfileModule"
import getProfile from "../../../helpers/Profile"
import { AvatarImage } from "../../../airframe-components/Avatar/AvatarImage"
import CnxBookingModule from "../../CnxBooking/CnxBookingModule"
import { Link } from "react-router-dom"
import { StarRating } from "../../../airframe-components"
import { CnxRatingList } from "../../CnxRating/CnxRatingList"
import CnxRatingModule from "../../CnxRating/CnxRatingModule"
import FollowButton from "./elements/FollowButton"
import VideoControls, { Video } from "../../../VideoControls"
import Affiliation from "./elements/Affiliation"
import groupBy from "lodash/groupBy"
import TeachSubject from "./elements/TeachSubject"
import settings from "../../../settings"
import CnxBookingAskForm from "../../CnxBooking/CnxBookingAskForm/CnxBookingAskForm"
import CnxBookingAskFormStateMachine from "../../CnxBooking/CnxBookingAskForm/CnxBookingAskFormStateMachine"
import { CategoriesParamsContextProvider } from "../../../helpers/useSubjectParamsContext"

declare interface SchTutorProfileDetailsProps extends RouteComponentProps<{ id: string }> {
    machine: SchTutorProfileDetailsStateMachine

    messages?: OMReference<NotifMessage>[]
    photo?: OMReference<ProfilePhoto>
    user?: OMReference<User>
}

interface SchTutorProfileDetailsComponentState {
    machineState: SchTutorProfileDetailsStateMachine.State
    queryParams: ListStateMachine.QueryParams<SchTutorProfile>

    newChallenge?: string
    newEmail?: string
    activeTab: string

    serviceCategories?: CnxServiceCategory[]

    showAskNowModal?: boolean
}

class SchTutorProfileDetails
    extends React.Component<SchTutorProfileDetailsProps, SchTutorProfileDetailsComponentState> {

    private machine: SchTutorProfileDetailsStateMachine

    constructor(props: SchTutorProfileDetailsProps) {
        super(props)
        this.machine = this.props.machine
        this.state = {
            machineState: this.machine.state,
            queryParams: {
                currentPage: 0,
                sortBy: "createdAt",
                sortDirection: "DESC",
            },
            activeTab: "0",
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)
        this.machine.load(new OMReference(SchTutorProfile, this.props.match.params.id))
    }

    public componentDidUpdate(prevProps: SchTutorProfileDetailsProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.machine.load(new OMReference(SchTutorProfile, this.props.match.params.id))
        }
    }

    public componentWillUnmount() {
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
            case "loading":
                return <ModalSpinner />
            case "promptingResetPassword":
            case "performingResetPassword":
            case "promptingSuccessfulResetPassword":
            case "promptingSuspend":
            case "performingSuspend":
            case "promptingUnsuspend":
            case "performingUnsuspend":
            case "promptingChangeEmail":
            case "performingChangeEmail":
            case "promptingDelete":
            case "performingDelete":
            case "presenting":
                const isApproved = !!machineState.item.approvedAt
                const isAvailable = isApproved && machineState.item.isOnline
                const affiliation = machineState.item.categories.find((each) => each.actualObject?.categoryType === "AFFILIATION")?.actualObject


                return (
                    <div id="SchTutorProfileDetail" className="container-420px">
                        <div className="m-neg-8px">

                            {/* {this.state.showAskNowModal && (
                                <Route render={(componentProps) => {
                                    // componentProps.match.url = `/${SchStudentProfile}/${machineState.item.id}`
                                    componentProps.match.params.id = undefined
                                    return <CnxBookingAskForm matchProvider={new OMReference(CnxProviderProfile, machineState.item.id)} machine={new CnxBookingAskFormStateMachine()} {...componentProps} />
                                }} />
                            )} */}

                            <Modal show={!!this.state.showAskNowModal} onDismiss={() => this.setState({ showAskNowModal: false })}>
                                <ModalDialog
                                    title={<Title2 className="d-block" style={{ marginLeft: 15, marginTop: 15 }}>Book A Session</Title2>}
                                    body={
                                        <Route
                                            render={(componentProps) => {
                                                if (!getProfile()) {
                                                    return null
                                                }

                                                const props = {
                                                    ...componentProps,
                                                    match: {
                                                        ...componentProps.match,
                                                        params: {},
                                                        url: `${componentProps.match.url}/${CnxBooking.Path}/ask`
                                                    }
                                                }
                                                return (
                                                    <CategoriesParamsContextProvider>
                                                        <CnxBookingAskForm
                                                            machine={new CnxBookingAskFormStateMachine()}
                                                            matchProvider={new OMReference(CnxProviderProfile, machineState.item.id)}
                                                            {...props}
                                                        />
                                                    </CategoriesParamsContextProvider>
                                                )
                                            }}
                                        />
                                    }
                                />
                            </Modal>

                            {/* {getProfile() && getProfile()!.id === machineState.item.id && (
                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.showEdit(machineState.item)}
                                >
                                    EDIT
                            </button>
                            </div>
                        )} */}

                            {/* <SchTutorProfileCard schTutorProfile={machineState.item} onAskNowButtonClick={() => this.props.history.push(`${this.props.match.url}/${CnxBooking.Path}/ask`)} /> */}








                            {/* Header */}
                            <div className="d-block">
                                {machineState.item.introVideo && (
                                    <Video
                                        src={machineState.item.introVideo?.actualObject?.url}
                                        className="w-100"
                                        /* topnav 55px; talent name 34; card padding 20px; book now button 60px; description 20px */
                                        style={{ objectFit: "cover", height: "40vh" }}
                                    />
                                )}
                                {!machineState.item.introVideo && (
                                    <img
                                        src={machineState.item.photo?.actualObject?.url}
                                        className="w-100"
                                        /* topnav 55px; talent name 34; card padding 20px; book now button 60px; description 20px */
                                        style={{ objectFit: "cover", height: "40vh" }}
                                    />
                                )}
                            </div>

                            {/* Content */}

                            <div className="card position-relative" style={{ marginTop: -16, overflow: "initial", borderRadius: "20px 20px 0 0", minHeight: "60vh" }}>
                                <div className="card-body p-20px">

                                    {machineState.item.introVideo && (
                                        <div className="position-absolute" style={{ left: 0, top: -45 }}>
                                            <VideoControls />
                                        </div>
                                    )}

                                    <div className="d-flex align-items center">
                                        <div className="flex-grow-1">
                                            <div>
                                                <Title2 className="d-inline mb-4px">{machineState.item.name}</Title2>
                                                {machineState.item.meetingsCount === 0 && <Title2 className="d-inline font-weight-normal ml-4px text-danger">ᴺᴱᵂᵎ</Title2>}
                                            </div>

                                            <div className="mb-16px">
                                                {affiliation
                                                    ? <Affiliation affiliation={affiliation} />
                                                    : <Subheadline className="d-block text-tertiary">{machineState.item.university || "-"}</Subheadline>
                                                }
                                            </div>
                                        </div>

                                        {machineState.item.id !== getProfile()?.id && (
                                            <div>
                                                <FollowButton targetProfile={machineState.item} onClick={() => this.forceUpdate()} />
                                            </div>
                                        )}
                                    </div>

                                    <Caption2 className="d-block text-tertiary font-weight-bold">Teaches:</Caption2>
                                    <Subheadline className="d-block text-dark mb-10px">{
                                        <TeachSubject provider={machineState.item} />
                                    }</Subheadline>


                                    <div className="mb-20px">
                                        <Caption2 className="d-block text-tertiary font-weight-bold">Tutor Link:</Caption2>
                                        <a className="callout text-primary font-weight-medium text-underline" target="_blank" href={`https://${settings.app_url}/${machineState.item.slug}`}>{settings.app_url}/{machineState.item.slug}</a>
                                        <span className="ml-4px text-primary callout fa fa-share" />
                                    </div>


                                    <div className="bg-overview-bg mt-4px" style={{ borderRadius: 8 }}>
                                        <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                                            <div className="d-flex">

                                                <div className="d-flex flex-fill flex-column justify-content-center align-items-center">
                                                    <Caption1 className="text-tertiary">Ratings</Caption1>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <Title3 className="text-accent-2 mr-10px"><span className="fa fa-star" /></Title3>
                                                        <Headline>{machineState.item.serviceRatingsByConsumersAverage?.toLocaleString(undefined, { maximumFractionDigits: 1 }) || "n/a"}</Headline>
                                                    </div>
                                                </div>

                                                <div className="d-flex flex-fill flex-column justify-content-center align-items-center" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.08)", borderRight: "1px solid rgba(0, 0, 0, 0.08)" }}>
                                                    <Caption1 className="text-tertiary">Favourites</Caption1>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <Title3 className="text-accent-2 mr-10px"><span className="fa fa-thumbs-up" /></Title3>
                                                        <Headline>{machineState.item.favoritedByConsumerCount || 0}</Headline>
                                                    </div>
                                                </div>

                                                <div className="d-flex flex-fill flex-column justify-content-center align-items-center">
                                                    <Caption1 className="text-tertiary">Sessions</Caption1>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <Title3 className="text-accent-2 mr-10px"><span className="fa fa-book" /></Title3>
                                                        <Headline>{machineState.item.meetingsCount || 0}</Headline>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {!isAvailable && (
                                        <div className="position-relative" style={{ top: 20 }}>
                                            <div className="text-center">
                                                <Callout className="text-secondary">Tutor is Offline</Callout>
                                            </div>
                                        </div>
                                    )}

                                    <div style={{ margin: -20, marginTop: 0 }}>
                                        <div className="position-relative">
                                            <hr className="position-relative" style={{ margin: 0, marginTop: 45 }} />


                                            <div className="position-relative" style={{ marginTop: -22, marginLeft: 40, marginRight: 40 }}>
                                                {(() => {
                                                    switch (true) {
                                                        case !isApproved:
                                                            return (
                                                                <button role="button" className="btn btn-block btn-secondary" disabled style={{ opacity: 1 }}>
                                                                    <Headline>Unavailable</Headline>
                                                                </button>
                                                            )
                                                        default:
                                                            return (
                                                                <button role="button" className="btn btn-block btn-primary" onClick={() => {
                                                                    if (!getProfile()) {
                                                                        this.props.history.push(`${this.props.match.url}/${CnxBooking.Path}/ask`)
                                                                    } else {
                                                                        this.setState({ showAskNowModal: true })
                                                                    }
                                                                }}>
                                                                    <Headline>Ask Now</Headline>
                                                                </button>
                                                            )
                                                    }
                                                })()}
                                            </div>

                                        </div>


                                        <div className="p-20px">
                                            <CnxRatingModule provider={new OMReference(CnxProviderProfile, machineState.item.id)} />
                                        </div>
                                    </div>

                                </div>

                            </div>



                        </div>
                    </div>
                )
            case "showingError":
                return <ErrorAlert error={machineState.error} />
            case "showingEdit":
                this.props.history.push(`${this.props.match.url}/edit`)
                return null
            case "completed":
                this.props.history.goBack()
                return null
        }
    }

    private subscriptionCallback = (machineState: SchTutorProfileDetailsStateMachine.State) => this.setState({ machineState })

    private showEdit = (item: SchTutorProfile) => () => {
        this.machine.showEdit(item)
    }

    private performResetPassword = () => {
        if (!this.state.newChallenge) {
            return
        }

        this.machine.performResetPassword(this.state.newChallenge)
    }

    private performChangeEmail = () => {
        if (!this.state.newEmail) {
            return
        }


        this.machine.performChangeEmail(this.state.newEmail)
    }
}

export default SchTutorProfileDetails
