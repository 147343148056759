import { OMFile, OMReference, OMUniverse } from "firmament-node-sdk"
import moment from "moment"
import React from "react"
import Dropzone, { DropEvent, DropzoneProps } from "react-dropzone"
import { SortableContainer, SortableElement, SortableHandle, SortEnd, SortEndHandler, arrayMove } from "react-sortable-hoc"
import isEqual from "react-fast-compare"

import Carousel from "../Carousel"
import FileRenderer from "../FileRenderer"
import Icon from "../Icon"
import { Modal, ModalDialog } from "../Modal"
import "./PhotoPicker.css"

type MediaType = "image" | "video" | "pdf"

export interface PhotoPickerComponentProps extends DropzoneProps {
    max?: number
    onChange?: (file?: OMFile[]) => void
    value?: OMFile[]
    type: MediaType
    disabled?: boolean
}

export interface PhotoPickerComponentState {
    value: OMFile[]

    isActive: boolean
    selected?: OMFile
}

const DragHandle = SortableHandle(({ children }: any) => children)
const SortableList = SortableContainer<React.HTMLAttributes<HTMLDivElement>>(({ children }: any) => <div>{children}</div>)
const SortableItem = SortableElement<React.HTMLAttributes<HTMLDivElement>>(({ children }: any) => <div style={{ float: "left" }}>{children}</div>)

class PhotoPicker extends React.Component<PhotoPickerComponentProps, PhotoPickerComponentState> {
    constructor(props: PhotoPickerComponentProps) {
        super(props)
        this.state = {
            isActive: false,
            value: props.value || [],
        }
    }

    public componentDidUpdate(prevProps: PhotoPickerComponentProps, prevState: PhotoPickerComponentState) {
        if (!isEqual(this.props.value, prevProps.value)) {
            this.setState({ value: this.props.value || [] })
        }

        if (this.props.onChange && this.state.value !== prevState.value) {
            this.props.onChange(this.state.value)
        }
    }

    public render() {
        switch (this.props.disabled) {
            case true:
                return (
                    <>
                        {/* tslint:disable-next-line */}
                        <Modal show={this.state.isActive} onDismiss={() => this.setState({ isActive: false })}>
                            <div className="modal-dialog" role="document">
                                <div className="modal-content rounded">
                                    {
                                        this.state.selected && (
                                            <Carousel
                                                slides={[
                                                    <FileRenderer
                                                        key={this.state.selected.id}
                                                        hideDescription={this.props.type !== "pdf"}
                                                        file={new OMReference(this.state.selected.constructor as any, this.state.selected)}
                                                        style={{ objectFit: "cover" }}
                                                        className="d-block w-100"
                                                        type={this.props.type}
                                                    />,
                                                ]}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </Modal>

                        <div>
                            {
                                this.state.value && this.state.value.map((item, index) => (
                                    <div style={{ float: "left" }}>
                                        {/* tslint:disable-next-line */}
                                        <div className="photopicker-thumb" key={index} onClick={() => this.setState({ isActive: true, selected: item })}>
                                            <div className="photopicker-thumb-inner">
                                                <FileRenderer
                                                    key={item.id}
                                                    preview={true}
                                                    hideDescription={true}
                                                    file={new OMReference(item.constructor as any, item)}
                                                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                                    type={this.props.type}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )
            default:
                const acceptableMimetype = (() => {
                    switch (this.props.type) {
                        case "image":
                            return "image/*"
                        case "video":
                            return "video/*"
                        case "pdf":
                            return "application/pdf"
                        default:
                            return undefined
                    }
                })()

                return (
                    <aside className="d-flex">
                        <SortableList onSortEnd={this.onSortEnd} axis="xy" distance={1}>
                            {
                                this.state.value && this.state.value.map((item, index) => (
                                    <SortableItem index={index}>
                                        <div className="photopicker-thumb">
                                            <button
                                                style={{
                                                    position: "absolute",
                                                    right: "1px",
                                                }}
                                                type="button"
                                                className="close"
                                                onClick={this.remove(index)}
                                            >
                                                <i className="fa fa-fw fa-times-circle align-top" />
                                            </button>

                                            <div className="photopicker-thumb-inner">
                                                <FileRenderer
                                                    key={item.id}
                                                    preview={true}
                                                    hideDescription={this.props.type !== "pdf"}
                                                    file={new OMReference(item.constructor as any, item)}
                                                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                                    type={this.props.type}
                                                />
                                            </div>
                                        </div>
                                    </SortableItem>
                                ))
                            }

                            <div style={{ float: "left" }}>
                                <Dropzone
                                    {...this.props}
                                    multiple={true}
                                    onDropAccepted={this.onDropAccepted}
                                    accept={acceptableMimetype}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} className={this.props.max === undefined || (this.state.value.length < this.props.max) ? "photopicker-thumb" : "d-none"}>
                                            <input {...getInputProps()} />
                                            <div className="photopicker-thumb-inner">
                                                <div style={{ width: "100px", textAlign: "center", verticalAlign: "middle", lineHeight: "100px", fontSize: "2rem", cursor: "pointer" }}>
                                                    +
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </SortableList>
                    </aside >
                )
        }

    }

    private onDropAccepted = (files: File[], event: DropEvent) => {

        const newValues = files.map((file, index) => new OMFile({ file, mimetype: file.type, createdAt: moment().add(index, "seconds") }))

        OMUniverse.shared.touch(newValues)

        this.setState({ value: [...this.state.value, ...newValues].slice((this.props.max || 0) * -1) })
    }

    private remove = (index: number) => () => {
        if (!this.state.value[index]) {
            return
        }

        this.setState({
            value: [...this.state.value.slice(0, index), ...this.state.value.slice(index + 1)],
        })

    }

    private onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (!this.state.value) {
            return
        }

        if (oldIndex === newIndex) {
            return
        }

        this.setState(({ value }) => ({
            value: arrayMove(value || [], oldIndex, newIndex)
                .map((articleContent, index) => new (articleContent.constructor as any)({ ...articleContent, sequence: index })),
        }))
    }

}

export default PhotoPicker
