//  CnxBid.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-06-02
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxMeeting,
    CnxProviderProfile,
} from ".."


export interface CnxBidProperties extends DomainObjectProperties {
    bidStatus?: "PENDING" | "WITHDRAWN" | "ACCEPTED" | "REJECTED"
    earningProcessingFeeCents?: number
    earningPlatformFeeCents?: number
    earningBaseRateCents?: number
    earningCurrency?: string
    basedCurrency?: string
    currencyExchangeRate?: number
    currencyExchangeConstant?: number

    meeting: OMReference<CnxMeeting>
    provider: OMReference<CnxProviderProfile>
}

class CnxBid extends DomainObject implements CnxBidProperties {

    public static Type: string = "cnx_bid"
    public static Path: string = "cnx_bids"

    public bidStatus: "PENDING" | "WITHDRAWN" | "ACCEPTED" | "REJECTED"
    public earningProcessingFeeCents?: number
    public earningPlatformFeeCents?: number
    public earningBaseRateCents?: number
    public earningCurrency?: string
    public basedCurrency?: string
    public currencyExchangeRate?: number
    public currencyExchangeConstant?: number

    public meeting: OMReference<CnxMeeting>
    public provider: OMReference<CnxProviderProfile>

    constructor(object: CnxBidProperties) {
        super(object)

        this.bidStatus = object.bidStatus || "PENDING"
        this.earningProcessingFeeCents = object.earningProcessingFeeCents
        this.earningPlatformFeeCents = object.earningPlatformFeeCents
        this.earningBaseRateCents = object.earningBaseRateCents
        this.earningCurrency = object.earningCurrency
        this.basedCurrency = object.basedCurrency
        this.currencyExchangeRate = object.currencyExchangeRate
        this.currencyExchangeConstant = object.currencyExchangeConstant

        this.meeting = new OMReference(CnxMeeting, object.meeting)
        this.provider = new OMReference(CnxProviderProfile, object.provider)
    }
}

export default CnxBid
