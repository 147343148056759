import React from "react"
import classnames from "classnames"

import { CnxConsumerProfile, CnxProviderProfile, SchStudentProfile } from "../../../../domain"
import getProfile from "../../../../helpers/Profile"
import { OMQuery, OMReference, OMUniverse } from "firmament-node-sdk"

const MAX_FOLLOWERS = 12

const FollowButton = ({ targetProfile, onClick }: { targetProfile: CnxProviderProfile, onClick?: Function }) => {
    const [hasFollowedUser, setHasFollowedUser] = React.useState(false)
    const [followerCount, setFollowerCount] = React.useState(0)

    const ownProfile = React.useMemo(() => getProfile(), [])

    React.useEffect(() => {
        if (!ownProfile) {
            return
        }

        ;(async () => {
            const query = new OMQuery(CnxProviderProfile, "favorite_providers", new OMReference(CnxConsumerProfile, ownProfile.id))
                .filter("id", "equals", targetProfile.id)
                .include("user")
                .include("user.identities" as any)
                .include("certificates")
                .include("moneyWallet")
                .include("photo")
                .include("categories")

            await query.execute()

            setFollowerCount(query.meta.count)
            setHasFollowedUser(query.meta.count > 0)
        })()
    }, [])

    const handleClick = React.useCallback(() => {
        if (!(ownProfile instanceof CnxConsumerProfile)) {
            return
        }

        if (followerCount >= MAX_FOLLOWERS) {
            return
        }

        if (hasFollowedUser) {
            targetProfile.unfollow(ownProfile)
            targetProfile.favoritedByConsumerCount = (targetProfile.favoritedByConsumerCount || 0) - 1
            OMUniverse.shared.touch(targetProfile)
            setHasFollowedUser(false)
        } else {
            targetProfile.follow(ownProfile)
            targetProfile.favoritedByConsumerCount = (targetProfile.favoritedByConsumerCount || 0) + 1
            OMUniverse.shared.touch(targetProfile)
            setHasFollowedUser(true)
        }

        if (onClick) {
            onClick()
        }
    }, [hasFollowedUser])

    return (
        <button
            type="button"
            className={classnames("btn btn-outline-warning", { active: hasFollowedUser })}
            style={{ height: 47.36, borderRadius: "50%" }}
            onClick={() => {
                if (!ownProfile) {
                    window.location = "/authentication/menu" as any
                }

                handleClick()
            }}
        >
            <i className="fa fa-thumbs-up" style={{ fontSize: 23 }}></i>
        </button>
    )
}

export default FollowButton
