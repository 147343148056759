// Data Imports
import { OMReference, OMUniverse } from "firmament-node-sdk"
import * as React from "react"
import { RouteComponentProps } from "react-router"

// Shared views
import {
    AsyncEntitySelectHeader,
    ErrorAlert,
    LoadingButton,
    ModalSpinner,
    Caption1,
    Headline,
    Title3,
    Form,
} from "../../../components"

import { CnxMeeting, GenImage, CnxMemberProfile, CnxMessage } from "../../../domain"

// Feature imports
import CnxMessageFormStateMachine from "./CnxMessageFormStateMachine"
import CnxMessageFieldset from "./elements/CnxMessageFieldset"

export interface CnxMessageFormComponentProps extends RouteComponentProps<{ id?: string }> {
    machine: CnxMessageFormStateMachine

    meeting?: OMReference<CnxMeeting>
    attachedImage?: OMReference<GenImage>
    sender?: OMReference<CnxMemberProfile>
}

export interface CnxMessageFormComponentState {
    machineState: CnxMessageFormStateMachine.State

    cnxMessage?: CnxMessage

    meetingRef?: OMReference<CnxMeeting>
    senderRef?: OMReference<CnxMemberProfile>
}

class CnxMessageForm extends React.Component<CnxMessageFormComponentProps, CnxMessageFormComponentState> {
    constructor(props: CnxMessageFormComponentProps) {
        super(props)

        this.state = {
            machineState: this.props.machine.state,

            meetingRef: this.props.meeting,
            senderRef: this.props.sender,
        }

        this.props.machine.subscribe(this.subscriptionCallback)
    }

    public componentDidMount() {
        const ref = this.props.match.params.id ? new OMReference(CnxMessage, this.props.match.params.id) : undefined
        this.props.machine.load(ref)
    }

    public componentWillUnmount() {
        this.props.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState

        switch (machineState.name) {
            case "start":
            case "loading":
                return <ModalSpinner />
            case "presenting":
            case "saving":
            case "showingError":
                return (
                    <>
                        {machineState.name === "showingError" && (
                            <ErrorAlert error={machineState.error} />
                        )}

                        <div className="p-3 col d-flex justify-content-between">
                            <Title3>{this.props.match.params.id ? "Edit" : "New"} Message</Title3>
                        </div>

                        <Form onSubmit={this.onSubmit}>

                            <div className="card m-3">

                                <div className="card-header"><Headline>Message Info</Headline></div>
                                <div className="card-body">
                                    <div className="container">

                                        <AsyncEntitySelectHeader
                                            value={this.state.meetingRef}
                                            label="Meeting"
                                            labelField="title"
                                            objectType={CnxMeeting}
                                            query={CnxMeeting.query()}
                                            onSelectChange={this.onMeetingChange}
                                        />
                                        <AsyncEntitySelectHeader
                                            value={this.state.senderRef}
                                            label="Sender"
                                            labelField="title"
                                            objectType={CnxMemberProfile}
                                            query={CnxMemberProfile.query()}
                                            onSelectChange={this.onSenderChange}
                                        />

                                        <CnxMessageFieldset
                                            cnxMessage={this.state.cnxMessage}
                                            onChange={this.onCnxMessageChange}

                                            meeting={this.state.meetingRef}
                                            sender={this.state.senderRef}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between p-3">
                                <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={this.props.machine.dismiss}
                                >
                                    Cancel
                                </button>

                                <LoadingButton
                                    type="submit"
                                    isLoading={machineState.name === "saving"}
                                >
                                    SAVE
                                </LoadingButton>
                            </div>

                        </Form>
                    </>
                )
            case "completed":
                this.props.history.goBack()
                return null
        }
    }

    private subscriptionCallback = (machineState: CnxMessageFormStateMachine.State) => {
        this.setState({
            machineState,

            cnxMessage: this.state.cnxMessage || machineState.name === "presenting" && machineState.item || undefined,
        })
    }

    private checkFormComplete = (): boolean => {
        if (!this.state.cnxMessage) {
            return false
        }

        return true
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()
        this.save()
    }

    private onMeetingChange = (meetingRef?: OMReference<CnxMeeting>) => {
        this.setState({ meetingRef })
    }

    private onSenderChange = (senderRef?: OMReference<CnxMemberProfile>) => {
        this.setState({ senderRef })
    }

    private onCnxMessageChange = (cnxMessage?: CnxMessage) => {
        this.setState({ cnxMessage })
    }

    private onChange = (keyPath: keyof CnxMessageFormComponentState) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({
                [keyPath]: event.target.value,
            } as any)
        }

    private save = () => {
        if (!this.checkFormComplete) {
            return
        }

        if (!this.state.cnxMessage) {
            return
        }

        this.props.machine.save(this.state.cnxMessage)
    }
}

export default CnxMessageForm
