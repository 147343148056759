interface Setting {
    app_url: string
    providers_group_uuid: string
    consumers_group_uuid: string
    default_service_tier_uuid: string
    phoebee_profile_uuid: string
    branch_io_branch_key: string
    stripe_api_key: string
    google_client_id: string
    app_store_url: string
    play_store_url: string
}

const settings = {
    "production": {
        app_url: "app.askbee.my",
        providers_group_uuid: "0b29fd8a-286f-11eb-89f4-026b573689da",
        consumers_group_uuid: "0b29b92e-286f-11eb-89ef-026b573689da",
        default_service_tier_uuid: "7106d87c-2873-11eb-9137-026b573689da",
        phoebee_profile_uuid: "b079df89-d655-4239-a85e-6a46c37dd58e",
        branch_io_branch_key: "key_live_hmM9rkYwl7UmnezAqZwf0kaiCxlBjzfa",
        stripe_api_key: "pk_live_LhGDB8vcyfhe3ZAAYiAJQYer00ZJI52eXS",
        google_client_id: "584898779671-k9csk1uoshbh039g482bfjmrvr8u7lk8.apps.googleusercontent.com",
        app_store_url: "https://apps.apple.com/us/app/id1516956391",
        play_store_url: "https://play.google.com/store/apps/details?id=my.applab.sch.connectx.android",
    },
    "default": {
        app_url: "app-staging.askbee.my",
        providers_group_uuid: "0b191018-8a88-11ea-afc6-026b573689da",
        consumers_group_uuid: "0b18b4ce-8a88-11ea-afc1-026b573689da",
        default_service_tier_uuid: "f32dd512-8a8f-11ea-84a0-026b573689da",
        phoebee_profile_uuid: "2cfd3b6d-f2f7-4aef-86d2-09c9a766b328",
        branch_io_branch_key: "key_test_miT6Ei0sbZLieixvr7rp2lpdEBiqpwkw",
        stripe_api_key: "pk_test_BOWS1PicW53yvGrCMcPiYNpH00jOPG7VRp",
        google_client_id: "584898779671-k9csk1uoshbh039g482bfjmrvr8u7lk8.apps.googleusercontent.com",
        app_store_url: "https://apps.apple.com/us/app/id1516956391",
        play_store_url: "https://play.google.com/store/apps/details?id=my.applab.sch.connectx.android",
    }
}

export default ((settings as any)[process.env.REACT_APP_ENV || "default"] || settings["default"]) as Setting
