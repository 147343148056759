import * as React from "react"
import { styled } from '@material-ui/core/styles';

import { CnxMeeting } from "../../../../domain"
import { VideoProvider } from "../../../../components/TwilioVideo/components/VideoProvider"
// import ErrorDialog from "../../../../components/TwilioVideo/components/ErrorDialog/ErrorDialog"
import AppStateProvider, { useAppState } from "../../../../components/TwilioVideo/state";
import generateConnectionOptions from "../../../../components/TwilioVideo/utils/generateConnectionOptions/generateConnectionOptions"
import { errorToast } from "../../../../components";
import useVideoContext from "../../../../components/TwilioVideo/hooks/useVideoContext/useVideoContext";
import useRoomState from "../../../../components/TwilioVideo/hooks/useRoomState/useRoomState";
import { LocalVideoTrack } from "twilio-video";
import VideoTrack from "../../../../components/TwilioVideo/components/VideoTrack/VideoTrack";
import Controls from "../../../../components/TwilioVideo/components/Controls/Controls";
import Room from "../../../../components/TwilioVideo/components/Room/Room";
import LocalVideoPreview from "../../../../components/TwilioVideo/components/LocalVideoPreview/LocalVideoPreview";
import useHeight from "../../../../components/TwilioVideo/hooks/useHeight/useHeight";

export interface TwilioComponentProps {
    cnxMeeting: CnxMeeting
}

export interface TwilioComponentState {

}

const Container = styled('div')({
    // display: 'grid',
    // gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
    overflow: 'hidden',
});

const VideoApp: React.FunctionComponent<any> = ({ children, onDisconnect }) => {
    const { error, setError, settings } = useAppState();
    const connectionOptions = generateConnectionOptions(settings);

    return (
        <>
            {error && errorToast(error)}
            <VideoProvider options={connectionOptions} onError={setError} onDisconnect={onDisconnect}>
                {/* <ErrorDialog dismissError={() => setError(null)} error={error} /> */}
                {children}
                <App />
            </VideoProvider>
        </>
    );
};

const App: React.FunctionComponent = () => {
    const roomState = useRoomState()

    return (
        <Container style={{ position: "fixed", top: 10, right: 10, zIndex: 1, pointerEvents: "none"  }}>
            {roomState === "connected" && (
                <>
                    <Room />
                    {/* <Controls /> */}
                </>
            )}
        </Container>
    )
}

export default VideoApp
