//  SchVoucherRedemption.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2019-08-15
//  Copyright © 2019 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
} from "firmament-node-sdk"
import {
    WltTransaction,
    WltTransactionProperties,
    SchTopupVoucher,
} from ".."

export interface SchVoucherRedemptionProperties extends WltTransactionProperties {

    voucher: OMReference<SchTopupVoucher>
}

console.log(WltTransaction, SchTopupVoucher)
class SchVoucherRedemption extends WltTransaction implements SchVoucherRedemptionProperties {

    public static Type: string = "sch_voucher_redemption"
    public static Path: string = "sch_voucher_redemptions"


    public voucher: OMReference<SchTopupVoucher>

    constructor(object: SchVoucherRedemptionProperties) {
        super(object)


        this.voucher = new OMReference(SchTopupVoucher, object.voucher)
    }
}

export default SchVoucherRedemption
