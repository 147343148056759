import React, { useRef, useEffect } from 'react';
import { IVideoTrack } from '../../types';
import { styled } from '@material-ui/core/styles';
import { Track } from 'twilio-video';
import classnames from "classnames"
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import { useAppState } from '../../../../views/App/elements/AppStateProvider';

const Video = styled('video')({
  // width: '100%',
  // maxHeight: '100%',
  objectFit: 'cover',

//   height: 163,
//   width: 163,
    width: '100%',
    height: '100%',

//   border: "6px solid white",

//   borderRadius: '50%',
//   // maskImage: '-webkit-radial-gradient(white, black)', /* NOTE: Conflict with boxShadow */

//   boxShadow: "0 4px 12px 0 rgba(15, 18, 26, 0.2)",
//   margin: 12,
});

const ScreenCaptureWindow = styled('video')({
  // width: '100%',
  // maxHeight: '100%',
  objectFit: 'cover',

  height: 500,
  width: 500,
  maxWidth: '90vw',
  maxHeight: '90vw',

  borderRadius: '1rem',
  maskImage: '-webkit-radial-gradient(white, black)',
});

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

export default function VideoTrack({ track, isLocal, priority }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaStreamTrack(track);

  const [isAnimating, setIsAnimating] = React.useState(false)

  const { twilioTrackNameOnFocus, setTwilioTrackNameOnFocus, twilioVideoPresentationMode, setTwilioVideoPresentationMode, cycleTwilioVideoPresentationMode } = useAppState() // global state
//   const [isFocus, setIsFocus] = React.useState(false) // local video state

    console.log(track.name, twilioTrackNameOnFocus)

  const isFocus = track.name === twilioTrackNameOnFocus

  useEffect(() => {
    setIsAnimating(true)
    const timeout = setTimeout(() => {
        setIsAnimating(false)
    }, 200)
    return () => clearTimeout(timeout)
  }, [twilioVideoPresentationMode])

  // Auto fullscreen when sharing screen
  useEffect(() => {
      console.log("Auto fullscreen when sharing screen")
      if (track.name.includes('Screen')) {
          setTwilioTrackNameOnFocus(track.name)
          setTwilioVideoPresentationMode("fullscreen.single")
      }
  }, [])

  useEffect(() => {
      const dblclickCallback = () => {
        // NOTE: skip going to full screen if current path is meeting lounge
        if (window.location.pathname.includes("/lounge")) {
            return
        }

        cycleTwilioVideoPresentationMode()

        if (twilioVideoPresentationMode === "fullscreen.side-by-side") {
            // next mode will be fullscreen.single, so set track focus
            setTwilioTrackNameOnFocus(track.name)
        }
      }

      ref.current.addEventListener("dblclick", dblclickCallback)
      // ref.current.addEventListener("touchend", dblclickCallback)


      // TODO: temporal fix Safari related css bug, during fullscreen/unfullscreen,
      // .video-bubbles-container child container width will be undefined,
      // changing className will force Safari to recalculate the width and height
    //   if (!isFocus) {
          const $container = document.querySelector(".video-bubbles-container") as HTMLElement | null
          const $videoBubbles = $container?.children && [...$container?.children as any] as HTMLElement[]

          if (!$videoBubbles) {
            return
          }

          $videoBubbles.forEach(($videoBubble) => {
            $videoBubble.children[0].className = ""
            setTimeout(() => {
              $videoBubble.children[0].className = "w-100 h-100"
            }, 100)
          })
    //   }

      return () => {
        ref.current.removeEventListener("dblclick", dblclickCallback)
        // ref.current.removeEventListener("touchend", dblclickCallback)
      }
  }, [ref, twilioVideoPresentationMode])

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored if it is not facing the environment.
  const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const style = isLocal && isFrontFacing ? { transform: 'rotateY(180deg)' } : {};

  switch (true) {
    // case track.name.includes('Screen'):
    //   return <ScreenCaptureWindow ref={ref} style={style} />;
    default:
      return (
          <div
            style={{ zIndex: isFocus ? 1 : 2 }}
            className={classnames(
                "transition-all", {
                    "tvpm-bubble": twilioVideoPresentationMode === "bubble" || (twilioVideoPresentationMode === "fullscreen.single" && !isFocus),
                    "tvpm-fullscreen-side-by-side": twilioVideoPresentationMode === "fullscreen.side-by-side",
                    "tvpm-fullscreen-single": isFocus && twilioVideoPresentationMode === "fullscreen.single",
                },
            )}
          >
              <Video ref={ref} style={{ ...style, objectFit: track.name.includes('Screen') ? "contain" : "cover" }} className={!isAnimating && "backdrop-blur" || undefined} />
          </div>
      );
  }
}
