import React from "react"
import { OMReference } from "firmament-node-sdk"
import classnames from "classnames"
import moment, { Moment } from "moment"

import { VideoProvider } from "../../../../components/TwilioVideo/components/VideoProvider"
import LocalVideoPreview from "../../../../components/TwilioVideo/components/LocalVideoPreview/LocalVideoPreview"
import { Controls } from "../../../Playground/PlaygroundDetails/Playground"
import Pie from "../../../../components/Pie"
import { Title2, Subheadline, Body, Headline, Caption1, Title3 } from "../../../../components"
import { CnxProviderProfile, SchTutorProfile, CnxMeeting, CnxServiceCategory, CnxConsumerProfile, SchStudentProfile } from "../../../../domain"
import { useRouteMatch } from "react-router"
import { Link } from "react-router-dom"
import getProfile from "../../../../helpers/Profile"
import ic_portrait_placeholder from "../../../../assets/ic_portrait_placeholder.png"
import Affiliation from "../../../SchTutorProfile/SchTutorProfileDetails/elements/Affiliation"
import TeachSubject from "../../../SchTutorProfile/SchTutorProfileDetails/elements/TeachSubject"

const PotentialMatch: React.FunctionComponent<{ src?: string, matched?: boolean }> = React.memo(({ src, matched }) => {
    const minRadius = 40 /* TODO: could use css scale() instead */
    const maxRadius = 60

    const angle = React.useMemo(() => Math.random() * Math.PI * 2, [])

    const randomRadius = Math.random() * (maxRadius - minRadius) + minRadius

    return (
        <div className="position-absolute h-100 w-100">
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
                <div
                    className={classnames({ "compass__container": matched })}
                    style={{
                        height: 162 * 2,
                        width: 162 * 2,
                        transformOrigin: "50% 50%",
                        transform: `rotate(${~~(angle * 180 / Math.PI)}deg)`,
                    }}
                >
                    <div
                        className={classnames("compass position-absolute bg-white", { "compass-match-success": matched, "compass-match-failed": matched !== undefined && !matched })}
                        style={{
                            height: randomRadius * 2,
                            width: randomRadius * 2,

                            borderRadius: "50%",

                            boxShadow: "0 5px 8px 0px rgba(15,18,26,0.2)",

                            backgroundImage: `url("${src || ""}")`,
                            backgroundSize: "cover",
                            backgroundPosition: "cover",

                            transform: `rotate(-${~~(angle * 180 / Math.PI)}deg)`,

                            objectFit: "cover"
                        }}
                    />
                </div>
            </div>
        </div>
    )
})









interface CircleProps {
    className?: string
    style: React.CSSProperties
}
const Circle: React.FunctionComponent<CircleProps> = ({ className, style }) => {
    return (
        <div
            className={classnames("position-absolute pulsing", className)}
            style={{
                width: Math.min(window.innerWidth, window.innerHeight) / 3,
                height: Math.min(window.innerWidth, window.innerHeight) / 3,
                borderRadius: "50%",
                ...style
            }}
        />
    )
}

const PulsingRadar: React.FunctionComponent<{ hidden?: boolean }> = ({ hidden }) => {
    return (
        <div className="position-absolute h-100 w-100">
            <div className={classnames("d-flex justify-content-center align-items-center h-100", { "fade-out": hidden })} style={{ zIndex: -1 }}>
                <Circle style={{ animationDelay: "0ms" }} />
                <Circle style={{ animationDelay: "300ms" }} />
                <Circle style={{ animationDelay: "600ms" }} />
                <Circle style={{ animationDelay: "900ms" }} />
            </div>
        </div>
    )
}







interface WaitingLoungeProps {
    meeting: CnxMeeting
    potentialMatches?: OMReference<CnxProviderProfile>[]
    match?: OMReference<CnxProviderProfile>
    consumer?: OMReference<CnxConsumerProfile>
    subject?: OMReference<CnxServiceCategory>

    onCancelButtonClicked?: Function
    onTryOtherTutorButtonClicked?: Function

    scheduledStartAt: Moment
    expiredAt: Moment
}

const WaitingLounge: React.FunctionComponent<WaitingLoungeProps> = ({
    meeting,
    potentialMatches,
    match,
    consumer,
    subject,
    onCancelButtonClicked,
    scheduledStartAt,
    expiredAt,
    onTryOtherTutorButtonClicked,
}) => {
    const routeMatch = useRouteMatch<{ id: string }>()

    const initialSeconds = React.useMemo(() => Math.max(0, expiredAt.diff(new Date(), "seconds")), [])
    const [secondsLeft, setSecondsLeft] = React.useState(initialSeconds)

    React.useEffect(() => {
        const id = setInterval(() => {
            if (meeting.createdAt.clone().add((meeting.serviceTier.actualObject?.preserviceMins || 0) / 6, "minute").isSameOrBefore()) {
                setSuggestTryOtherTutors(true)
            }
            setSecondsLeft((s) => {
                if (s <= 0) {
                    clearInterval(id)
                    return 0
                }
                return expiredAt.diff(new Date(), "seconds")
            })
        }, 1000)
        return () => clearInterval(id)
    }, [])

    const duration = moment.duration(secondsLeft, "seconds")

    const [suggestTryOtherTutors, setSuggestTryOtherTutors] = React.useState(false)
    const [reactedToTryOtherTutors, setReactedToTryOtherTutors] = React.useState(meeting.createdAt.clone().add((meeting.serviceTier.actualObject?.preserviceMins || 0) / 6, "minute").isSameOrBefore())
    const [matched, setMatched] = React.useState(false)

    React.useEffect(() => {
        setMatched(!!match)
    }, [match])

    return (
        <div className="position-fixed" style={{ top: 0, bottom: 0, left: 0, right: 0 }}>
            <div className="position-relative h-100 w-100">


                {/* Top */}
                <div className="position-absolute w-100" style={{ top: 0, zIndex: 1 }}>
                    <div className="my-20px mx-20px">
                        {/* NOTE: btn-link already have paddingX of 13px and paddingY of 7px */}
                        {/* NOTE: to make up the margin to 20px/20px for both axis, add left and top margin of 7px and 13px */}
                        {meeting.meetingStatus === "PENDING" && (
                            <button type="button" className="btn btn-link position-absolute" style={{ left: 7, top: 13 }} onClick={() => onCancelButtonClicked && onCancelButtonClicked()}>
                                <span className="fa fa-times" style={{ fontSize: 20 }} />
                            </button>
                        )}
                        <Headline className="d-block text-center">
                            {subject?.actualObject?.title || "-"}
                        </Headline>
                    </div>
                </div>





                {/* Center */}
                <div className="position-absolute h-50 w-100">
                    <PulsingRadar hidden={secondsLeft === 0 || matched} />

                    {getProfile() instanceof CnxConsumerProfile && potentialMatches?.map((each) => {
                        return <PotentialMatch key={each.id} matched={match ? each.id === match.id : secondsLeft === 0 ? false : undefined} src={each.actualObject?.photo?.actualObject?.preview || ic_portrait_placeholder} />
                    })}

                    {getProfile() instanceof CnxProviderProfile && <PotentialMatch key={consumer?.id} matched={true} src={consumer?.actualObject?.photo?.actualObject?.preview || ic_portrait_placeholder} />}

                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className={classnames("position-relative", { "shift-47px-right": matched })} style={{ transition: "all 1.5s" }}>
                            <LocalVideoPreview />
                            <Controls isLocal />
                        </div>
                    </div>
                </div>





                {/* Bottom */}
                {(() => {
                    switch (true) {
                        case getProfile() instanceof SchTutorProfile:
                            return (
                                <div className="position-absolute w-100" style={{ bottom: 20 }}>
                                    <div className="container-420px">

                                        <Title2 className="d-block text-center mb-20px">Your student is here!</Title2>

                                        <div className="card mx-20px mb-20px">
                                            <div className="card-body p-20px">
                                                <Title2 className="d-block mb-4px">{consumer?.actualObject?.name ?? "-"}</Title2>
                                                <Subheadline className="d-block mb-12px text-tertiary">{new OMReference(SchStudentProfile, consumer?.id || "").actualObject?.school ?? "-"}</Subheadline>

                                                <Subheadline className="d-block mb-4px">{subject?.actualObject?.title || "-"}</Subheadline>

                                                <div>
                                                    <div className="mb-12px">
                                                        {consumer?.actualObject?.categories
                                                            .map((each) => each.actualObject!)
                                                            .sort((lhs, rhs) => (lhs.sequence || 0) - (rhs.sequence || 0))
                                                            .map((each) => each.categoryType === "GRADE" && each.title)
                                                            .filter(Boolean)
                                                            .map((each) => <span className="badge badge-success mx-4px">{each}</span>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-8px" style={{ marginLeft: 46, marginRight: 46 }}>
                                            <a href={`/${CnxMeeting.Path}/${routeMatch?.params.id}`}>
                                                <button type="button" className="btn btn-block btn-accent">
                                                    <Headline>Enter</Headline>
                                                </button>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            )
                        case matched && !!match:
                            const affiliation = match?.actualObject?.categories.find((each) => each.actualObject?.categoryType === "AFFILIATION")?.actualObject
                            return (
                                <div className="position-absolute w-100" style={{ bottom: 20 }}>
                                    <div className="container-420px">

                                        <Title2 className="d-block text-center mb-20px">Yay! We found you a tutor!</Title2>

                                        <div className="card mx-20px mb-20px">
                                            <div className="card-body p-20px">
                                                <Title2 className="d-block mb-4px">{match!.actualObject?.name ?? "-"}</Title2>

                                                <div className="mb-16px">
                                                    {affiliation
                                                        ? <Affiliation affiliation={affiliation} />
                                                        : <Subheadline className="d-block text-tertiary">{new OMReference(SchTutorProfile, match!.id).actualObject?.university || "-"}</Subheadline>
                                                    }
                                                </div>

                                                <Body className="mb-8px">
                                                    <TeachSubject provider={match!.actualObject!} />
                                                </Body>

                                                <div className="bg-overview-bg mt-12px" style={{ borderRadius: 8 }}>
                                                    <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                                                        <div className="d-flex">

                                                            <div className="d-flex flex-fill flex-column justify-content-center align-items-center">
                                                                <Caption1 className="text-tertiary">Ratings</Caption1>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <Title3 className="text-accent-2 mr-10px"><span className="fa fa-star" /></Title3>
                                                                    <Headline>{match?.actualObject?.serviceRatingsByConsumersAverage?.toLocaleString(undefined, { maximumFractionDigits: 1 }) || "n/a"}</Headline>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex flex-fill flex-column justify-content-center align-items-center" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.08)", borderRight: "1px solid rgba(0, 0, 0, 0.08)" }}>
                                                                <Caption1 className="text-tertiary">Favourites</Caption1>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <Title3 className="text-accent-2 mr-10px"><span className="fa fa-thumbs-up" /></Title3>
                                                                    <Headline>{match?.actualObject?.favoritedByConsumerCount || 0}</Headline>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex flex-fill flex-column justify-content-center align-items-center">
                                                                <Caption1 className="text-tertiary">Sessions</Caption1>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <Title3 className="text-accent-2 mr-10px"><span className="fa fa-book" /></Title3>
                                                                    <Headline>{match?.actualObject?.meetingsCount || 0}</Headline>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-8px" style={{ marginLeft: 46, marginRight: 46 }}>
                                            <a href={`/${CnxMeeting.Path}/${routeMatch?.params.id}`}>
                                                <button type="button" className="btn btn-block btn-accent">
                                                    <Headline>Enter</Headline>
                                                </button>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            )
                        case !secondsLeft:
                            return (
                                <div className="position-absolute w-100" style={{ bottom: 20 }}>
                                    <div className="container-420px">
                                        <div style={{ marginLeft: 46, marginRight: 46 }}>
                                            <Title2 className="d-block text-center mb-20px">Sorry, all of our tutors are busy right now. Try again later.</Title2>

                                            <button type="button" className="btn btn-block btn-link text-accent" onClick={() => onCancelButtonClicked && onCancelButtonClicked()}>
                                                <Headline>Cancel</Headline>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        case suggestTryOtherTutors:
                            return (
                                <div className="position-absolute w-100" style={{ bottom: 20 }}>
                                    <div className="container-420px">
                                        <div style={{ marginLeft: 46, marginRight: 46 }}>
                                            <Title2 className="d-block text-center mb-12px">Sorry, this is taking longer than usual.</Title2>

                                            <div className="d-flex justify-content-start align-items-center h-100 ml-auto mr-auto mb-12px" style={{ width: "5rem" }}>
                                                <Pie max={initialSeconds} current={secondsLeft} />
                                                <div className="ml-8px text-accent" style={{ fontSize: 20 }}>
                                                    {`${duration.minutes()}:${duration.seconds().toString().padStart(2, "0")}`}
                                                </div>
                                            </div>

                                            {!reactedToTryOtherTutors && (
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-accent"
                                                    onClick={() => {
                                                        if (onTryOtherTutorButtonClicked) {
                                                            onTryOtherTutorButtonClicked()
                                                            setReactedToTryOtherTutors(true)
                                                        }
                                                    }}>
                                                    <Headline>Try Other Tutors</Headline>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        case !matched:
                            return (
                                <div className="position-absolute w-100" style={{ bottom: 20 }}>
                                    <div className="container-420px">
                                        <Title2 className="d-block text-center mb-16px">Contacting Tutors...</Title2>

                                        {/* NOTE: fix width to 5rem because there will be a maximum of 5 character here (including the pie chart) */}
                                        {/* NOTE: by fixing the width, this container will not move around when the second ticks away (not easily perceivable at least :D) */}
                                        <div className="d-flex justify-content-start align-items-center h-100 ml-auto mr-auto" style={{ width: "5rem" }}>
                                            <Pie max={initialSeconds} current={secondsLeft} />
                                            <div className="ml-8px text-accent" style={{ fontSize: 20 }}>
                                                {`${duration.minutes()}:${duration.seconds().toString().padStart(2, "0")}`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        default:
                            return null
                    }
                })()}



            </div>
        </div>
    )
}

const WaitingLoungeWrapper: React.FunctionComponent<WaitingLoungeProps> = React.memo((props) => {
    return (
        <VideoProvider onError={() => {/* TODO */ }}>
            <WaitingLounge {...props} />
        </VideoProvider>
    )
})

export default WaitingLoungeWrapper
