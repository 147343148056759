import { ArticleContent, ArticleContentProperties } from "."

export interface ArticleHeaderProperties extends ArticleContentProperties {
    level: number
}

class ArticleHeader extends ArticleContent implements ArticleHeaderProperties {

    public static Type: string = "article_header"
    public static Path: string = "article_headers"

    public static isType(object: any): object is ArticleHeader {
        return object.type === "article_header" || object.objectType === "article_header"
    }

    public level: number

    constructor(object: ArticleHeaderProperties) {
        super(object)
        this.level = object.level
    }

}

export default ArticleHeader
