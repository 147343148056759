//  SchAvatar.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2021-01-05
//  Copyright © 2021 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    GenImage,
    GenImageProperties,
    CnxServiceCategory,
    CnxMessage,
} from ".."

export interface SchAvatarProperties extends GenImageProperties {

}

class SchAvatar extends GenImage implements SchAvatarProperties {

    public static Type: string = "sch_avatar"
    public static Path: string = "sch_avatars"



    constructor(object: SchAvatarProperties) {
        super(object)


    }
}

export default SchAvatar
