import React, { useMemo } from "react"

interface Props {
    max: number
    current: number
}

const Pie: React.FunctionComponent<Props> = ({ max, current }) => {

    const ref = React.useRef<HTMLCanvasElement>(null)

    const radius = useMemo(() => {
        return current / max * 2 * Math.PI
    }, [max, current])

    React.useEffect(() => {
        if (!ref.current) {
            return
        }

        const ctx = ref.current.getContext("2d")!

        ctx.clearRect(0, 0, 20, 20)
        ctx.beginPath()

        ctx.moveTo(10, 10) /* TODO: is this necessary */
        ctx.arc(10, 10, 10, -(Math.PI / 2) - radius, -(Math.PI / 2))
        ctx.lineTo(10, 10)

        ctx.fillStyle = "#1167f5"
        ctx.fill()
    }, [max, current])

    return <canvas ref={ref} height={20} width={20} />
}

export default Pie
