// Data imports
import { FSMNamedState as FSMState, OMReference } from "firmament-node-sdk"
import { Article } from "../../../domain"

// Feature imports
import { DetailsStateMachineWithDelete } from "../../../components"

declare namespace ArticleDetailsStateMachine {
    export interface ShowEdit extends FSMState {
        name: "showingEdit"
        item: Article
    }

    export type State = DetailsStateMachineWithDelete.State<Article> |
        ShowEdit
}

class ArticleDetailsStateMachine extends DetailsStateMachineWithDelete<Article, ArticleDetailsStateMachine.State> {

    /**
     * Show Edit Transition
     * [presenting] -- dismiss -> [showingEdit]
     */
    public showEdit = (item: Article): void => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingEdit", item }
            default:
                return
        }
    }

    // NOTE: id.id is treated as slug here
    public load = async (id: OMReference<Article>) => {
        switch (this.state.name) {
            case "start":
                this.state = { name: "loading" }
                break
            default:
                return
        }

        try {
            const query = Article.query()

            query
                .filter("slug", "equals", id.id)
                .include("coverImage").include("segments").include("segments.image" as any)

            await query.execute()

            if (!query.resultObjects[0]) {
                throw new Error("Requested object cannot be found")
            }

            this.success(query.resultObjects[0])
        } catch (error) {
            this.fail(error)
        }

    }

}

export default ArticleDetailsStateMachine
