// Data imports
import { FSMNamedState as FSMState, OMReference, OMIdentity } from "firmament-node-sdk"
import { SchTutorProfile } from "../../../domain"

// Feature imports
import { DetailsStateMachineWithDelete } from "../../../components"

declare namespace SchTutorProfileDetailsStateMachine {
    export interface ShowEdit extends FSMState {
        name: "showingEdit"
        item: SchTutorProfile
    }

    export interface PromptingResetPassword extends FSMState {
        name: "promptingResetPassword"
        item: SchTutorProfile
    }

    export interface PerformingResetPassword extends FSMState {
        name: "performingResetPassword"
        item: SchTutorProfile
    }
    export interface PromptingSuccessfulResetPassword extends FSMState {
        name: "promptingSuccessfulResetPassword"
        item: SchTutorProfile
    }

    export interface PromptingSuspend {
        name: "promptingSuspend"
        item: SchTutorProfile
    }
    export interface PerformingSuspend {
        name: "performingSuspend"
        item: SchTutorProfile
    }

    export interface PromptingUnsuspend {
        name: "promptingUnsuspend"
        item: SchTutorProfile
    }
    export interface PerformingUnsuspend {
        name: "performingUnsuspend"
        item: SchTutorProfile
    }

    export interface PromptingChangeEmail {
        name: "promptingChangeEmail"
        item: SchTutorProfile
    }

    export interface PerformingChangeEmail {
        name: "performingChangeEmail"
        item: SchTutorProfile
    }

    export type State = DetailsStateMachineWithDelete.State<SchTutorProfile> |
        ShowEdit |
        PromptingResetPassword |
        PerformingResetPassword |
        PromptingSuccessfulResetPassword |
        PromptingSuspend |
        PerformingSuspend |
        PromptingUnsuspend |
        PerformingUnsuspend |
        PromptingChangeEmail |
        PerformingChangeEmail
}

class SchTutorProfileDetailsStateMachine extends DetailsStateMachineWithDelete<SchTutorProfile, SchTutorProfileDetailsStateMachine.State> {

    public promptingChangeEmail = () => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "promptingChangeEmail", item: this.state.item }
                break
            default:
                return
        }
    }
    public performChangeEmail = async (email: string) => {
        switch (this.state.name) {
            case "promptingChangeEmail":
                this.state = { name: "performingChangeEmail", item: this.state.item }
                break
            default:
                return
        }

        if (!this.state.item.user.actualObject) {
            this.fail(new Error("Expecting user object"))
            return
        }

        const identities = this.state.item.user.actualObject.identities.map((identity) => identity.actualObject!).filter(Boolean)

        const userpassIdentity = identities.find((identity) => identity.identityType === "userpass")
        const emailIdentity = identities.find((identity) => identity.identityType === "email")

        if (!userpassIdentity || !emailIdentity) {
            this.fail(new Error("Expecting identity objects"))
            return
        }

        userpassIdentity.identifier = email
        emailIdentity.identifier = email

        try {
            await Promise.all([userpassIdentity.save(), emailIdentity.save()])
            this.success(this.state.item)
        } catch (error) {
            this.fail(error)
        }
    }

    public promptSUDAction = () => {
        switch (this.state.name) {
            case "presenting":
                break
            default:
                return
        }

        if (!this.state.item.user.actualObject) {
            this.fail(new Error("Expecting user object"))
            return
        }

        switch (this.state.item.user.actualObject.status) {
            case "suspended":
                this.state = { name: "promptingUnsuspend", item: this.state.item }
                break
            case "active":
                this.state = { name: "promptingSuspend", item: this.state.item }
                break
            default:
                break
        }

    }

    public performSUDAction = async () => {
        switch (this.state.name) {
            case "promptingSuspend":
                this.state = { "name": "performingSuspend", item: this.state.item }
                break
            case "promptingUnsuspend":
                this.state = { "name": "performingUnsuspend", item: this.state.item }
                break
            default:
                return
        }

        if (!this.state.item.user.actualObject) {
            this.fail(new Error("Expecting user object"))
            return
        }

        try {
            switch (this.state.name) {
                case "performingSuspend":
                    await this.state.item.user.actualObject.suspend()
                    break
                case "performingUnsuspend":
                    await this.state.item.user.actualObject.unsuspend()
                    break
            }

            this.success(this.state.item)
        } catch (error) {
            this.fail(error)
        }
    }

    public promptResetPassword = () => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "promptingResetPassword", item: this.state.item }
                break
            default:
                return
        }
    }

    public performResetPassword = (newChallenge?: string) => {
        switch (this.state.name) {
            case "promptingResetPassword":
                this.state = { name: "performingResetPassword", item: this.state.item }
                break
            default:
                return
        }

        const user = this.state.item && this.state.item.user && this.state.item.user.actualObject
        const identities = user && user.identities.map((identity) => identity.actualObject!).filter(Boolean)
        const userpassIdentity = identities && identities.find((identity) => identity.identityType === "userpass")

        if (!userpassIdentity) {
            this.success(this.state.item)
            return
        }

        userpassIdentity.challenge = newChallenge
        userpassIdentity
            .save()
            .then(() => {
                if (this.state.name !== "performingResetPassword") {
                    return
                }
                this.state = { name: "promptingSuccessfulResetPassword", item: this.state.item }
            })
            .catch((error) => {
                this.fail(error)
            })
    }

    public load = (id: OMReference<SchTutorProfile>): void => {
        switch (this.state.name) {
            case "start":
            case "presenting":
                this.state = { name: "loading" }
                break
            default:
                return
        }

        id
            .load(SchTutorProfile.query()
                .include("user")
                .include("user.identities" as any)
                .include("certificates")
                .include("moneyWallet")
                .include("photo")
                .include("introVideo")
                .include("categories")
            )
            .then((result: SchTutorProfile) => {

                if (!result.approvedAt || !(result.user.actualObject?.status === "active")) {
                    throw new Error("Profile is not available now, please try again later.")
                }

                this.success(result)
            })
            .catch((error) => {
                this.fail(error)
            })
    }

    /**
     * Show Edit Transition
     * [presenting] -- dismiss -> [showingEdit]
     */
    public showEdit = (item: SchTutorProfile): void => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingEdit", item }
            default:
                return
        }
    }

    public success = (item: SchTutorProfile): void => {
        switch (this.state.name) {
            case "loading":
            case "promptingResetPassword":
            case "performingResetPassword":
            case "promptingSuccessfulResetPassword":
            case "performingSuspend":
            case "performingUnsuspend":
            case "promptingSuspend":
            case "promptingUnsuspend":
            case "promptingChangeEmail":
            case "performingChangeEmail":
                this.state = { name: "presenting", item }
                break
            default:
                return
        }
    }

    public fail = (error: Error): void => {
        switch (this.state.name) {
            case "loading":
            case "performingResetPassword":
            case "promptingSuspend":
            case "performingSuspend":
            case "promptingUnsuspend":
            case "performingUnsuspend":
            case "promptingChangeEmail":
            case "performingChangeEmail":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

    public cancel = () => {
        switch (this.state.name) {
            case "promptingResetPassword":
            case "promptingSuccessfulResetPassword":
            case "promptingSuspend":
            case "performingSuspend":
            case "promptingUnsuspend":
            case "performingUnsuspend":
            case "promptingChangeEmail":
            case "performingChangeEmail":
            case "promptingDelete":
                this.state = { name: "presenting", item: this.state.item }
                break
            default:
                break
        }
    }

    public performDelete = async () => {
        switch (this.state.name) {
            case "promptingDelete":
                this.state = { name: "performingDelete", item: (this.state as any).item }
                break
            default:
                return
        }

        try {
            const item = this.state.item;

            await (async function preDeleteActions() {

                const user = item.user && item.user.actualObject
                const identities = user && user.identities && user.identities.map((each) => each.actualObject).filter(Boolean) as OMIdentity[]

                identities && await Promise.all(identities.map((each) => each.delete()))
                user && await user.delete()
            })()

            await item.delete()

            this.dismiss()
        } catch (error) {
            this.fail(error)
        }
    }

}

export default SchTutorProfileDetailsStateMachine
