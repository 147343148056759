import { OMUniverse } from "firmament-node-sdk";
import { Profile, User, SchTutorProfile, SchStudentProfile, CnxMemberProfile } from "../domain";

export default function getProfile(fromUser?: User) {

    function _getProfile(profile: Profile): SchTutorProfile | SchStudentProfile | CnxMemberProfile | Profile | undefined {
        switch (profile.type) {
            case SchTutorProfile.Type:
                return OMUniverse.shared.find(SchTutorProfile, profile.id)
            case SchStudentProfile.Type:
                return OMUniverse.shared.find(SchStudentProfile, profile.id)
            case CnxMemberProfile.Type:
                return OMUniverse.shared.find(CnxMemberProfile, profile.id)
            default:
                return OMUniverse.shared.find(Profile, profile.id)
        }
    }

    const user = fromUser || OMUniverse.shared.user

    if (!user) {
        return undefined
    }

    if (user.profile && user.profile.actualObject) {
        return _getProfile(user.profile.actualObject)
    }

    if (user.profiles && user.profiles[0] && user.profiles[0].actualObject) {
        return _getProfile(user.profiles[0].actualObject)
    }

    return undefined
}
