//  PmtCurrencyPair.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-08
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMObjectProperties as ObjectProperties,
    CommandManager,
    Command,
} from "firmament-node-sdk"
import { memoize } from "lodash"
import moment, { Moment } from "moment"
import {
    Object as OMObject,
    PmtCurrencyRate,
} from ".."

export interface PmtCurrencyPairProperties extends ObjectProperties {
    minimumRate?: number
    baseCurrency: string
    currency: string
    currencySymbol: string
    maximumRate?: number
    exchangeSpread: number

    exchangeRates?: Array<OMReference<PmtCurrencyRate>>

    exchangeRatesLatestRate?: number
}

class PmtCurrencyPair extends OMObject implements PmtCurrencyPairProperties {

    public static Type: string = "pmt_currency_pair"
    public static Path: string = "pmt_currency_pairs"

    public minimumRate?: number
    public baseCurrency: string
    public currency: string
    public currencySymbol: string
    public maximumRate?: number
    public exchangeSpread: number

    public exchangeRates: Array<OMReference<PmtCurrencyRate>>

    public exchangeRatesLatestRate?: number

    protected static FetchLatestRatesCommand = class FetchLatestRatesCommand extends Command<PmtCurrencyPair> {
        public static readonly Path = "fetch_latest_rates"
    }

    public static fetchLatestRates() {
        try {
            const command = new PmtCurrencyPair.FetchLatestRatesCommand(PmtCurrencyPair)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    constructor(object: PmtCurrencyPairProperties) {
        super(object)

        this.minimumRate = object.minimumRate
        this.baseCurrency = object.baseCurrency
        this.currency = object.currency
        this.currencySymbol = object.currencySymbol
        this.maximumRate = object.maximumRate
        this.exchangeSpread = object.exchangeSpread

        this.exchangeRates = object.exchangeRates ? object.exchangeRates.map((item) => new OMReference(PmtCurrencyRate, item)) : []

        this.exchangeRatesLatestRate = object.exchangeRatesLatestRate
    }

    public static findByCurrency = memoize((currency: string): PmtCurrencyPair | undefined => {
        const raw = Object.values(OMUniverse.shared.cache.getState() as any).find((each: any) => each.type === PmtCurrencyPair.Type && each.currency === currency) as any

        if (!raw) {
            // fallback to USD
            return PmtCurrencyPair.findByCurrency("USD")
        }

        return OMUniverse.shared.find(PmtCurrencyPair, raw.id)
    })
}

export default PmtCurrencyPair
