import {
    FSMNamedState as FSMState,
    ObservableFiniteStateMachine,
    OMObject,
} from "firmament-node-sdk"

declare namespace ListStateMachine {

    /* SECTION: Query Parameters */

    export interface Sort<T extends OMObject> {
        sortBy: keyof T
        sortDirection: "ASC" | "DESC"
    }

    export interface QueryParams<T extends OMObject> extends Sort<T> {
        currentPage: number
    }

    /* SECTION: State */

    export interface Loading<T extends OMObject> extends FSMState {
        name: "loading",
        items: T[],
        totalItems: number
    }

    export type State<T extends OMObject> = FSMState.Start |
        Loading<T> |
        FSMState.ShowingError |
        FSMState.PresentingList<T> |
        FSMState.ShowingDetails<T> |
        FSMState.Completed
}

abstract class ListStateMachine<T extends OMObject, State extends FSMState>
    extends ObservableFiniteStateMachine<ListStateMachine.State<T> | State> {

    constructor() {
        super({ name: "start" })
    }

    /**
     * Load Transition
     * [start] | [showingError] -- load -> [loading]
     */
    public abstract load(): void

    /**
     * Success Transition
     * [loading] -- success -> [presenting]
     */
    public success(items: T[], totalItems: number = 0): void {
        switch (this.state.name) {
            case "loading":
                this.state = { name: "presenting", items, totalItems }
                break
            default:
                return
        }
    }

    /**
     * Fail Transition
     * [loading] -- fail -> [showingError]
     */
    public fail(error: Error): void {
        switch (this.state.name) {
            case "loading":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

    /**
     * Dismiss Transition
     * [presenting] | [showingError] -- dismiss -> completed
     */
    public dismiss(): void {
        switch (this.state.name) {
            case "presenting":
            case "showingError":
                this.state = { name: "completed" }
                break
            default:
                return
        }
    }
}

export default ListStateMachine
