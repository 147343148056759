// Data imports
import {
    FSMNamedState as FSMState,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import uuid from "uuid/v4"
import moment from "moment"

import { CnxBooking, CnxVideoRoom, CnxConsumerProfile, CnxConsumerPurchase, CnxMeeting } from "../../../domain"

// Feature imports
import { FormStateMachine } from "../../../components"
import getProfile from "../../../helpers/Profile"
import todayOperatingHours from "../../../helpers/OperatingHour"

declare namespace CnxBookingAskFormStateMachine {

    export interface Presenting {
        name: "presenting"
        item?: CnxBooking
    }

    export interface Saving {
        name: "saving"
        item: CnxBooking
    }

    export interface ShowingError extends FSMState.ShowingError {
        item?: CnxBooking
    }

    export interface ShowingMeetingDetail {
        name: "showingMeetingDetail"
        meeting: CnxMeeting
    }

    export type State = FSMState.Start |
        FSMState.Loading |
        ShowingError |
        Presenting |
        Saving |
        FSMState.Completed |
        ShowingMeetingDetail

}

class CnxBookingAskFormStateMachine
    extends FormStateMachine<CnxBooking, CnxBookingAskFormStateMachine.State> {

    /* SECTION: Override */
    public load = (ref?: OMReference<CnxBooking>): void => {
        switch (this.state.name) {
            case "start":
                this.state = { name: "loading" }
                break
            default:
                return
        }

        if (!ref) {
            this.state = { name: "presenting" }
            return
        }

        if (ref.actualObject !== undefined) {
            this.success(ref.actualObject)
        } else {
            ref.load()
                .then((item: CnxBooking) => this.success(item))
                .catch(this.fail)
        }
    }

    public save = async (item: CnxBooking) => {
        switch (this.state.name) {
            case "presenting":
            case "showingError":
                this.state = { name: "saving", item }
                break
            default:
                return
        }

        try {

            const operatingHours = todayOperatingHours()
            if (!operatingHours.isOperating()) {
                throw new Error(`We are currently closed. Please come back later at ${operatingHours.toString()}`)
            }

            const meeting = item.meeting && item.meeting.actualObject

            if (!meeting) {
                throw new Error("Expecting meeting object to be defined.")
            }

            const consumerProfile = getProfile() && new OMReference(CnxConsumerProfile, getProfile()!.id).actualObject
            const sessionWallet = consumerProfile && consumerProfile.sessionWallet

            if (!consumerProfile) {
                throw new Error("Expecting consumerProfile object")
            }

            if (!sessionWallet) {
                throw new Error("Expecting sessionWallet object")
            }

            const isFree = sessionWallet.actualObject?.transactionsCount === 0


            const subject = meeting.serviceCategories.find((each) => each.actualObject?.categoryType === "SUBJECT")?.actualObject
            const grade = subject?.parentCategory?.actualObject
            const slug = `${consumerProfile.slug && consumerProfile.slug.toUpperCase() || "N/A"}-${subject?.slug?.toUpperCase() || "N/A"}-${grade?.slug?.toUpperCase() || "N/A"}-${moment().format("YYYYMMDDHHmmss")}`
            meeting.slug = slug


            const serviceTier = meeting.serviceTier.actualObject

            if (!serviceTier) {
                throw new Error("Service tier not found.")
            }


            // NOTE: Ensure that wallet has sufficient balance
            if (!isFree && (sessionWallet.actualObject?.transactionsAvailableBalance ?? 0) < serviceTier.serviceGrossCents) {
                throw new Error("Insufficient session balance.")
            }





            const purchase = new CnxConsumerPurchase({
                slug: slug,

                amountInCents: isFree ? 0 : serviceTier.serviceGrossCents,
                currency: "SSN",
                transactionType: "CREDIT",
                transactionStatus: "APPROVED",

                wallet: sessionWallet,

                meeting: item.meeting
            })
            meeting.purchases = [new OMReference(CnxConsumerPurchase, purchase.id)]



            const videoRoom = new CnxVideoRoom({
                slug: uuid(),
                meeting: item.meeting,
            })
            meeting.videoRoom = new OMReference(CnxVideoRoom, videoRoom.id)



            meeting.bookings = []
            OMUniverse.shared.touch([item, meeting, videoRoom, purchase])



            await meeting.save()

            item.purchase = new OMReference(CnxConsumerPurchase, purchase.id)
            meeting.bookings = [new OMReference(CnxBooking, item.id)]

            meeting.permissions = 111
            OMUniverse.shared.touch(meeting)

            await item.save()

            await meeting.setCategories(meeting.serviceCategories)
            await meeting.matchProviders && meeting.setMatchProviders(meeting.matchProviders)

            await meeting.addConsumer(new OMReference(CnxConsumerProfile, consumerProfile.id))

            await meeting.notifyMatchProviders(meeting.matchProviders)

            // this.dismiss()
            this.state = { name: "showingMeetingDetail", meeting }
        } catch (error) {
            this.fail(error)
        }
    }

    public back = () => {
        switch (this.state.name) {
            case "showingError":
                this.state = { name: "presenting", item: (this.state as any).item as any }
                break
            default:
                return
        }
    }
}

export default CnxBookingAskFormStateMachine
