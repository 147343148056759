import { OMFile, OMFileProperties, OMReference } from "firmament-node-sdk"

import { Article, ArticleMedia } from "."

export interface ArticleImageProperties extends OMFileProperties {
    articleMedia?: OMReference<ArticleMedia>
    article?: OMReference<Article>
}

class ArticleImage extends OMFile implements ArticleImageProperties {

    public static Type: string = "article_image"
    public static Path: string = "article_images"

    public articleMedia?: OMReference<ArticleMedia>
    public article?: OMReference<Article>

    constructor(object: ArticleImageProperties) {
        super(object)
        this.articleMedia = object.articleMedia && new OMReference(ArticleMedia, object.articleMedia)
        this.article = object.article && new OMReference(Article, object.article)
    }

}

export default ArticleImage
