//  CnxServiceCategory.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxBooking,
    CnxConsumerProfile,
    CnxProviderProfile,
    CnxArticle,
    CnxBid,
    GenImage,
    Setting,
} from ".."
import CnxServiceTier from "./CnxServiceTier"

type CategoryType = "SUBJECT" | "QUALIFICATION" | "LANGUAGE" | "GRADE" | "COUNTRY" | "GENDER" | "AFFILIATION" | "CAMPAIGN"

export interface CnxServiceCategoryProperties extends DomainObjectProperties {
    categoryType?: CategoryType
    isPublished?: boolean
    sequence?: number
    subtitle?: string
    grouping?: string
    backgroundColor?: string
    nominalValue?: number

    booking?: Array<OMReference<CnxBooking>>
    consumers?: Array<OMReference<CnxConsumerProfile>>
    parentCategory?: OMReference<CnxServiceCategory>
    subcategories?: Array<OMReference<CnxServiceCategory>>
    providers?: Array<OMReference<CnxProviderProfile>>
    serviceArticle?: Array<OMReference<CnxArticle>>
    bid?: Array<OMReference<CnxBid>>
    applicants?: Array<OMReference<CnxProviderProfile>>
    backgroundPhoto?: OMReference<GenImage>
    icon?: OMReference<GenImage>
    defaultTier?: OMReference<CnxServiceTier>
    availableTiers?: OMReference<CnxServiceTier>[]

    meetingCount?: number
    providerCount?: number
    consumerCount?: number
    serviceArticleCount?: number
    availableTierCount?: number
    availableTierSet?: string[]
}

class CnxServiceCategory extends DomainObject implements CnxServiceCategoryProperties {

    public static Type: string = "cnx_service_category"
    public static Path: string = "cnx_service_categories"

    public categoryType?: CategoryType
    public isPublished?: boolean
    public sequence?: number
    public subtitle?: string
    public grouping?: string
    public backgroundColor?: string
    public nominalValue?: number

    public booking: Array<OMReference<CnxBooking>>
    public consumers: Array<OMReference<CnxConsumerProfile>>
    public parentCategory?: OMReference<CnxServiceCategory>
    public subcategories: Array<OMReference<CnxServiceCategory>>
    public providers: Array<OMReference<CnxProviderProfile>>
    public serviceArticle: Array<OMReference<CnxArticle>>
    public bid: Array<OMReference<CnxBid>>
    public applicants: Array<OMReference<CnxProviderProfile>>
    public backgroundPhoto?: OMReference<GenImage>
    public icon?: OMReference<GenImage>
    public defaultTier?: OMReference<CnxServiceTier>
    public availableTiers?: OMReference<CnxServiceTier>[]

    public meetingCount: number
    public providerCount: number
    public consumerCount: number
    public serviceArticleCount: number
    public availableTierCount: number
    public availableTierSet: string[]

    constructor(object: CnxServiceCategoryProperties) {
        super(object)

        this.categoryType = object.categoryType
        this.isPublished = object.isPublished
        this.sequence = object.sequence
        this.subtitle = object.subtitle
        this.grouping = object.grouping
        this.backgroundColor = object.backgroundColor
        this.nominalValue = object.nominalValue

        this.booking = object.booking ? object.booking.map((item) => new OMReference(CnxBooking, item)) : []
        this.consumers = object.consumers ? object.consumers.map((item) => new OMReference(CnxConsumerProfile, item)) : []
        this.parentCategory = object.parentCategory && new OMReference(CnxServiceCategory, object.parentCategory)
        this.subcategories = object.subcategories ? object.subcategories.map((item) => new OMReference(CnxServiceCategory, item)) : []
        this.providers = object.providers ? object.providers.map((item) => new OMReference(CnxProviderProfile, item)) : []
        this.serviceArticle = object.serviceArticle ? object.serviceArticle.map((item) => new OMReference(CnxArticle, item)) : []
        this.bid = object.bid ? object.bid.map((item) => new OMReference(CnxBid, item)) : []
        this.applicants = object.applicants ? object.applicants.map((item) => new OMReference(CnxProviderProfile, item)) : []
        this.backgroundPhoto = object.backgroundPhoto && new OMReference(GenImage, object.backgroundPhoto)
        this.icon = object.icon && new OMReference(GenImage, object.icon)
        this.defaultTier = object.defaultTier && new OMReference(CnxServiceTier, object.defaultTier)
        this.availableTiers = object.availableTiers ? object.availableTiers.map((item) => new OMReference(CnxServiceTier, item)) : []

        this.meetingCount = object.meetingCount || 0
        this.providerCount = object.providerCount || 0
        this.consumerCount = object.consumerCount || 0
        this.serviceArticleCount = object.serviceArticleCount || 0
        this.availableTierCount = object.availableTierCount || 0
        this.availableTierSet = object.availableTierSet || []
    }


    public get serviceTier() {
        return this.defaultTier?.actualObject || OMUniverse.shared.find(CnxServiceTier, Setting.find("cnx_onboarding_consumer_default_service_tier")?.value!)
    }

    public get lowestPricedTier(): CnxServiceTier | undefined {
        return this.availableTiers?.reduce<CnxServiceTier | undefined>((map, each) => {
            if (map === undefined || each.actualObject!.servicePriceCents < map.servicePriceCents) {
                return each.actualObject
            } else {
                return map
            }
        }, undefined)
    }
}

export default CnxServiceCategory
