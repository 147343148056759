import React from "react"
import uuid from "uuid/v4"

export interface CarouselComponentProps {
    id?: string
    slides: React.ReactNode[]
}

export interface CarouselComponentState {
}

class Carousel extends React.Component<CarouselComponentProps, CarouselComponentState> {

    private id: string

    constructor(props: CarouselComponentProps) {
        super(props)
        this.id = this.props.id || uuid()
        this.state = {}
    }

    // public componentDidMount() {
    //     ($(`#${this.id}`) as any).carousel()
    // }

    public componentDidUpdate(prevProps: CarouselComponentProps, prevState: CarouselComponentState) {
        //
    }

    public render() {
        return (
            <div id={this.id} className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    {this.props.slides.map((slide, index) => (
                        <div className={index === 0 ? "carousel-item active" : "carousel-item"} key={index}>
                            {slide}
                        </div>
                    ))}
                </div>

                {/* tslint:disable-next-line */}
                <a className="d-none carousel-control-prev" href={`#${this.id}`} role="button" data-slide="prev" style={{ backgroundColor: "rgba(0,0,0,0.1)" }} onClick={this.preventDefault}>
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                </a>

                {/* tslint:disable-next-line */}
                <a className="d-none carousel-control-next" href={`#${this.id}`} role="button" data-slide="next" style={{ backgroundColor: "rgba(0,0,0,0.1)" }} onClick={this.preventDefault}>
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                </a>
            </div>
        )
    }

    private preventDefault = (event: React.MouseEvent<HTMLAnchorElement>) => {
        // event.preventDefault()
    }
}

export default Carousel
