//  Command.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-03-31
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMObjectProperties as ObjectProperties,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    Object,
} from ".."

export interface CommandProperties extends ObjectProperties {
    status?: string
    receiverUuid: string
    receiverType: string

}

class Command extends Object implements CommandProperties {

    public static Type: string = "om_command"
    public static Path: string = "om_commands"

    public status?: string
    public receiverUuid: string
    public receiverType: string


    constructor(object: CommandProperties) {
        super(object)

        this.status = object.status
        this.receiverUuid = object.receiverUuid
        this.receiverType = object.receiverType

    }
}

export default Command
