type Debounce<T = any> = (f: T, milliseconds: number) => T

const debounce: Debounce = (f, miliseconds) => {
    let worker: NodeJS.Timeout | undefined

    return (...arg: ReturnType<typeof f>) => new Promise((resolve) => {
        if (worker) {
            clearTimeout(worker)
        }
        worker = setTimeout(() => {
            resolve(f(...arg))
        }, miliseconds)
    })
}

export default debounce
