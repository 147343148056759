//  CnxPointWallet.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-28
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    WltWallet,
    WltWalletProperties,
    WltTransaction,
    CnxMemberProfile,
} from ".."

export interface CnxPointWalletProperties extends WltWalletProperties {

    pointOwner: OMReference<CnxMemberProfile>
}

class CnxPointWallet extends WltWallet implements CnxPointWalletProperties {

    public static Type: string = "cnx_point_wallet"
    public static Path: string = "cnx_point_wallets"


    public pointOwner: OMReference<CnxMemberProfile>

    constructor(object: CnxPointWalletProperties) {
        super(object)


        this.pointOwner = new OMReference(CnxMemberProfile, object.pointOwner)
    }
}

export default CnxPointWallet
