import * as React from "react"

import { Logo } from "./Logo"
import { BuildNumber } from "../components"
import { Footnote, Subheadline } from "./deprecated-components"
import { Setting } from "../domain"

export interface ContactUsProps {

}

export interface ContactUsState {

}

class ContactUs extends React.PureComponent<ContactUsProps, ContactUsState> {
    public render() {
        return (
            <>
                <Subheadline className="text-tertiary mb-4p/x">Need urgent assistance?</Subheadline><br />
                <a target="_blank" href={`https://wa.me/${Setting.find("sch_support_whatsapp_number")?.value}`} className="text-reset">
                    <span className="fa fa-fw fa-whatsapp text-success" />
                    <Subheadline className="text-dark">Send us a message on WhatsApp</Subheadline>
                </a>
            </>
        )
    }
}

export { ContactUs }
