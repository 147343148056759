import React from "react"
import PathToRegexp from "path-to-regexp"
import { useHistory, useLocation } from "react-router"
import getProfile from "../../../helpers/Profile"
import { CnxMeeting, CnxRating, CnxServiceCategory, SchStudentProfile, SchTutorProfile, WltTransaction, WltWallet } from "../../../domain"
import { Link } from "react-router-dom"

const BackButton = () => {
    const history = useHistory()
    const location = useLocation()

    const profile = React.useMemo(() => getProfile(), [])

    const shouldHideBackButton = React.useMemo(() => {
        switch (true) {
            case PathToRegexp(`/${SchTutorProfile.Path}`).test(location.pathname):
            case PathToRegexp(`/${CnxMeeting.Path}`).test(location.pathname):

            case PathToRegexp(`/${CnxServiceCategory.Path}/:id`).test(location.pathname):
            case PathToRegexp(`/${WltWallet.Path}/:id`).test(location.pathname):

            case PathToRegexp(`/${WltTransaction.Path}`).test(location.pathname) && profile instanceof SchStudentProfile:

            case PathToRegexp(`/${SchStudentProfile.Path}/:id/menu`).test(location.pathname):
            case PathToRegexp(`/${SchTutorProfile.Path}/:id/menu`).test(location.pathname):
            case PathToRegexp(`/identities/register`).test(location.pathname) && window.location.search.includes("utm_campaign"):
                return true
            default:
                return false
        }
    }, [location])

    if (history.length < 1 || shouldHideBackButton) {
        return null
    }

    // Render "Close button" in tutor session complete and create new rating screen
    // cnx_meetings/:id/cnx_ratings/new
    // wlt_transactions/...&source=endmeeting
    if (
        PathToRegexp(`/${CnxMeeting.Path}/:id/${CnxRating.Path}/new`).test(location.pathname) ||
        (PathToRegexp(`/${WltTransaction.Path}/:id`).test(location.pathname) && location.search.includes("source=endmeeting"))
    ) {
        return (
            <Link to="/">
                <button role="button" className="btn btn-light" style={{ padding: "5px 20px 5px 12px" }}>
                    <span className="fa fa-fw fa-close" />
                    Close
                </button>
            </Link>
        )
    }

    return (
        <button className="btn btn-light" onClick={() => history.goBack()} style={{ padding: "5px 20px 5px 12px" }}>
            <span className="fa fa-fw fa-chevron-left" />
            Back
        </button>
    )
}

export default BackButton
