export default class HmapCoder {
    public value?: Record<string, string>

    constructor(private hmap?: string) {
        this.decode()
    }

    private decode() {
        if (!this.hmap) {
            return
        }

        this.value = Object.fromEntries(
            this.hmap
                .split(", ")
                .map((kvPair) => kvPair.split("=>").map((each) => each.substring(1, each.length - 1)))
        )
    }

    encode() {
        if (!this.value) {
            return undefined
        }

        return Object.entries(this.value).map(([key, value]) => `"${key}"=>"${value}"`).join(", ")
    }

    toJSON() {
        return this.encode()
    }
}
