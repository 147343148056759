// Data Imports
import { OMReference, OMUniverse } from "firmament-node-sdk"
import moment from "moment"
import * as React from "react"
import { RouteComponentProps } from "react-router"
import inflection from "inflection"

// Shared views
import {
    AsyncEntitySelectHeader,
    ErrorAlert,
    LoadingButton,
    ModalSpinner,
    Caption1,
    Headline,
    Title3,
    Form,
    Subheadline,
} from "../../../components"

import { CnxMemberProfile, CnxConsumerRefund, CnxMeeting, CnxProviderChargeback, CnxRating, Setting, WltTransaction } from "../../../domain"

// Feature imports
import CnxRatingFormStateMachine from "./CnxRatingFormStateMachine"
import CnxRatingFieldset from "./elements/CnxRatingFieldset"
import { AvatarImage } from "../../../airframe-components/Avatar/AvatarImage"
import { StarRating } from "../../../airframe-components"
import { Link } from "react-router-dom"

export interface CnxRatingFormComponentProps extends RouteComponentProps<{ id?: string }> {
    machine: CnxRatingFormStateMachine

    submitter?: OMReference<CnxMemberProfile>
    refund?: OMReference<CnxConsumerRefund>
    meeting?: OMReference<CnxMeeting>
    providerChargeback?: OMReference<CnxProviderChargeback>
}

export interface CnxRatingFormComponentState {
    machineState: CnxRatingFormStateMachine.State

    cnxRating?: CnxRating

    submitterRef?: OMReference<CnxMemberProfile>
    meetingRef?: OMReference<CnxMeeting>
}

class CnxRatingForm extends React.Component<CnxRatingFormComponentProps, CnxRatingFormComponentState> {
    constructor(props: CnxRatingFormComponentProps) {
        super(props)

        this.state = {
            machineState: this.props.machine.state,

            submitterRef: this.props.submitter,
            meetingRef: this.props.meeting,
        }

        this.props.machine.subscribe(this.subscriptionCallback)
    }

    public componentDidMount() {
        const ref = this.props.match.params.id ? new OMReference(CnxRating, this.props.match.params.id) : undefined
        this.props.machine.load(ref)

        if (this.props.meeting && !this.props.meeting.actualObject) {
            new OMReference(CnxMeeting, this.props.meeting.id).load(
                CnxMeeting.query()
                    .include("provider")
                    .include("provider.photo" as any)
                    .include("serviceCategories")
            ).then(() => this.forceUpdate())
        }
    }

    public componentWillUnmount() {
        this.props.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState

        switch (machineState.name) {
            case "start":
            case "loading":
                return <ModalSpinner />
            case "presenting":
            case "saving":
            case "showingError":

                return (
                    <div className="container-420px position-relative">
                        {machineState.name === "showingError" && (
                            <ErrorAlert error={machineState.error} />
                        )}

                        <Headline className="d-block text-center mb-12px">{this.props.meeting?.actualObject?.serviceCategories.find((each) => each.actualObject?.categoryType === "SUBJECT")?.actualObject?.title}</Headline>

                        <Subheadline className="d-block text-center text-secondary mb-20px">
                            {`${this.props.meeting?.actualObject?.actualStartAt?.format("DD-MM-YYYY")} • ${this.props.meeting?.actualObject?.actualStartAt?.format("hh:mma")} - ${this.props.meeting?.actualObject?.actualEndAt?.format("hh:mma") || moment().format("hh:mma")}`}
                        </Subheadline>

                        <Form onSubmit={this.onSubmit}>
                            <CnxRatingFieldset meeting={this.props.meeting} onChange={this.onCnxRatingChange} />

                            <div className="d-flex justify-content-center p-3">
                                <LoadingButton
                                    type="submit"
                                    isLoading={machineState.name === "saving"}
                                    className="btn btn-block btn-primary"
                                >
                                    <Headline>
                                        Submit
                                    </Headline>
                                </LoadingButton>
                            </div>

                        </Form>
                    </div>
                )
            case "completed":
                if (this.props.match.url.includes(`/${WltTransaction.Path}/`)) {
                    this.props.history.goBack()
                } else {
                    this.props.history.push("/")
                }
                return null
        }
    }

    private subscriptionCallback = (machineState: CnxRatingFormStateMachine.State) => {
        this.setState({
            machineState,

            cnxRating: this.state.cnxRating || machineState.name === "presenting" && machineState.item || undefined,
        })
    }

    private checkFormComplete = (): boolean => {
        if (!this.state.cnxRating) {
            return false
        }

        return true
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()
        this.save()
    }

    private onSubmitterChange = (submitterRef?: OMReference<CnxMemberProfile>) => {
        this.setState({ submitterRef })
    }

    private onMeetingChange = (meetingRef?: OMReference<CnxMeeting>) => {
        this.setState({ meetingRef })
    }

    private onCnxRatingChange = (cnxRating?: CnxRating) => {
        this.setState({ cnxRating })
    }

    private onChange = (keyPath: keyof CnxRatingFormComponentState) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({
                [keyPath]: event.target.value,
            } as any)
        }

    private save = () => {
        if (!this.checkFormComplete) {
            return
        }

        if (!this.state.cnxRating) {
            return
        }

        this.props.machine.save(this.state.cnxRating)
    }
}

export default CnxRatingForm
