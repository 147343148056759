import * as React from "react"
import qs from "qs"

import { CnxServiceCategory } from "../../../../domain"
import { OMUniverse } from "firmament-node-sdk"

interface AffiliationContext {
    affiliation?: CnxServiceCategory
    campaign?: CnxServiceCategory
}

export const AffiliationContext = React.createContext<AffiliationContext>(null as any)

export const useAppState = () => {
    const affiliationContext = React.useContext(AffiliationContext)
    return affiliationContext
}

export const AffiliationProvider: React.FunctionComponent = (props) => {
    const [affiliation, setAffiliation] = React.useState<CnxServiceCategory | undefined>(OMUniverse.shared.find(CnxServiceCategory, window.localStorage.getItem("affiliation") || ""))
    const [campaign, setCampaign] = React.useState<CnxServiceCategory>()


    // Cookie is consumed by create tutor profile in order to display affiliation
    React.useEffect(() => {
        if (window.location.pathname !== "/identities/register") {
            return
        }

        if (affiliation?.id) {
            window.localStorage.setItem("affiliation", affiliation.id)
        } else {
            window.localStorage.removeItem("affiliation")
        }
    }, [affiliation])

    React.useEffect(() => {
        if (window.location.pathname !== "/identities/register") {
            return
        }

        const queryString = qs.parse(window.location.search.slice(1))

        if (!queryString.utm_campaign) {
            return
        }
        const utmCampaign = queryString.utm_campaign as string

        let slugs: string[]
        switch (true) {
            case typeof utmCampaign === "string":
                slugs = utmCampaign.split(",")
                break
            case Array.isArray(utmCampaign):
                slugs = utmCampaign[0].split(",")
                break
            default:
                setAffiliation(undefined)
                setCampaign(undefined)
                return
        }

        ; (async () => {

            const query = CnxServiceCategory.query()
                // .filter("categoryType", "like", "AFFILIATION")
                .include("icon")
                .include("backgroundPhoto")
                .limit(50)

            query.filterGroup(slugs.map((slug) => ["slug", "equals", slug]), "OR")

            await query.execute()

            const affiliation = query.resultObjects?.find((category) => category.categoryType === "AFFILIATION")
            const campaign = query.resultObjects?.find((category) => category.categoryType === "CAMPAIGN")

            if (!affiliation && !campaign) {
                return null
            }

            setAffiliation(affiliation)
            setCampaign(campaign)
        })()
    }, [window.location.search])


    return (
        <AffiliationContext.Provider
            value={{ affiliation, campaign }}
        >
            {props.children}
        </AffiliationContext.Provider>
    )
}

