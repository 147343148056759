//  CnxTopupPackage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-07-23
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxTopupPayment,
} from ".."
import { Publishable } from "../common"

export interface CnxTopupPackageProperties extends DomainObjectProperties, Publishable {
    finalPriceCents: number
    originalPriceCents: number
    currency: string
    limitPerUser?: number
    sequence?: number
    writeup?: string

    purchases?: Array<OMReference<CnxTopupPayment>>
}

class CnxTopupPackage extends DomainObject implements CnxTopupPackageProperties, Publishable {

    public static Type: string = "cnx_topup_package"
    public static Path: string = "cnx_topup_packages"

    public finalPriceCents: number
    public originalPriceCents: number
    public currency: string
    public unpublishAt?: Moment
    public publishAt?: Moment
    public limitPerUser?: number
    public sequence?: number
    public writeup?: string

    public purchases: Array<OMReference<CnxTopupPayment>>

    constructor(object: CnxTopupPackageProperties) {
        super(object)

        this.finalPriceCents = object.finalPriceCents
        this.originalPriceCents = object.originalPriceCents
        this.currency = object.currency
        this.unpublishAt = object.unpublishAt && moment(object.unpublishAt)
        this.publishAt = object.publishAt && moment(object.publishAt)
        this.limitPerUser = object.limitPerUser
        this.sequence = object.sequence
        this.writeup = object.writeup

        this.purchases = object.purchases ? object.purchases.map((item) => new OMReference(CnxTopupPayment, item)) : []
    }
}

export default CnxTopupPackage
