//  OmFactory.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-03-31
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMObjectProperties as ObjectProperties
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    Object,
} from ".."

export interface OmFactoryProperties extends ObjectProperties {
    defaultParentAttribute?: string
    instanceOwnerUuid?: string
    factoryType: string
    instancePermissions?: number
    instanceGroupUuid?: string

}

class OmFactory extends Object implements OmFactoryProperties {

    public static Type: string = "om_factory"
    public static Path: string = "om_factories"

    public defaultParentAttribute?: string
    public instanceOwnerUuid?: string
    public factoryType: string
    public instancePermissions?: number
    public instanceGroupUuid?: string


    constructor(object: OmFactoryProperties) {
        super(object)

        this.defaultParentAttribute = object.defaultParentAttribute
        this.instanceOwnerUuid = object.instanceOwnerUuid
        this.factoryType = object.factoryType
        this.instancePermissions = object.instancePermissions
        this.instanceGroupUuid = object.instanceGroupUuid

    }
}

export default OmFactory
