import React, { Component } from "react"
import PropTypes from "prop-types"

class Pagination extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: parseFloat(this.props.currentPage) || 0
        }

        this.DEFAULT_MAX_PAGES = 9
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentPage !== this.state.currentPage) {
            this.setState({ "currentPage": parseFloat(nextProps.currentPage) || 0 })
        }
    }

    render() {
        const maxPages = this.props.maxPages || this.DEFAULT_MAX_PAGES
        const numberOfPages = Math.ceil(this.props.totalItems / this.props.itemsPerPage)

        if (numberOfPages <= 1) {
            return null
        }

        const lowerBound = Math.max(0, this.state.currentPage - Math.floor(maxPages / 2))
        const upperBound = Math.min(lowerBound + maxPages, numberOfPages) || 0

        const isTruncatedLeft = lowerBound > 0
        const isTruncatedRight = upperBound < numberOfPages

        const pages = Array.of(...Array(upperBound - lowerBound)).map((_, i) => i + lowerBound)

        return (
            <nav aria-label="Pagination" className={this.props.className}>
                <ul className="pagination">
                    <li className={`page-item ${this.state.currentPage === 0 ? "disabled" : ""}`}><button type="button" className="page-link" onClick={() => this.decrementPage()}>Previous</button></li>
                    {isTruncatedLeft && (
                        <li key={0} className={`page-item ${this.state.currentPage === 0 ? "active" : ""}`}>
                            <button type="button" className="page-link" onClick={() => {
                                this.setState({ "currentPage": 0 })
                                if (this.props.onPageChanged) {
                                    this.props.onPageChanged(0)
                                }
                            }}>{1}</button>
                        </li>
                    )}
                    {isTruncatedLeft && <li className={"page-item disabled"}><button type="button" disabled className="page-link">...</button></li>}
                    {
                        pages.map((page) =>
                            <li key={page} className={`page-item ${this.state.currentPage === page ? "active" : ""}`}>
                                <button type="button" className="page-link" onClick={() => {
                                    this.setState({ "currentPage": page })
                                    if (this.props.onPageChanged) {
                                        this.props.onPageChanged(page)
                                    }
                                }}>{page + 1}</button>
                            </li>
                        )
                    }
                    {isTruncatedRight && <li className={"page-item disabled"}><button type="button" disabled className="page-link">...</button></li>}
                    {isTruncatedRight && (
                        <li key={numberOfPages - 1} className={`page-item ${this.state.currentPage === numberOfPages - 1 ? "active" : ""}`}>
                            <button type="button" className="page-link" onClick={() => {
                                this.setState({ "currentPage": numberOfPages - 1 })
                                if (this.props.onPageChanged) {
                                    this.props.onPageChanged(numberOfPages - 1)
                                }
                            }}>{numberOfPages}</button>
                        </li>
                    )}
                    <li className={`page-item ${this.state.currentPage === numberOfPages - 1 ? "disabled" : ""}`}><button type="button" className="page-link" onClick={() => this.incrementPage()}>Next</button></li>
                </ul>
            </nav>
        )
    }

    incrementPage() {
        const numberOfPages = Math.ceil(this.props.totalItems / this.props.itemsPerPage) - 1
        this.setState({ "currentPage": Math.min(this.state.currentPage + 1, numberOfPages) }, () => {
            if (this.props.onPageChanged) {
                this.props.onPageChanged(this.state.currentPage)
            }
        })
    }

    decrementPage() {
        this.setState({ "currentPage": Math.max(this.state.currentPage - 1, 0) }, () => {
            if (this.props.onPageChanged) {
                this.props.onPageChanged(this.state.currentPage)
            }
        })
    }
}

Pagination.propTypes = {
    "totalItems": PropTypes.number.isRequired,
    "itemsPerPage": PropTypes.number.isRequired,
    "onPageChanged": PropTypes.func,
    "className": PropTypes.string,
    "maxPages": PropTypes.number
}

export default Pagination
