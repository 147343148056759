// Data imports
import { FSMNamedState as FSMState, OMReference } from "firmament-node-sdk"
import { CnxRating } from "../../../domain"

// Feature imports
import { DetailsStateMachineWithDelete } from "../../../components"

declare namespace CnxRatingDetailsStateMachine {
    export interface ShowEdit extends FSMState {
        name: "showingEdit"
        item: CnxRating
    }

    export type State = DetailsStateMachineWithDelete.State<CnxRating> |
        ShowEdit
}

class CnxRatingDetailsStateMachine extends DetailsStateMachineWithDelete<CnxRating, CnxRatingDetailsStateMachine.State> {

    /**
     * Show Edit Transition
     * [presenting] -- dismiss -> [showingEdit]
     */
    public showEdit = (item: CnxRating): void => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingEdit", item }
            default:
                return
        }
    }

}

export default CnxRatingDetailsStateMachine
