//  WltWallet.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-04-08
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    Command,
    CommandManager,
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    WltTransaction,
    Profile,
} from ".."

export interface WltWalletProperties extends DomainObjectProperties {
    currency: string

    transactions?: Array<OMReference<WltTransaction>>
    profile?: OMReference<Profile>

    transactionsAllTimeEarned?: number
    transactionsAvailableBalance?: number
    transactionsFloatingBalance?: number

    transactionsCount?: number
}

class WltWallet extends DomainObject implements WltWalletProperties {

    public static Type: string = "wlt_wallet"
    public static Path: string = "wlt_wallets"

    public currency: string

    public transactions: Array<OMReference<WltTransaction>>
    public profile?: OMReference<Profile>

    public transactionsAllTimeEarned: number
    public transactionsAvailableBalance: number
    public transactionsFloatingBalance: number

    public transactionsCount: number




    protected static SummarizeMonthlyCommand = class SummarizeMonthlyCommand extends Command<WltWallet> {
        public static readonly Path = "summarize_monthly"

        public constructor(
            receiver: WltWallet,
            public startDate: Moment,
            public endDate: Moment,
        ) {
            super(receiver)
        }
    }

    public summarizeMonthly(startDate: Moment, endDate: Moment) {
        try {
            const command = new WltWallet.SummarizeMonthlyCommand(this, startDate, endDate)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }




    constructor(object: WltWalletProperties) {
        super(object)

        this.currency = object.currency

        this.transactions = object.transactions ? object.transactions.map((item) => new OMReference(WltTransaction, item)) : []
        this.profile = object.profile && new OMReference(Profile, object.profile)

        this.transactionsAllTimeEarned = object.transactionsAllTimeEarned || 0
        this.transactionsAvailableBalance = object.transactionsAvailableBalance || 0
        this.transactionsFloatingBalance = object.transactionsFloatingBalance || 0

        this.transactionsCount = object.transactionsCount || 0
    }
}

export default WltWallet
