//  Profile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMObjectProperties as ObjectProperties,
    CommandManager,
    Command,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    Object,
    Article,
    User,
    ProfilePhoto,
    PmtPayment,
} from ".."

export interface ProfileProperties extends ObjectProperties {
    stripeCustomerId?: string
    name?: string

    article?: Array<OMReference<Article>>
    user: OMReference<User>
    photo?: OMReference<ProfilePhoto>
    payments?: Array<OMReference<PmtPayment>>
}

class Profile extends Object implements ProfileProperties {

    public static Type: string = "cnx_consumer_profile"
    public static Path: string = "cnx_consumer_profiles"

    public stripeCustomerId?: string
    public name?: string

    public article: Array<OMReference<Article>>
    public user: OMReference<User>
    public photo?: OMReference<ProfilePhoto>
    public payments: Array<OMReference<PmtPayment>>

    protected static CreatePaymentIntentCommand = class CreatePaymentIntentCommand extends Command<Profile> {
        public static readonly Path = "create_payment_intent"
        public static readonly Type = "pmt_profile_create_payment_intent_command"

        public constructor(
            receiver: Profile,
            public amountInCents: number,
            public currency: string,
            public captureAutomatically: boolean,
            public receiptEmail: string,
            public description: string,
        ) {
            super(receiver)
        }
    }

    public createPaymentIntent(
        amountInCents: number,
        currency: string,
        captureAutomatically: boolean,
        receiptEmail: string,
        description: string
    ) {
        try {
            const command = new Profile.CreatePaymentIntentCommand(this, amountInCents, currency, captureAutomatically, receiptEmail, description)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }



    protected static RegisterStripeCustomerCommand = class RegisterStripeCustomerCommand extends Command<Profile> {
        public static readonly Path = "register_stripe_customer"

        public constructor(
            receiver: Profile,
            public name?: string,
            public email?: string,
            public phone?: string,
        ) {
            super(receiver)
        }
    }

    public registerStripeCustomer(obj: {
        name?: string,
        email?: string,
        phone?: string,
    }) {
        try {
            const command = new Profile.RegisterStripeCustomerCommand(
                this,
                obj.name,
                obj.email || OMUniverse.shared.user?.identityUsername,
                obj.phone || OMUniverse.shared.user?.identities.find((each) => each.actualObject?.identityType === "phone")?.actualObject?.identifier,
            )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }




    constructor(object: ProfileProperties) {
        super(object)

        this.stripeCustomerId = object.stripeCustomerId
        this.name = object.name

        this.article = object.article ? object.article.map((item) => new OMReference(Article, item)) : []
        this.user = new OMReference(User, object.user)
        this.photo = object.photo && new OMReference(ProfilePhoto, object.photo)
        this.payments = object.payments ? object.payments.map((item) => new OMReference(PmtPayment, item)) : []
    }
}

export default Profile
