// Data module imports
import { OMReference } from "firmament-node-sdk"
import * as React from "react"
import { RouteComponentProps } from "react-router"

import { ArticleDetailsStateMachine } from "."
import ArticleCard from "./elements/ArticleCard"

import {
    ErrorAlert,
    LoadingButton,
    Modal,
    ModalDialog,
    ModalSpinner,
    Title3,
    ListStateMachine,
} from "../../../components"

import { ArticleContent, Profile, ArticleImage, Article } from "../../../domain"
import ArticleContentGroupCard from "../../ArticleContent/ArticleContentDetails/elements/ArticleContentGroupCard"

declare interface ArticleDetailsProps extends RouteComponentProps <{ id: string }> {
    machine: ArticleDetailsStateMachine

    segments?: OMReference<ArticleContent>[]
    author?: OMReference<Profile>
    coverImage?: OMReference<ArticleImage>
}

interface ArticleDetailsComponentState {
    machineState: ArticleDetailsStateMachine.State
    queryParams: ListStateMachine.QueryParams<Article>
}

class ArticleDetails
extends React.Component <ArticleDetailsProps, ArticleDetailsComponentState> {

    private machine: ArticleDetailsStateMachine

    constructor(props: ArticleDetailsProps) {
        super(props)
        this.machine = this.props.machine
        this.state = {
            machineState: this.machine.state,
            queryParams: {
                currentPage: 0,
                sortBy: "createdAt",
                sortDirection: "DESC",
            },
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)
        this.machine.load(new OMReference(Article, this.props.match.params.id))
    }

    public componentWillUnmount() {
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
            case "loading":
                return <ModalSpinner />
            case "promptingDelete":
            case "performingDelete":
            case "presenting":
                return (
                    <div id="ArticleDetail" className="container-420px sm-px-15px" style={{ maxWidth: 500 }}>

                        {/* <Title3 className="my-4">{machineState.item.title}</Title3>

                        <hr /> */}

                        {
                            machineState.item.segments && (
                                <div className="my-3">
                                    <ArticleContentGroupCard
                                        articleContents={
                                            machineState.item.segments
                                                .map((segment) => segment.actualObject!)
                                                .sort((left, right) => (left.sequence || 0) - (right.sequence || 0))
                                        }
                                    />
                                </div>
                            )
                        }

                        {/* <div className="d-flex justify-content-between mt-3">
                            <button
                                type="button"
                                className="btn btn-link"
                                onClick={this.machine.dismiss}
                            >
                                {"< Back"}
                            </button>
                        </div> */}

                    </div>
                )
            case "showingError":
                return <ErrorAlert error={machineState.error} />
            case "showingEdit":
                this.props.history.push(`${this.props.match.url}/edit`)
                return null
            case "completed":
                this.props.history.goBack()
                return null
        }
    }

    private subscriptionCallback = (machineState: ArticleDetailsStateMachine.State) => this.setState({machineState})

    private showEdit = (item: Article) => () => {
        this.machine.showEdit(item)
    }
}

export default ArticleDetails
