import React from "react"

export interface EmailInputComponentProps {
    onChange: (value?: string) => void
    value?: string
}

export interface EmailInputComponentState {
    value?: string
    rawValue?: string
}

class EmailInput extends React.Component<EmailInputComponentProps, EmailInputComponentState> {

    private static ValidEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    constructor(props: EmailInputComponentProps) {
        super(props)
        this.state = {
            rawValue: this.props.value,
            value: this.props.value,
        }
    }

    public componentDidUpdate(prevProps: EmailInputComponentProps, prevState: EmailInputComponentState) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value })
        }

        if (this.props.onChange && this.state.value !== prevState.value) {
            this.props.onChange(this.isValid ? this.state.value : undefined)
        }
    }

    public render() {
        return (
            <>
                <input
                    type="email"
                    autoCapitalize="off"
                    className="form-control"

                    value={this.state.value}
                    onChange={this.onChange}
                />
                <small className={this.isValid ? "d-none" : "text-danger"}>Please enter valid email address</small>
            </>
        )
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            rawValue: event.target.value,
            value: event.target.value.toLowerCase() || undefined,
        } as any)
    }

    private get isValid() {
        return this.state.rawValue ? EmailInput.ValidEmailRegex.test(this.state.value || "") : true
    }
}

export default EmailInput
