import {
    Command,
    CommandManager,
    JsonUtils,
    OMObject,
    OMObjectProperties,
    OMReference,
    OMUniverse,
    Profile,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"

import { ArticleContent, ArticleImage } from "."

export interface ArticleProperties extends OMObjectProperties {
    articleType?: string
    summary?: string
    publishAt?: Moment
    unpublishAt?: Moment

    author?: OMReference<Profile>
    coverImage?: OMReference<ArticleImage>
    segments?: Array<OMReference<ArticleContent>>

    segmentsCount?: number
}

class Article extends OMObject implements ArticleProperties {

    public static Type: string = "article"
    public static Path: string = "articles"

    public articleType?: string
    public summary?: string
    public publishAt?: Moment
    public unpublishAt?: Moment

    public author?: OMReference<Profile>
    public coverImage?: OMReference<ArticleImage>
    public segments: Array<OMReference<ArticleContent>>

    public segmentsCount: number

    constructor(object: ArticleProperties) {
        super(object)
        this.articleType = object.articleType
        this.summary = object.summary
        this.publishAt = object.publishAt && moment(object.publishAt)
        this.unpublishAt = object.unpublishAt && moment(object.unpublishAt)

        this.author = object.author && new OMReference(Profile, object.author)
        this.coverImage = object.coverImage && new OMReference(ArticleImage, object.coverImage)
        this.segments = object.segments ? object.segments.map((item) => new OMReference(ArticleContent, item)) : []

        this.segmentsCount = object.segmentsCount || 0
    }

}

export default Article
