import React from "react"

const withContextConsumer = (Context) => (Component) => {
    return class WithContextConsumer extends React.Component {
        render() {
            return (
                <Context.Consumer>
                    {(props) => <Component {...props} {...this.props} />}
                </Context.Consumer>
            )
        }
    }
}

export default withContextConsumer
