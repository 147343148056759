import * as React from "react"
import classnames from "classnames"
import { Route, useHistory } from "react-router"

const VolumeStateContext = React.createContext<[boolean, Function]>([false, () => { /*  */ }])

export const VolumeStateProvider: React.FunctionComponent = (props) => {
    const [volumeState, setVolumeState] = React.useState(false)

    return (
        <VolumeStateContext.Provider value={[volumeState, setVolumeState]}>
            {props.children}
        </VolumeStateContext.Provider>
    )
}

export const useVolumeState = (context = VolumeStateContext): [boolean, Function] => {
    const [volumeState, setVolumeState] = React.useContext(VolumeStateContext)
    return [volumeState, setVolumeState]
}



interface VideoProps extends React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
    mouseOverPlayback?: boolean
}

/* TODO: improve typing */
// React.FunctionComponent < React.VideoHTMLAttributes < HTMLVideoElement >>
export const Video: React.FunctionComponent<VideoProps> = React.forwardRef((props: VideoProps, ref) => {
    const [volumeState, setVolumeState] = useVolumeState()
    const videoRef = React.useRef<HTMLVideoElement>(ref as any)

    React.useImperativeHandle(ref, () => {
        return videoRef.current
    })

    React.useEffect(() => {
        if (!videoRef.current) {
            return
        }

        /* TODO: difficulty with playsinline with audio on Safari iOS */
        videoRef.current.muted = !volumeState
    }, [volumeState])

    return (
        <video
            preload="none"
            ref={videoRef}
            muted={!volumeState}
            playsInline
            autoPlay
            controls={false}
            loop
            onMouseOver={(event) => {
                if (!props.mouseOverPlayback) {
                    return
                }
                ; ([...document.querySelectorAll("video") as any] as HTMLVideoElement[]).forEach(($video) => {
                    if ($video === videoRef.current) {
                        return
                    }
                    $video.pause()
                })

                videoRef.current?.play().catch(() => { })

                event.preventDefault()
            }}
            onMouseLeave={(event) => {
                if (!props.mouseOverPlayback) {
                    return
                }
                videoRef.current.pause()
                videoRef.current.currentTime = 0
                event.preventDefault()
            }}
            // onClick={(event) => {
            //     setVolumeState(!volumeState)

            //         ; ([...document.querySelectorAll("video") as any] as HTMLVideoElement[]).forEach(($video) => {
            //             if ($video === videoRef.current) {
            //                 return
            //             }
            //             $video.pause()
            //         })

            //     videoRef.current?.play().catch(() => { })

            //     event.preventDefault()
            // }}
            {...props}
        />
    )
}) as any







const VideoControls: React.FunctionComponent = () => {
    const [volumeState, setVolumeState] = useVolumeState()

    return (
        <div className="d-flex justify-content-start align-items-end h-100 w-100">
            <button type="button" className="btn btn-link" onClick={(event) => {
                setVolumeState(!volumeState)
                event.preventDefault()
            }}>
                <span className={classnames("fa fa-2x text-white", volumeState ? "fa-volume-up" : "fa-volume-off")} />
            </button>
        </div>
    )
}

export const VideoMenus: React.FunctionComponent<{ src: string }> = ({ src }) => {
    const history = useHistory()

    return (
        <div className="d-flex justify-content-start align-items-end h-100 w-100">
            <div className="d-flex w-100 bg-search-field-bg">

                <div className="mr-auto">
                    <button type="button" className="btn btn-link" onClick={() => history.length > 1 ? history.goBack() : history.push("/")}>
                        <span className="fa fa-fw text-white fa-times" />
                    </button>
                </div>

                <a target="_blank" href={src}>
                    <button type="button" className="btn btn-link">
                        <span className="fa fa-fw text-white fa-download" />
                    </button>
                </a>

                {/* <button type="button" className="btn btn-link">
                    <span className="fa fa text-white fa-share" />
                </button> */}

            </div>
        </div>
    )
}

export const VideoPlayPause: React.FunctionComponent<{ videoRef?: React.RefObject<HTMLVideoElement> }> = ({ videoRef }) => {
    const [isPlaying, setIsPlaying] = React.useState(false)

    React.useEffect(() => {
        if (isPlaying) {
            videoRef?.current?.play().catch(() => { })
        } else {
            videoRef?.current?.pause()
        }
    }, [isPlaying])

    return (
        <div className="d-flex justify-content-center align-items-center h-100 w-100" onClick={() => {
            setIsPlaying(!isPlaying)
            if (videoRef?.current) {
                videoRef.current.muted = false
            }
        }}>
            <span className={classnames("fa fa-fw fa-2x text-white", !isPlaying && "fa-play")} style={{ transform: "translateX(0.5rem)" }} />
        </div>
    )
}

export default VideoControls
