// Data module imports
import { OMReference } from "firmament-node-sdk"
import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import { RouteComponentProps } from "react-router"
import classnames from "classnames"
import moment from "moment"

import { CnxMeetingDetailsStateMachine } from "."
import CnxMeetingCard from "./elements/CnxMeetingCard"
import Toggle from "react-toggle"

import {
    ErrorAlert,
    LoadingButton,
    Modal,
    ModalDialog,
    ModalSpinner,
    Title3,
    ListStateMachine,
    DetailColumn,
    Headline,
    Callout,
    Caption1,
    Title2,
    Body,
    Checkbox,
} from "../../../components"
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap"

import { CnxConsumerProfile, CnxBid, CnxBooking, CnxMessage, CnxProviderProfile, CnxRating, CnxVideoRoom, CnxMeeting, SchTutorProfile, SchStudentProfile, CnxServiceTier, CnxMemberProfile, CnxServiceCategory, WltTransaction } from "../../../domain"
import CnxMessageModule from "../../CnxMessage/CnxMessageModule"
import CnxRatingModule from "../../CnxRating/CnxRatingModule"
import { Link } from "react-router-dom"
import CnxBookingModule from "../../CnxBooking/CnxBookingModule"
import CnxVideoRoomCard from "../../CnxVideoRoom/CnxVideoRoomDetails/elements/CnxVideoRoomCard"
import { StarRating } from "../../../airframe-components"
import CnxBidModule from "../../CnxBid/CnxBidModule"
import useVideoContext from "../../../components/TwilioVideo/hooks/useVideoContext/useVideoContext"
import { CnxVideoRoomDetails, CnxVideoRoomDetailsStateMachine } from "../../CnxVideoRoom/CnxVideoRoomDetails"
import getProfile from "../../../helpers/Profile"

import ic_graphic_end_session from "../../../assets/ic_graphic_end_session@2x.png"
import AppStateProvider from "../../../components/TwilioVideo/state"
import { AppStateContext } from "../../../views/App/elements/AppStateProvider"
import MeetingTimer from "./elements/MeetingTimer"

declare interface CnxMeetingDetailsProps extends RouteComponentProps <{ id: string }> {
    machine: CnxMeetingDetailsStateMachine

    cnxServiceTier?: OMReference<CnxServiceTier>
    bookings?: OMReference<CnxBooking>[]
    acceptedBid?: OMReference<CnxBid>
    bids?: OMReference<CnxBid>[]
    consumer?: OMReference<CnxConsumerProfile>
    consumers?: OMReference<CnxConsumerProfile>[]
    currentParticipants?: OMReference<CnxMemberProfile>[]
    transcript?: OMReference<CnxMessage>[]
    provider?: OMReference<CnxProviderProfile>
    ratings?: OMReference<CnxRating>[]
    matchProviders?: OMReference<CnxProviderProfile>[]
    videoRoom?: OMReference<CnxVideoRoom>
    cnxServiceCategories?: OMReference<CnxServiceCategory>[]
}

interface CnxMeetingDetailsComponentState {
    machineState: CnxMeetingDetailsStateMachine.State
    queryParams: ListStateMachine.QueryParams<CnxMeeting>
    activeTab: string

    shouldShowLeaveEarlyConfirmationPrompt?: boolean
}

class CnxMeetingDetails
extends React.Component <CnxMeetingDetailsProps, CnxMeetingDetailsComponentState> {

    private worker?: NodeJS.Timeout

    private machine: CnxMeetingDetailsStateMachine

    static contextType = AppStateContext

    constructor(props: CnxMeetingDetailsProps) {
        super(props)
        this.machine = this.props.machine
        this.state = {
            machineState: this.machine.state,
            queryParams: {
                currentPage: 0,
                sortBy: "createdAt",
                sortDirection: "DESC",
            },
            activeTab: "0",
        }
    }

    public componentDidMount() {
        this.machine.subscribe(this.subscriptionCallback)
        this.machine.load(new OMReference(CnxMeeting, this.props.match.params.id))
    }

    public componentWillUnmount() {
        this.machine.unsubscribeRealtime()
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    private children = () => {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "promptingDelete":
            case "performingDelete":
            case "presenting":
            case "leavingMeeting":
            case "participantHasLeft":
            case "meetingHasEnded":
                const profile = getProfile()
                const myRating = machineState.item.ratings[0]?.actualObject // TODO: in 1-M meeting, first rating is not necessarily yours

                return (
                    <>
                        {
                            this.state.shouldShowLeaveEarlyConfirmationPrompt && (
                                ReactDOM.createPortal(
                                    <Modal show={this.state.shouldShowLeaveEarlyConfirmationPrompt} onDismiss={() => this.setState({ shouldShowLeaveEarlyConfirmationPrompt: false })} >
                                        <ModalDialog
                                            body={
                                                <div className="text-center">
                                                    <Title2 className="d-block">End Session Early?</Title2>
                                                    <Body className="d-block mt-8px text-secondary">
                                                        {getProfile() instanceof CnxProviderProfile
                                                            ? (
                                                                <>
                                                                    Are you sure you want to end this session early? You will not receive payout for this session.
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    Are you sure you want to end this session early? <br />Your remaining minutes will not be carried forward.
                                                                </>
                                                            )
                                                        }
                                                    </Body>
                                                    <img className="mt-8px" width={252} alt="" src={ic_graphic_end_session} />

                                                    {/* TODO: implement Share transcript with everyone */}
                                                    {/* {getProfile() instanceof CnxConsumerProfile && (
                                                        <div className="mt-16px">
                                                            <Checkbox label="Share transcript with everyone." defaultChecked={true} />
                                                        </div>
                                                    )} */}

                                                    <button className="btn-block btn btn-primary mt-20px" onClick={() => this.machine.leaveMeeting()}>
                                                        <Headline>Leave Sesssion</Headline>
                                                    </button>

                                                    <button className="btn-block  btn btn-link mt-8px" onClick={() => this.setState({ shouldShowLeaveEarlyConfirmationPrompt: false })}>
                                                        <Headline>Cancel</Headline>
                                                    </button>

                                                </div>
                                            }
                                        />
                                    </Modal>
                                , document.querySelector("body")!)
                            )
                        }

                        {
                            machineState.name === "meetingHasEnded" && (
                                ReactDOM.createPortal((
                                    <Modal show={machineState.name === "meetingHasEnded"} onDismiss={() => { /*  */ }} >
                                        <ModalDialog
                                            body={
                                                <div className="text-center">
                                                    <Title2 className="d-block">Time's Up</Title2>
                                                    <Body className="d-block mt-8px">Session has ended.</Body>

                                                    <div className="text-center mb-20px">
                                                        <button className="btn btn-primary mt-20px" onClick={() => this.machine.dismiss()}>
                                                            <Headline>Leave Meeting</Headline>
                                                        </button>
                                                    </div>


                                                </div>
                                            }
                                        />
                                    </Modal>
                                ), document.querySelector("body")!)
                            )
                        }

                        {
                            machineState.name === "participantHasLeft" && ReactDOM.createPortal((
                                <Modal show={machineState.name === "participantHasLeft"} onDismiss={() => { /*  */ }} >
                                    <ModalDialog
                                        body={
                                            (() => {
                                                switch (true) {
                                                    case profile instanceof SchStudentProfile && !machineState.item.isSafeToLeave:
                                                        return (
                                                            <div className="text-center">
                                                                <Title2 className="d-block">Tutor has left the meeting</Title2>

                                                                <Body className="d-block mt-8px">{"Oh no, looks like the tutor has left early. Don't worry, your tokens will not be deducted. 😅"}</Body>

                                                                <img className="mt-8px" width={252} alt="" src={ic_graphic_end_session} />

                                                                <div className="text-center mb-20px">
                                                                    <button className="btn btn-primary mt-20px" onClick={() => this.machine.leaveMeeting()}>
                                                                        <Headline>Leave Session</Headline>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    default:
                                                        return (
                                                            <div className="text-center">
                                                                <Title2 className="d-block">Attention</Title2>

                                                                <Body className="d-block mt-8px">Participant has left the meeting session.</Body>

                                                                <div className="text-center mb-20px">
                                                                    <button className="btn btn-primary mt-20px" onClick={() => this.machine.leaveMeeting()}>
                                                                        <Headline>Leave Session</Headline>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                }
                                            })()
                                        }
                                    />
                                </Modal>
                            ), document.querySelector("body")!)
                        }

                        {machineState.name === "leavingMeeting" && <ModalSpinner />}

                        {/* Top nav menu */}
                        <div className="position-absolute bg-white" style={{ top: 0, left: 0, right: 0, boxShadow: "0 1px 0 0 rgba(0, 0, 0, 0.12)", height: 55, zIndex: 1 }}>
                            <div className="d-flex flex-column justify-content-center align-items-center h-100">

                                {!this.props.match.path.includes(`/${WltTransaction.Path}/`) && (
                                    <button type="button" className="btn btn-link position-absolute" style={{ left: 7, top: 7 }} onClick={() => this.leaveMeeting()}>
                                        <span className="fa fa-times" style={{ fontSize: 18 }} />
                                    </button>
                                )}

                                <Headline>
                                    {machineState.item.serviceCategories.map((each) => each.actualObject?.categoryType === "SUBJECT" && each.actualObject.title).filter(Boolean)[0]}
                                </Headline>
                                {/* TODO: nullity ratingsAverage for 1-M consumers does not means that you have voted  */}
                                {!this.props.match.path.includes(`/${WltTransaction.Path}/`)
                                    ? null
                                    : myRating
                                        ? <StarRating at={Math.round(myRating.overall || 0)} max={5} />
                                        : <Caption1>Not Rated Yet</Caption1>
                                }
                            </div>
                        </div>




                        {/* Bottom nav/footer action (rate this session / "session under review" / etc) */}
                        {this.props.match.url.includes(WltTransaction.Path) && getProfile() instanceof SchStudentProfile && (
                            <div className="position-absolute bg-white" style={{ bottom: 0, left: 0, right: 0, boxShadow: "0 -1px 0 0 rgba(0, 0, 0, 0.12)", zIndex: 1 }}>
                                <div className="position-relative" style={{ marginLeft: 40, marginRight: 40 }}>
                                    {(() => {
                                        switch (true) {
                                            // NOTE: rate this session
                                            case !myRating && machineState.item.meetingStatus !== "FAILED":
                                                return (
                                                    <Link to={`${this.props.match.url}/${CnxRating.Path}/new`}>
                                                        <button type="button" className="btn btn-block btn-primary my-8px">
                                                            <Callout>Rate This Session</Callout>
                                                        </button>
                                                    </Link>
                                                )

                                            // NOTE: session refunded
                                            case myRating && !!myRating?.refund:
                                                return (
                                                    <button type="button" className="btn btn-block btn-light my-8px" disabled>
                                                        <Callout>Session Refunded</Callout>
                                                    </button>
                                                )

                                            // NOTE: session under review
                                            case myRating && !!myRating?.report:
                                                return (
                                                    <button type="button" className="btn btn-block btn-light my-8px" disabled>
                                                        <Callout>Session Under Review</Callout>
                                                    </button>
                                                )

                                            // TODO: report session
                                            case myRating && myRating.overall && myRating.overall <= 1:
                                                return (
                                                    <button type="button" className="btn btn-block btn-outline-primary my-8px">
                                                        <Callout>Report This Session</Callout>
                                                    </button>
                                                )

                                            // TODO: no action
                                            default:
                                                return (
                                                    <button type="button" className="btn btn-block btn-light my-8px" disabled>
                                                        <Callout>{'¯\\_(ツ)_/¯'}</Callout>
                                                    </button>
                                                )
                                        }
                                    })()}
                                </div>
                            </div>
                        )}


                        {/* Count down timer */}
                        <MeetingTimer meeting={machineState.item} forceEndMeeting={() => {
                            if (getProfile() instanceof SchStudentProfile) {
                                this.leaveMeeting()
                            }
                        }} />


                        {!this.props.match.path.includes(`/${WltTransaction.Path}/`) && (
                            <CnxVideoRoomDetails {...this.props} meeting={new OMReference(CnxMeeting, machineState.item.id)} machine={new CnxVideoRoomDetailsStateMachine()} match={{ ...this.props.match, params: { id: machineState.item.videoRoom && machineState.item.videoRoom.id || "" } }} />
                        )}
                        <CnxMessageModule meeting={new OMReference(CnxMeeting, machineState.item.id)} />
                    </>
                )
            default:
                return null
        }
    }

    public render() {
        const machineState = this.state.machineState
        switch (machineState.name) {
            case "start":
            case "loading":
                return <ModalSpinner />
            case "promptingDelete":
            case "performingDelete":
            case "presenting":
            case "leavingMeeting":
            case "participantHasLeft":
            case "meetingHasEnded":
                return this.props.match.url.includes(`/${WltTransaction.Path}/`)
                    ? (
                        <div id="CnxMeetingDetail" className="position-relative m-neg-8px" style={{ height: "calc(100vh - 55px)" }}>
                            {this.children()}
                        </div>
                    )
                    : (
                        <div id="CnxMeetingDetail" className="position-fixed" style={{ top: 0, bottom: 0, left: 0, right: 0 }}>
                            <div className="position-relative w-100 h-100">
                                {this.children()}
                            </div>
                        </div>
                    )
            case "showingError":
                return <ErrorAlert error={machineState.error} />
            case "showingEdit":
                this.props.history.push(`${this.props.match.url}/edit`)
                return null
            case "leavedPrematurely":
                window.removeEventListener('beforeunload', this.beforeunload)
                // NOTE: Conditions of reaching "leavedPrematurely" state
                // for student, this is when student leave a meeting before a match
                // for tutor, tutor left a meeting before scheduledEndAt
                window.location = "/" as any
                return null
            case "completed":
                window.removeEventListener('beforeunload', this.beforeunload)
                switch (true) {
                    case getProfile() instanceof SchStudentProfile:
                        // show rating form
                        window.location = `${this.props.history.location.pathname}/${CnxRating.Path}/new` as any
                        break
                    case getProfile() instanceof SchTutorProfile:
                        ;(async () => {
                            const meeting = new OMReference(CnxMeeting, this.props.match.params.id).actualObject || await new OMReference(CnxMeeting, this.props.match.params.id).load()
                            const earning = meeting.earning

                            if (!earning) {
                                window.location = "/" as any
                                return
                            }

                            const queryParams = `?wlt_transactions[startAt]=${moment().startOf("month").add(8, "hours").toISOString()}&wlt_transactions[endAt]=${moment().endOf("month").add(8, "hours").toISOString()}`

                            window.location = `/${WltTransaction.Path}/${earning?.id}${queryParams}&source=endmeeting` as any
                        })()
                        return <ModalSpinner />
                        // show earning detail
                        break
                    default:
                        return null
                }
                // window.location = "/" as any
                return null
        }
    }

    private beforeunload = (event: any) => {
        // // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event

        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Older browsers supported custom message
        event.returnValue = '';
    }

    private subscriptionCallback = (machineState: CnxMeetingDetailsStateMachine.State) => {
        this.setState({ machineState })

        if (machineState.name === "presenting" && ["PENDING", "SCHEDULED", "STARTED"].includes(machineState.item.meetingStatus || "")) {
            window.addEventListener('beforeunload', this.beforeunload)
        } else {
            window.removeEventListener('beforeunload', this.beforeunload)
        }




        // NOTE: set context.participant
        // context.participant is consumed by ParticipantTrack to render
        // participant image
        if (machineState.name === "presenting") {
            const meeting = machineState.item

            const provider = meeting.provider?.actualObject
            const consumer = meeting.consumers[0].actualObject

            const currentProfile = getProfile()

            if (currentProfile?.id !== provider?.id) {
                this.context.setParticipant(provider)
            } else {
                this.context.setParticipant(consumer)
            }
        }
    }

    private showEdit = (item: CnxMeeting) => () => {
        this.machine.showEdit(item)
    }

    private leaveMeeting = () => {
        if (this.machine.state.name !== "presenting") {
            return
        }

        if (this.machine.state.item.scheduledEndAt?.isAfter() && this.machine.state.item.meetingStatus !== "COMPLETED") {
            /* NOTE: Show leave early confirmation prompt */
            this.setState({ shouldShowLeaveEarlyConfirmationPrompt: true })
        } else {
            /* NOTE: Safe to leave meeting */
            this.machine.leaveMeeting()
        }
    }
}

export default CnxMeetingDetails
