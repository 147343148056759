import { OMUniverse } from "firmament-node-sdk"
import { GenImage } from "../domain"

interface Dimension {
    x: number
    y: number
}

const cache: { [id: string]: Dimension } = {}
const pendingTasks: { [id: string]: boolean } = {}

const getImageDimension = (id: string) => {
    if (cache[id]) {
        return cache[id]
    }

    if (pendingTasks[id]) {
        return
    }

    (async () => {
        pendingTasks[id] = true

        const genImage = OMUniverse.shared.find(GenImage, id)

        if (!genImage) {
            return undefined
        }

        const $img = new Image()

        const dimensionPromise: Promise<Dimension> = new Promise((resolve) => {
            $img.onload = function () {
                resolve({ x: $img.width, y: $img.height })
            }
        })

        $img.src = genImage.url

        const dimension = await dimensionPromise

        // Add result to cache
        cache[id] = dimension
        pendingTasks[id] = false

        return dimension
    })()

}

export default getImageDimension
