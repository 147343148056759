export default class DelayRun {

    private id?: NodeJS.Timeout

    constructor(private seconds: number) { }

    public execute = (f: (...arg: any) => any) => {
        if (this.id) {
            clearInterval(this.id)
        }

        return new Promise((resolve) => {
            this.id = setTimeout(() => {
                resolve(f())
                this.id = undefined
            }, this.seconds * 1000)
        })
    }

}
