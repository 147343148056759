// Data imports
import { FSMNamedState as FSMState, OMQuery, OMReference } from "firmament-node-sdk"
import { Moment } from "moment"
import { CnxMemberProfile, CnxConsumerRefund, CnxMeeting, CnxProviderChargeback, CnxRating, CnxProviderProfile } from "../../../domain"

// Feature imports
import ListStateMachine from "../../../components/ListStateMachine"

declare namespace CnxRatingListStateMachine {

    export interface ShowingCreate extends FSMState {
        name: "showingCreate"
    }

    export interface PromptingDelete {
        name: "promptingDelete"
        item: CnxRating

        items: CnxRating[]
        totalItems: number
    }

    export interface PerformingDelete {
        name: "performingDelete"
        item: CnxRating

        items: CnxRating[]
        totalItems: number
    }

    export type State = ListStateMachine.State<CnxRating> |
        ShowingCreate |
        PromptingDelete |
        PerformingDelete
}

class CnxRatingListStateMachine extends ListStateMachine<CnxRating, CnxRatingListStateMachine.State> {

    public promptDelete = (item: CnxRating) => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "promptingDelete", items: this.state.items, totalItems: this.state.totalItems, item }
                break
            default:
                return
        }
    }

    public performDelete = async () => {
        switch (this.state.name) {
            case "promptingDelete":
                this.state = { name: "performingDelete", items: this.state.items, totalItems: this.state.totalItems, item: this.state.item }
                break
            default:
                return
        }

        try {
            const item = this.state.item
            await item.delete()
            this.state = { name: "presenting", items: this.state.items.filter((each) => each.id !== item.id), totalItems: this.state.totalItems - 1 }
        } catch (error) {
            this.fail(error)
        }
    }

    public cancel = (): void => {
        switch (this.state.name) {
            case "promptingDelete":
                this.state = { name: "presenting", items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                return
        }
    }

    public load = (filters: {
        currentPage?: number,
        sortBy?: keyof CnxRating,
        sortDirection?: "ASC" | "DESC",
        startAt?: Moment,
        endAt?: Moment,

        submitter?: OMReference<CnxMemberProfile>,
        meeting?: OMReference<CnxMeeting>,
        provider?: OMReference<CnxProviderProfile>,
    } = { currentPage: 0, sortBy: "createdAt", sortDirection: "DESC" }): void => {
        switch (this.state.name) {
            case "start":
            case "showingError":
                this.state = { name: "loading",  items: [], totalItems: 0 }
                break
            case "presenting":
                this.state = { name: "loading",  items: this.state.items, totalItems: this.state.totalItems }
                break
            default:
                return
        }

        const query = CnxRating.query()
            .include("provider")
            .include("provider.categories" as any)
            .include("submitter")
            .include("submitter.photo" as any)
            .include("meeting")
            .include("meeting.serviceCategories" as any)
            .limit(10)

        if (filters.currentPage) {
            query.offset(filters.currentPage)
        }

        if (filters.sortBy) {
            query.sort(filters.sortBy, filters.sortDirection!)
        }

        if (filters.startAt) {
            query.filter("createdAt", "moreThan", filters.startAt.toISOString() as any)
        }

        if (filters.endAt) {
            query.filter("createdAt", "lessThan", filters.endAt.toISOString() as any)
        }

        if (filters.submitter) {
            query.filter("submitter", "equals", filters.submitter.id as any)
        }

        if (filters.meeting) {
            query.filter("meeting", "equals", filters.meeting.id as any)
        }

        if (filters.provider) {
            query.filter("provider", "equals", filters.provider.id as any)
        }

        query
            .execute()
            .then(() => this.success(query.resultObjects, query.meta.count))
            .catch((error: Error) => this.fail(error))
    }

    public showDetail = (ref: OMReference<CnxRating>) => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingDetail", ref }
                break
            default:
                return
        }
    }

    public showCreate = () => {
        switch (this.state.name) {
            case "presenting":
                this.state = { name: "showingCreate" }
                break
            default:
                return
        }
    }

    public success(items: CnxRating[], totalItems: number = 0): void {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
                this.state = { name: "presenting", items, totalItems }
                break
            default:
                return
        }
    }

    public fail(error: Error): void {
        switch (this.state.name) {
            case "loading":
            case "performingDelete":
                this.state = { name: "showingError", error }
                break
            default:
                return
        }
    }

}

export default CnxRatingListStateMachine
