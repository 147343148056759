import { OMReference } from "firmament-node-sdk"
import { Moment } from "moment"
import * as React from "react"

import {
    DatePicker,
    TextInput,
    Checkbox,
} from "../../../../components"

import { CnxMeeting, GenImage, CnxMemberProfile, CnxMessage, CnxMessageProperties } from "../../../../domain"

export interface CnxMessageFieldsetComponentProps {
    onChange?: (cnxMessage?: CnxMessage) => void
    cnxMessage?: CnxMessage

    meeting?: OMReference<CnxMeeting>
    attachedImage?: OMReference<GenImage>
    sender?: OMReference<CnxMemberProfile>
}

export interface CnxMessageFieldsetComponentState extends Partial<CnxMessageProperties> {
    cnxMessage?: CnxMessage
}

class CnxMessageFieldset extends React.Component<CnxMessageFieldsetComponentProps, CnxMessageFieldsetComponentState> {
    constructor(props: CnxMessageFieldsetComponentProps) {
        super(props)
        this.state = {
            cnxMessage: props.cnxMessage,
            ...props.cnxMessage,
        }
    }

    public componentDidUpdate(
        prevProps: CnxMessageFieldsetComponentProps,
        prevState: CnxMessageFieldsetComponentState,
    ) {

        if (
            this.state.messageType !== prevState.messageType ||
            this.state.text !== prevState.text ||
            this.state.createdAt !== prevState.createdAt ||

            this.props.meeting !== prevProps.meeting ||
            this.props.attachedImage !== prevProps.attachedImage ||
            this.props.sender !== prevProps.sender ||
            false
        ) {
            const model = this.model()
            this.setState({ cnxMessage: model })
        }

        if (this.props.onChange && this.state.cnxMessage !== prevState.cnxMessage) {
            this.props.onChange(this.state.cnxMessage)
        }
    }

    public render() {
        return (
            <fieldset>
                <div className="row">
                    <div className="col-12 col-md-6 form-group">
                        <label htmlFor="CnxMessage.messageType">Message Type</label>
                        <TextInput
                            id="CnxMessage.messageType"
                            className="form-control"
                            
                            value={this.state.messageType}
                            onChange={this.onChange("messageType")}
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label htmlFor="CnxMessage.text">Text</label>
                        <TextInput
                            id="CnxMessage.text"
                            className="form-control"
                            
                            value={this.state.text}
                            onChange={this.onChange("text")}
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label htmlFor="CnxMessage.createdAt">Created At</label>
                        <DatePicker
                            id="CnxMessage.createdAt"

                            value={this.state.createdAt}
                            onChange={this.onChange("createdAt")}
                        />
                    </div>
                </div>
            </fieldset>
        )
    }

    private onChange = (keyPath: keyof CnxMessageFieldsetComponentState) =>
        (value?: any) => {
            this.setState({
                [keyPath]: value || null,
            })
        }

    private isValid(): boolean {
        // Check that all required attributes & relationships are present
        return (
            this.state.messageType !== undefined &&
            true
        )
    }

    private model(): CnxMessage | undefined {
        if (!this.isValid()) {
            return undefined
        }

        let cnxMessage = this.state.cnxMessage

        // Check that all required attributes & relationships are present
        if (
            this.state.messageType !== undefined &&

            this.props.meeting !== undefined &&
            this.props.sender !== undefined &&

            true
        ) {

            const props: CnxMessageProperties = {
                ...this.state,
                messageType: this.state.messageType,

                meeting: this.props.meeting,
                attachedImage: this.props.attachedImage,
                sender: this.props.sender,
            }

            cnxMessage = new CnxMessage({ ...this.props.cnxMessage, ...props })
        }

        return cnxMessage
    }
}

export default CnxMessageFieldset
