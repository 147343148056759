//  ProfilePhoto.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-03-19
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
    OMFileProperties as FileProperties,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    File,
    Profile,
} from ".."

export interface ProfilePhotoProperties extends FileProperties {

    ownerProfile: OMReference<Profile>
}

class ProfilePhoto extends File implements ProfilePhotoProperties {

    public static Type: string = "profile_photo"
    public static Path: string = "profile_photos"


    public ownerProfile: OMReference<Profile>

    constructor(object: ProfilePhotoProperties) {
        super(object)


        this.ownerProfile = new OMReference(Profile, object.ownerProfile)
    }
}

export default ProfilePhoto
