// Data Imports
import { OMReference, OMUniverse, OMQuery } from "firmament-node-sdk"
import * as React from "react"
import { Route, RouteComponentProps } from "react-router"
import ic_portrait_placeholder from "../../../assets/ic_portrait_placeholder.png"
import moment from "moment"
import intersection from "lodash/intersection"

// Shared views
import {
    AsyncEntitySelectHeader,
    ErrorAlert,
    LoadingButton,
    ModalSpinner,
    Caption1,
    Headline,
    Title3,
    Form,
    DetailColumn,
    Body,
    Subheadline,
    Footnote,
    Caption2,
    Callout,
    errorToast,
    Modal,
    Title2,
} from "../../../components"

import { CnxMeeting, CnxConsumerProfile, CnxBooking, CnxServiceTier, CnxConsumerPurchase, CnxSessionWallet, CnxProviderProfile, CnxServiceCategory, Setting, SchTutorProfile, SchStudentProfile, SchTopupPackage } from "../../../domain"

// Feature imports
import CnxBookingAskFormStateMachine from "./CnxBookingAskFormStateMachine"
import CnxBookingFieldset from "./elements/CnxBookingFieldset"
import CnxMeetingFieldset from "../../CnxMeeting/CnxMeetingForm/elements/CnxMeetingFieldset"
import getProfile from "../../../helpers/Profile"
import { SchTutorProfileForm } from "../../SchTutorProfile/SchTutorProfileForm"
import { AvatarImage } from "../../../airframe-components/Avatar/AvatarImage"
import { AvatarAddonIcon } from "../../../airframe-components/Avatar/AvatarAddonIcon"
import settings from "../../../settings"
import TokensLeftCaption from "../../WltWallet/WltWalletDetails/elements/TokensLeftCaption"
import SelectTutor, { SelectedType } from "./elements/SelectTutor"
import { SchTopupPackageList, SchTopupPackageListStateMachine } from "../../SchTopupPackage/SchTopupPackageList"

import bg_top_up_header from "../../../assets/bg_top_up_header.png"
import ic_splash_graphic_iphone from "../../../assets/ic_splash_graphic_iphone@2x.png"
import ic_honeycomb_filled from "../../../assets/ic_honeycomb_filled@2x.png"
import { withContextConsumer } from "../../../hoc"
import { CategoriesParamsContext } from "../../../helpers/useSubjectParamsContext"

export interface CnxBookingAskFormComponentProps extends RouteComponentProps<{ id?: string }> {
    machine: CnxBookingAskFormStateMachine

    meeting?: OMReference<CnxMeeting>
    consumer?: OMReference<CnxConsumerProfile>

    category?: OMReference<CnxServiceCategory>
    matchProvider?: OMReference<CnxProviderProfile>

    /* withContextConsumer(CategoriesParamsContext) */
    categories?: OMReference<CnxServiceCategory>[]
}

export interface CnxBookingAskFormComponentState {
    machineState: CnxBookingAskFormStateMachine.State

    cnxBooking?: CnxBooking

    meeting?: CnxMeeting
    meetingRef?: OMReference<CnxMeeting>
    consumerRef?: OMReference<CnxConsumerProfile>
    serviceTierRef?: OMReference<CnxServiceTier>

    subjects?: CnxServiceCategory[]

    selectedSubject?: OMReference<CnxServiceCategory>

    isEditingSubject: boolean
    isEditingTutor: boolean

    firstThreeMatchProviders?: OMReference<CnxProviderProfile>[]
    matchProvidersCount: number

    shouldCollapseSubject?: boolean
    shouldCollapseTutor?: boolean

    tutorSelectedType?: SelectedType

    matchProviders?: OMReference<CnxProviderProfile>[]

    serviceTier?: CnxServiceTier
}

class CnxBookingAskForm extends React.Component<CnxBookingAskFormComponentProps, CnxBookingAskFormComponentState> {

    private machine: CnxBookingAskFormStateMachine

    constructor(props: CnxBookingAskFormComponentProps) {
        super(props)

        this.machine = new CnxBookingAskFormStateMachine()
        this.state = {
            machineState: this.machine.state,

            meetingRef: this.props.meeting,
            consumerRef: this.props.consumer,

            isEditingSubject: false,
            isEditingTutor: false,

            matchProvidersCount: 0
        }

        this.machine.subscribe(this.subscriptionCallback)
    }

    public componentDidUpdate(prevProps: CnxBookingAskFormComponentProps, prevState: CnxBookingAskFormComponentState) {
        if (this.props.category !== prevProps.category || this.props.matchProvider !== prevProps.matchProvider) {
            this.loadAndUpdateSubjects()
        }

        if (this.props.matchProvider !== prevProps.matchProvider ) {
            this.setState({ selectedSubject: this.props.category })
        }

        if (this.props.category !== prevProps.category) {
            this.setState({ selectedSubject: this.props.category })
        }

        // v1.0.13: For now, select lowest priced tier and don't allow to change tier
        // TODO: v1.1 let user select serviceTier
        if (this.state.selectedSubject !== prevState.selectedSubject) {
            this.setState({ serviceTier: this.state.selectedSubject?.actualObject?.lowestPricedTier })
        }

        if (
            this.state.tutorSelectedType !== prevState.tutorSelectedType ||
            this.state.firstThreeMatchProviders !== prevState.firstThreeMatchProviders
        ) {
            const serviceTierSlug = this.state.serviceTier?.slug
            switch (this.state.tutorSelectedType) {
                case "PREFERRED_TUTOR":
                    this.setState({ matchProviders: this.state.firstThreeMatchProviders })
                    break
                case "MY_FAVOURITE_TUTORS":
                    ;(async () => {
                        this.setState({ matchProviders: undefined })
                        const query = new OMQuery(CnxProviderProfile, "favoriteProviders", new OMReference(CnxConsumerProfile, getProfile()!.id))
                            .limit(50) // TOOD: limit to first X providers
                            .sort("createdAt", "DESC")
                            .include("photo")
                            .include("categories")
                            .include("categories.availableTiers" as any)

                            .filter("user.status" as any, "equals", "active")
                            .filter("approvedAt", "isNotNull", undefined)
                            .filter("categorySet", "contains", [this.state.selectedSubject?.actualObject?.slug].join(",")) /* TODO: should compare all categories */
                            .filter("serviceTierSet", "contains", [serviceTierSlug].filter(Boolean).join(","))
                            .filter("isOnline", "equals", true)


                        await query.execute()

                        this.setState({ matchProviders: query.results })
                    })()
                    break
                case "ANY_AVAILABLE_TUTORS":
                    ; (async () => {
                        this.setState({ matchProviders: undefined })
                        const query = CnxProviderProfile.query()
                            .limit(50) // TOOD: limit to first X providers
                            .sort("createdAt", "DESC")
                            .include("photo")
                            .include("categories")
                            .include("categories.availableTiers" as any)
                            .filter("categorySet", "contains", [this.state.selectedSubject?.actualObject?.slug].join(",")) /* TODO: should compare all categories */
                            .filter("serviceTierSet", "contains", [serviceTierSlug].filter(Boolean).join(","))
                            .filter("isOnline", "equals", true)


                        await query.execute()

                        this.setState({ matchProviders: query.results })
                    })()
                    break
            }
        }
    }

    public componentDidMount() {
        const ref = this.props.match.params.id ? new OMReference(CnxBooking, this.props.match.params.id) : undefined
        this.machine.load(ref)

        // TODO
        // if (!this.props.matchProvider && this.props.category) {
        //     this.loadAvailableTutors()
        // }

        this.loadAndUpdateSubjects()

        // init selected subject state from props
        if (this.props.category) {
            this.setState({ selectedSubject: this.props.category, shouldCollapseSubject: true })
        }

        if (this.props.matchProvider) {
            this.setState({ firstThreeMatchProviders: [this.props.matchProvider], shouldCollapseTutor: true })
        }
    }

    public loadAndUpdateSubjects = () => {
        const currentConsumerProfile = getProfile() as CnxConsumerProfile
        const grade = currentConsumerProfile.grade

        switch (true) {
            case this.props.matchProvider && !this.props.category && (this.props.matchProvider.actualObject?.categories.length || 0) > 0:
                {
                    // Can be inferred as user is at tutor details screen
                    // Only shows categories that can be teached by this tutor
                    const categories = this.props.matchProvider?.actualObject!.categories
                        .map((each) => each.actualObject!)
                        .filter((each) => each.categoryType === "SUBJECT" && grade?.id === each.parentCategory?.id)
                        .filter((each) => intersection(each!.availableTierSet, this.props.matchProvider?.actualObject?.serviceTierSet ?? []).length > 0)

                    this.setState({ subjects: categories })
                }
                break
            case (this.props.categories?.filter((each) => each.actualObject?.categoryType === "SUBJECT").length ?? 0) >= 1:
                {
                    const subjects = this.props.categories?.map((each) => each.actualObject?.categoryType === "SUBJECT" && each.actualObject!).filter(Boolean) as CnxServiceCategory[]
                    this.setState({ subjects, selectedSubject: subjects.length === 1 && new OMReference(CnxServiceCategory, subjects[0].id) || undefined })
                }
                break
            default:
                {
                    // load all subjects
                    const query = CnxServiceCategory.query()
                        .filter("categoryType", "equals", "SUBJECT")
                        .filter("isPublished", "equals", true)
                        .include("availableTiers")
                        .sort("sequence", "ASC")
                        .limit(100)

                    if (grade) {
                        query.filter("parentCategory", "equals", grade.id as any)
                    }

                    query.execute().then(() => this.setState({ subjects: query.resultObjects }))
                }
                break
        }
    }

    public componentWillUnmount() {
        this.machine.unsubscribe(this.subscriptionCallback)
    }

    public render() {
        const machineState = this.state.machineState

        switch (machineState.name) {
            case "start":
            case "loading":
                return <ModalSpinner />
            case "presenting":
            case "saving":
            case "showingError":
                if (machineState.name === "showingError") {
                    errorToast(machineState.error)
                }
                const profile = getProfile() as SchStudentProfile | undefined
                return (
                    <>
                        <Modal show={machineState.name === "showingError" && machineState.error.message === "Insufficient session balance."} onDismiss={() => this.machine.back()}>
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div
                                        className="modal-header background-image bg-gray-100"
                                        style={{ height: "20rem", backgroundImage: `url(${bg_top_up_header})`, backgroundPosition: "bottom" }}
                                    >
                                        <div className="d-flex flex-column justify-content-between align-items-center mt-4 h-100">
                                            <h3 className="w-100 text-center text-white">Top Up Store</h3>
                                            <div className="row align-items-center mb-4">
                                                <div className="col-5 px-0">
                                                    <img className="w-100" src={ic_splash_graphic_iphone} />
                                                </div>
                                                <div className="col-7 px-0">
                                                    <small>
                                                        <ul>
                                                            <li>Balance don't expire, use them anytime.</li>
                                                            <li>All subjects covered.</li>
                                                            <li>Book an exclusive class and concentrate on learning.</li>
                                                        </ul>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <h5 className="modal-title">
                                            {/* {this.props.title || "Modal title"} */}
                                        </h5>
                                        <button
                                            type="button"
                                            className="close"
                                            aria-label="Close"
                                            onClick={() => this.machine.back()}
                                        >
                                            <span className="fa fa-times-circle" />
                                        </button>
                                    </div>
                                    <div className="modal-body bg-gray-100">
                                        <Route
                                            render={(componentProps) => {
                                                if (machineState.name !== "showingError" || machineState.error.message !== "Insufficient session balance.") {
                                                    return null
                                                }
                                                const props = {
                                                    ...componentProps,
                                                    match: {
                                                        ...componentProps,
                                                        url: `/${SchStudentProfile.Path}/${this.props.match.params.id}/${SchTopupPackage.Path}`
                                                    }
                                                }
                                                componentProps.match.url = `/${SchStudentProfile.Path}/${this.props.match.params.id}/${SchTopupPackage.Path}`
                                                return <SchTopupPackageList {...this.props} {...props} machine={new SchTopupPackageListStateMachine()} />
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Form onSubmit={this.onSubmit}>

                            <div className="container">

                                {this.state.serviceTier && (
                                    <div className="position-relative">
                                        <div className="position-absolute" style={{ right: 0, top: -45 }}>
                                            <span className="badge badge-pill badge-secondary">
                                                <Caption2>{this.state.serviceTier.title}</Caption2>
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <div className="row">

                                    {this.state.subjects && (
                                        <DetailColumn className="col-12 text-tertiary mb-20px"
                                            label="Subject"
                                            value={
                                                (() => {
                                                    switch (true) {
                                                        case this.state.shouldCollapseSubject && !!this.state.selectedSubject:
                                                            return (
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1">
                                                                        <Headline className="text-dark">{this.state.selectedSubject?.actualObject?.title || "-"}</Headline>
                                                                    </div>

                                                                    <Callout className="text-primary" style={{ cursor: "pointer" }} onClick={() => this.setState({ shouldCollapseSubject: false })}>Change</Callout>
                                                                </div>
                                                            )
                                                        default:
                                                            return (
                                                                <div style={{ marginLeft: -6, marginRight: -6 }}>
                                                                    {this.state.subjects.map((subject) => {
                                                                        const isSelectedSubject = this.state.selectedSubject && subject.id === this.state.selectedSubject.id || false
                                                                        return (
                                                                            <a
                                                                                style={{ cursor: "pointer", margin: 6 }}
                                                                                className={`badge badge-pill ${isSelectedSubject ? "badge-accent" : "badge-outline-accent"} mr-12px`}
                                                                                onClick={() => { this.setState({ selectedSubject: new OMReference(CnxServiceCategory, subject.id), shouldCollapseSubject: true }) }}
                                                                            >
                                                                                <Caption2>{subject.title}</Caption2>
                                                                            </a>
                                                                        )
                                                                    })}
                                                                </div>
                                                            )
                                                    }
                                                })()
                                            }
                                        />
                                    )}

                                    <DetailColumn className="col-12 mb-2 text-tertiary"
                                        label="Tutors"
                                        value={(() => {
                                            switch (true) {
                                                case this.state.shouldCollapseTutor && !!this.props.matchProvider:
                                                    return (
                                                        <div>
                                                            <div style={{ display: "none" }}>
                                                                <SelectTutor onSelectedTypeChange={(tutorSelectedType) => this.setState({ tutorSelectedType, shouldCollapseTutor: true })} subject={this.state.selectedSubject} preferredTutor={this.props.matchProvider as OMReference<SchTutorProfile>} onTutorsChange={(firstThreeMatchProviders, matchProvidersCount) => { this.setState({ firstThreeMatchProviders, matchProvidersCount: matchProvidersCount || 0 }) }} />
                                                            </div>

                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <Headline className="text-dark">
                                                                        {(() => {
                                                                            switch (this.state.tutorSelectedType) {
                                                                                case "ANY_AVAILABLE_TUTORS":
                                                                                    return "Any Available Tutors"
                                                                                case "MY_FAVOURITE_TUTORS":
                                                                                    return "My Favourite Tutors"
                                                                                default:
                                                                                    return "Preferred Tutor"
                                                                            }
                                                                        })()}
                                                                    </Headline>
                                                                </div>

                                                                <Callout className="text-primary" style={{ cursor: "pointer" }} onClick={() => this.setState({ shouldCollapseTutor: false })}>Change</Callout>
                                                            </div>

                                                            {(() => {
                                                                switch (this.state.tutorSelectedType) {
                                                                    case "ANY_AVAILABLE_TUTORS":
                                                                    case "MY_FAVOURITE_TUTORS":
                                                                        return (
                                                                            <div className="d-flex align-items-center">
                                                                                {this.state.matchProvidersCount === 0 && (
                                                                                    <AvatarImage
                                                                                        size="md"
                                                                                        src={ic_portrait_placeholder}
                                                                                    />
                                                                                )}
                                                                                {this.state.firstThreeMatchProviders?.slice(0, 3).filter(Boolean).map((each) => (
                                                                                    <AvatarImage
                                                                                        size="md"
                                                                                        src={each.actualObject?.photo?.actualObject?.preview || ic_portrait_placeholder}
                                                                                    />
                                                                                ))}
                                                                                {(() => {
                                                                                    switch (true) {
                                                                                        case this.state.matchProvidersCount <= 3:
                                                                                            return <Subheadline className="ml-8px" style={{ color: "rgba(15, 18, 26, 0.6)" }}>{`${this.state.matchProvidersCount} tutors available`}</Subheadline>
                                                                                        case this.state.matchProvidersCount > 3:
                                                                                            return <Subheadline className="ml-8px" style={{ color: "rgba(15, 18, 26, 0.6)" }}>{`+${this.state.matchProvidersCount - 3} tutors available`}</Subheadline>
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        )
                                                                    default:
                                                                        return (
                                                                            <div className="d-flex align-items-center">
                                                                                <AvatarImage
                                                                                    size="md"
                                                                                    src={this.props.matchProvider?.actualObject?.photo?.actualObject?.preview || ic_portrait_placeholder}
                                                                                />

                                                                                <Subheadline className="text-dark ml-8px">{this.props.matchProvider?.actualObject?.name || "-"}</Subheadline>
                                                                            </div>
                                                                    )
                                                                }
                                                            })()}

                                                        </div>
                                                    )
                                                default:
                                                    return (
                                                        <div>
                                                            <div>
                                                                <SelectTutor onSelectedTypeChange={(tutorSelectedType) => this.setState({ tutorSelectedType, shouldCollapseTutor: true })} subject={this.state.selectedSubject} preferredTutor={this.props.matchProvider as OMReference<SchTutorProfile>} onTutorsChange={(firstThreeMatchProviders, matchProvidersCount) => { this.setState({ firstThreeMatchProviders, matchProvidersCount: matchProvidersCount || 0 })}} />
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                        })()}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="card bg-yellow text-dark" style={{ boxShadow: "none" }}>
                                            <div className="card-body">
                                                <Headline className="d-block mb-12px font-weight-bold">Tips</Headline>
                                                <Subheadline className="d-block card-text" style={{ color: "rgba(15, 18, 26, 0.6)" }}>Put on your earphones for better audio quality.</Subheadline>
                                                <Subheadline className="d-block card-text" style={{ color: "rgba(15, 18, 26, 0.6)" }}>Please be patient, your tutor will help yoou soon.</Subheadline>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="mb-2" />

                            {this.state.serviceTier
                                ? <Subheadline className="d-block text-center mb-8px font-weight-medium">{`${this.state.serviceTier?.durationMins} Minutes ${this.state.serviceTier?.title} Session`}</Subheadline>
                                : <Subheadline className="d-block text-center mb-8px font-weight-medium">Please select a subject.</Subheadline>
                            }

                            <Title2 className="d-block text-center mb-12px font-weight-bold">
                                {(this.state.serviceTier?.serviceGrossCents || 0) / 100}
                                <img className="ml-2" style={{ height: 20 }} src={ic_honeycomb_filled} alt="ic_honeycomb_filled" />
                            </Title2>

                            <div className="d-flex justify-content-center mb-2" style={{ paddingLeft: 20, paddingRight: 20 }}>
                                <LoadingButton
                                    disabled={!this.state.selectedSubject || (this.state.matchProviders?.length ?? 0) < 1}
                                    type="submit"
                                    isLoading={machineState.name === "saving"}
                                    className="btn btn-block btn-accent"
                                >
                                    Ask Now
                                </LoadingButton>
                            </div>

                            <Footnote className="d-block text-center mb-8px text-secondary font-weight-medium">
                                {profile?.sessionWallet?.actualObject?.transactionsCount === 0
                                    ? "First session FREE on us! 🥳"
                                    : <TokensLeftCaption />
                                }
                            </Footnote>

                        </Form>
                    </>
                )
            case "showingMeetingDetail":
                window.location = `/${CnxMeeting.Path}/${machineState.meeting.id}/lounge` as any
                return null
            case "completed":
                if (this.state.meeting) {
                    window.location = `/${CnxMeeting.Path}/${this.state.meeting.id}/lounge` as any
                } else {
                    this.props.history.goBack()
                }
                // this.state.meeting
                return null
        }
    }

    private subscriptionCallback = (machineState: CnxBookingAskFormStateMachine.State) => {
        this.setState({
            machineState,

            cnxBooking: this.state.cnxBooking || machineState.name === "presenting" && machineState.item || undefined,
        })
    }

    private checkFormComplete = (): boolean => {
        if (!this.state.cnxBooking) {
            return false
        }

        return true
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()
        this.save()
    }

    private onMeetingChange = (meeting?: CnxMeeting) => {
        this.setState({ meeting, meetingRef: meeting && new OMReference(CnxMeeting, meeting.id) })
    }

    private onConsumerChange = (consumerRef?: OMReference<CnxConsumerProfile>) => {
        this.setState({ consumerRef })
    }

    private onServiceTierChange = (serviceTierRef?: OMReference<CnxServiceTier>) => {
        this.setState({ serviceTierRef })
    }

    private onCnxBookingChange = (cnxBooking?: CnxBooking) => {
        this.setState({ cnxBooking })
    }

    private onChange = (keyPath: keyof CnxBookingAskFormComponentState) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({
                [keyPath]: event.target.value,
            } as any)
        }

    private save = async () => {
        const profile = getProfile() as CnxConsumerProfile

        if (!profile) {
            throw new Error("Expecting profile to be defined.")
        }

        const serviceTier = this.state.serviceTier

        if (!serviceTier) {
            throw new Error("Expecting serviceTier to be defined.")
        }

        const meeting = new CnxMeeting({
            serviceTier: new OMReference(CnxServiceTier, serviceTier.id),
            maxBids: serviceTier.maxBids,
            minBids: serviceTier.minBids,
            maxBookings: serviceTier.maxBookings,
            minBookings: serviceTier.minBookings,
            serviceTimingType: serviceTier.serviceTimingType,
            serviceLeadType: serviceTier.serviceLeadType,

            serviceCategories: this.state.selectedSubject && [this.state.selectedSubject],
            matchProviders: this.state.matchProviders, /* TODO: default to preferred provider if none is selected */
        })

        const booking = new CnxBooking({
            meeting: new OMReference(CnxMeeting, meeting.id),
            consumer: new OMReference(CnxConsumerProfile, getProfile()!.id)
        })

        meeting.bookings = [new OMReference(CnxBooking, booking.id)]
        OMUniverse.shared.touch(meeting)

        /* TODO: disable saving if tutor type is not selected (e.g. preferred, favourite, any available) */
        this.machine.save(booking)
    }
}

export default withContextConsumer(CategoriesParamsContext)(CnxBookingAskForm)
