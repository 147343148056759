//  CnxProviderChargeback.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxBid,
    CnxBooking,
    CnxRating,
} from ".."
import { WltTransaction, WltTransactionProperties } from "../Wallet"

export interface CnxProviderChargebackProperties extends WltTransactionProperties {
    chargebackReason?: string

    bid?: OMReference<CnxBid>
    booking?: OMReference<CnxBooking>
    rating?: OMReference<CnxRating>
}

class CnxProviderChargeback extends WltTransaction implements CnxProviderChargebackProperties {

    public static Type: string = "cnx_provider_chargeback"
    public static Path: string = "cnx_provider_chargebacks"

    public chargebackReason?: string

    public bid?: OMReference<CnxBid>
    public booking?: OMReference<CnxBooking>
    public rating?: OMReference<CnxRating>

    constructor(object: CnxProviderChargebackProperties) {
        super(object)

        this.chargebackReason = object.chargebackReason

        this.bid = object.bid && new OMReference(CnxBid, object.bid)
        this.booking = object.booking && new OMReference(CnxBooking, object.booking)
        this.rating = object.rating && new OMReference(CnxRating, object.rating)
    }
}

export default CnxProviderChargeback
