//  CnxMemberProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    Profile,
    ProfileProperties,
    User,
    Article,
    ProfilePhoto,
    CnxRating,
    CnxMessage,
    CnxMoneyWallet,
    OMProfile,
    CnxPointWallet,
} from ".."
import CnxMeeting from "./CnxMeeting"

export interface CnxMemberProfileProperties extends ProfileProperties {
    name?: string

    ratings?: Array<OMReference<CnxRating>>
    messages?: Array<OMReference<CnxMessage>>
    invitedBy?: OMReference<CnxMemberProfile>
    invitees?: OMReference<CnxMemberProfile>[]
    moneyWallet?: OMReference<CnxMoneyWallet>
    pointWallet?: OMReference<CnxPointWallet>
    currentMeeting?: OMReference<CnxMeeting>
}

class CnxMemberProfile extends OMProfile implements CnxMemberProfileProperties {

    public static Type: string = "cnx_member_profile"
    public static Path: string = "cnx_member_profiles"

    public name?: string

    public ratings: Array<OMReference<CnxRating>>
    public messages: Array<OMReference<CnxMessage>>
    public invitedBy?: OMReference<CnxMemberProfile>
    public invitees?: OMReference<CnxMemberProfile>[]
    public moneyWallet?: OMReference<CnxMoneyWallet>
    public pointWallet?: OMReference<CnxPointWallet>
    public currentMeeting?: OMReference<CnxMeeting>

    constructor(object: CnxMemberProfileProperties) {
        super(object)

        this.name = object.name

        this.ratings = object.ratings ? object.ratings.map((item) => new OMReference(CnxRating, item)) : []
        this.messages = object.messages ? object.messages.map((item) => new OMReference(CnxMessage, item)) : []
        this.invitedBy = object.invitedBy && new OMReference(CnxMemberProfile, object.invitedBy)
        this.invitees = object.invitees ? object.invitees.map((item) => new OMReference(CnxMemberProfile, item)) : []
        this.moneyWallet = object.moneyWallet && new OMReference(CnxMoneyWallet, object.moneyWallet)
        this.pointWallet = object.pointWallet && new OMReference(CnxPointWallet, object.pointWallet)
        this.currentMeeting = object.currentMeeting && new OMReference(CnxMeeting, object.currentMeeting)
    }

    public get isProfileComplete(): boolean {
        return !!this.name // TODO: add more conditions
    }
}

export default CnxMemberProfile
