//  SchTopupPackage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-07-23
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    CnxTopupPackage,
    CnxTopupPackageProperties,
    CnxTopupPayment,
    GenImage,
} from ".."

export interface SchTopupPackageProperties extends CnxTopupPackageProperties {
    baseSessions: number
    bonusSessions: number
    textColor?: string
    promoText?: string

    icon?: OMReference<GenImage>
    backgroundImage?: OMReference<GenImage>
}

class SchTopupPackage extends CnxTopupPackage implements SchTopupPackageProperties {

    public static Type: string = "sch_topup_package"
    public static Path: string = "sch_topup_packages"

    public baseSessions: number
    public bonusSessions: number
    public textColor?: string
    public promoText?: string

    public icon?: OMReference<GenImage>
    public backgroundImage?: OMReference<GenImage>

    constructor(object: SchTopupPackageProperties) {
        super(object)

        this.baseSessions = object.baseSessions
        this.bonusSessions = object.bonusSessions
        this.textColor = object.textColor
        this.promoText = object.promoText

        this.icon = object.icon && new OMReference(GenImage, object.icon)
        this.backgroundImage = object.backgroundImage && new OMReference(GenImage, object.backgroundImage)
    }
}

export default SchTopupPackage
