//  CnxProviderPayout.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2020-05-01
//  Copyright © 2020 Apptivity Lab. All rights reserved.
//

import {
    OMReference,
    OMUniverse,
} from "firmament-node-sdk"
import moment, { Moment } from "moment"
import {
    WltTransaction,
    WltTransactionProperties,
    WltWallet,
} from ".."

type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
type TransactionType = "DEBIT" | "CREDIT"
export interface CnxProviderPayoutProperties extends WltTransactionProperties {
    approvedAt?: Moment
    referenceNumber?: string
    rejectedAt?: Moment
    rejectionReason?: string

}

class CnxProviderPayout extends WltTransaction implements CnxProviderPayoutProperties {

    public static Type: string = "cnx_provider_payout"
    public static Path: string = "cnx_provider_payouts"

    public approvedAt?: Moment
    public referenceNumber?: string
    public rejectedAt?: Moment
    public rejectionReason?: string


    constructor(object: CnxProviderPayoutProperties) {
        super(object)

        this.approvedAt = moment(object.approvedAt)
        this.referenceNumber = object.referenceNumber
        this.rejectedAt = moment(object.rejectedAt)
        this.rejectionReason = object.rejectionReason

    }
}

export default CnxProviderPayout
