import * as React from "react"

import { Logo } from "./Logo"
import { BuildNumber } from "../components"

export interface Error404ComponentProps {

}

export interface Error404ComponentState {

}

class Error404 extends React.PureComponent<Error404ComponentProps, Error404ComponentState> {
    public render() {
        return(
            <div className="fullscreen fullscreen-minus-nav">
                <div className="fullscreen__section fullscreen__section--center">
                    <div className="fullscrenn__section__child" style={{ width: 420 }}>
                        <Logo />

                        <h5 className="text-center">
                            Error 404
                        </h5>

                        <BuildNumber />
                    </div>
                </div>
            </div>
        )
    }
}

export { Error404 }
