import {
    OMObject,
    OMObjectProperties,
    OMUniverse,
} from "firmament-node-sdk"

export interface SettingProperties extends OMObjectProperties {
    key: string
    value?: string
    environment?: string
    bundleIdentifier?: string
    maxBuildNumber?: string
    minBuildNumber?: string
    platform?: string
    module?: string
}

class Setting extends OMObject implements SettingProperties {

    public static Type: string = "setting"
    public static Path: string = "settings"

    public key: string
    public value?: string
    public environment?: string
    public bundleIdentifier?: string
    public maxBuildNumber?: string
    public minBuildNumber?: string
    public platform?: string
    public module?: string

    constructor(object: SettingProperties) {
        super(object)
        this.key = object.key
        this.value = object.value
        this.environment = object.environment
        this.bundleIdentifier = object.bundleIdentifier
        this.maxBuildNumber = object.maxBuildNumber
        this.minBuildNumber = object.minBuildNumber
        this.platform = object.platform
        this.module = object.module
    }

    public static find(key: string): Setting | undefined {
        // Check if object exists in memory cache
        const memoryCachedObjects = OMUniverse.shared.cache.getState() as { [_: string]: any | undefined }

        let setting: any | undefined

        // find setting from memory cache
        setting = Object.values(memoryCachedObjects).find((obj) => obj.type === Setting.Type && obj.key === key)
        if (!setting) {
            // find setting from persist store
            const persistedLocalStorage = (OMUniverse.shared as any).persistedLocalStorage as { [_: string]: any }
            setting = Object.values(persistedLocalStorage).find((obj) => obj.type === Setting.Type && obj.key === key)
        }

        if (setting) {
            return new Setting(setting)
        }
    }
}

export default Setting
