import React from "react"
import ReactDOM from "react-dom"

export interface ModalBackdropComponentProps {
    show: boolean
    animating: boolean
}

export interface ModalBackdropComponentState {

}

class ModalBackdrop extends React.Component<ModalBackdropComponentProps, ModalBackdropComponentState> {
    get modalBackdropClassName() {
        if (!this.props.show && this.props.animating) {
            return "modal-backdrop fade"
        } else {
            return "modal-backdrop fade show"
        }
    }

    public render() {
        if (this.props.show) {
            return ReactDOM.createPortal(
                <div className={this.modalBackdropClassName} />,
                document.body,
            )
        }

        return null
    }
}

export default ModalBackdrop
